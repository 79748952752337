// ========================================================================
// Component: PromoBanner
// ========================================================================

.ni__promo-banner--content {
  background-color: var(--ni-green);
  display: flex;
  align-items: center;
  padding: 20px;

  h2 {
    color: var(--off-white);
    font-weight: 500;
    margin: 0;
  }
}

.ni__promo-banner--image .parbase div > div {
  width: 100%;
  height: 162px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

.ni__promo-banner--button {
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--forrest-green);

  a:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='25' viewBox='0 0 18 25'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='#f4f4f4' stroke-width='3'%3E%3Cpath d='M0.5 8.5L9 17.5 17.5 8.5' transform='translate(-605 -63) rotate(-90 343 -258)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 15px;
    height: 23px;
    right: 0;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-left: 15px;
  }

  a,
  .ni__rich-text a,
  .richtext a {
    text-decoration: underline;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 30px;

    &:hover:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='25' viewBox='0 0 18 25'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='#03b585' stroke-width='3'%3E%3Cpath d='M0.5 8.5L9 17.5 17.5 8.5' transform='translate(-605 -63) rotate(-90 343 -258)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

@include media-breakpoint-up(sm) {
  .ni__promo-banner {
    max-width: 584px;
    margin: 0 auto;
    overflow: hidden;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .ni__promo-banner--image .parbase div > div {
    height: 260px;
  }

  .ni__promo-banner--content {
    padding: 30px 24px;
  }

  .ni__promo-banner--button {
    height: 108px;
  }
}

@include media-breakpoint-up(md) {
  .ni__promo-banner {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 9px;
    border-top-right-radius: 9px;
    display: flex;
    max-height: 115px;
    max-width: 826px;
  }

  .ni__promo-banner--image .parbase div > div {
    height: 115px;
    min-width: 188px;
    width: 188px;
  }

  .ni__promo-banner--content {
    min-width: 307px;
    padding-bottom: 0;
    padding-top: 0;
    width: 307px;
  }

  .ni__promo-banner--button {
    height: 115px;
    min-width: 331px;
    width: 331px;
  }
}

@include media-breakpoint-up(lg) {
  .ni__promo-banner {
    max-width: 1110px;
  }

  .ni__promo-banner--image .parbase div > div {
    min-width: 254px;
    width: 254px;
  }

  .ni__promo-banner--content {
    width: 410px;
    padding-left: 30px;
  }

  .ni__promo-banner--button {
    min-width: 446px;
    width: 446px;
  }
}
