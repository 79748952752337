$niGreen: '03b585';
$offWhite: 'f4f4f4';
$powerGren: '32eb96';

@mixin backgroundImage($color) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23'%3E%3Cg fill='none'%3E%3Cpath d='M0 0h23v23H0z'/%3E%3Cpath fill='%23#{$color}' d='M19.167 14.375a.957.957 0 111.916 0v3.833a2.875 2.875 0 01-2.875 2.875H4.792a2.875 2.875 0 01-2.875-2.875v-3.833a.957.957 0 111.916 0v3.833c0 .53.43.959.959.959h13.416a.96.96 0 00.959-.959v-3.833zm-8.625-2.314V2.875a.957.957 0 111.916 0v9.186l3.156-3.155a.957.957 0 011.355 0 .957.957 0 010 1.355l-4.791 4.792a.96.96 0 01-1.356 0L6.031 10.26a.957.957 0 010-1.355.957.957 0 011.355 0l3.156 3.155z'/%3E%3C/g%3E%3C/svg%3E");
}

.ni-collapsable-container {
  margin-bottom: 28px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 44px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 18px;
  }

  .ni__body-text--3 {
    color: var(--forrest-green);
  }

  .trigger {
    color: #044a27;
    display: block;
    margin-bottom: 28px;
    margin-top: 6px;
    position: relative;
    text-decoration: none;
    transition: all 150ms ease-out;

    @include media-breakpoint-up(sm) {
      margin-bottom: 18px;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 25'%3E%3Cpath fill='%2303B585' d='M12.92 10.232l3.997 3.995c.392.392.392 1.026 0 1.418-.188.188-.443.293-.709.293H8.214c-.554 0-1.003-.448-1.003-1.002 0-.266.106-.52.294-.709l3.997-3.995c.391-.391 1.026-.391 1.418 0z'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      content: '';
      height: 25px;
      position: absolute;
      top: -3px;
      transform: rotate(180deg);
      width: 25px;
    }

    &[aria-expanded='true'] {
      margin-bottom: 22px;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 25'%3E%3Cpath fill='%2303B585' d='M12.92 10.232l3.997 3.995c.392.392.392 1.026 0 1.418-.188.188-.443.293-.709.293H8.214c-.554 0-1.003-.448-1.003-1.002 0-.266.106-.52.294-.709l3.997-3.995c.391-.391 1.026-.391 1.418 0z'/%3E%3C/svg%3E%0A");
        transform: rotate(0);
      }
    }
  }

  .collapsable {
    display: block;
    max-height: 119px;
    overflow: hidden;
    transition: all 350ms;

    @include media-breakpoint-up(sm) {
      max-height: 70px;
    }

    @include media-breakpoint-up(md) {
      max-height: 100px;
    }

    @include media-breakpoint-up(lg) {
      max-height: 73px;
    }

    .description {
      display: block;
      line-height: 24px !important;
      margin-bottom: 32px !important;
    }

    h3 {
      display: inline-block;
      width: 100%;
    }

    ul {
      list-style: none;
      margin-bottom: 32px;
      padding-left: 0;

      li {
        color: var(--forrest-green);
        font-family: FoundersGrotesk;
        font-size: 18px;
        letter-spacing: normal;
        line-height: 1.33;
        margin-bottom: 10px;
        padding-left: 20px;
        position: relative;

        &:before {
          background-color: var(--ni-green);
          background-size: contain;
          content: '';
          display: inline-block;
          height: 8px;
          left: 0;
          position: absolute;
          top: 8px;
          width: 8px;
        }
      }
    }

    .bottom {
      line-height: 0;

      .items {
        color: #354033;
      }

      .list-inline {
        font-size: 0;

        span {
          color: #354033;
          font-size: 16px;
          line-height: 22px;

          &:first-of-type {
            padding-left: 6px;
          }

          &.divider {
            padding: 0 6px;

            &:last-of-type {
              display: none;
            }
          }
        }
      }
    }

    &.show {
      max-height: 100vh;
    }
  }

  .ni-btn {
    margin-top: 12px;
    padding: 5px 14px 5px 44px;
    position: relative;

    @include media-breakpoint-up(sm) {
      padding: 5px 28px 5px 56px;
    }

    &::before {
      @include backgroundImage($niGreen);

      background-repeat: no-repeat;
      content: '';
      height: 23px;
      left: 14px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 22px;

      @include media-breakpoint-up(sm) {
        left: 26px;
      }
    }

    &:hover {
      color: #f4f4f4 !important;

      &::before {
        @include backgroundImage($offWhite);
      }
    }

    &:active {
      &::before {
        @include backgroundImage($powerGren);
      }
    }
  }
}
