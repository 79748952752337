.ni_multi-cta-banner {
  > .ni__container {
    background-color: var(--off-white);
    padding-bottom: 40px;
    padding-top: 40px;

    @include media-breakpoint-up(sm) {
      border-top-left-radius: 40px;
      max-width: 584px;
    }

    @include media-breakpoint-up(md) {
      max-width: 828px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1110px;
    }

    .ni__offset-md-1 {
      @include media-breakpoint-up(md) {
        padding: 0 1.98px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 2px;
      }
    }

    .items {
      justify-content: space-between;

      .ni__col-md-4,
      .ni__col-md-6 {
        &:last-child {
          .ni_multi-cta-banner-item {
            margin-bottom: 0;
          }
        }

        .ni_multi-cta-banner-item {
          display: block;
          margin-bottom: 47px;

          @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
            padding: 0 30px;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
            padding: 0;
          }
        }
      }

      .ni__col-md-4 {
        @include media-breakpoint-up(md) {
          max-width: 286px;
        }
      }

      .ni_multi-cta-banner-item {
        @include media-breakpoint-up(md) {
          min-width: 331px;
        }
      }
    }
  }

  .headline,
  .subheadline,
  .text {
    color: var(--forrest-green);
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .headline {
    font-family: FinancierDisplay;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      padding: 0 30px;
      font-size: 24px;
      margin-bottom: 13px;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .subheadline {
    font-family: FoundersGrotesk;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 32px;
      padding: 0 30px;
    }

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .ni_multi-cta-banner-item {
    display: block;
    margin-bottom: 47px;

    img {
      margin-bottom: 24px;
      margin-top: 0;
      max-height: 127px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 32px;
        max-height: 184px;
      }

      @include media-breakpoint-up(md) {
        max-height: 125px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 24px;
        max-height: 169px;
      }
    }

    .text {
      border-bottom: 1px solid var(--pale-green);
      display: block;
      font-family: FoundersGrotesk;
      font-size: 18px;
      overflow: hidden;
      padding-bottom: 19px;
      position: relative;
      transition: 100ms ease-in-out;
      white-space: nowrap;

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        content: '';
        height: 10.8px;
        left: auto;
        position: absolute;
        right: 0;
        bottom: 26.2px;
        white-space: nowrap;
        width: 13.2px;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }
    }

    &:hover {
      .text {
        color: var(--ni-green);
      }
    }
  }

  // Theme
  &.extended {
    > .ni__container {
      background-color: var(--off-white);
      padding-bottom: 61px;
      padding-top: 16px;

      @include media-breakpoint-up(sm) {
        padding-bottom: 60px;
        padding-top: 40px;
      }

      @include media-breakpoint-up(sm) {
        padding-bottom: 46px;
        padding-top: 40px;
      }
    }

    .items {
      .ni__col-md-4,
      .ni__col-md-6 {
        &:last-child {
          .ni_multi-cta-banner-item {
            margin-bottom: 0;
          }
        }

        .ni_multi-cta-banner-item {
          display: block;
          margin-bottom: 36px;

          @include media-breakpoint-up(sm) {
            margin-bottom: 40px;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
            max-width: 189px;
            min-width: 0;
          }

          @include media-breakpoint-up(lg) {
            max-width: 255px;
            min-width: 0;
          }
        }
      }
    }
  }
}
