// ========================================================================
// Component: Typography
// ========================================================================

p {
  margin: 0;
}

// @see https://organic-inc.atlassian.net/browse/NC-2299
ul {
  list-style-type: square;
}

ul > li::marker {
  color: var(--ni-green);
}
