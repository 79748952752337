// ========================================================================
// Component: Category
// ========================================================================

.ni__category {
  @extend .ni__button;
  border-width: 1px;
  height: 32px;
  line-height: 32px;
  font-size: 18px;
  font-weight: normal;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-color: var(--power-green);
  padding-left: 15px;
  padding-right: 15px;
  min-width: auto;
  padding-top: 1px;

  &:focus {
    outline-color: #007bff;
    outline-style: auto;
    outline-width: 1px;
  }

  &.is-ni__category--active {
    font-weight: 500;
    background-color: var(--power-green);
  }
}

.ni__category__close {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  pointer-events: none;
  color: var(--ni-green);

  svg {
    display: block;
    width: 8px;
    height: 8px;
  }

  &:hover {
    color: var(--forrest-green);
  }
}
