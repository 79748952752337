// ========================================================================
// Component: Legal Line
// ========================================================================
// Note: Don't remove !important bangs; it's needed to override
// Perspective Article .ni__rich-text stuff

// settings => misc
$LegalLine-Component-TopMargin: 30px;
$LegalLine-Logo-MaxWidth: 160px;
$LegalLine-Logo-TopSpacing: 12px;

// settings => text
$LegalLine-Component-FontColor: var(--forrest-green);
$LegalLine-Component-FontFamily: map-get($fonts, 'sans');
$LegalLine-Component-FontSize: 12px;
$LegalLine-Component-LineHeight: 1.33;

.ni__legal-line,
.ni__rich-text .ni__legal-line {
  margin: $LegalLine-Component-TopMargin auto 0;
  width: 100%;
  max-width: 375px !important;

  @include media-breakpoint-up(sm) {
    max-width: 720px !important;
  }

  @include media-breakpoint-up(md) {
    max-width: 1024px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1440px !important;
  }

  > div {
    margin: 0 auto;
    max-width: 334px;

    @include media-breakpoint-up(sm) {
      max-width: 484px;
    }

    @include media-breakpoint-up(md) {
      max-width: 826px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1110px;
    }
  }

  p {
    color: $LegalLine-Component-FontColor;
    font-family: $LegalLine-Component-FontFamily;
    font-size: $LegalLine-Component-FontSize !important;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: $LegalLine-Component-LineHeight !important;
    margin: 0;
  }

  img {
    display: block;
    height: auto;
    margin: $LegalLine-Logo-TopSpacing 0 0 0;
    max-width: $LegalLine-Logo-MaxWidth;
    width: 100%;
  }

  img + img {
    margin-top: calc(#{$LegalLine-Logo-TopSpacing} - 2px);
  }
}
