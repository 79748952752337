.ni__perspective-article-background-content {
  background: var(--forrest-green);
  color: var(--white-three);
  padding: 72px 0 220px 0;
  margin: 176px 0;
  font-size: 18px;
  position: relative;
  display: none;

  @include breakpoint('desktop large') {
    display: block;
  }

  &-container {
    margin: 0 auto;
  }

  &-eyebrow {
    font-family: map-get($fonts, 'mono');
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 0 46px;
    text-transform: uppercase;
  }

  &-title {
    font-family: map-get($fonts, 'sans');
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin: 0 0 75px;
  }

  &-text {
    font-family: map-get($fonts, 'sans');
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin: 0 0 43px;
  }

  &-image {
    position: absolute;
    width: 699px;
    height: 566px;
    right: 0;
    top: calc(220px - 58px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
