// ========================================================================
// Component: Nav Content Resources Systems
// ========================================================================

// prettier-ignore
.ni__nav-content-resources-systems {
  color: var(--forrest-green);

  h2, h3, p {
    color: var(--forrest-green);
  }

  h2 {
    margin-bottom: 24px;
  }

  h2 + p {
    margin-bottom: 40px;
  }

  .ni__button--link,
  .ni__visible--\@desktop a,
  .ni__hidden--\@desktop a {
    display: block;
    position: relative;
    width: 100%;
    font-size: 18px;
    line-height: 3.17;
    @extend .ni__button--link;
  }

  // aem tweaks
  .ni__visible--\@desktop,
  .ni__hidden--\@desktop {
    span {
      display: block;
      width: 100%;
    }
  }

  .ni__molecule-content-module {
    margin-bottom: 16px;
    @include breakpoint('small') { margin-bottom: 40px; }
  }

  .ni__molecule-content-module-body-container h3 {
    margin-top: 0;
  }

  .ni__molecule-content-module-body-container .richtext a {
    @include type-class('.ni__eyebrow--3');
  }
}

// Icon based on -item class
// ========================================================================
// prettier-ignore
.ni__nav-content-resources-systems {
  .ni__molecule-content-module[class*='ni__content-module--'] a {
    display: block;
    padding-left: 30px;
    padding-top: 2px;
    position: relative;
    width: 100%;

    @include breakpoint('desktop') { padding-left: 36px; }
  }

  //_____________________________________
  // icon => download
  .ni__content-module--download.ni__molecule-content-module a {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H23V23H0z' transform='translate(-371 -406) translate(101) translate(246 389) translate(24 17)'/%3E%3Cpath fill='%2303B585' d='M19.167 14.375c0-.53.429-.958.958-.958.53 0 .958.429.958.958v3.833c0 1.588-1.287 2.875-2.875 2.875H4.792c-1.588 0-2.875-1.287-2.875-2.875v-3.833c0-.53.429-.958.958-.958.53 0 .958.429.958.958v3.833c0 .53.43.959.959.959h13.416c.53 0 .959-.43.959-.959v-3.833zm-8.625-2.314V2.875c0-.53.429-.958.958-.958.53 0 .958.429.958.958v9.186l3.156-3.155c.374-.375.981-.375 1.355 0 .375.374.375.98 0 1.355l-4.791 4.792c-.375.374-.981.374-1.356 0L6.031 10.26c-.375-.374-.375-.981 0-1.355.374-.375.98-.375 1.355 0l3.156 3.155z' transform='translate(-371 -406) translate(101) translate(246 389) translate(24 17)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 23px;
      left: 0;
      position: absolute;
      top: 0;
      width: 22px;
    }
  }

  //_____________________________________
  // icon => paper
  .ni__content-module--paper.ni__molecule-content-module a {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3Cpath fill='%2303B585' d='M13 4.18V8c0 .552.448 1 1 1h3.82c-.098-.216-.235-.415-.406-.586l-3.828-3.828c-.171-.171-.37-.308-.586-.406zM18 11h-4c-1.657 0-3-1.343-3-3V4H8c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h8c1.105 0 2-.895 2-2v-7zm-5.328-9c.53 0 1.039.21 1.414.586l5.328 5.328c.375.375.586.884.586 1.414V18c0 2.21-1.79 4-4 4H8c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h4.672z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 21px;
      left: 0;
      position: absolute;
      top: 0;
      width: 19px;

      @include breakpoint('small') { height: 22px; }
    }
  }

  //_____________________________________
  // icon => video
  .ni__content-module--video.ni__molecule-content-module a {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3Cpath fill='%2303B585' d='M5.564 6h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v6.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267-.327-.174-.583-.43-.757-.756-.174-.326-.267-.65-.267-1.54V8.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267zM17 10.333l2.36-1.966c.424-.354 1.055-.296 1.408.128.15.18.232.406.232.64v5.73c0 .552-.448 1-1 1-.234 0-.46-.082-.64-.232L17 13.667v-3.334z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: 13px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 19px;

      @include breakpoint('small') { height: 15px; }
    }
  }
}
