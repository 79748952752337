// ========================================================================
// Component: SysClientInfo
// ========================================================================

.ni__block-quote {
  padding-top: 30px;
  @include media-breakpoint-up(sm) {
    padding-top: 40px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }
  
  .blockquote-align-left {
    width: 100%;
    padding: 0 20px 0 20px;
    display: flex;
  }
  
  .blockquote-align-right {
    width: 100%;
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: end;
  }
}

.ni__block-quote--content {
  margin: 0 auto;
  max-width: 90%;
}

.ni__block-quote h4 {
  font-family: FinancierDisplay;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  margin-bottom: 16px;
  text-align: left;
  position: relative;

  &:after,
  &:before {
    background-color: var(--ni-green);
    content: '';
    display: block;
    height: 15px;
    position: absolute;
    width: 15px;
  }

  &:before {
    border-top-left-radius: 300px;
    left: -15px;
    top: -15px;
  }

  &:after {
    border-bottom-right-radius: 300px;
    bottom: -15px;
    right: -15px;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 15px;
    &:after,
    &:before {
      height: 30px;
      width: 30px;
    }

    &:before {
      left: -30px;
      top: -30px;
    }

    &:after {
      bottom: -30px;
      right: -30px;
    }
  }

  @include media-breakpoint-up(md) {
    &:after,
    &:before {
      height: 40px;
      width: 40px;
    }

    &:before {
      left: -40px;
      top: -40px;
    }

    &:after {
      bottom: -40px;
      right: -40px;
    }
  }
}

.ni__block-quote--author {
  font-family: FoundersGrotesk;
  font-size: 14px;
  line-height: 16px;
}

.ni__block-quote--name {
  padding-right: 56px;
  text-align: right;
  width: 100%;
  span {
    font-size: 14px;
  }
}

.ni__block-quote--author  {
  padding-right: 1rem;
  font-family: FoundersGrotesk;
  font-size: 14px;
  line-height: 16px;
}


.blockquote-align-left {
  margin: 0 auto;
  max-width: 90%;
  display: flex;
}

.blockquote-align-right {
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  justify-content: end;
}

@include media-breakpoint-up(sm) {
  .ni__block-quote {
    margin: 0 auto;
  }
}

@include media-breakpoint-up(md) {
  .ni__block-quote--content {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }
  
  .ni__block-quote {
    display: inline-block;
    padding: 40px 40px 0 40px;
  }
  
  .ni__block-quote--content, .blockquote-align-left, .blockquote-align-right {
    margin: auto 20px auto 20px;
  }
}
