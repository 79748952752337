// ========================================================================
// Component: PerspectiveBanner
// ========================================================================

.ni__perspective-banner {
  width: 100%;
  background-color: var(--ni-green);
  padding-top: 45px;
  padding-bottom: 64px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 60px;

  .ni__row {
    align-items: center;
  }
}

.ni__perspective-banner__image {
  display: none;
  height: 302px;
  max-width: 450px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ni__perspective-banner__num {
  display: flex;
  align-items: center;
  span {
    font-size: 45px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--white-three);
    text-transform: uppercase;
    font-family: map-get($fonts, 'mono');
  }

  &:after {
    margin-left: 12px;
    content: '';
    height: 3px;
    background-color: var(--forrest-green);
    width: 114px;
  }
}

.ni__perspective-banner__title {
  font-size: 140px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: -0.1px;
  color: var(--forrest-green);
  font-family: map-get($fonts, 'mono');
  padding-bottom: 28px;
}

.ni__perspective-banner__text {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--forrest-green);
  font-family: map-get($fonts, 'sans');
  padding-bottom: 28px;
}

//sm up
@include media-breakpoint-up(sm) {
  .ni__perspective-banner {
    margin-top: 80px;
    // margin-bottom: -40px;
  }
}

// table up
@include media-breakpoint-up(md) {
  .ni__perspective-banner {
    margin-top: 120px;
    // margin-bottom: -20px;
  }
}

// desktop up
@include media-breakpoint-up(lg) {
  .ni__perspective-banner {
    padding: 0;
    margin-top: 176px;
    margin-bottom: 0px;
  }

  .ni__perspective-banner__image {
    display: block;
  }

  .ni__perspective-banner__container {
    display: flex;
    max-width: map-get($breakpoints, 'grid');
    margin: 0 auto;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .ni__perspective-banner__100 {
    max-width: 260px;
    margin: 0 auto;
  }

  .ni__perspective-banner__text {
    font-size: 18px;
    line-height: 1;
    max-width: 256px;
  }
}
