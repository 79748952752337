.ni__eyebrow_long_form {
  .ni__eyebrow {
    color: var(--forrest-green);
    padding-bottom: 40px;
    text-align: left;
    span {
      font-size: 12px;
    }
    @include type-class('.ni__eyebrow--5');
    @include media-breakpoint-up(sm) {
      padding-bottom: 53px;
      span {
        font-size: 14px;
      }
    }
  }
}
