// ========================================================================
// Molecules: Product & Services Card
// ========================================================================

$NI__ProductAndServicesCard--BackgroundColor-Default: var(--off-white);
$NI__ProductAndServicesCard--BackgroundColor-Click: var(--forrest-green);

$NI__ProductAndServicesCard--Color-Default: var(--forrest-green);
$NI__ProductAndServicesCard--Color-Click: var(--power-green);

$NI__ProductAndServicesCard--IconSize: 48px;
$NI__ProductAndServicesCard--HeightMinimum: 96px;
$NI__ProductAndServicesCard--Padding: 24px;

$NI__ProductAndServicesCard--BorderRadius: 22px;
$NI__ProductAndServicesCard--BorderWeight: 2px;
$NI__ProductAndServicesCard--BorderColor-Default: transparent;
$NI__ProductAndServicesCard--BorderColor-Hover: var(--ni-green);
$NI__ProductAndServicesCard--BorderColor-Click: var(--power-green);

$NI__ProductAndServicesCard--TooltipAreaWidth: 66px;
$NI__ProductAndServicesCard--TooltipIconSize-Mobile: 24px;
$NI__ProductAndServicesCard--TooltipIconSize-Tablet: 16px;

// Component
// ========================================================================

.ni__product-service-card {
  background-color: $NI__ProductAndServicesCard--BackgroundColor-Default;
  border: 2px solid $NI__ProductAndServicesCard--BorderColor-Default;
  border-radius: 0;
  border-top-left-radius: $NI__ProductAndServicesCard--BorderRadius;
  height: 100%;
  max-width: none;
  min-height: calc(#{$NI__ProductAndServicesCard--HeightMinimum} - #{$NI__ProductAndServicesCard--Padding});
  padding: $NI__ProductAndServicesCard--Padding;
  position: relative;
  width: 100%;

  @include display-flex(row nowrap, center, space-between);

  &-content {
    width: 100%;
    @include display-flex(row nowrap, center, flex-start);
  }

  &-content img,
  &-content > .parbase,
  &-content > .parbase > div {
    height: $NI__ProductAndServicesCard--IconSize;
    image-rendering: pixelated;
    object-fit: contain;
    overflow: visible;
    width: $NI__ProductAndServicesCard--IconSize;
  }

  &-content-label {
    color: $NI__ProductAndServicesCard--Color-Default;
    margin-left: 12px;
    @include type-class('.ni__small-detail--1');
    line-height: 1.38 !important;
  }
}

// Component => Tooltip Area
// ========================================================================
.ni__product-service-card .ni__product-service-card-tooltip {
  &-area {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: none;
    font: inherit;
    height: 100%;
    min-height: calc(#{$NI__ProductAndServicesCard--HeightMinimum} - #{$NI__ProductAndServicesCard--Padding});
    outline: inherit;
    padding: 0;
    width: $NI__ProductAndServicesCard--TooltipAreaWidth;
    @include position-absolute(0, 0, 0, auto, 1);
  }

  &-icon {
    height: 100%;
    width: 100%;
  }

  &-icon:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg fill-rule='nonzero'%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-307 -548) translate(20 512) translate(287 36)'/%3E%3C/g%3E%3Cg fill='%2303B585'%3E%3Cpath d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm.834 10.33V14H9.088v-1.67h1.746zM10.046 6C12.002 6 13 7.072 13 8.368c0 1.059-.735 1.682-1.589 2.292-.46.35-.656.599-.67 1.01h-1.51c.014-.66.277-1.184.92-1.67.578-.436 1.116-.81 1.116-1.57 0-.623-.407-1.122-1.234-1.122-.959 0-1.418.599-1.418 1.508 0 .262.04.474.092.686l-1.615-.063C7.039 9.19 7 8.941 7 8.704 7 7.059 8.208 6 10.046 6z' transform='translate(-307 -548) translate(20 512) translate(287 36) translate(2 2)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    height: $NI__ProductAndServicesCard--TooltipIconSize-Mobile;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $NI__ProductAndServicesCard--TooltipIconSize-Mobile;

    @include breakpoint('small') {
      height: $NI__ProductAndServicesCard--TooltipIconSize-Tablet;
      width: $NI__ProductAndServicesCard--TooltipIconSize-Tablet;
    }
  }

  &-area:hover .ni__product-service-card-tooltip-icon:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg fill-rule='nonzero'%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-307 -548) translate(20) translate(0 512) translate(287 36)'/%3E%3C/g%3E%3Cg fill='%2332EB96'%3E%3Cpath d='M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm.834 10.33V14H9.088v-1.67h1.746zM10.046 6C12.002 6 13 7.072 13 8.368c0 1.059-.735 1.682-1.589 2.292-.46.35-.656.599-.67 1.01h-1.51c.014-.66.277-1.184.92-1.67.578-.436 1.116-.81 1.116-1.57 0-.623-.407-1.122-1.234-1.122-.959 0-1.418.599-1.418 1.508 0 .262.04.474.092.686l-1.615-.063C7.039 9.19 7 8.941 7 8.704 7 7.059 8.208 6 10.046 6z' transform='translate(-307 -548) translate(20) translate(0 512) translate(287 36) translate(2 2)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

// Component => Hover & Click (Focus)
// ========================================================================

.ni__cta-cards-three-column-account,
.ni__cta-cards-four-column-account {
  .ni__product-service-card {
    &:hover {
      border: 2px solid $NI__ProductAndServicesCard--BorderColor-Hover !important;
    }

    &:active {
      background: var(--forrest-green) !important;

      .ni__product-service-card-content-label {
        color: var(--power-green);
      }
    }
  }
}

.ni__cta-cards-four-column-account {
  .ni__product-service-card {
    background: var(--white-three);
  }
}

// Component => Has Tooltip Modifier
// ========================================================================
.ni__cta-cards-three-column-account-tooltip.has-tooltip {
  .ni__product-service-card {
    .ni__product-service-card-content {
      margin-right: calc(#{$NI__ProductAndServicesCard--TooltipAreaWidth} / 1.525);
    }

    .ni__product-service-card-tooltip-area {
      display: block;
    }
  }

  &.tooltip-active .ni__product-service-card {
    background-color: $NI__ProductAndServicesCard--BackgroundColor-Default;

    &-content-label {
      color: $NI__ProductAndServicesCard--Color-Default;
    }
  }
}
