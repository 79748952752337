// ========================================================================
// ABSTRACTS => DEFINITIONS => FONT FACES
// ========================================================================

// FinancierDisplay
@font-face {
  font-family: FinancierDisplay;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: FinancierDisplay;
  font-weight: 900;
  font-style: italic;
}

// FoundersGrotesk
@font-face {
  font-family: FoundersGrotesk;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: FoundersGrotesk;
  font-weight: 700;
  font-style: italic;
}

// SpaceMono
@font-face {
  font-family: SpaceMono;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SpaceMono;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: SpaceMono;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: SpaceMono;
  font-weight: 700;
  font-style: italic;
}
