// ========================================================================
// Component: LeadQuote
// ========================================================================

// settings
$NI__leadQuoteStats-color: var(--forrest-green);
$NI__leadQuoteStats-item__color: #03b585;
$NI__leadQuoteStats-font: map-get($fonts, 'serif');

.ni__lead-quote-stats {
  display: flex;
  padding: 25px 0 32px;
  flex-direction: column;

  @include breakpoint('medium') {
    padding: 93px 20px 32px;
  }
}

// mobile & desktop
// prettier-ignore
.ni__lead-quote-stats {
  &--desktop  { display: none; }
  &--mobile   { display: flex; }
  
  @include breakpoint('medium') {
    &--desktop  { display: flex;
    padding-bottom: 0; }
    &--mobile   { display: none; }
  }
}

.ni__lead-quote-stats.ni__lead-quote-stats--mobile {
  & > :nth-child(2) {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

.ni__lead-quote-stats__item {
  min-width: 118px;

  h4 {
    display: block !important;
    color: $NI__leadQuoteStats-color;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    margin: 0;
    @include type-class('.ni__number-eyebrow--2');
  }

  span {
    color: $NI__leadQuoteStats-item__color;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    @include type-class('.ni__button-text');
  }
}

// mobile up
@include breakpoint('desktop small') {
  .ni__lead-quote-stats > .ni__lead-quote-stats__item,
  .ni__lead-quote-stats.ni__lead-quote-stats--desktop > div {
    margin-bottom: 80px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
