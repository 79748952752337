.ni__modal-share-activation-code {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;

  &.show {
    display: block;
  }

  .dialog {
    height: 100%;
    margin: 0 auto;
    max-width: 375px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(sm) {
      max-width: 384px;
    }

    @include media-breakpoint-up(md) {
      max-width: 540px;
    }
  }

  .content {
    background-color: var(--white-three);
    height: 100vh;
    padding: 104px 65px 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 375px;

    @include media-breakpoint-up(sm) {
      height: 352px;
      padding-bottom: 80px;
      padding-top: 72px;
      width: 384px;
    }

    @include media-breakpoint-up(md) {
      width: 540px;
    }

    .close {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.586 12 4.293 5.707a1 1 0 0 1 1.414-1.414L12 10.586l6.293-6.293a1 1 0 1 1 1.414 1.414L13.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L10.586 12z' fill='%2303B585'/%3E%3C/svg%3E%0A");
      height: 24px;
      opacity: 1;
      position: absolute;
      right: 20px;
      top: 40px;
      width: 24px;

      @include media-breakpoint-up(sm) {
        right: 24px;
        top: 24px;
      }

      @include media-breakpoint-up(md) {
        right: 40px;
        top: 40px;
      }

      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.586 12 4.293 5.707a1 1 0 0 1 1.414-1.414L12 10.586l6.293-6.293a1 1 0 1 1 1.414 1.414L13.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L10.586 12z' fill='%23044123'/%3E%3C/svg%3E%0A");
      }

      &:focus {
        outline: none;
      }
    }

    .heading {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 32px;
      margin-bottom: 24px;
      text-align: left;
    }

    .text,
    .activation-code {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 24px;
    }

    .text {
      margin-bottom: 40px;
      strong {
        font-weight: 500;
      }
    }
  }
}
