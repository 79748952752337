.ni__gray-hero a:not(.ni__button--outline) {
  color: var(--ni-green) !important;
  text-decoration: underline !important;
}

.ni__gray-hero {
  background-color: var(--off-white);
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
}
.ni__gray-hero .image-container {
  display: none;
}

.ni__hero--content {
  margin-bottom: 8px;
  margin-right: 30.5px;
}

.ni__button--hero {
  margin-top: 21px;
  margin-bottom: 20px;
}

.ni__link--hero:not(.ni__link--hero:last-child) {
  padding-bottom: 14px;
}

.ni__gray-hero a:hover,
.ni__gray-hero .ni__link--hero a:hover {
  color: var(--forrest-green) !important;
}

.ni__gray-hero .ni__body-text--5, .ni__gray-hero .ni__h5 {
  color: var(--forrest-green);
}

.ni__education .ni__button--outline {
  text-decoration: none !important;
}

.ni__education h1 {
  margin-bottom: 12px;
}

.ni__education .ni__hero--content {
  & > p {
    min-height: 62px;
  }
}

@include media-breakpoint-up(sm) {
  .ni__education h1 {
    margin-bottom: 16px;
  }
  .ni__gray-hero .image-container {
    display: block;
  }
  .ni__gray-hero {
    padding-left: 53px;
  }
  .ni__gray-hero .ni__offset-md-1 {
    margin-left: 27px;
  }

  .ni__gray-hero {
    border-bottom-left-radius: 0;
  }

  .ni__hero--content {
    margin-bottom: 0;
    margin-right: 105px;
  }

  .ni__gray-hero.has-image {
    .ni__h5 {
      margin-top: 0px;
    }
    img {
      margin-top: 10px;
      max-width: 250px;
    }
    .ni__hero--content {
      margin-right: 0;
    }
    .image-copy {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__education h1 {
    margin-top: 60px !important;
  }
  .ni__gray-hero {
    border-bottom-left-radius: 400px;
    padding-left: 260px;
    padding-bottom: 0;
    padding-top: 0;
  }

  .ni__gray-hero.has-image {
    .ni__offset-md-1 {
      margin-left: 40px;
    }
    .ni__hero--content {
      margin-right: 0;
    }
  }

  .ni__gray-hero.has-image {
    padding-left: 170px;
    img {
      max-width: 255px;
      margin-left: 26px;
    }

    .ni__hero--content {
      margin-bottom: 0px;
    }
  }

  .ni__education {
    border-bottom-left-radius: 200px;
  }

  .ni__software,
  .ni__training,
  .ni__education {
    padding-left: 170px;
  }

  .ni__education .ni__offset-md-1 {
    margin-left: 40px !important;
  }

  .ni__gray-hero .ni__offset-md-1 {
    margin-left: 95px;
  }

  .ni__gray-hero .ni__h5 {
    margin-top: 38px;
  }

  .ni__hero--content {
    margin-bottom: 38px;
    margin-right: 157px;
  }
}

@include media-breakpoint-up(lg) {
  .ni__gray-hero .ni__h5 {
    margin-top: 38px;
  }

  .ni__gray-hero.has-image {
    padding-left: 260px;
    min-height: 282px;
    position: relative;
    img {
      max-width: 391px;
      margin: 0 0 0 37px;
    }
  }

  .ni__hero--content {
    margin-bottom: 57px;
    margin-right: 270px;
  }

  .ni__gray-hero {
    padding-left: 260px;
  }
}
