// ========================================================================
// Component: NavOffers
// ========================================================================

.ni__nav-offers {
  max-width: 100%;
  color: var(--forrest-green);

  .ni__nav-list {
    margin-top: 50px;
  }

  // themes
  &.is-dark {
    background-color: var(--forrest-green);
    .ni__nav-offers--content {
      p {
        color: var(--white-three);
      }
    }
  }
  // themes
  &.is-light {
    background-color: var(--off-white);
  }
}

.ni__nav-offers--header {
  background-color: var(--white-three);
  padding: 0 20px 22px 20px;

  @include media-breakpoint-up(sm) {
    padding: 0 0 22px 0;
  }

  @include media-breakpoint-up(md) {
    padding: 0 20px 22px 20px;
  }

  h3 {
    color: #054123;
    font-weight: normal;
  }

  h2 {
    color: var(--forrest-green);
    margin-bottom: 71px;
  }
}

.ni__nav-offers--content {
  color: var(--white-three);
  padding: 31px 20px 64px 20px;

  p {
    color: var(--forrest-green);
  }

  a {
    @include type-class('.ni__small-detail--1');
  }
}

.ni__nav-offers--image .parbase div > div {
  width: 100%;
  height: 322px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

@include media-breakpoint-up(sm) {
  .ni__nav-offers {
    max-width: 582px;
    margin: 0 auto;
    .ni__nav-list {
      margin-top: 38px;
    }
  }

  .ni__nav-offers--image .parbase div > div {
    height: 500px;
  }

  .ni__nav-offers--header {
    padding-bottom: 48px;
  }

  .ni__nav-offers--content {
    padding: 45px 40px 79px 40px;
  }
}

@include media-breakpoint-up(md) {
  .ni__nav-offers {
    max-width: map-get($breakpoints, 'desktop');
    border-top-left-radius: 400px;
    position: relative;

    .ni__nav-list {
      margin-top: 38px;
      > div {
        max-width: 188px;
        width: 100%;
        display: inline-block;
      }

      > div:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }

  .ni__nav-offers--image .parbase div > div {
    width: 330px;
    height: 284px;
    margin-top: -142px;
    position: absolute;
    right: 96px;
    top: 50%;
  }

  .ni__nav-offers--header {
    padding: 0;
    background-color: transparent;
    h2 {
      margin-bottom: 37px;
    }
  }

  .is-dark .ni__nav-offers--header {
    h2,
    h3 {
      color: var(--white-three);
    }
  }

  .ni__nav-offers--container {
    margin-left: 173px;
    max-width: 425px;
    padding-top: 164px;
    padding-bottom: 147px;
  }

  .ni__nav-offers--content {
    padding: 24px 0 0 0;
    p {
      max-width: 331px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__nav-offers {
    max-width: map-get($breakpoints, 'max content');
    display: flex;
    justify-content: center;

    .ni__nav-list {
      margin-top: 31px;
      > div {
        max-width: 158px;
      }
    }
  }

  .ni__nav-offers--image .parbase div > div {
    width: 540px;
    height: 464px;
    right: 165px;
    margin-top: -232px;
  }

  .ni__nav-offers--content {
    p {
      max-width: 350px;
    }
  }

  .ni__nav-offers--container {
    margin-left: -560px;
    max-width: 385px;
    padding-top: 233px;
    padding-bottom: 272px;
  }
}
