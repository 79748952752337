// ========================================================================
// Component: MultiImage
// ========================================================================

.ni__multi-image {
  text-align: center;
  max-width: 335px;
  margin: 0 auto;
}

.ni__multi-image--item {
  display: inline-block;

  &:nth-child(1) {
    margin-bottom: 15px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    margin-bottom: 30px;
  }

  h4 {
    margin-top: 17px;
    color: var(--forrest-green);
  }
}

.ni__multi-image--src1 .parbase div > div,
.ni__multi-image--src2 .parbase div > div,
.ni__multi-image--src3 .parbase div > div,
.ni__multi-image--src4 .parbase div > div,
.ni__multi-image--src5 .parbase div > div {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.ni__multi-image--src1 .parbase div > div,
.ni__multi-image--src2 .parbase div > div,
.ni__multi-image--src3 .parbase div > div,
.ni__multi-image--src4 .parbase div > div,
.ni__multi-image--src5 .parbase div > div {
  width: 157px;
  height: 103px;
}

.ni__multi-image--src1 > .parbase > div,
.ni__multi-image--src2 > .parbase > div,
.ni__multi-image--src3 > .parbase > div,
.ni__multi-image--src4 > .parbase > div,
.ni__multi-image--src5 > .parbase > div
 {
  display: flex;
  justify-content: center;
}

@include media-breakpoint-up(sm) {
  .ni__multi-image {
    max-width: 720px;
  }

  .ni__multi-image--item {
    &:first-child {
      width: 100%;
      text-align: center;
      > div {
        display: inline-block;
      }
    }

    &:nth-child(1) {
      margin-bottom: 30px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      margin: 0 58px;
      margin-bottom: 49px;
    }

    &:nth-child(4),
    &:nth-child(5) {
      margin: 0 58px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__multi-image {
    max-width: 1024px;
    display: flex;
    justify-content: center;
    .ni__multi-image--item {
      width: auto;
      margin: 0 23px;
      > div {
        display: block;
      }
    }
  }

  .ni__multi-image--src1 .parbase div > div,
  .ni__multi-image--src2 .parbase div > div,
  .ni__multi-image--src3 .parbase div > div,
  .ni__multi-image--src4 .parbase div > div,
  .ni__multi-image--src5 .parbase div > div {
    width: 118px;
    height: 77px;
  }
}

@include media-breakpoint-up(lg) {
  .ni__multi-image {
    max-width: 1440px;
    .ni__multi-image--item {
      margin: 0 30px;
    }
  }

  .ni__multi-image--src1 .parbase div > div,
  .ni__multi-image--src2 .parbase div > div,
  .ni__multi-image--src3 .parbase div > div,
  .ni__multi-image--src4 .parbase div > div,
  .ni__multi-image--src5 .parbase div > div {
    width: 158px;
    height: 104px;
  }
}
