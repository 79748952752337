.ni__form-field-dual-sections {
  padding: 64px 0;
  width: 100%;
  @include media-breakpoint-up(sm) {
    padding: 56px 0 80px 0;
  }
  h3 {
    color: #044123;
    font-family: FoundersGrotesk;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.33;
    margin: 0 0 24px 0;
    padding: 0;
    text-align: left;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
  }
  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    @include media-breakpoint-up(md) {
      align-items: stretch;
      flex-direction: row;
      justify-content: space-between;
    }
    // AEM wrapper div
    .form-sections {
      width: 100%;
    }
    .ni__form-field-dual-sections___section {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 0 32px 0;
      width: 100%;
      @include media-breakpoint-up(sm) {
        padding: 0 0 24px 0;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      h3 {
        color: #044123;
        font-family: FoundersGrotesk;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        margin: 0 0 24px;
        width: 100%;
        @include media-breakpoint-up(md) {
          font-size: 24px;
        }
      }
      .form-field {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        @include media-breakpoint-up(md) {
          width: 50%;
        }
        .custom-select {
          height: 50px;
          width: 100%;
        }
      }
      @include media-breakpoint-up(md) {
        .form-field:nth-child(even) {
          padding: 0 12.5px 0 0;
        }
        .form-field:nth-child(odd) {
          padding: 0 0 0 12.5px;
        }
      }
    }
    .ni__form-field-dual-sections___button {
      width: 100%;
      .submit-button {
        button {
          background-color: #ffffff;
          border: solid 1px #f4f4f4;
          width: auto;
        }
        button.inactive {
          color: #767676;
        }
        button.active {
          background-color: #044123;
          color: #ffffff;
        }
        button.active:focus {
          border: 1px solid #32eb96;
          color: #32eb96;
        }
      }
    }
  }
}
