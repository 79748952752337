// ========================================================================
// Component: PerspectiveHero
// ========================================================================

.ni__perspective-hero {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  max-width: 1440px;
  margin: auto;
}

.ni__perspective-hero__image {
  width: 100%;
  height: auto;
  position: relative;

  a {
    display: inline-block;
  }

  &--img {
    width: 100%;
    height: auto;
  }

  .ni__perspective-article__next100 {
    right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .ni__perspective-hero {
    height: 644px;
  }
}
