$NI__Carousel-Background-Color: var(--pale-green);
$NI__Carousel-Color: var(--forrest-green);
$NI__Carousel-Min-Height: 630px;
$NI__Carousel-Content-Max-Width: 1015px;

.ni__carousel.ni__carousel--large-image {
  color: $NI__Carousel-Color;
  font-size: 18px;
  margin: 0 auto 64px;
  // min-height: $NI__Carousel-Min-Height;
  overflow: hidden;
  padding: 0;
  width: 100vw;

  * {
    opacity: 1 !important;
    animation: none !important;
  }

  @include breakpoint('desktop small') {
    margin: 0 auto 120px;
    padding: 176px 0 0;
  }

  // prettier-ignore
  .ni__slide-desktop {
    display: none;
    @include breakpoint('desktop small') { display: block; }
  }

  // prettier-ignore
  .ni__slide-mobile {
    display: block;
    @include breakpoint('desktop small') { display: none; }
  }
}

.ni__carousel.ni__carousel--large-image .ni__slide-mobile {
  padding: 64px 0;

  .ni__carousel-image-container {
    position: relative;
    width: 100%;
    margin: 0 0 32px;
  }

  .ni__carousel-image {
    margin: 0;
  }

  .ni__carousel-content-title {
    width: 75%;
    margin: 0 0 22px;
  }

  .ni__carousel-content-title,
  .ni__carousel-eyebrow,
  .ni__carousel-content-name,
  .ni__carousel-content-text,
  .ni__carousel-content-facts {
    padding: 0 20px;
  }

  .ni__carousel-bullets {
    margin: 0 auto 56px;
  }

  .ni__col-xxxs-12 {
    padding: 0 20px;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-item-container {
  box-sizing: border-box !important;

  @media (max-width: map-get($breakpoints, 'tablet') - 1px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint('medium') {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.ni__carousel.ni__carousel--large-image .ni__container {
  @media (max-width: map-get($breakpoints, 'tablet') - 1px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint('medium') {
    max-width: none;
  }

  @include breakpoint('grid') {
    max-width: map-get($breakpoints, 'grid');
  }
}

.ni__carousel.ni__carousel--large-image .glide__slide > .ni__container {
  @include breakpoint('medium') {
    // min-height: 910px;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-container {
  background: $NI__Carousel-Background-Color;
  height: 100%;
  margin: 0 auto;
  min-height: 0;

  &:before,
  &:after {
    bottom: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 15vw;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(to right, $NI__Carousel-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    right: auto;
  }

  &:after {
    background: linear-gradient(to left, $NI__Carousel-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: auto;
    right: 0;
  }

  @include breakpoint('tablet') {
    border-radius: 0 300px 0 0;
    padding: 102px 0 0;

    &.ni__carousel--border-left {
      border-radius: 0 0 0 300px;
    }
  }

  @include breakpoint('large') {
    border-radius: 0 300px 0 0;
    padding: 176px 0 0;

    &.ni__carousel--border-left {
      border-radius: 0 0 0 300px;
    }
  }

  @include breakpoint('desktop large') {
    &:before,
    &:after {
      // content: '';
    }
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-items {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 0;
  -webkit-touch-callout: none;

  &.no-transform {
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-grid {
  width: 100%;
  @include display-flex(column-reverse nowrap, center, space-between);

  @include breakpoint('desktop small') {
    flex-flow: row-reverse nowrap;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-grid-left {
  box-sizing: border-box;
  margin: 0;
  padding: 0 20px 64px;
  width: 100%;

  @include breakpoint('desktop small') {
    max-width: 347px;
    padding: 0;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-grid-right {
  margin: 0;
  width: 100%;

  @include breakpoint('desktop small') {
    max-width: none;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-grid-left {
  display: none;
  @include breakpoint('desktop small') {
    display: block;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-eyebrow {
  color: inherit;
  letter-spacing: normal;
  margin: 0 0 48px;
  opacity: 0;
  text-transform: uppercase;
  @include type-class('.ni__eyebrow--5');

  &.ni__carousel-eyebrow-desktop {
    display: none;
    @include breakpoint('desktop small') {
      display: block;
      margin: 0;
    }
  }

  &.ni__carousel-eyebrow-mobile {
    display: block;
    @include breakpoint('desktop small') {
      display: none;
    }
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-content {
  font-family: map-get($fonts, 'sans');
  text-align: left;
  position: relative;
  z-index: 1;

  @include breakpoint('medium') {
    padding-left: 15px;
    padding-right: 50px;
  }

  &-title {
    color: inherit;
    letter-spacing: normal;
    margin: 0 0 32px;
    opacity: 0;
    @include type-class('.ni__h2');

    @include breakpoint('tablet') {
      margin: 0 auto 64px 0;
    }

    // @include breakpoint('large') {
    //   margin: 0 0 64px;
    // }
  }

  &-name {
    color: inherit;
    letter-spacing: normal;
    margin: 0 0 10px;
    opacity: 0;
    @include type-class('.ni__subhead--1');
  }

  &-text {
    color: inherit;
    letter-spacing: normal;
    margin: 0 0 37px;
    opacity: 0;
    @include type-class('.ni__body-text--3');
  }

  &-facts-title {
    color: inherit;
    letter-spacing: normal;
    margin: 0 0 10px;
    @include type-class('.ni__eyebrow-detail');
  }

  &-facts-list {
    min-height: 72px;
    padding-left: 15px;
  }

  &-facts-list-item {
    color: inherit;
    font-family: map-get($fonts, 'mono');
    font-size: 0.667em;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 2;
    list-style: disc;
    text-transform: uppercase;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-image {
  $tabSizeW: 470px;
  $tabSizeH: 560px;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 395px;
  margin: 0 0 32px;
  opacity: 0;
  width: 100%;

  @media (min-width: 540px) {
    height: 80vw;
  }

  @include breakpoint('small') {
    height: 576px;
  }

  @include breakpoint('tablet') {
    width: $tabSizeW;
    height: $tabSizeH;
    margin: 0;
  }

  @media (min-width: 1140px) {
    width: calc(#{$tabSizeW} * 1.1);
    height: calc(#{$tabSizeH} * 1.1);
  }

  @include breakpoint('large') {
    width: calc(#{$tabSizeW} * 1.15);
    height: calc(#{$tabSizeH} * 1.15);
  }

  @media (min-width: 1320px) {
    width: calc(#{$tabSizeW} * 1.275);
    height: calc(#{$tabSizeH} * 1.275);
  }

  @media (min-width: 1440px) {
    width: calc(#{$tabSizeW} * 1.325);
    height: calc(#{$tabSizeH} * 1.325);
  }
}

// prettier-ignore
.ni__carousel.ni__carousel--large-image .ni__carousel-image-container {
  position: absolute;
  left: 0;
  bottom: 0;
  @include breakpoint('desktop small') { bottom: -10px; }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-bullets {
  height: 12px;
  margin: 0 auto 60px;

  @include breakpoint('desktop small') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-bullets .glide__bullet {
  appearance: none;
  background: var(--gray-20);
  border-radius: 50%;
  border: 0;
  height: 8px;
  margin: 0;
  padding: 0;
  width: 8px;

  &.glide__bullet--active {
    background: var(--forrest-green);
    height: 12px;
    width: 12px;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-controls-container {
  display: none;
  margin: 50px auto;
  width: 100%;

  @include breakpoint('desktop small') {
    @include display-flex(row-reverse nowrap, center, flex-end);
  }

  @include breakpoint('large') {
    margin: 50px auto;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-counter {
  color: var(--forrest-green);
  font-family: map-get($fonts, 'serif');
  font-size: 0.889em;
  line-height: 1;
  margin: 0 0 0 40px;

  .ni__carousel-counter-current {
    color: inherit;
  }

  .ni__carousel-counter-divider {
    color: inherit;
    margin: 0 4px;
    opacity: 0.6;
  }

  .ni__carousel-counter-total {
    color: inherit;
    opacity: 0.6;
  }
}

.ni__carousel.ni__carousel--large-image .ni__carousel-controls {
  @include display-flex(row nowrap, center, flex-end);

  & > button {
    box-sizing: border-box;
  }
}

.ni__carousel.ni__carousel--large-image .glide__slide {
  position: relative;

  @include breakpoint('desktop small') {
    height: auto;
    min-height: 768px;
  }
}

// animations
.ni__carousel.ni__carousel--large-image .glide__slide--active {
  .ni__carousel-eyebrow {
    // @include animation-fade-in(left, small);
  }

  .ni__carousel-content-title {
    animation-delay: 100ms;
    // @include animation-fade-in(left, small);
  }

  .ni__carousel-content-text {
    animation-delay: 200ms;
    // @include animation-fade-in(left, small);
  }

  .ni__carousel-content > .ni__button {
    animation-delay: 300ms;
    // @include animation-fade-in(left, small);
  }

  .ni__carousel-image {
    // @include animation-fade-in(right, small);
  }
}

.ni__carousel {
  @include breakpoint('max content') {
    max-width: map-get($breakpoints, 'max content');
    margin: 0 auto;
  }
}
