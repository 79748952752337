// ========================================================================
// Component: Next 100 Badge
// ========================================================================

// settings => global
$NI__Next100Badge-BG-Color: var(--power-green);
$NI__Next100Badge-Color: #064024;

// settings => fonts
$NI__Next100Badge-FontFamily-Title: map-get($fonts, 'sans');
$NI__Next100Badge-FontFamily-Numbers: map-get($fonts, 'mono');
$NI__Next100Badge-FontSize-Mobile: 20px;
$NI__Next100Badge-FontSize-Desktop: 28px;

// settings => spacing
$NI__Next100Badge-Padding-Vertical--Mobile: 12px;
$NI__Next100Badge-Padding-Horizontal--Mobile: 20px;
$NI__Next100Badge-Padding-Vertical--Desktop: 14px;
$NI__Next100Badge-Padding-Horizontal--Desktop: 29px;

// width 00/100 =   263px
// width 100/100 =  306px
// diff =           43px

.ni__next100__badge {
  box-sizing: content-box; // required for the ____ line
  background-color: $NI__Next100Badge-BG-Color;
  color: $NI__Next100Badge-Color;
  font-size: $NI__Next100Badge-FontSize-Mobile;
  overflow: hidden;

  // padding
  padding-top: $NI__Next100Badge-Padding-Vertical--Mobile;
  padding-right: calc(#{$NI__Next100Badge-Padding-Horizontal--Mobile} + 1.5px);
  padding-bottom: calc(#{$NI__Next100Badge-Padding-Vertical--Mobile} + 1.5px);
  padding-left: $NI__Next100Badge-Padding-Horizontal--Mobile;

  // display
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  // mobile widths
  width: 100%;
  max-width: fit-content;

  // mobile heights
  height: 100%;

  @include breakpoint('desktop small') {
    font-size: $NI__Next100Badge-FontSize-Desktop;

    // padding
    padding-top: $NI__Next100Badge-Padding-Vertical--Desktop;
    padding-right: calc(#{$NI__Next100Badge-Padding-Horizontal--Desktop} + 1.5px);
    padding-bottom: calc(#{$NI__Next100Badge-Padding-Vertical--Desktop} + 2px);
    padding-left: $NI__Next100Badge-Padding-Horizontal--Desktop;
  }
}

// title (first row)
// ========================================================================
.ni__next100__badge .ni__next100__badge--title {
  font-family: $NI__Next100Badge-FontFamily-Title;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1em;
  font-size: 0.571428571em;
  text-transform: uppercase;
  display: block;
  overflow: hidden;
  width: 100%;

  &:after {
    content: '';
    display: inline-block;
    background: $NI__Next100Badge-Color;
    height: 1px;
    width: 100%;
  }
}

// next _____ line
// ========================================================================
.ni__next100__badge .ni__next100__badge--line {
  background: $NI__Next100Badge-Color;
  height: 1px;
  width: 100%;
}

// numbers (second row)
// ========================================================================
.ni__next100__badge .ni__next100__badge--numbers {
  font-family: $NI__Next100Badge-FontFamily-Numbers;
  font-size: 1em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.875;
  letter-spacing: -0.0465em;
  margin-left: -0.0195em;

  @include breakpoint('desktop small') {
    letter-spacing: -0.095em;
  }
}

// numbers (second row) => negative left alignment
// ========================================================================
// prettier-ignore
.ni__next100__badge {
  &[data-dynamic-value^="5"] .ni__next100__badge--numbers { margin-left: -0.045em; }
  &[data-dynamic-value^="6"] .ni__next100__badge--numbers { margin-left: -0.05em; }
  &[data-dynamic-value^="8"] .ni__next100__badge--numbers { margin-left: -0.05em; }
  &[data-dynamic-value^="9"] .ni__next100__badge--numbers { margin-left: -0.05em; }
}

// generics
// ========================================================================
.ni__next100__badge div {
  white-space: nowrap;
}

.ni__next100__badge span {
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  white-space: nowrap;
}
