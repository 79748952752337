// ========================================================================
// Component: RichText
// ========================================================================

.ni__rich-text {
  h2,
  p,
  h4 {
    margin-bottom: 20px;
  }

  h2 {
    // font-family: $fontFamilySerif;
    // font-size: 32px;
    // font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.25;
    letter-spacing: normal;
    color: var(--forrest-green);
    @include type-class('.ni__h6');
  }

  p {
    // font-family: $fontFamilySans;
    // font-size: 24px;
    // font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.33;
    letter-spacing: normal;
    color: var(--forrest-green);
    @include type-class('.ni__body-text--2');
  }

  h4 {
    // font-family: $fontFamilySans;
    // font-size: 30px;
    // font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.2;
    letter-spacing: normal;
    color: var(--forrest-green);
    @include type-class('.ni__h4');
  }

  h5 {
    // font-family: $fontFamilySans;
    // font-size: 24px;
    // font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.33;
    letter-spacing: normal;
    color: var(--forrest-green);
    margin-bottom: 20px;
    @include type-class('.ni__subhead--3');
  }

  h6 {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--forrest-green);
    margin-bottom: 20px;
    @include type-class('.ni__subhead--3');
  }

  ol,
  ul {
    list-style: none;
    padding-left: 0;
    counter-reset: ol-counter;
  }

  ol li,
  ul li {
    counter-increment: ol-counter;
    font-family: FoundersGrotesk;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--forrest-green);
    padding-left: 40px;
    margin-bottom: 25px;
    position: relative;

    &::before {
      content: counter(ol-counter) '. ';
      font-family: $fontFamilyMono;
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #35b77d;
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  ul li {
    padding-left: 30px;
  }

  // order list
  ul li::before {
    content: '';
    width: 14px;
    height: 14px;
    background-color: #35b77d;
    border-top-left-radius: 4px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 9px;
    left: 0;
  }

  b,
  strong {
    font-weight: inherit;
  }
}

// table up
@include media-breakpoint-up(md) {
  .ni__rich-text {
    h2 {
      // font-size: 40px;
      // line-height: 1.2;
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 28px;
    }

    h2 {
      margin-bottom: 30px;
    }

    .ni__perspective-photo-caption {
      position: relative;
      &::after,
      &::before {
        content: '';
        width: 52px;
        height: 52px;
        position: absolute;
        top: 0;
        left: -52px;
        display: block;
        border-top-left-radius: 52px;
        background-color: var(--ni-green);
      }

      &::after {
        border-top-left-radius: 0;
        border-bottom-left-radius: 52px;
        background-color: var(--power-green);
        top: 52px;
      }

      img {
        margin-bottom: 0px !important;
      }
    }

    // prettier-ignore
    p, ul, ol, h4, h3, h4,
     .ni__perspective-photo-caption {
       max-width: 575px;
     }
  }
}

// table up
@include media-breakpoint-up(lg) {
  .ni__rich-text {
    h2 {
      // font-size: 40px;
      // line-height: 1.2;
      margin-bottom: 30px;
      max-width: 825px;
    }

    p {
      margin-bottom: 28px;
    }

    h2 {
      margin-bottom: 30px;
    }

    p,
    ul,
    ol,
    h4,
    .ni__perspective-photo-caption {
      margin-bottom: 30px;
    }

    .ni__perspective-photo-caption--image {
      max-height: 343px;
    }

    // prettier-ignore
    p, ul, ol, h4, h3, h4,
    .ni__perspective-photo-caption {
      max-width: 635px;
    }
  }
}

// table up
@include media-breakpoint-down(md) {
  .ni__rich-text {
    img {
      max-width: 100%;
    }
  }
}
