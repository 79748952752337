// ========================================================================
// ABSTRACTS => DEFINITIONS => FONT FAMILIES
// ========================================================================

/***
SUMMARY
=======================
Define your font-family stacks in the following variable formats. 
***/

$fontFamilyMono: 'SpaceMono', 'Courier New', Courier, monospace;
$fontFamilySans: 'FoundersGrotesk', 'Helvetica', Arial, sans-serif;
$fontFamilySerif: 'FinancierDisplay', Georgia, 'Times New Roman', Times, serif;
