section.ni__prodcut-detail-model {
  #s7mixedmedia_div {
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: -23px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: -15px;
    }

    &.s7touchinput {
      margin-bottom: 64px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 94px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 42px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
      }
    }

    /*
  ___                                
 |_ _| _ __ ___    __ _   __ _   ___ 
  | | | '_ ` _ \  / _` | / _` | / _ \
  | | | | | | | || (_| || (_| ||  __/
 |___||_| |_| |_| \__,_| \__, | \___|
                         |___/       
*/
    #s7mixedmedia_div_container {
      height: 419px !important;
      max-height: 419px !important; /* 375 + 44 = target height + swatch height (#s7mixedmedia_div_swatches) */
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 394px !important;
        max-height: 394px !important; /* 338 + 56 */
        max-width: 331px !important;
      }

      @include media-breakpoint-up(lg) {
        height: 513px !important;
        max-height: 513px !important; /* 457 + 56 */
        max-width: 445px !important;
      }
    }

    #s7mixedmedia_div_zoomView {
      border: 3px solid var(--off-white);

      &:focus {
        outline: none;
      }
    }

    &.s7size_large {
      @include media-breakpoint-up(md) {
        // FULLSCREEN ONLY
        #s7mixedmedia_div_zoomView {
          border: none;
        }
      }
    }

    /*
  _____  _                        _                    _  _      
 |_   _|| |__   _   _  _ __ ___  | |__   _ __    __ _ (_)| | ___ 
   | |  | '_ \ | | | || '_ ` _ \ | '_ \ | '_ \  / _` || || |/ __|
   | |  | | | || |_| || | | | | || |_) || | | || (_| || || |\__ \
   |_|  |_| |_| \__,_||_| |_| |_||_.__/ |_| |_| \__,_||_||_||___/
                                                                 
*/

    #s7mixedmedia_div_swatches {
      @include media-breakpoint-down(md) {
        height: 44px;

        & > div {
          left: 50% !important;
          top: 32px !important;
          transform: translateX(-50%);
        }
      }

      @include media-breakpoint-up(md) {
        height: 56px; /* this is actually the width once it's rotated to be vertical */
        left: calc(100% + 56px) !important;
        margin-left: 24px;
        top: -6px;
        transform-origin: left top;
        transform: rotate(90deg);
      }
    }

    &.s7touchinput {
      #s7mixedmedia_div_swatches {
        bottom: -44px;
      }
    }

    &.s7mixedmediaviewer .s7thumbcell {
      margin: 0 10px 0 0;

      &:focus-visible {
        outline: none;
      }

      &:focus {
        outline: none;

        .s7thumb {
          border: 6px solid var(--ni-green);
        }
      }

      @include media-breakpoint-up(md) {
        margin-right: 16px;

        &:focus {
          .s7thumb {
            border-width: 1px;
          }
        }
      }
    }

    .s7thumb {
      height: 12px;
      width: 12px;

      &[state='default'] {
        border-color: var(--off-white);
        border-radius: 6px;
        border-width: 6px;

        &:after {
          border: 2px solid white;
          border-radius: 6px;
          content: '';
          height: 12px;
          left: 0;
          position: absolute;
          top: 0;
          width: 12px;

          @include media-breakpoint-up(md) {
            content: none;
          }
        }

        @include media-breakpoint-up(md) {
          border-width: 1px;
        }
      }

      &[state='over'] {
        border-color: var(--ni-green);
        border-radius: 6px;
        border-width: 6px;

        @include media-breakpoint-up(md) {
          border-width: 1px;
        }
      }

      &[state='selected'] {
        border-color: var(--ni-green);
        border-width: 6px;
        border-radius: 6px;

        @include media-breakpoint-up(md) {
          border-width: 1px;
        }
      }

      @include media-breakpoint-up(md) {
        border-radius: 0;
        height: 56px;
        transform: rotate(-90deg);
        width: 56px;
      }
    }

    // FULLSCREEN ONLY THUMBNAILS
    &.s7size_large #s7mixedmedia_div_swatches {
      @include media-breakpoint-up(md) {
        left: unset !important;
        margin-left: 100px;
        top: unset;
        transform: translate(0, -56px);

        .s7thumb {
          transform: rotate(-0deg);
        }

        & > div {
          left: calc(50% - 100px) !important;
          transform: translateX(-50%);
        }
      }
    }

    /*
  ____          _    _                        
 | __ )  _   _ | |_ | |_  ___   _ __   ___  _ 
 |  _ \ | | | || __|| __|/ _ \ | '_ \ / __|(_)
 | |_) || |_| || |_ | |_| (_) || | | |\__ \ _ 
 |____/  \__,_| \__| \__|\___/ |_| |_||___/(_)
                                              
 */

    @include media-breakpoint-down(md) {
      &.s7touchinput .s7toolbarcontainer {
        @include sr-only;
      }
    }

    /*
     _____                          ___        
    |__  / ___    ___   _ __ ___   |_ _| _ __  
      / / / _ \  / _ \ | '_ ` _ \   | | | '_ \ 
     / /_| (_) || (_) || | | | | |  | | | | | |
    /____|\___/  \___/ |_| |_| |_| |___||_| |_|
                                              
    */
    &.s7mixedmediaviewer .s7zoominbutton {
      background-size: cover;
      height: 17px;
      left: 20px;
      width: 17px;

      &[state='up'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwNDQxMjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjM1NyAyMC4yMzZsLTMuNDg0LTMuNDg1YzEuMTY4LTEuNDYgMS44NjYtMy4zMTEgMS44NjYtNS4zMjYgMC00LjcxMy0zLjgyLTguNTMzLTguNTM0LTguNTMzLTQuNzEzIDAtOC41MzMgMy44Mi04LjUzMyA4LjUzMyAwIDQuNzEzIDMuODIgOC41MzQgOC41MzMgOC41MzQgMi4wMTUgMCAzLjg2Ny0uNjk5IDUuMzI3LTEuODY3bDMuNDg1IDMuNDg1Yy4zNy4zNy45Ny4zNyAxLjM0IDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTQtOC44MWMwIDMuNjY1LTIuOTcyIDYuNjM2LTYuNjM4IDYuNjM2LTMuNjY1IDAtNi42MzctMi45NzEtNi42MzctNi42MzcgMC0zLjY2NiAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM4IDIuOTcxIDYuNjM4IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDQ0MTIzIiBkPSJNMTEuODY5IDEwLjc0OGgzLjQ0M2MuMjg0IDAgLjUxMy4zOTQuNTEzLjY3OCAwIC4yODMtLjIzLjcxNy0uNTEzLjcxN0gxMS44N3YzLjM4N2MwIC4yODQtLjM3OC41MTQtLjY2Mi41MTQtLjI4MyAwLS42OTMtLjIzLS42OTMtLjUxNHYtMy4zODdINy4xMDNjLS4yODMgMC0uNTEzLS40MzQtLjUxMy0uNzE3IDAtLjI4NC4yMy0uNjc4LjUxMy0uNjc4aDMuNDExVjcuMzIxYzAtLjI4My40MS0uNTEzLjY5NC0uNTEzLjI4MyAwIC42NjEuMjMuNjYxLjUxM3YzLjQyN3oiLz4KPC9zdmc+Cg==');
      }

      &[state='over'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwM0I1ODUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjM1NyAyMC4wOTdsLTMuNDg0LTMuNDg0YzEuMTY4LTEuNDYgMS44NjYtMy4zMTIgMS44NjYtNS4zMjcgMC00LjcxMy0zLjgyLTguNTMzLTguNTM0LTguNTMzLTQuNzEzIDAtOC41MzMgMy44Mi04LjUzMyA4LjUzMyAwIDQuNzEzIDMuODIgOC41MzQgOC41MzMgOC41MzQgMi4wMTUgMCAzLjg2Ny0uNjk5IDUuMzI3LTEuODY3bDMuNDg1IDMuNDg1Yy4zNy4zNy45Ny4zNyAxLjM0IDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTQtOC44MWMwIDMuNjY1LTIuOTcyIDYuNjM2LTYuNjM4IDYuNjM2LTMuNjY1IDAtNi42MzctMi45NzEtNi42MzctNi42MzcgMC0zLjY2NSAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM4IDIuOTcyIDYuNjM4IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDNCNTg1IiBkPSJNMTEuODY5IDEwLjYxaDMuNDQzYy4yODQgMCAuNTEzLjM5NC41MTMuNjc3IDAgLjI4My0uMjMuNzE3LS41MTMuNzE3SDExLjg3djMuMzg4YzAgLjI4My0uMzc4LjUxMy0uNjYyLjUxMy0uMjgzIDAtLjY5My0uMjMtLjY5My0uNTEzdi0zLjM4OEg3LjEwM2MtLjI4MyAwLS41MTMtLjQzNC0uNTEzLS43MTcgMC0uMjg0LjIzLS42NzguNTEzLS42NzhoMy40MTFWNy4xODJjMC0uMjgzLjQxLS41MTMuNjk0LS41MTMuMjgzIDAgLjY2MS4yMy42NjEuNTEzdjMuNDI3eiIvPgo8L3N2Zz4K');
      }

      &[state='down'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwM0I1ODUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjM1NyAyMC4wOTdsLTMuNDg0LTMuNDg0YzEuMTY4LTEuNDYgMS44NjYtMy4zMTIgMS44NjYtNS4zMjcgMC00LjcxMy0zLjgyLTguNTMzLTguNTM0LTguNTMzLTQuNzEzIDAtOC41MzMgMy44Mi04LjUzMyA4LjUzMyAwIDQuNzEzIDMuODIgOC41MzQgOC41MzMgOC41MzQgMi4wMTUgMCAzLjg2Ny0uNjk5IDUuMzI3LTEuODY3bDMuNDg1IDMuNDg1Yy4zNy4zNy45Ny4zNyAxLjM0IDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTQtOC44MWMwIDMuNjY1LTIuOTcyIDYuNjM2LTYuNjM4IDYuNjM2LTMuNjY1IDAtNi42MzctMi45NzEtNi42MzctNi42MzcgMC0zLjY2NSAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM4IDIuOTcyIDYuNjM4IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDNCNTg1IiBkPSJNMTEuODY5IDEwLjYxaDMuNDQzYy4yODQgMCAuNTEzLjM5NC41MTMuNjc3IDAgLjI4My0uMjMuNzE3LS41MTMuNzE3SDExLjg3djMuMzg4YzAgLjI4My0uMzc4LjUxMy0uNjYyLjUxMy0uMjgzIDAtLjY5My0uMjMtLjY5My0uNTEzdi0zLjM4OEg3LjEwM2MtLjI4MyAwLS41MTMtLjQzNC0uNTEzLS43MTcgMC0uMjg0LjIzLS42NzguNTEzLS42NzhoMy40MTFWNy4xODJjMC0uMjgzLjQxLS41MTMuNjk0LS41MTMuMjgzIDAgLjY2MS4yMy42NjEuNTEzdjMuNDI3eiIvPgo8L3N2Zz4K');
        background-size: 90%;
      }

      &[state='disabled'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDIwIDIxIj4KICAgIDxwYXRoIGZpbGw9IiM3Njc2NzYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE3LjUyOSAxNy4yMjRsLTIuOTY2LTIuOTY2Yy45OTQtMS4yNDIgMS41ODgtMi44MTcgMS41ODgtNC41MzIgMC00LjAxLTMuMjUtNy4yNjEtNy4yNi03LjI2MS00LjAxMSAwLTcuMjYyIDMuMjUtNy4yNjIgNy4yNjEgMCA0LjAxIDMuMjUgNy4yNjEgNy4yNjEgNy4yNjEgMS43MTUgMCAzLjI5LS41OTQgNC41MzItMS41ODdsMi45NjYgMi45NjVjLjMxNS4zMTUuODI2LjMxNSAxLjE0IDAgLjMxNi0uMzE1LjMxNi0uODI2IDAtMS4xNDF6bS0yLjk5MS03LjQ5OGMwIDMuMTItMi41MjkgNS42NDgtNS42NDggNS42NDgtMy4xMTkgMC01LjY0Ny0yLjUyOS01LjY0Ny01LjY0OCAwLTMuMTE5IDIuNTI4LTUuNjQ4IDUuNjQ3LTUuNjQ4IDMuMTIgMCA1LjY0OCAyLjUyOSA1LjY0OCA1LjY0OHoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPgogICAgPHBhdGggZmlsbD0iIzc2NzY3NiIgZD0iTTkuNDUzIDkuMTVoMi45M2MuMjQxIDAgLjQzNi4zMzUuNDM2LjU3NiAwIC4yNDEtLjE5NS42MS0uNDM2LjYxaC0yLjkzdjIuODgzYzAgLjI0LS4zMjIuNDM2LS41NjMuNDM2LS4yNCAwLS41OS0uMTk1LS41OS0uNDM2di0yLjg4M0g1LjM5OGMtLjI0MiAwLS40MzctLjM2OS0uNDM3LS42MXMuMTk1LS41NzYuNDM3LS41NzZIOC4zVjYuMjMzYzAtLjI0LjM1LS40MzYuNTktLjQzNi4yNDEgMCAuNTYzLjE5NS41NjMuNDM2VjkuMTV6Ii8+Cjwvc3ZnPgo=');
      }

      @include media-breakpoint-up(lg) {
        width: 20px;
        height: 20px;
        left: 22px;
      }
    }
    /*
     _____                           ___          _   
    |__  / ___    ___   _ __ ___    / _ \  _   _ | |_ 
      / / / _ \  / _ \ | '_ ` _ \  | | | || | | || __|
     / /_| (_) || (_) || | | | | | | |_| || |_| || |_ 
    /____|\___/  \___/ |_| |_| |_|  \___/  \__,_| \__|

    */
    &.s7mixedmediaviewer .s7zoomoutbutton {
      background-size: cover;
      height: 17px;
      left: 44px;
      width: 17px;

      &[state='up'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwNDQxMjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjQ5IDIwLjE1bC0zLjQ4NC0zLjQ4NGMxLjE2Ny0xLjQ2IDEuODY2LTMuMzEyIDEuODY2LTUuMzI3IDAtNC43MTMtMy44MjEtOC41MzMtOC41MzQtOC41MzMtNC43MTMgMC04LjUzMyAzLjgyLTguNTMzIDguNTMzIDAgNC43MTMgMy44MiA4LjUzNCA4LjUzMyA4LjUzNCAyLjAxNSAwIDMuODY3LS42OTkgNS4zMjctMS44NjZsMy40ODQgMy40ODRjLjM3LjM3Ljk3LjM3IDEuMzQxIDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTUtOC44MWMwIDMuNjY1LTIuOTcxIDYuNjM3LTYuNjM3IDYuNjM3LTMuNjY1IDAtNi42MzctMi45NzItNi42MzctNi42MzggMC0zLjY2NSAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM3IDIuOTcyIDYuNjM3IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDQ0MTIzIiBkPSJNNi43ODcgMTIuMTcxYy0uMzQ0IDAtLjYyMy0uMy0uNjIzLS42NzEgMC0uMzcxLjI3OS0uNjcyLjYyMy0uNjcyaDguNzE3Yy4zNDQgMCAuNjIzLjMuNjIzLjY3MiAwIC4zNy0uMjc5LjY3MS0uNjIzLjY3MUg2Ljc4N3oiLz4KPC9zdmc+Cg==');
      }

      &[state='over'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwM0I1ODUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjQ5IDIwLjE1bC0zLjQ4NC0zLjQ4NGMxLjE2Ny0xLjQ2IDEuODY2LTMuMzEyIDEuODY2LTUuMzI3IDAtNC43MTMtMy44MjEtOC41MzMtOC41MzQtOC41MzMtNC43MTMgMC04LjUzMyAzLjgyLTguNTMzIDguNTMzIDAgNC43MTMgMy44MiA4LjUzNCA4LjUzMyA4LjUzNCAyLjAxNSAwIDMuODY3LS42OTkgNS4zMjctMS44NjZsMy40ODQgMy40ODRjLjM3LjM3Ljk3LjM3IDEuMzQxIDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTUtOC44MWMwIDMuNjY1LTIuOTcxIDYuNjM3LTYuNjM3IDYuNjM3LTMuNjY1IDAtNi42MzctMi45NzItNi42MzctNi42MzggMC0zLjY2NSAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM3IDIuOTcyIDYuNjM3IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDNCNTg1IiBkPSJNNi43ODcgMTIuMTcxYy0uMzQ0IDAtLjYyMy0uMy0uNjIzLS42NzEgMC0uMzcxLjI3OS0uNjcyLjYyMy0uNjcyaDguNzE3Yy4zNDQgMCAuNjIzLjMuNjIzLjY3MiAwIC4zNy0uMjc5LjY3MS0uNjIzLjY3MUg2Ljc4N3oiLz4KPC9zdmc+Cg==');
      }

      &[state='down'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGZpbGw9IiMwM0I1ODUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIxLjQ5IDIwLjE1bC0zLjQ4NC0zLjQ4NGMxLjE2Ny0xLjQ2IDEuODY2LTMuMzEyIDEuODY2LTUuMzI3IDAtNC43MTMtMy44MjEtOC41MzMtOC41MzQtOC41MzMtNC43MTMgMC04LjUzMyAzLjgyLTguNTMzIDguNTMzIDAgNC43MTMgMy44MiA4LjUzNCA4LjUzMyA4LjUzNCAyLjAxNSAwIDMuODY3LS42OTkgNS4zMjctMS44NjZsMy40ODQgMy40ODRjLjM3LjM3Ljk3LjM3IDEuMzQxIDAgLjM3LS4zNy4zNy0uOTcgMC0xLjM0em0tMy41MTUtOC44MWMwIDMuNjY1LTIuOTcxIDYuNjM3LTYuNjM3IDYuNjM3LTMuNjY1IDAtNi42MzctMi45NzItNi42MzctNi42MzggMC0zLjY2NSAyLjk3Mi02LjYzNyA2LjYzNy02LjYzNyAzLjY2NiAwIDYuNjM3IDIuOTcyIDYuNjM3IDYuNjM3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjMDNCNTg1IiBkPSJNNi43ODcgMTIuMTcxYy0uMzQ0IDAtLjYyMy0uMy0uNjIzLS42NzEgMC0uMzcxLjI3OS0uNjcyLjYyMy0uNjcyaDguNzE3Yy4zNDQgMCAuNjIzLjMuNjIzLjY3MiAwIC4zNy0uMjc5LjY3MS0uNjIzLjY3MUg2Ljc4N3oiLz4KPC9zdmc+Cg==');
        background-size: 90%;
      }

      &[state='disabled'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDIxIDIwIj4KICAgIDxwYXRoIGZpbGw9IiM3Njc2NzYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE4LjQyMyAxNi40MThsLTIuOTY1LTIuOTY1Yy45OTQtMS4yNDIgMS41ODgtMi44MTggMS41ODgtNC41MzMgMC00LjAxLTMuMjUxLTcuMjYtNy4yNjEtNy4yNlMyLjUyMyA0LjkxIDIuNTIzIDguOTJzMy4yNTEgNy4yNjIgNy4yNjIgNy4yNjJjMS43MTQgMCAzLjI5LS41OTQgNC41MzItMS41ODhsMi45NjUgMi45NjVjLjMxNS4zMTUuODI2LjMxNSAxLjE0MSAwIC4zMTUtLjMxNS4zMTUtLjgyNiAwLTEuMTQxem0tMi45OS03LjQ5OGMwIDMuMTItMi41MyA1LjY0OC01LjY0OCA1LjY0OC0zLjEyIDAtNS42NDgtMi41MjgtNS42NDgtNS42NDggMC0zLjExOSAyLjUyOS01LjY0NyA1LjY0OC01LjY0NyAzLjExOSAwIDUuNjQ3IDIuNTI4IDUuNjQ3IDUuNjQ3eiIgY2xpcC1ydWxlPSJldmVub2RkIi8+CiAgICA8cGF0aCBmaWxsPSIjNzY3Njc2IiBkPSJNNS45MTMgOS42MjljLS4yOTMgMC0uNTMtLjI1Ni0uNTMtLjU3MiAwLS4zMTYuMjM3LS41NzIuNTMtLjU3Mmg3LjQxOGMuMjkyIDAgLjUzLjI1Ni41My41NzIgMCAuMzE2LS4yMzguNTcyLS41My41NzJINS45MTN6Ii8+Cjwvc3ZnPgo=');
      }

      @include media-breakpoint-up(lg) {
        height: 20px;
        left: 53px;
        width: 20px;
      }
    }

    /*
     _____        _  _   ____                                
    |  ___|_   _ | || | / ___|   ___  _ __  ___   ___  _ __  
    | |_  | | | || || | \___ \  / __|| '__|/ _ \ / _ \| '_ \ 
    |  _| | |_| || || |  ___) || (__ | |  |  __/|  __/| | | |
    |_|    \__,_||_||_| |____/  \___||_|   \___| \___||_| |_|
    */
    &.s7mixedmediaviewer .s7fullscreenbutton {
      display: none;

      &[selected='true'][state='up'],
      &[selected='true'][state='over'],
      &[selected='true'][state='down'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDMwIDMxIj4KICAgIDxwYXRoIGZpbGw9IiMwNDQxMjMiIGQ9Ik0xMy4yMzIgMTUuNUw1LjM2NiA3LjYzNGMtLjQ4OC0uNDg4LS40ODgtMS4yOCAwLTEuNzY4czEuMjgtLjQ4OCAxLjc2OCAwTDE1IDEzLjczMmw3Ljg2Ni03Ljg2NmMuNDg4LS40ODggMS4yOC0uNDg4IDEuNzY4IDBzLjQ4OCAxLjI4IDAgMS43NjhMMTYuNzY4IDE1LjVsNy44NjYgNy44NjZjLjQ4OC40ODguNDg4IDEuMjggMCAxLjc2OHMtMS4yOC40ODgtMS43NjggMEwxNSAxNy4yNjhsLTcuODY2IDcuODY2Yy0uNDg4LjQ4OC0xLjI4LjQ4OC0xLjc2OCAwcy0uNDg4LTEuMjggMC0xLjc2OGw3Ljg2Ni03Ljg2NnoiLz4KPC9zdmc+Cg==');
        height: 30px;
        position: fixed;
        right: 50px;
        top: 50px;
        transition: 200ms ease-in-out;
        width: 30px;
      }

      &[selected='true'][state='over'] {
        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2303B585' d='M10.586 12L4.293 5.707c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L12 10.586l6.293-6.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414L13.414 12l6.293 6.293c.39.39.39 1.024 0 1.414-.39.39-1.024.39-1.414 0L12 13.414l-6.293 6.293c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414L10.586 12z'/%3E%3C/svg%3E%0A");
        }
      }

      &[selected='false'][state='up'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDM1IDM1Ij4KICAgIDxwYXRoIGZpbGw9IiMwNDQxMjMiIGQ9Ik0yNC43NjggOS4wODRsLTQuNTgxLS4zNTNjLS4zNi0uMDI3LS42MjgtLjM0LS42LS43LjAyNy0uMzYuMzQtLjYyOC43LS42bDUuOTk1LjQ2MWMuMzIxLjAyNS41NzYuMjguNi42bC40NjIgNS45OTZjLjAyNy4zNTktLjI0Mi42NzItLjYuNy0uMzYuMDI4LS42NzMtLjI0MS0uNy0uNmwtLjM1My00LjU4Mi01LjQ1NCA1LjQ1NGMtLjI1NS4yNTUtLjY2OC4yNTUtLjkyMyAwLS4yNTQtLjI1NS0uMjU0LS42NjggMC0uOTIybDUuNDU0LTUuNDU0ek0xMC4xMDcgMjUuOThsNC41ODEuMzUzYy4zNi4wMjguNjI4LjM0MS42LjctLjAyNy4zNi0uMzQuNjI4LS43LjZsLTUuOTk1LS40NmMtLjMyMS0uMDI1LS41NzYtLjI4LS42LS42bC0uNDYyLTUuOTk2Yy0uMDI3LS4zNi4yNDEtLjY3My42LS43LjM2LS4wMjguNjczLjI0LjcuNmwuMzUzIDQuNTgxIDUuNDU0LTUuNDU0Yy4yNTUtLjI1NC42NjgtLjI1NC45MjMgMCAuMjU0LjI1NS4yNTQuNjY4IDAgLjkyM2wtNS40NTQgNS40NTR6Ii8+Cjwvc3ZnPgo=');
      }

      &[selected='false'][state='over'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDM1IDM1Ij4KICAgIDxwYXRoIGZpbGw9IiMwM2I1ODUiIGQ9Ik0yNC43NjggOS4wODRsLTQuNTgxLS4zNTNjLS4zNi0uMDI3LS42MjgtLjM0LS42LS43LjAyNy0uMzYuMzQtLjYyOC43LS42bDUuOTk1LjQ2MWMuMzIxLjAyNS41NzYuMjguNi42bC40NjIgNS45OTZjLjAyNy4zNTktLjI0Mi42NzItLjYuNy0uMzYuMDI4LS42NzMtLjI0MS0uNy0uNmwtLjM1My00LjU4Mi01LjQ1NCA1LjQ1NGMtLjI1NS4yNTUtLjY2OC4yNTUtLjkyMyAwLS4yNTQtLjI1NS0uMjU0LS42NjggMC0uOTIybDUuNDU0LTUuNDU0ek0xMC4xMDcgMjUuOThsNC41ODEuMzUzYy4zNi4wMjguNjI4LjM0MS42LjctLjAyNy4zNi0uMzQuNjI4LS43LjZsLTUuOTk1LS40NmMtLjMyMS0uMDI1LS41NzYtLjI4LS42LS42bC0uNDYyLTUuOTk2Yy0uMDI3LS4zNi4yNDEtLjY3My42LS43LjM2LS4wMjguNjczLjI0LjcuNmwuMzUzIDQuNTgxIDUuNDU0LTUuNDU0Yy4yNTUtLjI1NC42NjgtLjI1NC45MjMgMCAuMjU0LjI1NS4yNTQuNjY4IDAgLjkyM2wtNS40NTQgNS40NTR6Ii8+Cjwvc3ZnPgo=');
      }

      &[selected='false'][state='down'] {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDM1IDM1Ij4KICAgIDxwYXRoIGZpbGw9IiMwM2I1ODUiIGQ9Ik0yNC43NjggOS4wODRsLTQuNTgxLS4zNTNjLS4zNi0uMDI3LS42MjgtLjM0LS42LS43LjAyNy0uMzYuMzQtLjYyOC43LS42bDUuOTk1LjQ2MWMuMzIxLjAyNS41NzYuMjguNi42bC40NjIgNS45OTZjLjAyNy4zNTktLjI0Mi42NzItLjYuNy0uMzYuMDI4LS42NzMtLjI0MS0uNy0uNmwtLjM1My00LjU4Mi01LjQ1NCA1LjQ1NGMtLjI1NS4yNTUtLjY2OC4yNTUtLjkyMyAwLS4yNTQtLjI1NS0uMjU0LS42NjggMC0uOTIybDUuNDU0LTUuNDU0ek0xMC4xMDcgMjUuOThsNC41ODEuMzUzYy4zNi4wMjguNjI4LjM0MS42LjctLjAyNy4zNi0uMzQuNjI4LS43LjZsLTUuOTk1LS40NmMtLjMyMS0uMDI1LS41NzYtLjI4LS42LS42bC0uNDYyLTUuOTk2Yy0uMDI3LS4zNi4yNDEtLjY3My42LS43LjM2LS4wMjguNjczLjI0LjcuNmwuMzUzIDQuNTgxIDUuNDU0LTUuNDU0Yy4yNTUtLjI1NC42NjgtLjI1NC45MjMgMCAuMjU0LjI1NS4yNTQuNjY4IDAgLjkyM2wtNS40NTQgNS40NTR6Ii8+Cjwvc3ZnPgo=');
        background-size: 90%;
      }

      &[selected='true'][state='down'] {
        background-size: 90%;
      }

      @include media-breakpoint-up(md) {
        background-size: cover;
        display: block;
        height: 30px;
        right: 10px;
        top: -42px;
        width: 30px;
      }

      @include media-breakpoint-up(lg) {
        width: 33px;
        height: 33px;
        right: 10px;
        top: unset;
        bottom: 13px;
      }
    }

    &.s7mixedmediaviewer .s7zoomresetbutton,
    &.s7mixedmediaviewer .s7setindicator {
      @include sr-only;
    }
  }
}

//----

.single-image {
  section.ni__prodcut-detail-model #s7mixedmedia_div {
    #s7mixedmedia_div_container {
      @include media-breakpoint-up(md) {
        height: 412px !important;
        margin-bottom: 65px;
        max-height: 412px !important;
        max-width: 540px !important;
      }

      @include media-breakpoint-up(lg) {
        height: 554px !important;
        max-height: 554px !important;
      }
    }
  }
}
