// ========================================================================
// Component: Dual Category Offerings
// @see https://organic-inc.atlassian.net/browse/NC-1474
// ========================================================================

// prettier-ignore
.ni__dual-category-offerings {
  
  // spacer below link columns to added bottom space
  .ni__dual-category-offerings--spacer {
    background-color: var(--off-white);
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    height: 84px;
    @include breakpoint('small')   { height: 101px; }
    @include breakpoint('desktop') { height: 102px; }
    @include breakpoint('large')   { height: 121px; }
  }

  // imgs are 100% width
  img { width: 100%; }

  // buttons are 100% width
  .ni__category-offering-item .ni__button {
    padding: 0 4px;
    width: 100%;
    min-width: 0;
  }

  // aem div button wrapper fix
  .ni__category-offering-item .ni__col-mobile-6.ni__flex.ni__flex--bottom div {
    width: 100%;
  }
  
  //_____________________________________
  // CopyAndImage--Light bottom spacing removal
  .ni__copy-and-image.ni__copy-and-image--light {
    padding-bottom: 0 !important;
  }
  
  //_____________________________________
  // CopyAndImage--Light Text Color
  .ni__copy-and-image.ni__copy-and-image--light {
    .ni__copy-and-image-title,
    .ni__copy-and-image-subtitle,
    .ni__copy-and-image-body-text {
      color: var(--forrest-green);
    }
  }
  
  //_____________________________________
  // Divider styles
  .ni__dual-category-offerings-divider {
    border-top: solid 1px var(--ni-nickel);
    border-bottom: 0;
    opacity: 0.22;
    width: 100%;
    margin: 32px auto 28px;
    @include breakpoint('small')   { margin: 59px auto 60px; }
    @include breakpoint('desktop') { margin: 84px auto 71px; }
    @include breakpoint('large')   { margin: 73px auto 76px; }

    //-- Divider wrapper
    &-container {
      background-color: var(--off-white);
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  //_____________________________________
  // Offering Items
  .ni__category-offering-items {
    background-color: var(--off-white);
    max-width: 1440px;
    margin: 0 auto;
  }

  //_____________________________________
  // Offering Items => Custom ni__container padding
  .ni__category-offering-items > .ni__container {
    @include breakpoint('desktop') { padding-left: 60px; padding-right: 60px; }
    @include breakpoint('large') { padding-left: 100px; padding-right: 100px; }
    @include breakpoint('max content') { padding-left: 20px; padding-right: 20px; }
  }

  //_____________________________________
  // Offering Items => Add top spacing to second col when stacked
  .ni__category-offering-items > .ni__container > .ni__row > div:nth-child(2) {
    @media (max-width: calc(#{map-get($breakpoints, 'desktop')} - 1px)) {
      margin-top: 60px;
    }
  }

  //_____________________________________
  // Offering Item
  .ni__category-offering-item {}

  //_____________________________________
  // Offering Item => Headline
  .ni__category-offering-item-headline {
    color: var(--forrest-green);
    margin: 17px 0 26px;
    @include breakpoint('small')   { margin: 0 0 16px; }
    @include breakpoint('desktop') { margin: 0 0 20px; }
    @include breakpoint('large')   { margin: 0 0 22px; }
  }

  //_____________________________________
  // Offering Item => List
  .ni__category-offering-item-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  //_____________________________________
  // Offering Item => List => List Item
  .ni__category-offering-item-list a {
    @include display-flex(column nowrap, flex-start, center);

    border-bottom: solid 1px var(--pale-green);
    display: block;
    font-family: FoundersGrotesk;
    font-size: 18px;
    position: relative;

    // Link Item => Padding
    padding: 18px 0 12px;
    @include breakpoint('small') { padding: 18px 0 22px; }
    @include breakpoint('large') { padding: 24px 0 22px; }
  }

  //_____________________________________
  // Offering Item => List => List Item => Arrow Bit
  .ni__category-offering-item-list a:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    @include breakpoint('small')   { bottom: 26px; }
    @include breakpoint('desktop') { bottom: 28px; }
    left: auto;
    right: 0;
    top: auto;
    height: 12px;
    width: 15px;
  }
  
  //_____________________________________
  // Offering Items => Large Breakpoint Buttons
  .ni__category-offering-items-large-button-row {
    margin-top: 40px;
    display: none;
    @include breakpoint('large') { display: flex; }
  }
}
