.pm-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 828px;
    padding-bottom: 178px;
    padding-top: 56px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1110px;
  }

  h2 {
    color: var(--forrest-green);
    font-family: FoundersGrotesk;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.33;
    margin: 0 0 9px;
  }

  h3 {
    color: var(--ni-green);
    font-family: SpaceMono;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 36px;
    margin-bottom: 11px;
    text-transform: uppercase;
  }

  p {
    color: var(--forrest-green);
    line-height: 24px !important;
    margin-bottom: 32px;
  }

  .iframe-container {
    p:not(.disclaimer) {
      font-family: FoundersGrotesk;
      font-size: 16px !important;
      line-height: 16px !important;
    }

    .ni__h5 {
      color: var(--forrest-green);
    }
  }

  .ni__price-container + p {
    font-family: FoundersGrotesk;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    margin-bottom: 22px;
  }

  // spacer clase not being used in styleguide but hidding it in case there's still aem templates using it.
  // See ticket: https://ni.visualstudio.com/IT/_boards/board/t/Organic%20-%20N2P/Work%20Items/?workitem=1736471
  .spacer {
    background-color: #fff;
    height: 56px;
    order: 1;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
    display: none;
  }

  .iframe-container,
  .desc-container {
    a {
      &:not(.ni__in-page-nav--1):not(.trigger):not(.download):not(.support-tab-cta):not(.distributor-link):not(.subscription-disclaimer) {
        @include type-class('.ni__body-text--3');

        color: var(--ni-green) !important;
        font-size: 16px !important;
        line-height: 16px !important;
        text-decoration: underline;

        &:hover {
          color: var(--forrest-green) !important;
          text-decoration: underline;
        }
      }
    }

    .collapsable {
      .body {
        a {
          color: var(--forrest-green) !important;
          text-decoration: none !important;

          p {
            margin-bottom: 0 !important;
          }

          &:hover {
            color: var(--ni-green) !important;
            text-decoration: none !important;

            p {
              @include type-class('.ni__body-text--3');

              color: var(--ni-green) !important;
              transition: 200ms ease-in-out;
            }
          }
        }
      }
    }

    .subscription-disclaimer {
      color: var(--ni-green) !important;
      text-decoration: underline;

      &:hover {
        color: var(--forrest-green) !important;
        text-decoration: underline;
      }
    }

    #customer-education-courses-details {
      p {
        @include media-breakpoint-between(mobile, tablet) {
          margin-bottom: 0px;
          padding-bottom: 80px;
        }

        @include media-breakpoint-down(mobile) {
          margin-bottom: 0px;
          padding-bottom: 64px;
        }
      }

      a {
        font-size: 18px !important;
      }
    }
  }

  .ct .gallery-container,
  .iframe-container,
  .desc-container {
    width: 100%;
  }

  .left-container {
    order: 2;

    @include media-breakpoint-up(md) {
      height: fit-content;
      position: sticky;
      top: 56px;
    }
  }

  .gallery-container {
    display: inline-block;
    order: 2;
    width: 100%;

    @include media-breakpoint-up(md) {
      float: left;
      max-width: 402px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 540px;
    }
  }

  .iframe-container {
    margin: 0 auto 64px auto;
    order: 3;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }

    .media-warning {
      display: flex;
      height: auto;
      padding: 15px 0;
      width: 100%;

      span {
        &.img {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%2303B585' fill-rule='evenodd' d='M1.43 8.765c0 3.682 2.984 6.667 6.666 6.667 3.682 0 6.667-2.985 6.667-6.667S11.778 2.1 8.096 2.1 1.43 5.083 1.43 8.765zm12 0c0 2.946-2.388 5.334-5.334 5.334-2.945 0-5.333-2.388-5.333-5.334 0-2.945 2.388-5.333 5.333-5.333 2.946 0 5.334 2.388 5.334 5.333zm-6 0c0-.368.298-.666.666-.666.369 0 .667.298.667.666v2.667c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667V8.765zm-.167-2.5c0 .46.373.834.833.834.46 0 .834-.373.834-.834 0-.46-.373-.833-.834-.833-.46 0-.833.373-.833.833z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
          display: flex;
          flex-basis: 16px;
          height: 16px;
          margin-right: 4px;
          width: 16px;
        }
      }

      p {
        font-family: FoundersGrotesk;
        font-size: 14px !important;
        left: 17.5px;
        line-height: 16px !important;
        margin-bottom: 0 !important;

        strong {
          font-weight: 500;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      margin-bottom: 80px;
      max-width: 584px;
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      float: right;
      height: fit-content;
      max-width: 331px;
      padding-bottom: 150px;
      position: sticky;
      top: 56px;
      z-index: 1;
    }
    @include media-breakpoint-up(lg) {
      max-width: 445px;
    }
  }

  .desc-container {
    clear: left;
    margin: 0 auto;
    order: 4;
    overflow: hidden;
    padding: 0 20px;

    @include media-breakpoint-up(sm) {
      max-width: 584px;
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      margin: 0;
      max-width: 402px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 540px;
    }

    .ni__body-text--3.description {
      margin-bottom: 1rem;
    }

    .ni__nav-container {
      margin-top: 20px !important;

      .ni__in-page-nav--1 {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }

    .ni__button.ni__button--outline {
      margin-bottom: 36px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .pm-full {
    order: 5;
    margin: 64px auto 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-top: 80px;
      max-width: 584px;
      padding-bottom: 120px;
    }

    @include media-breakpoint-up(md) {
      max-width: 826px;
      order: 4;
      padding-bottom: 0;
      position: relative;
      z-index: 100;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1110px;
    }
  }

  .ni__h6 {
    color: #02542c;
  }
}
