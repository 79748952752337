.image-component img {
  height: auto;
  margin: 0 0 20px 0.6px;
  width: 100%;
}

.image-wrapper {
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  @include breakpoint('small') {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.image-wrapper img {
  height: 500px;
  margin: 0 0 20px 0.6px;
  width: auto;
  @include breakpoint('small') {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .image-wrapper::-webkit-scrollbar {
    height: 5px;
  }

  .image-wrapper::-webkit-scrollbar-track {
    background-color: var(--off-white);
    box-shadow: inset 0 0 0 1px var(--white-three);
  }

  .image-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--ni-green);
    border-radius: 15px;
  }
}

.photo-caption {
  padding: 0 0 30px 0;
}

.photo-caption span {
  width: 100%;
  color: var(--forrest-green);
  flex-grow: 0;
  font-family: FoundersGrotesk;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 18px;
  letter-spacing: normal;
  line-height: 1.14;
  text-align: left;
}

.scroll-caption {
  width: 100%;
  text-align: left;
  margin: 16px 0 0 0;
  @include breakpoint('small') {
    margin: 0;
  }
}
