// ========================================================================
// Component: Button
// Forked via https://getuikit.com/
// ========================================================================

// settings
$NI__Button-Border-Size: 1px;
$NI__Button-Border-Radius-Alt: 10px 0 10px 15px;
$NI__Button-Border-Radius: 15px 10px 10px 0;
$NI__Button-Font-Family: map-get($fonts, 'sans');
$NI__Button-Font-Size: 18px;
$NI__Button-Font-Weight: 500;
$NI__Button-Line-Height: 2.45;
$NI__Button-Padding: 0 28px;
$NI__Button-Min-Width: 160px;

//
// ========================================================================
// settings => primary
$NI__Button-Primary-BG-Color: var(--power-green);
$NI__Button-Primary-Text-Color: var(--forrest-green);

//
// ========================================================================
// settings => secondary
$NI__Button-Secondary-BG-Color: var(--forrest-green);
$NI__Button-Secondary-Text-Color: var(--off-white);

//
// ========================================================================
// settings => outline
$NI__Button-Outline-BG-Color: transparent;
$NI__Button-Outline-Text-Color: var(--forrest-green);
$NI__Button-Outline-Border-Color: var(--ni-green);

//
// ========================================================================
// settings => hover and click
$NI__Button-Hover-BG-Color: var(--ni-green);
$NI__Button-Hover-Text-Color: var(--off-white);
$NI__Button-Hover-Border-Color: var(--ni-green);

$NI__Button-Focus-Text-Color: var(--power-green);
$NI__Button-Focus-BG-Color: var(--forrest-green);
$NI__Button-Focus-Border-Color: var(--power-green);

//
// ========================================================================
// settings => controller
$NI__Button-Controller-Size: 48px;
$NI__Button-Controller-Border-Size: 0.7px;
$NI__Button-Controller-Border-Color: var(--forrest-green);
$NI__Button-Controller-Arrow-Color: var(--ni-green);

// base button
// ========================================================================
.ni__button {
  // Remove margins in Chrome, Safari and Opera.
  margin: 0;

  // Default border for application.
  border: $NI__Button-Border-Size solid transparent;

  // Address `overflow` set to `hidden` in IE.
  overflow: visible;

  // Correct `font` properties and `color` not being inherited for `button`.
  font: inherit;

  // Remove the inheritance of text transform in Edge, Firefox, and IE.
  text-transform: none;

  // Style
  appearance: none;
  background: inherit;
  border-radius: $NI__Button-Border-Radius;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-family: $NI__Button-Font-Family;
  font-size: $NI__Button-Font-Size;
  font-style: normal;
  font-weight: $NI__Button-Font-Weight;
  min-width: $NI__Button-Min-Width;
  padding: $NI__Button-Padding;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;

  // `line-height` is used to create a height because it also centers
  // the text vertically for `a` elements. Better would be to use height and
  // flexbox to center the text vertically but flexbox doesn't work in
  // Firefox on `button` elements.
  line-height: $NI__Button-Line-Height;
  height: $NI__Button-Line-Height;

  // Align text if button has a width
  text-align: center;

  // Required for `a`.
  text-decoration: none;

  // transition props
  transition: 100ms ease-in-out;
  transition-property: background, border-color, color;
  will-change: background, border-color, color;

  // Hovers
  &:hover {
    color: inherit;
    text-decoration: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

// primary variant & states
// ========================================================================
.ni__button--primary {
  background: $NI__Button-Primary-BG-Color;
  color: $NI__Button-Primary-Text-Color;
}

// secondary variant & states
// ========================================================================
.ni__button--secondary {
  background: $NI__Button-Secondary-BG-Color;
  color: $NI__Button-Secondary-Text-Color;
}

// outline variant & states
// ========================================================================
.ni__button--outline {
  background: $NI__Button-Outline-BG-Color;
  border-color: $NI__Button-Outline-Border-Color;
  color: $NI__Button-Outline-Text-Color;
}

// transition hover/focus styles
// ========================================================================
.ni__button--primary,
.ni__button--secondary,
.ni__button--outline {

  &:hover,
  &-hover,
  &-hover:hover {
    background: $NI__Button-Hover-BG-Color;
    border-color: $NI__Button-Hover-Border-Color;
    color: $NI__Button-Hover-Text-Color;

    span {
      color: $NI__Button-Hover-Text-Color;
    }
  }

  &:active,
  &-active,
  &-active:hover {
    background: $NI__Button-Focus-BG-Color;
    border-color: $NI__Button-Focus-Border-Color;
    color: $NI__Button-Focus-Text-Color;

    span {
      color: $NI__Button-Focus-Text-Color;
    }
  }

  &:focus,
  &-focus,
  &-focus:focus {
    background: $NI__Button-Hover-BG-Color;
    border-color: $NI__Button-Hover-Border-Color;
    color: $NI__Button-Hover-Text-Color;

    span {
      color: $NI__Button-Hover-Text-Color;
    }
  }
}

// controller variant & states
// ========================================================================
// prettier-ignore
.ni__button--controller {
  background: none;
  border-style: solid;
  border-width: $NI__Button-Controller-Border-Size;
  border-color: $NI__Button-Controller-Border-Color;
  border-top-left-radius: 10px;
  border-top-right-radius: 15px;
  color: $NI__Button-Controller-Arrow-Color;
  font-family: sans-serif;
  height: $NI__Button-Controller-Size;
  line-height: 1;
  min-width: 0;
  padding: 0;
  width: $NI__Button-Controller-Size;
  transition: transform 100ms ease-in-out;
  @include display-flex(column nowrap, center, center);

  svg,
  svg g {
    stroke: $NI__Button-Controller-Arrow-Color;
    transition: transform 100ms ease-in-out;
  }

  &:hover,
  &-hover,
  &-hover:hover,
  &-hover:focus {
    background: $NI__Button-Hover-BG-Color;
    border-color: $NI__Button-Hover-Border-Color;
    transform: scale(1);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }

  &:active,
  &-active,
  &-active:hover {
    background: none;
    border-color: $NI__Button-Controller-Border-Color;
    transform: scale(0.9);

    svg,
    svg g {
      stroke: $NI__Button-Controller-Arrow-Color;
    }
  }

  &.ni__button--controller-left {
    border-radius: $NI__Button-Border-Radius-Alt;

    svg {
      transform: rotate(180deg);
    }
  }

  &+.ni__button--controller {
    margin-left: 10px;
  }
}

// controller => dark theme
// ========================================================================
// prettier-ignore
.ni__button--controller-dark {
  background: none;
  border-color: var(--white-three) !important;

  svg,
  svg g {
    stroke: var(--white-three);
  }

  &:hover {
    background: var(--power-green);
    border-color: var(--power-green) !important;
    transform: scale(1);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }

  &:active,
  &:focus,
  &.ni__button--controller-active,
  &.ni__button--controller-active:hover,
  &.ni__button--controller-active:focus {
    background: none;
    border-color: var(--white-three) !important;
    transform: scale(0.9);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }

  &.ni__button--controller-hover,
  &.ni__button--controller-hover:hover,
  &.ni__button--controller-hover:focus {
    background: var(--power-green);
    border-color: var(--power-green) !important;
    transform: scale(1);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }
}

// controller => pale theme
// ========================================================================
// prettier-ignore
.ni__button--controller-pale {
  background: none;
  border-color: var(--forrest-green);

  svg,
  svg g {
    stroke: var(--forrest-green);
  }

  &:hover {
    background: var(--forrest-green);
    border-color: var(--forrest-green);
    transform: scale(1);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }

  &:active,
  &:focus,
  &.ni__button--controller-active,
  &.ni__button--controller-active:hover,
  &.ni__button--controller-active:focus {
    background: none;
    border-color: var(--forrest-green);
    transform: scale(0.9);

    svg,
    svg g {
      stroke: var(--forrest-green);
    }
  }

  &.ni__button--controller-hover,
  &.ni__button--controller-hover:hover,
  &.ni__button--controller-hover:focus {
    background: var(--forrest-green);
    border-color: var(--forrest-green);
    transform: scale(1);

    svg,
    svg g {
      stroke: var(--white-three);
    }
  }
}

// link => default theme
// ========================================================================
// prettier-ignore
.ni__button--link {
  color: var(--forrest-green);
  border-bottom: solid 1px var(--pale-green);
  border-radius: 0;
  padding: 0;
  font-size: 24px;
  line-height: 2.45;
  text-align: left;
  font-weight: normal;
  min-width: 160px;

  &:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    bottom: 22px;
    left: auto;
    right: 0;
    top: auto;
    height: 12px;
    width: 15px;

    @media (max-width: calc(#{map-get($breakpoints, 'small')} - 1px)) {
      display: none;
    }
  }
}

// link => dark theme
// ========================================================================
// prettier-ignore
.ni__button--link-dark {
  color: var(--power-green);
  font-size: 16px;
  line-height: 2.5;

  &:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%23FFFFFF' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    bottom: 14px;
  }
}

/**
 * ===================
 * This mixin allows us to @include a specific
 * button class defined above in any component.
 * e.g. @include button-style('.ni__button.ni__button--outline')
 * ===================
 */
@mixin button-style($string) {
  @extend #{$string} !optional;
}

.ni-design-system-button {
  font-family: FoundersGrotesk;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  padding: 15px 28px;
  line-height: 18px;
  text-transform: none;
  position: relative;

  &.ni-disabled-button {
    pointer-events: none;
    cursor: default;
  }

  &.ni-compact-button {
    font-size: 16px;
    padding: 10px 20px;
    line-height: 16px;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    transition: ease-out .125s;
  }

  &.ni-btn {
    /* .ni-btn forbids line breaks by default. Override that behavior for Design System buttons */
    white-space: normal;
  }

  &.ni-primary-button-round {
    
    & {
      color: #f6f6f6 !important;
      background-color: #009b65;
      border: none;
      
      &:hover,
      &:focus {
        background-color: rgba(0, 107, 70, 0.9);
      }
      
      &:active {
        background-color: #006b46;
      }

      &.ni-disabled-button {
        color: #ffffff!important;
        background-color: #e4e4e4;
      }
    }

    :is(#{$dark-background-selector}) & {
      color: #333333 !important;
      background-color: #32eb96;
      border: none;

      &:hover,
      &:focus {
        background-color: rgba(50, 235, 150, 0.9);
      }

      &:active {
        background-color: #60e7a5;
      }

      &.ni-disabled-button {
        color: #767676!important;
        background-color: #e4e4e4;
      }
    }
  }

  &.ni-secondary-button-round {
    & {
      color: #009b65 !important;
      border: solid 1px #009b65;

      &:hover,
      &:focus {
        background-color: rgba(204, 220, 200, 0.2);
      }

      &:active {
        background-color: rgba(204, 220, 200, 0.3);
      }

      &.ni-disabled-button {
        color: #e4e4e4!important;
        border-color: #e4e4e4;
      }
    }

    :is(#{$dark-background-selector}) & {
      color: #32eb96 !important;
      background-color: transparent;
      border: solid 1px #32eb96;

      &:hover,
      &:focus {
        background-color: rgba(204, 220, 200, 0.2);
      }

      &:active {
        background-color: rgba(204, 220, 200, 0.3);
      }

      &.ni-disabled-button {
        color: #767676!important;
        border-color: #767676;
      }
    }

    :is(#{$pale-background-selector}) & {
      color: #006b46 !important;
      background-color: transparent;
      border: solid 1px #006b46;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.4);
      }

      &.ni-disabled-button {
        color: #afafaf!important;
        border-color: #afafaf;
      }
    }
  }

  &.ni-tertiary-button-round {
    &::after {
      font-family: "Font Awesome 5 Pro";
      display: inline-block;
      margin-left: 8px;
      content: "\f054";
    }

    & {
      border: none;
      color: #009b65 !important;

      &.ni-tertiary-button-with-container {
        &:hover,
        &:focus {
          background-color: rgba(204, 220, 200, 0.2);
        }

        &:active {
          background-color: rgba(204, 220, 200, 0.3);
        }
      }

      &.ni-tertiary-button-containerless {
        &:hover,
        &:focus {
          color: rgba(0, 107, 70, 0.9) !important;
        }

        &:active {
          color: #006b46 !important;
        }
      }

      &.ni-disabled-button {
        color: #e4e4e4!important;
      }
    }

    :is(#{$dark-background-selector}) & {
      border: none;
      color: #32eb96 !important;

      &:hover,
      &:focus {
        background-color: rgba(204, 220, 200, 0.2);
      }

      &:active {
        background-color: rgba(204, 220, 200, 0.3);
      }

      &.ni-disabled-button {
        color: #767676!important;
      }
    }

    :is(#{$pale-background-selector}) & {
      border: none;
      color: #006b46 !important;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.4);
      }

      &.ni-disabled-button {
        color: #afafaf!important;
      }
    }
  }

  &.ni-button-icon-internal {
    --ni-button-icon: "\f054";
  }

  &.ni-button-icon-external {
    --ni-button-icon: "\f08e";
  }

  &.ni-button-icon-download {
    --ni-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24"><path fill="%23009b65" fill-rule="evenodd" d="M11.37 3v9.586L8.077 9.293a1 1 0 0 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-1.414-1.414l-3.293 3.293V3a1 1 0 1 0-2 0Zm10 11a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1Z" clip-rule="evenodd"></path></svg>');
    --ni-button-icon-width: 20px;

    &.ni-primary-button-round {
      /* primary buttons need to use an off-white fill */
      --ni-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24"><path fill="%23f6f6f6" fill-rule="evenodd" d="M11.37 3v9.586L8.077 9.293a1 1 0 0 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5a1 1 0 0 0-1.414-1.414l-3.293 3.293V3a1 1 0 1 0-2 0Zm10 11a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1h-14a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1Z" clip-rule="evenodd"></path></svg>');
    }

    &.ni-compact-button {
      --ni-button-icon-width: 16px;
    }
  }

  &.ni-leading-icon::before, &.ni-trailing-icon::after {
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    content: var(--ni-button-icon);
    vertical-align: middle;
    width: var(--ni-button-icon-width);
  }

  &.ni-leading-icon::before {
    margin-right: 8px;
  }

  &.ni-trailing-icon::after {
    margin-left: 8px;
  }
}

.link a.ni-link-component, .link span.ni-link-component {
  text-decoration: none !important;
  position: relative;
  display: inline-block;
  color: var(--interactive-text-color);

  &::after {
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    margin-left: 4px;
  }

  &.ni-internal-link::after {
    content: "\f054";
  }

  &.ni-external-link::after {
    content: "\f08e";
  }

  &.ni-show-visited-links:visited {
    color: #804ad9;
  }

  &:hover {
    text-decoration: underline !important;
  }

  &.ni-quiet-link {
    text-decoration: underline !important;
    color: var(--non-interactive-text-color);

    &:hover {
      text-decoration: none !important;
    }
  }

  $icon-sizes: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48;

  @each $icon-size in $icon-sizes {
    &.ni-link-icon-#{$icon-size}::after {
      font-size: #{$icon-size}px;
    }
  }

}
