// ========================================================================
// Component: Breadcrumb
// ========================================================================
.ni__breadcrumb {
  padding-top: 10px;
  padding-bottom: 10px;

  span,
  a {
    color: var(--interactive-text-color);
    float: inherit;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    @include type-class('.ni__eyebrow--3');
  }

  .delimiter {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
}
