.ni__headline-sidebar {
  margin: 0 auto;
  max-width: 335px;

  @include media-breakpoint-up(sm) {
    max-width: 100%;
  }

  h1 {
    color: var(--forrest-green);
    font-family: FinancierDisplay;
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 40px;
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .text {
    color: var(--forrest-green);
    font-family: FoundersGrotesk;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 22px;
    margin-bottom: 40px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .right-side {
    border-left: 3px var(--ni-green) solid;
    margin-top: 0;
    padding-left: 30px;

    @include media-breakpoint-up(sm) {
      margin-top: 5px;
      padding-left: 15px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 12px;
      padding-left: 30px;
    }
  }

  .bold {
    color: var(--forrest-green);
    font-family: FoundersGrotesk;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 16px;
    margin-bottom: 32px;
    text-align: left;

    @include media-breakpoint-up(sm) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 32px;
    }
  }
}
