.ni__nav-list {
  font-family: map-get($fonts, 'sans');

  a {
    border-bottom: solid 1px var(--pale-green);
    padding: 20px 0;
    display: block;
    width: 100%;
    padding-right: 15px;
    position: relative;
    color: var(--interactive-text-color);
  }

  &.is-inline > div {
    max-width: 100%;
    width: 100%;
    display: block;
  }
}

@include media-breakpoint-up(md) {
  .ni__nav-list {
    a {
      padding-top: 12px;
      padding-bottom: 11px;
    }

    &:not(.ni__nav-list--no-arrows) a::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      position: absolute;
      top: 40%;
      right: 0;
      height: 12px;
      width: 15px;
    }
  }

  .is-dark .ni__nav-list:not(.ni__nav-list--no-arrows) a::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%23FFF' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

// Accordion styles

.ni__navlist-accordion .ni__accordion {
  > li {
    border-top: none;
    border-bottom: none;
  }

  > li a {
    border-bottom: 1px solid rgba(#03b585, 0.22);
  }

  .ni__nav-list a {
    // color: var(--forrest-green);
    @include type-class('.ni__text-link--1');
  }

  .uk-accordion-title:before {
    margin-top: 3px;
  }

  .uk-accordion-content {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    width: 100%;
    > li {
      flex-grow: 1;
      flex-basis: 0;

      .ni__nav-list {
        margin-right: 27px;
      }

      &:last-child .ni__nav-list {
        margin-right: 0;
      }
    }

    .ni__nav-list a {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .uk-accordion-content {
      display: block !important;
    }

    .uk-accordion-title {
      pointer-events: none;
    }

    .uk-accordion-title:before {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 90px;
    max-width: 1440px;
    margin: 0 auto 0;
  }
}
