.ni__disclaimer {
  .heading {
    color: var(--ni-green);
    font-family: FoundersGrotesk;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 10px;
    text-align: left;
  }

  .text {
    color: var(--forrest-green);
    font-family: FoundersGrotesk;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.14 !important;
    text-align: left;
  }
}
