.ni__image__description {
  margin: 10px 0 0 0;
}

.ni__image__description p {
  color: var(--forrest-green);
  word-break: break-word;
  @include type-class('.ni__small-detail--4');

  &.ni__image__description__title {
    @include type-class('.ni__small-detail--5');
  }
}
