// ========================================================================
// Component: Audio
// ========================================================================

.ni__audio-js {
  // position: relative;
  height: auto !important;
  width: 100%;
  z-index: 1;
  top: 0;

  // height: 320px;
  // @include breakpoint('desktop') {
  //   height: 555px;
  // }

  .vjs-tech {
    position: relative !important;
    height: auto !important;
  }

  .vjs-poster {
    display: block !important;
    background-size: cover !important;
  }
}

.ni__audio-js .bc-player-default_default:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
