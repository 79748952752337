// ========================================================================
// Component: HeroHub
// ========================================================================

.ni__hero-hub {
  margin: 24px auto 0 auto;
  .button.parbase {
    text-align: left;
    padding: 0;
  }
}

.ni__hero-hub--title {
  color: var(--forrest-green);
  max-width: 330px;
  padding: 8px 20px 0 20px;
  margin-bottom: 20px;
  position: relative;
}

.ni__hero-hub--title-wrapper > div {
  margin-bottom: 10px;
}

.ni__hero-hub--image {
  background-color: #f4f4f4;
  margin-bottom: 34px;

  .parbase div > div {
    height: 300px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.ni__hero-hub--content {
  padding: 0 20px;
  p {
    color: var(--forrest-green);
    margin-bottom: 35px;
  }

  >div {
    margin-bottom: 10px;
  }
}

// theme
.ni__hero-hub.is-dark {
  .ni__hero-hub--image {
    height: 300px;
    background-color: var(--pale-green);
    .parbase div > div {
      width: 274px;
      height: 144px;
      margin-top: 72px;
      margin-left: 20px;
    }
  }
}

.ni__hero-hub.is-alternate {
  .ni__hero-hub--content {
    display: none;
  }

  .ni__hero-hub--title:after {
    display: none;
  }

  .ni__hero-hub--image {
    margin-bottom: 0;
    .parbase div > div {
      background-size: 335px;
      background-position-x: right;
    }
  }
}

.ni__hero-hub.is-darkgreen {
  @include breakpoint('desktop') {
    background-color: #022b25;
  }

  .ni__hero-hub--title,
  .ni__body-text--3 {
    @include breakpoint('desktop') {
      color: var(--off-white);
    }
  }

  .ni__hero-hub--image {
    background-color: #022b25;
    @include breakpoint('desktop') {
    }
  }
}

// tablet up
@include breakpoint('small') {
  .ni__hero-hub {
    max-width: 584px;
  }

  .ni__hero-hub--image .parbase div > div {
    height: 465px;
  }

  .ni__hero-hub--title {
    padding-top: 25px;

    // &:after {
    //   width: 29px;
    //   height: 29px;
    //   top: 0;
    //   left: -29px;
    // }
  }

  .ni__hero-hub--title,
  .ni__hero-hub--content {
    padding-left: 0;
    padding-right: 0;
  }

  // theme
  .ni__hero-hub.is-dark {
    .ni__hero-hub--image {
      background-color: var(--pale-green);
      height: 465px;
      .parbase div > div {
        width: 434px;
        height: 228px;
        margin-top: 172px;
        margin-left: 100px;
      }
    }
  }

  .ni__hero-hub.is-alternate {
    .ni__hero-hub--image {
      .parbase div > div {
        background-size: contain;
      }
    }

    .ni__hero-hub--title {
      max-width: 383px;
      margin-bottom: 33px;
    }
  }
}

// desktop up
@include breakpoint('desktop small') {
  .ni__hero-hub {
    background-color: var(--off-white);
    max-width: 100%;
    display: flex;
    position: relative;
    min-height: 354px;
    overflow: hidden;
    padding-top: 47px;
    padding-bottom: 47px;
    padding-left: 100px;
    margin-top: 56px;
  }

  .ni__hero-hub--image {
    position: absolute;
    right: -80px;
    top: 50%;
    margin-top: -140px;
    z-index: 0;
    .parbase div > div {
      width: 353px;
      height: 281px;
    }
  }

  .ni__hero-hub--title-wrapper {
    position: relative;
    padding-top: 15px;
    padding-right: 48px;
    margin-right: 48px;
    max-width: 378px;

    // prettier-ignore
    &:before {
      // @if $UseGraphicBit == true { content: ''; }
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 260px;
      width: 1px;
      background-color: var(--pale-green);
    }
  }

  .ni__hero-hub--content {
    max-width: 330px;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
  }

  // theme
  .ni__hero-hub.is-dark {
    background-color: var(--pale-green);
    padding-left: 98px;

    .ni__hero-hub--image {
      background-color: var(--pale-green);
      height: 100%;
      right: 0;
      margin: 0;
      top: 0;
      .parbase div > div {
        width: 259px;
        height: 136px;
        margin-top: 175px;
        margin-right: 30px;
      }
    }

    .ni__hero-hub--title-wrapper {
      align-items: center;
      display: flex;
      padding: 0;
      width: 331px;

      h2 {
        padding: 0;
        margin: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  .ni__hero-hub.is-alternate {
    .ni__hero-hub--title {
      max-width: 402px;
    }

    .ni__hero-hub--title-wrapper {
      max-width: 402px;
      margin-right: 0;
      padding-right: 0;
      &:before {
        display: none;
      }
    }

    .ni__hero-hub--image {
      margin-top: -136px;
      right: 0;
      .parbase div > div {
        width: 500px;
        height: 272px;
      }
    }
  }
}

// large up
@include breakpoint('large') {
  .ni__hero-hub {
    border-bottom-left-radius: 400px;
    background-color: var(--off-white);
    min-height: 377px;
    padding-left: 220px;
    padding-top: 56px;
    padding-bottom: 56px;
    max-width: 1440px;
  }

  .ni__hero-hub--title-wrapper {
    padding-right: 63px;
    margin-right: 63px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 440px;
  }

  .ni__hero-hub--title {
    max-width: 446px;
  }

  // theme
  .ni__hero-hub.is-dark {
    padding-left: 0;

    .ni__hero-hub--image {
      background-color: var(--pale-green);
      .parbase div > div {
        width: 385px;
        height: 202px;
        margin-top: 116px;
        margin-right: 30px;
      }
    }

    .ni__hero-hub--title-wrapper {
      width: 400px;
      margin-right: 45px;
      padding-right: 45px;
      &:before {
        background-color: var(--ni-green);
      }
    }

    .ni__hero-hub--content {
      margin-right: 130px;
      max-width: 280px;
    }
  }

  .ni__hero-hub.is-alternate {
    padding-left: 190px;

    .ni__hero-hub--title {
      max-width: 634px;
    }

    .ni__hero-hub--title-wrapper {
      max-width: 634px;
    }

    .ni__hero-hub--image {
      margin-top: -154px;
      right: 0;
      .parbase div > div {
        width: 500px;
        height: 308px;
      }
    }
  }

  .ni__hero-hub.is-dark {
    .ni__hero-hub--title-wrapper {
      margin-left: 150px;
    }
  }
}

@include breakpoint('max content') {
  .ni__hero-hub {
    width: 100%;
    padding-left: 290px;
  }

  .ni__hero-hub--image {
    right: -60px;
    margin-top: -186px;
    .parbase div > div {
      width: 468px;
      height: 372px;
    }
  }

  // .ni__hero-hub--title:after {
  //   content: '';
  //   position: absolute;
  //   display: block;
  //   border-top-left-radius: 300px;
  //   background-color: var(--ni-green);
  //   width: 29px;
  //   height: 29px;
  //   top: 0;
  //   left: -29px;
  // }

  // theme
  .ni__hero-hub.is-dark {
    background-color: var(--pale-green);
    padding-left: 240px;

    .ni__hero-hub--title-wrapper {
      width: 470px;
      margin-left: 0;
    }

    .ni__hero-hub--content {
      margin-right: 170px;
      max-width: 340px;
    }
  }

  .ni__hero-hub.is-alternate {
    .ni__hero-hub--image .parbase div > div {
      width: 610px;
    }
  }
}
