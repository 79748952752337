// ========================================================================
// Component: Balde Product
// ========================================================================

.ni__blade-product {
  max-width: 333px;
  margin: 0 auto;
  color: var(--forrest-green);

  // themes
  &.is-dark {
    background-color: var(--forrest-green);
    .ni__blade-product--content {
      p {
        color: var(--white-three);
      }
      a {
        color: var(--power-green);
      }
    }
  }
  // themes
  &.is-light {
    background-color: var(--off-white);
  }

  .parbase {
    overflow: visible;
  }

  // fixes disappearing a tag bug
  a.ni__button--outline:not([href]),
  a.ni__button--outline:not([href]):hover {
    color: var(--forrest-green);
  }
}

.ni__blade-product--header {
  background-color: var(--white-three);
  padding: 0 0 39px 0;
  h2,
  h3 {
    color: var(--forrest-green);
    margin: 0;
  }

  h2 {
    margin-bottom: 71px;
  }

  // title
  h3 {
    max-width: 246px;
    position: relative;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   width: 15px;
    //   height: 15px;
    //   display: block;
    //   background-color: var(--ni-green);

    //   top: -18px;
    //   right: -20px;
    //   border-top-right-radius: 300px;
    // }
  }
}

.ni__blade-product--content {
  color: var(--white-three);
  padding: 26px 0 0px 0;

  p {
    color: var(--forrest-green);
  }
}

.ni__blade-product--image .parbase div > div {
  width: 335px;
  height: 210px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
}

.ni__blade-product--buttons {
  > div {
    width: 157px;
    display: inline-block;
    &:first-child {
      margin-right: 14px;
    }
  }

  .ni__button {
    margin-top: 26px;
    min-width: 157px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

@include media-breakpoint-up(sm) {
  .ni__blade-product {
    max-width: 582px;
    margin: 0 auto;
  }

  .ni__blade-product--image .parbase div > div {
    width: 501px;
    height: 313px;
    margin-left: 35px;
  }

  .ni__blade-product--header {
    padding-bottom: 55px;

    h3:before {
      width: 26px;
      height: 26px;
      top: -28px;
      right: -90px;
    }
  }

  .ni__blade-product--content {
    padding: 56px 0 0 0;
  }

  .ni__blade-product--buttons {
    > div {
      width: 184px;
      &:first-child {
        margin-right: 20px;
      }
    }

    .ni__button {
      min-width: 184px;
      margin-top: 35px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__blade-product {
    max-width: map-get($breakpoints, 'max content');
    position: relative;
  }

  .ni__blade-product--container {
    margin-left: 99px;
    max-width: 425px;
  }

  .ni__blade-product--image .parbase div > div {
    width: 401px;
    height: 250px;
    margin-top: -125px;
    position: absolute;
    right: 99px;
    top: 50%;
  }

  .ni__blade-product--header {
    padding: 0;
    background-color: transparent;
    h2 {
      margin-bottom: 28px;
    }

    h3:before {
      width: 15px;
      height: 15px;
      top: -10px;
      // right: -90px;
    }
  }

  .is-dark .ni__blade-product--header {
    h2,
    h3 {
      color: var(--white-three);
    }
  }

  .ni__blade-product--content {
    padding: 24px 0 0 0;
    p {
      max-width: 331px;
    }
  }
}

// @include media-breakpoint-up(lg) {
//   .ni__blade-product .ni__blade-product--container {
//     margin-left: 0;
//   }

//   .ni__blade-product .ni__blade-product--image .parbase div > div {
//     width: 540px;
//     height: 337px;
//     right: 0;
//     margin-top: -158px;
//   }
// }

@include media-breakpoint-up(lg) {
  .ni__blade-product {
    // max-width: 1110px;
    justify-content: center;
  }

  .ni__blade-product--header {
    h2 {
      margin-bottom: 50px;
    }

    h3:before {
      width: 26px;
      height: 26px;
      top: -28px;
      right: -90px;
    }
  }

  .ni__blade-product--container {
    margin-left: 0;
    max-width: 350px;
  }

  .ni__blade-product--image .parbase div > div {
    width: 540px;
    height: 337px;
    right: 0;
    margin-top: -158px;
  }

  .ni__blade-product--content {
    p {
      max-width: 350px;
    }
  }

  .ni__blade-product--buttons {
    > div {
      width: 159px;
      &:first-child {
        margin-right: 20px;
      }
    }

    .ni__button {
      min-width: 159px;
    }
  }
}
