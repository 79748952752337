// ========================================================================
// VARIABLES => DEFINITIONS => COLOR VARS
// ========================================================================

// map used for utility loops
$colors: (
  --category-colors-category-1: #ccdcc8,
  --category-colors-category-2: #03b585,
  --category-colors-category-3: #a5d8c8,
  --category-colors-category-4: #a5dded,
  --charcoal: #333333,
  --dark-gray-76: #767676,
  --dark-gray: #444,
  --digital-green: #009B65,
  --digital-green-dark: #006B46,
  --deep-lilac: #8e78b7,
  --forrest-green: #044123,
  --gray-100: #b2b2b2,
  --gray-20: #f4f4f4,
  --gray-40: #f7f7f7,
  --gray-60: #ddd,
  --gray-80: #ccc,
  --greyish: #b2b2b2,
  --labview-yellow-content: #fbda32,
  --labview-yellow: #ffbb00,
  --light-charcoal: #686868,
  --light-grey: #afafaf,
  --nasty-green: #76bc43,
  --ni-blue: #065fa3,
  --ni-copper: #eb8768,
  --ni-gold: #d2b42a,
  --ni-green: #03b585,
  --ni-lightblue: #3498db,
  --ni-navyblue: #0e385f,
  --ni-nickel: #aca09a,
  --ni-orange: #d73a0f,
  --ni-platinum: #8ad0c8,
  --ni-powercopper-light: #f5989e,
  --ni-red: #f04e23,
  --ni-silver: #cde6ef,
  --off-white: #f4f4f4,
  --pale-green: #ccdcc8,
  --pale-grey: #e4e4e4,
  --pinkish-grey: #cccccc,
  --power-copper: #ef463f,
  --power-gold: #ffc60b,
  --power-green: #32eb96,
  --power-nickel: #e54c8a,
  --power-platinum: #00aba5,
  --power-silver: #3cb4e7,
  --sky-light: #a5dded,
  --soft-purple: #aa5ea5,
  --white-three: #ffffff,
  --white-two: #e7e7e7,
  --white: #dddddd
);

:root {
  @each $name, $definition in $colors {
    #{$name}: #{$definition};
  }
}
