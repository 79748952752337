// ========================================================================
// Component: LeadQuote
// ========================================================================

// settings
$NI__leadQuote-bg__color: #ccdcc8;
$NI__leadQuote-color: var(--forrest-green);
$NI__leadQuote-font: map-get($fonts, 'serif');

$NI__leadQuoteImage-path__w: 663px;
$NI__leadQuoteImage-path__h: 1521px;

.ni__lead-quote-image__path {
  display: none;
  position: absolute;
  left: calc((#{$NI__leadQuoteImage-path__w} + 30px) * -1);
  top: calc((#{$NI__leadQuoteImage-path__h} - 13px) * -1);
  color: $NI__leadQuote-color;
  z-index: 1;
  pointer-events: none;
}

.ni__lead-quote-image {
  width: 100%;
  margin: 0 auto;
  padding: 2px 0 0;
}

.ni__lead-quote-image__wrapper {
  width: 100%;
}

.ni__lead-quote-image__headline {
  color: $NI__leadQuote-color;
  display: block;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin: 26px auto 87px;
  max-width: 308px;
  @include type-class('.ni__h4');
  @include ni__qoutes__headline;
}

.ni__lead-quote-image__image,
.ni__lead-quote-image__wrapper > .parbase > div > div,
.ni__lead-quote-image__wrapper .ni__lead-quote-image__headline + div > div > div {
  height: 432px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 420px;
  margin: 0 auto;

  @include breakpoint('small') {
    height: 432px;
    width: 479px;
    max-width: none;
  }
}

.ni__lead-qoute-image__client-wrapper {
  margin: 38px auto;
  width: 100%;
  max-width: 335px;
  display: flex;
  justify-content: flex-end;
}

.ni__lead-qoute-image__client {
  h5 {
    color: $NI__leadQuote-color;
    margin: 0;
    padding: 0;
    @include type-class('.ni__small-detail--5');
  }

  p {
    color: $NI__leadQuote-color;
    margin: 0;
    padding: 0;
    @include type-class('.ni__small-detail--4');
  }
}

// mobile up
@include breakpoint('small') {
  .ni__lead-quote-image {
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ni__lead-qoute-image__client-wrapper {
    margin-bottom: 0;
  }
}

// mobile up
@include breakpoint('desktop small') {
  .ni__lead-quote-image {
    padding: 38px 0 172px;
  }

  .ni__lead-quote-image__wrapper {
    position: relative;
    max-width: map-get($breakpoints, 'grid');
  }

  .ni__lead-quote-image__headline {
    margin-left: 8.3333%;
    max-width: 63.5vw;
  }

  .ni__lead-quote-image__image,
  .ni__lead-quote-image__image img,
  .ni__lead-quote-image__wrapper > .parbase > div > div,
  .ni__lead-quote-image__wrapper .ni__lead-quote-image__headline + div > div > div {
    background-size: cover;
    position: absolute;
    height: 479px;
    width: 100%;
    max-width: 420px;
    bottom: -210px;
    right: -152px;
    z-index: -1;
  }

  .ni__lead-qoute-image__client-wrapper {
    position: relative;
    bottom: -210px;
    max-width: 475px;
  }

  .ni__lead-qoute-image__client {
    position: relative;
  }
}

// prettier-ignore
.ni__lead-quote-image__headline {
  @media (min-width: 1100px) { max-width: 65.5vw; }
  @media (min-width: 1150px) { max-width: 755px; }
}

@media (min-width: 1024px) and (max-width: 1350px) {
  .ni__lead-qoute-image__client-wrapper {
    justify-content: flex-start;
    bottom: -175px;
  }

  .ni__lead-quote-image__image,
  .ni__lead-quote-image__image img,
  .ni__lead-quote-image__wrapper > .parbase > div > div,
  .ni__lead-quote-image__wrapper .ni__lead-quote-image__headline + div > div > div {
    right: 0;
    bottom: -170px;
  }

  .ni__lead-quote-image__headline {
    max-width: 600px;
  }
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .ni__lead-quote-image__headline {
    max-width: 500px;
  }
}

@media (min-width: 1351px) {
  .ni__lead-quote-image {
    padding: 38px 0 210px;
  }
}

@include breakpoint('max content') {
  .ni__lead-quote-image {
    padding: 38px 0 210px;
  }

  .ni__lead-quote-image__path {
    display: inline;
  }
}

.client-Info.parbase {
  overflow: visible !important;
}

// NO IMAGE state
// ========================================================================

.ni__lead-quote-image.no-image,
.ni__lead-quote-image.no-image.no-image_light_green {
  background-color: var(--off-white);
  padding-bottom: 47px;
  padding-top: 63px;

  .ni__lead-qoute-image__client {
    h5,
    p {
      color: $NI__leadQuote-color;
      font-family: FoundersGrotesk;
      font-size: 14px !important;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;

      @include media-breakpoint-up(desktop) {
        font-size: 16px !important;
      }
    }

    h5 {
      font-weight: 500;
    }

    p {
      font-weight: normal;
    }
  }

  .ni__lead-quote-image__image {
    display: none;
  }

  .ni__lead-quote-image__headline {
    max-width: 334px;
    margin-bottom: 55px;
    // margin-left: 26px;
    // margin-right: 26px;

    &:before,
    &:after {
      width: 15px;
      height: 15px;
    }

    &:before {
      left: -16px;
    }

    &:after {
      right: -16px;
    }
  }

  .ni__lead-qoute-image__client-wrapper {
    margin-bottom: 0;
    max-width: 308px;
    justify-content: flex-start;
    padding-left: 80px;
  }

  @include media-breakpoint-up(sm) {

    .ni__lead-quote-image__headline {
      max-width: 384px;
    }

    .ni__lead-qoute-image__client-wrapper {
      padding-left: 180px;
    }
  }

  @include media-breakpoint-up(md) {
    overflow: hidden;

    .ni__lead-quote-image__headline {
      max-width: 542px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 45px;

      &:after,
      &:before {
        width: 40px;
        height: 40px;
      }

      &:before {
        top: -42px;
        left: -40px;
      }

      &:after {
        right: -40px;
        bottom: -40px;
      }
    }

    .ni__lead-qoute-image__client-wrapper {
      padding-left: 0;
      bottom: 0;
      margin-bottom: 31px;
      margin-top: 137px;
      margin-right: 235px;
      justify-content: flex-end;
    }

    .ni__lead-qoute-image__client:before {
      content: '';
      width: 493px;
      height: 678px;
      display: block;
      position: absolute;
      top: -667px;
      left: -560px;
      border-left: 3px solid #044123;
      border-bottom: 3px solid #044123;
      border-bottom-left-radius: 400px;
    }
  }

  @include media-breakpoint-up(lg) {

    .ni__lead-quote-image__headline {
      max-width: 730px;
      margin-top: 85px;
    }

    .ni__lead-qoute-image__client-wrapper {
      margin-bottom: 41px;
      margin-top: 150px;
    }

    .ni__lead-quote-image__path {
      display: none;
    }

    .ni__lead-qoute-image__client:before {
      width: 726px;
      height: 680px;
      top: -667px;
      left: -760px;
    }
  }
}

.ni__lead-quote-image.no-image {
  background-color: var(--off-white);
}

.ni__lead-quote-image.no-image.no-image_light_green {
  background-color: var(--pale-green);
}
