// ========================================================================
// Component: AboutUsValues
// ========================================================================

// prettier-ignore
.ni__aboutus-values {
  margin: 0 auto;
  background: var(--gray-20);
  max-width: 1440px;
  position: relative;
  padding-bottom: 32px;
  @include media-breakpoint-up(lg) { padding-bottom: 88px; }
  padding-bottom: 0;
}

.ni__aboutus-values.video {
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  .ni__aboutus-values__imagecontainer {
    padding: 0;

    @include media-breakpoint-up(tablet) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .ni__aboutus-values__imagecontainer .video-container {
    padding: 0;

    @include media-breakpoint-up(tablet) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

// prettier-ignore
.ni__aboutus-values .ni__aboutus-values__container {
  background: var(--gray-20);
  padding-bottom: 64px;
  @include media-breakpoint-up(md) { padding-top: 176px; }
  padding-top: 64px;
  position: relative;
}

.ni__aboutus-values__line {
  border-top: 2px solid black;
  position: absolute;
  right: 0;
  top: 71px;
  width: 55%;

  @include media-breakpoint-up(tablet) {
    border: none;
    border-left: 2px solid black;
    height: 260px;
    left: -15px;
    top: -12px;
    width: 0;
    width: 65%;
  }

  @include media-breakpoint-up(lg) {
    border: none;
    border-left: 3px solid black;
    height: 373px;
    left: -12px;
    top: -38px;
    width: 0;
  }

  @media (min-width: 1250px) {
    left: -45px;
  }

  @media (min-width: 1340px) {
    left: -84px;
  }
}

// prettier-ignore
.ni__aboutus-values .ni__aboutus-values_eyebrow {
  color: var(--forrest-green);
  padding-right: 15px;
  position: relative;
  white-space: nowrap;
  width: 120px;
  @include media-breakpoint-up(lg) { width: auto; }
  @include type-class('.ni__eyebrow--2');
}

// AEM .parbase override
.ni__aboutus-values .ni__aboutus-values__value .value {
  overflow: visible !important;
}

.ni__aboutus-values .ni__aboutus-values__list {
  padding-top: 63px;

  @include media-breakpoint-up(lg) {
    padding-top: 141px;
    @include display-flex(row nowrap, flex-start, space-between);
  }

  // prettier-ignore
  .ni__aboutus-values__value {
    & + .ni__aboutus-values__value {
      margin-top: 40px;
      @include media-breakpoint-up(tablet) { margin-top: 0; }
    }

    // prettier-ignore
    @include media-breakpoint-up(md) {
      .ni__aboutus-values__valueTitle,
      .ni__aboutus-values__valueDescription {
        margin-left: 0 !important;
        width: 254px;
      }

      &:nth-child(2) .ni__aboutus-values__valueTitle,
      &:nth-child(2) .ni__aboutus-values__valueDescription {
        margin-left: 0 !important;
        width: 252px;
      }

      &:nth-child(3) .ni__aboutus-values__valueTitle,
      &:nth-child(3) .ni__aboutus-values__valueDescription {
        margin-left: 0 !important;
        width: 325px;
        // width: 349px;
      }
    }

    // revert to intended width at 1060px
    @media (min-width: 1060px) {
      &:nth-child(3) .ni__aboutus-values__valueTitle,
      &:nth-child(3) .ni__aboutus-values__valueDescription {
        width: 349px;
      }
    }

    .ni__aboutus-values__valueTitle {
      color: var(--forrest-green);
      letter-spacing: normal;
      margin: 0 auto 24px;
      white-space: nowrap;
      @include type-class('.ni__h2');
      @include media-breakpoint-up(tablet) { margin: 0 0 16px; }
      @include media-breakpoint-up(lg) { margin: 0 auto 32px; }
    }

    .ni__aboutus-values__valueDescription {
      color: var(--forrest-green);
      @include type-class('.ni__body-text--2');
      @include media-breakpoint-up(tablet) { line-height: 1.33; }
    }
  }
}

.ni__aboutus-values .ni__aboutus-values__imagecontainer {
  position: relative;

  @include media-breakpoint-up(lg) {
    margin: 0 auto 0;
    position: relative;
    background: var(--gray-20);
  }

  .ni__aboutus-values__imagedecoration {
    width: 17px;
    height: 17px;
    position: absolute;
    z-index: 999;
    background: var(--ni-green);
    transform: translate(0, 0);
    transition: 1250ms linear;
    transition-property: top, right, bottom, left;
    will-change: top, right, bottom, left;

    @include media-breakpoint-up(lg) {
      width: 50px;
      height: 50px;
      position: absolute;
    }
  }

  .ni__aboutus-values__imagedecoration-topRight {
    bottom: 0;
    right: 0;

    @include media-breakpoint-up(lg) {
      bottom: auto;
      top: -40px;
      right: -25px;
    }

    &[data-is-in-view='false'] {
      top: 50%;
      right: 50%;
    }
  }

  .ni__aboutus-values__imagedecoration-bottomLeft {
    top: 0;
    left: 0;

    @include media-breakpoint-up(lg) {
      top: auto;
      bottom: -35px;
      left: -25px;
    }

    &[data-is-in-view='false'] {
      bottom: 42%;
      left: 47%;
    }
  }

  .ni__aboutus-values__image {
    width: 100%;
    height: auto;

    &:active,
    &:focus {
      outline: none;
    }

    @include media-breakpoint-up(lg) {
      max-width: 1110px;
    }
  }

  .embed-responsive {
    z-index: 10;
    height: 100%;
  }

  .ni__aboutus-values__video-js {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
  }
}

.ni__aboutus-values .ni__aboutus-values__container-second {
  padding-top: 40px;
  padding-bottom: 0px;
  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    overflow: auto;
  }
  .ni__button {
    float: right;
  }
}

// eyebrow > base styles
// ========================================================================
.ni__aboutus-values .ni__aboutus-values__engineer-eyebrow {
  background: var(--gray-20);
  position: relative;
  z-index: 1;
  @include type-class('.ni__eyebrow--5');
}

// eyebrow > span styles
// ========================================================================
.ni__aboutus-values .ni__aboutus-values__engineer-eyebrow span {
  color: var(--forrest-green);
  margin-left: 20%;
}

// eyebrow > swoosh styles
// ========================================================================
.ni__aboutus-values .ni__aboutus-values__engineer-eyebrow span:before {
  border-bottom: 2px solid black;
  content: '';
  height: 0;
  left: -80%;
  // pointer-events: none;
  position: absolute;
  top: 4px;
  width: 100%;

  @include media-breakpoint-up(tablet) {
    border-bottom: 2px solid black;
    border-bottom-left-radius: 175px;
    border-left: 2px solid black;
    bottom: 6px;
    height: 750px;
    left: -415px;
    position: absolute;
    width: 490px;
    top: -742px;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 3px solid black;
    border-bottom-left-radius: 370px;
    border-left: 3px solid black;
    height: 1055px;
    left: -677px;
    width: 775px;
    top: -1046px;
  }

  @media (min-width: 1250px) {
    left: -710px;
    width: 807px;
  }

  @media (min-width: 1340px) {
    left: -749px;
    width: 845px;
  }
}

// eyebrow > floating
// ========================================================================
// prettier-ignore
.ni__aboutus-values .ni__aboutus-values__engineer-eyebrow {
  float: right;
  @include media-breakpoint-up(tablet) { float: none; }
}

// eyebrow > margins
// ========================================================================
// prettier-ignore
.ni__aboutus-values .ni__aboutus-values__engineer-eyebrow {
  margin-top: 64px;
  @include media-breakpoint-up(tablet)  { margin-top: 68px; }
  @include media-breakpoint-up(md)      { margin-top: 77px; }
  @include media-breakpoint-up(lg)      { margin-top: 181px; }
}
