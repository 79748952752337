// Overrides ni.css:5768 to remove the 10px padding
// on each side of the wrapper nav element
.ni-page-wrap nav.ni-wrapper-header,
nav.ni-wrapper-header {
  padding: 0;
}

// Overrides ni.css rule that hides
// breadcrumb on mobile
@media (max-width: 767px) {
  .breadcrumb {
    display: block !important;
    margin-left: 5px !important;
  }
}
