// ========================================================================
// Component: PerspectiveFilter
// ========================================================================

.ni__perspective-filter {
  padding-top: 60px;
}

.ni__perspective-filter__container {
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.ni__perspective-filter__dropdown {
  display: flex;
  align-items: center;
  // padding-bottom: 24px;
  overflow: hidden;
  height: 48px;
}

.ni__perspective-filter__categorys {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  width: 100%;

  .ni__category {
    margin-left: 15px;
    margin-top: 15px;
  }
}

.ni__perspective-filter__categorys--clear {
  font-family: map-get($fonts, 'sans');
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--forrest-green);
  cursor: pointer;
  padding-left: 15px;
  padding-right: 30px;
  line-height: 32px;
}

// sm up
@include media-breakpoint-up(sm) {
  .ni__perspective-filter {
    padding-top: 90px;
  }
}

// table up
@include media-breakpoint-up(tablet) {
  .ni__perspective-filter__container {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: baseline;
  }

  .ni__perspective-filter__categorys {
    // 204 is the width of the dropdown + clear all container
    width: calc(100% - 204px);
  }
}
