// ========================================================================
// Components => CTA Cards Three Column Account
// ========================================================================

// Component
// ========================================================================
// prettier-ignore
.ni__cta-cards-three-column-account {
  &-title {
    color: var(--forrest-green);
    margin-bottom: 32px;
    @include breakpoint('small') { margin-bottom: 40px; }
    @include breakpoint('desktop') { margin-bottom: 56px; }
  }

  // vertically space columns on mobile
  [class*='ni__col'] + [class*='ni__col'] {
    margin-top: 16px;
    
    // -- increase the margin on tablets
    @include breakpoint('small') {
      margin-top: 20px;
    }

    // -- on desktop, remove space from the top "row" of cols
    // -- but also increase subsequent vertical spacing
    @include breakpoint('desktop') {
      &:nth-child(1),
      &:nth-child(2) { margin-top: 0; }
      margin-top: 32px;
    }

    // -- on large, remove from new column joining the top "row"
    @include breakpoint('large') {
      &:nth-child(3) { margin-top: 0; }
    }
  }
}

// Component => Tooltip => Styles
// ========================================================================
.ni__cta-cards-three-column-account .ni__tooltip {
  border-top-left-radius: 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
  background-color: var(--white-three);
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.08);
  display: block;
  margin-left: 20px;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  width: calc(100% - 40px);
  transform: translateY(-75%);
  transition-duration: 300ms;
  transition-property: display, opacity, transform;
  transition-timing-function: ease-in-out;
  will-change: display, opacity, transform, z-index;
  @include position-absolute(0, 0, auto, 0, 0);

  @include breakpoint('desktop') {
    margin-left: 0;
    width: 100%;
  }

  .ni__tooltip-inner,
  .ni__tooltip-inner * {
    color: var(--forrest-green);
    @include type-class('.ni__small-detail--4');
  }

  .ni__tooltip-inner strong,
  .ni__tooltip-inner strong > a,
  .ni__tooltip-inner b,
  .ni__tooltip-inner b > a {
    font-weight: bold !important;
  }

  .ni__tooltip-inner a {
    text-decoration: underline;
  }

  .ni__tooltip-inner a:hover,
  .ni__tooltip-inner a:focus {
    color: var(--ni-green);
    text-decoration: underline;
  }

  // prettier-ignore
  &:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='29' viewBox='0 0 28 29'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFF'%3E%3Cg%3E%3Cg%3E%3Cpath d='M358 63s-12.779 5.107-6.577 29C331.315 77.044 330 63 330 63h28z' transform='translate(-859 -219) translate(165) translate(364 156) matrix(-1 0 0 1 688 0)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    height: 29px;
    pointer-events: none;
    width: 28px;
    @include position-absolute(auto, 16px, -18px, auto, 0);
    @include breakpoint('small')   { right: 14px; }
    @include breakpoint('desktop') { right: 18px; }
    @include breakpoint('large')   { right: 24px; }
  }
}

// Component => Tooltip => Hover
// ========================================================================
// prettier-ignore
.ni__cta-cards-three-column-account-tooltip.tooltip-active .ni__tooltip,
.ni__cta-cards-three-column-account-tooltip .ni__tooltip:hover {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(-82.5%);
  @include breakpoint('small')   { transform: translateY(-78%); }
  @include breakpoint('desktop') { transform: translateY(-75%); }
  z-index: 1;
}
