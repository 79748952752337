// ========================================================================
// Component: Video
// ========================================================================

.ni__video-component {
  height: auto;
  width: 100%;
  max-width: map-get($breakpoints, 'max content');
  margin: 0 auto;

  .embed-responsive {
    height: 100%;
  }

  .ni__video-js {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    top: 0;
  }

  .bc-player-default_default:-webkit-full-screen {
    width: 100% !important;
    height: 100% !important;
  }
}
