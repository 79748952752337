// ========================================================================
// Component Content Sectional w/Icons
// ========================================================================

// Theme => Default
// ========================================================================
// prettier-ignore
.ni__content-sectional-with-icons {

  //_____________________________________
  // row margins
    .ni__row {
                                      padding: 0px;
                                      padding: 0px;
      @include breakpoint('small') {  padding-top: 24px; padding-bottom: 24px;}
      @include breakpoint('xlarge'){  padding-top: 36px; padding-bottom: 36px; }
    }
    .ni__container > .ni__row{
      justify-content: center;
    }

  //_____________________________________
  // layout - tablet+ horizontal spacing
  // removes left margin of each first row's offset col
  // in order to correctly match zeplin comps
  .ni__container > .ni__row > .ni__offset-sm-1:nth-child(1) {
    @include breakpoint('small')   { margin-left: 0; }
  }
  
//_____________________________________
// horizontal + vertical dividers
@media (min-width:720px) {
  .ni__content-sectional-with-icons-divider-type {
    //horizontal divider
    &--horizontal > .ni__row:not(:last-child) {
      border-image-source: linear-gradient(
        to right,
        transparent 15%,
        var(--separator-color) 15% 85%,
        transparent 85%
      );
      border-bottom: 1px solid;
      border-image-slice: 1;
    }

    &--vertical {
      position: relative;
    }

    &--vertical::before {
      content: "";
      position: absolute;
      height: 75%;
      width: 1px;
      background-color: var(--separator-color);
      transform: translateY(15%);
      margin-left: 50%;
    }
  }

  > .ni__content-sectional-with-icons-divider-color {
    &--pale-green {
      --separator-color: var(--pale-green);
    }

    &--digital-green-dark {
      --separator-color: var(--digital-green-dark);
    }

    &--power-green {
      --separator-color: var(--power-green);
    }
  }
}

.ni__container {
  //_____________________________________
  // paddings and margins
  @media (min-width:720px) {
    padding-left: 0px;
    padding-right: 0px;
    > .ni__row:has(> .ni__content-sectional-with-icons-sectional-container) {
      flex-wrap: nowrap;
    }
  }

  > .ni__row > div > div{
    margin-left: 24px;
    margin-right: 24px;

    @include breakpoint('mobile') { 
      padding-left: 16px;
      padding-right: 16px;
      margin-left: 0px;
      margin-right: 0px;
    }  
    @include breakpoint('tablet')   { width: 294px; }
    @include breakpoint('desktop')  { width: 450px; }
    @include breakpoint('large')    { width: 540px; }
    }

    > .ni__row > div:first-child > div {
      @include breakpoint('mobile') {
        margin-right: 12px;
      }
    }

    //_____________________________________
    // title and text colors
    > .ni__content-sectional-with-icons-title-color {
      &--charcoal > div > div > .ni__content-sectional-with-icons-item-header > p {
        color: var(--charcoal);
      }
      &--off-white > div > div > .ni__content-sectional-with-icons-item-header > p {
        color: var(--off-white);
      }
      &--digital-green > div > div > .ni__content-sectional-with-icons-item-header > p {
        color: var(--digital-green);
      }
    }
    > .ni__content-sectional-with-icons-text-color {
      &--charcoal > div > div > .ni__content-sectional-with-icons-item-body > p {
        color: var(--charcoal);
      }
      &--off-white > div > div > .ni__content-sectional-with-icons-item-body > p {
        color: var(--off-white);
      }
      &--forest-green > div > div > .ni__content-sectional-with-icons-item-body > p {
        color: var(--forest-green);
      }
    }

    //_____________________________________
    // center sectionals in odd rows
    > .ni__row:not(:has(> :nth-child(2))) {
      @include breakpoint(small) {
        display: flex;
        justify-content: center;
        > div > div > .ni__content-sectional-with-icons-item-body {
          text-align: center;
        }
      }      
    }

}

  //_____________________________________
  // img icon png
  .ni__content-sectional-with-icons-item-image {
    margin-right: 24px;
  }

  .ni__content-sectional-with-icons-item-image img {
                                     width: 65px;
    @include breakpoint('small')   { width: 34px; }
    @include breakpoint('desktop') { width: 46px; }
    @include breakpoint('large')   { width: 65px; }
  }

  .ni__content-sectional-with-icons-item a {
    text-decoration: none;
  }

  //_____________________________________
  // item header (img and headline)
  .ni__content-sectional-with-icons-item-header {
    // -- flex props
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  //_____________________________________
  // item body (body copy + buttons)
  .ni__content-sectional-with-icons-item-body {
                                     margin-left: 95px;
    @include breakpoint('small')   { margin-left: 60px; }
    @include breakpoint('desktop') { margin-left: 70px; }
    @include breakpoint('large')   { margin-left: 95px; }
    
    > div > .ni-horizontal-links {
      margin-top: 24px;
    }
  }
}

// Themes
// ========================================================================
// prettier-ignore
.ni__content-sectional-with-icons.ni__content-sectional-with-icons {
  //_____________________________________
  // component background
  &--default { background-color: none; }
  &--light   { background-color: #f8f8f8; }

  //_____________________________________
  // component padding
  &--default { padding: 0; }

  //_____________________________________
  // headline element
  &--default .ni__content-sectional-with-icons-item h3 { @include type-class('.ni__h6'); }
  &--light .ni__content-sectional-with-icons-item h3   { @include type-class('.ni__h6'); }
}
