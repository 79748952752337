// ========================================================================
// Component: Banner Full Width Contact
// ========================================================================

$Solutions--Banner-Full-Width-Contact: '.ni__banner-full-width-contact';

#{$Solutions--Banner-Full-Width-Contact} {
  &.light-grey {
    .ni__banner-full-width-contact--header {
      background-color: var(--off-white);
    }
  }

  &.light-green {
    .ni__banner-full-width-contact--header {
      background-color: #cbdcc8;
    }
  }

  .ni__container {
    padding: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: map-get($breakpoints, 'max content');
    }

    .ni__banner-full-width-contact--header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 115px;
      padding: 0 20px;
      position: relative;
      width: 100%;

      @include media-breakpoint-up(sm) {
        padding: 0 50px;
      }

      @include media-breakpoint-up(md) {
        align-items: center;
        padding: 0;
        width: 49.75%;
      }

      h2 {
        @include type-class('.ni__h6');
        margin-bottom: 0;

        color: var(--forrest-green);
        font-size: 30px !important;

        @include media-breakpoint-up('xs') {
          font-size: 40px !important;
        }
      }

      p {
        @include type-class('.ni__body-text--3');

        color: var(--forrest-green);
      }
    }

    .ni__banner-full-width-contact--detail {
      background-color: var(--forrest-green);
      display: flex;
      min-height: 115px;
      width: 100%;

      @include media-breakpoint-up(sm) {
        padding: 0 50px;
      }

      @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: center;
        padding: 0;
        width: 58.25%;
      }

      .ni__banner-full-width-contact--profile {
        align-items: center;
        display: flex;
        width: 100%;

        @include media-breakpoint-up('sm') {
        }

        @include media-breakpoint-up('md') {
          align-items: inherit;
          padding-left: 95px;
        }

        @include media-breakpoint-up('lg') {
          // justify-content: center;
          padding-left: 192px;
        }

        .ni__profile {
          &.is-help {
            .ni__profile--image {
              display: none !important;

              @include media-breakpoint-up('sm') {
                display: flex !important;
              }

              @media (max-width: 812px) and (orientation: landscape) {
                display: none !important;
              }

              .parbase {
                display: flex;
                align-items: center;
              }
            }

            .ni__profile--content {
              @include media-breakpoint-up('md') {
                margin-left: 31px;
              }

              h4 {
                color: var(--off-white);
                font-size: 24px !important;
                letter-spacing: normal;
                line-height: 1.33 !important;
                margin-bottom: 0 !important;
              }

              span {
                color: var(--off-white);
              }

              a {
                color: var(--power-green);
              }

              span,
              a {
                font-family: FoundersGrotesk;
                font-size: 18px !important;
                font-stretch: normal;
                font-style: normal;
                font-weight: normal;
                letter-spacing: normal;
                line-height: 1.33 !important;
              }
            }
          }
        }

        .ni__profile--divider {
          padding-left: 6px;
          padding-right: 6px;

          @include media-breakpoint-up('md') {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}
