// ========================================================================
// Component: Responsive Spacer Blocks
// JIRA: https://organic-inc.atlassian.net/browse/NC-1476 (original)
// JIRA: https://organic-inc.atlassian.net/browse/NC-2164 (new sizes)
// ========================================================================

// prettier-ignore
.ni__spacer {
  position: relative;
  visibility: hidden;
  width: 100%;

  &--default,
  &--big {
                                     height: 64px;
    @include breakpoint('small')   { height: 120px; }
    @include breakpoint('desktop') { height: 176px; }
  }

  &--xlarge {
    height: 176px; 
  }

  &--large {
    height: 120px;
  }

  &--medium {
                                     height: 64px;
    @include breakpoint('small')   { height: 80px; }
    @include breakpoint('desktop') { height: 80px; }
  }

  &--small {
                                     height: 64px;
    @include breakpoint('small')   { height: 80px; }
    @include breakpoint('desktop') { height: 72px; }
  }

  &--xsmall {
                                     height: 64px;
    @include breakpoint('small')   { height: 56px; }
    @include breakpoint('desktop') { height: 56px; }
  }

  &--xxsmall {
    height: 24px;
  }

  &--4px {
    height: 4px; 
  }

  &--8px {
    height: 8px;
  }

  &--12px {
    height: 12px;
  }

  &--16px {
    height: 16px;
  }

  &--20px {
    height: 20px;
  }

  &--28px {
    height: 28px;
  }

  &--32px {
    height: 32px; 
  }

  &--36px {
    height: 36px; 
  }

  &--40px {
    height: 40px;
  }

  &--44px {
    height: 44px;
  }

  &--48px {
    height: 48px;
  }

  &--52px {
    height: 52px;
  }

  &--60px {
    height: 60px;
  }

  &--64px {
    height: 64px;
  }
}


.ni__spacer.spacer-hide{
  @media (max-width: 1023px) {
      display: none;
  }
}