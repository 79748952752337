// Form field
.form-field {
  padding: 0;
  margin: 0;

  // Label
  .label {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 0 8px 0px;
    width: 100%;
    .tooltip-icon {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 20px;
      justify-content: center;
      margin: 0 4px 0 0;
      width: 20px;
      position: relative;
      .tooltip-backdrop {
        height: 100vh;
        left: 0;
        opacity: 0.5;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
      }
      .tooltip-bubble {
        border-radius: 15px 15px 15px 0;
        box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.08);
        color: #044123;
        font-family: FoundersGrotesk;
        font-size: 14px;
        height: auto;
        left: -5px;
        line-height: 1.14;
        max-width: 335px;
        padding: 20px;
        position: absolute;
        text-align: left;
        width: 335px;
        z-index: 1001;
        background-color: #ffffff;

        .tooltip-text {
          overflow-wrap: break-word;
          position: relative;
          white-space: normal;
          max-width: 335px;

          p {
            overflow-wrap: break-word;
          }

          a {
            font-weight: bold;
            text-decoration: underline;
          }
          strong {
            font-weight: 500;
          }
          i {
            font-style: italic;
          }
        }
        .triangle {
          border-left: 7.5px solid transparent;
          border-right: 7.5px solid transparent;
          border-top: 15px solid #ffffff;
          bottom: -15px;
          box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.08);
          height: 0;
          left: 5.5px;
          margin-bottom: -30px;
          padding-bottom: 30px;
          position: absolute;
          width: 0;
        }
      }
      svg {
        pointer-events: none;
        z-index: 999;
        fill: #03b585;
      }
    }
    label {
      font-family: FoundersGrotesk;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #044123;
      margin-bottom: 0.1rem;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }

  // Text / email inputs
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  input[type='text'],
  input[type='email'] {
    background-color: #fff;
    color: #aca09a;
    font-family: FoundersGrotesk;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 50px;
    letter-spacing: normal;
    line-height: 1;
    margin: 0;
    padding: 24px;
    text-align: left;
    width: 100%;
  }

  input:hover,
  input:focus,
  input.empty:hover,
  input.empty:focus {
    border: solid 1px #03b585 !important;
    box-shadow: none !important;
  }

  // Filled state
  input.filled {
    border: solid 1px #044123;
  }

  .filled .select-selected {
    border: solid 1px #044123;
  }

  // Empty state
  input.empty {
    border: solid 1px #ccdcc8 !important;
  }

  // Select dropdown
  .custom-select {
    font-family: FoundersGrotesk;
    font-size: 18px;
    margin: 0;
    position: relative;
    select {
      display: none;
    }
  }
  .select-selected {
    background-color: #ffffff;
    height: 50px;
  }
  .select-selected:after {
    border-right: 1px solid #044123;
    border-top: 1px solid #044123;
    content: '';
    display: inline-block;
    height: 8px;
    right: 16px;
    top: 16px;
    transform: rotate(135deg);
    width: 8px;
    position: absolute;
  }
  .select-selected.select-arrow-active:after {
    transform: rotate(-45deg);
  }
  .select-items div {
    color: #aca09a;
  }
  .select-selected {
    border: 1px solid #ccdcc8;
  }
  .select-selected.select-arrow-active {
    border-bottom: 1px solid transparent;
    border-left: 1px solid #03b585;
    border-right: 1px solid #03b585;
    border-top: 1px solid #03b585;
  }
  .select-items div,
  .select-selected {
    padding: 8px 16px;
    cursor: pointer;
  }
  .select-items {
    background-color: #ffffff;
    border-bottom: 1px solid #03b585;
    border-left: 1px solid #03b585;
    border-right: 1px solid #03b585;
    left: 0;
    margin-top: -6px;
    right: 0;
    top: 100%;
    z-index: 99;
    position: absolute;
  }
  .select-hide {
    display: none;
  }
  .select-items div:hover {
    background-color: #03b585;
    color: #ffffff;
  }
  .select-items .select-selected,
  .select-items .same-as-selected {
    color: #044123;
  }
}

// Inactive select dropdown
.inactive .select-selected {
  pointer-events: none;
  color: #ccc;
}

// Filled select dropdown
.filled .select-selected {
  border: solid 1px #044123;
}

// Error state
.error {
  color: #cd1722;
  font-family: FoundersGrotesk;
  font-size: 12px;
  font-weight: 500;
  margin: 2px 0 0 0;
  min-height: 32px;
  width: 100%;
}
.field-error {
  .label {
    label {
      color: #cd1722;
    }
    .tooltip-icon {
      svg {
        fill: #cd1722;
      }
    }
  }
  input,
  input:hover,
  input:focus,
  input.empty,
  input.empty:hover,
  input.empty:focus {
    border: 1px solid transparent !important;
    outline: 2px solid #cd1722 !important;
  }
}
.serial-number-error {
  .label {
    label {
      color: #cd1722;
    }
  }
  input,
  input:hover,
  input:focus,
  input.empty,
  input.empty:hover,
  input.empty:focus {
    border: 1px solid transparent !important;
    outline: 2px solid #cd1722 !important;
  }
}
