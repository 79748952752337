// ========================================================================
// Component: HeroBleed
// ========================================================================

.ni__hero-bleed {
  h1,
  h2 {
    color: var(--forrest-green);
    margin-bottom: 30px;
    padding: 0 20px;
  }

  h1.ni__h10 {
    font-size: 40px !important;
    line-height: 1.2 !important;
  }
}

.ni__hero-bleed--image {
  .parbase div > div {
    height: 267px;
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.ni__hero-bleed--video {
  position: relative;
}

.ni__hero-bleed--profile {
  display: none;
  border-top-left-radius: 20px;
  overflow: hidden;
  .parbase div > div {
    width: 65px;
    height: 65px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.ni__contact {
  padding: 0 20px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
  }
}

.ni__contact--icon {
  color: var(--ni-green);
  width: 57px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--ni-green);
  border-radius: 11px;
  border-bottom-left-radius: 0;
  margin-right: 5px;
}

.ni__contact--details {
  width: 170px;
  margin-left: 25px;
  span {
    display: block;
  }
}

.ni__contact--title {
  color: var(--ni-green);
}

.ni__contact--label,
.ni__contact--label2 {
  color: var(--forrest-green);
  padding-top: 3px;
}

// theme
.ni__hero-bleed.is-video {
  .vjs-big-play-button {
    width: 47px !important;
    height: 42px !important;
    border-radius: 12px;
    &:before {
      margin: 7px auto !important;
    }
  }

  .ni__hero-bleed--video {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.ni__feature-highlights--video-imagedecoration {
  display: none;
}

@include media-breakpoint-up(sm) {
  .ni__hero-bleed {
    max-width: 585px;
    margin: 0 auto;

    h1,
    h2 {
      padding: 0;
    }

    h1.ni__h10 {
      font-size: 46px !important;
      line-height: 1.22 !important;
    }
  }

  .ni__contact {
    padding: 0;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .ni__contact--icon {
    margin-right: 9px;
  }

  .ni__hero-bleed--profile {
    display: block;
    margin-right: 30px;
  }

  .ni__contact--details {
    margin-left: 0;
    margin-right: 10px;
    display: flex;
    width: 255px;
    align-items: center;
  }

  .ni__hero-bleed--image {
    .parbase div > div {
      height: 409px;
      // background-position: center center;
    }
  }

  // theme
  .ni__hero-bleed.is-video {
    .ni__hero-bleed--video {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

h1 {
  &.contact-not-filled {
    max-width: 1440px !important;

    @include media-breakpoint-up(md) {
      margin-left: 165px;
      margin-right: 165px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__hero-bleed {
    max-width: 1024px;
    position: relative;

    h1,
    h2 {
      max-width: 380px;
      margin-left: 100px;
      margin-bottom: 30px;
    }

    h1 {
      &.contact-not-filled {
        max-width: 1440px;
        margin-left: 165px;
        margin-right: 165px;
      }
    }

    h1.ni__h10 {
      font-size: 60px !important;
      line-height: 1.2 !important;
    }

    .ni__contact {
      position: absolute;
      top: 115px;
      right: 100px;
      justify-content: flex-start;
    }
  }

  .ni__hero-bleed--image {
    .parbase div > div {
      height: 360px;
    }
  }

  .ni__hero-bleed.is-video {
    .vjs-big-play-button {
      width: 56px !important;
      height: 54px !important;
      border-radius: 20px;
      &:before {
        margin: 12px auto !important;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__hero-bleed {
    max-width: 1440px;

    h1,
    h2 {
      max-width: 635px;
      margin-left: 165px;
    }

    h1.ni__h10 {
      font-size: 72px !important;
      line-height: 1.11 !important;
    }

    .ni__contact {
      top: 80px;
      right: 165px;
    }
  }

  .ni__hero-bleed--image {
    .parbase div > div {
      height: 500px;
    }
  }

  // theme
  .ni__hero-bleed.is-video {
    .ni__hero-bleed--video {
      margin: 60px auto 0 auto;
      max-width: 1240px;
    }

    .vjs-big-play-button {
      width: 94px !important;
      height: 86px !important;
      border-radius: 30px;
      &:before {
        margin: 29px auto !important;
        font-size: 38px;
        width: 33px;
      }
    }
  }
}

.ni__hero-bleed__caption {
  font-family: FoundersGrotesk;
  font-size: 14px;
  line-height: 16px;
}

.ni__hero-bleed__image img {
  width: 100%;
}

.ni__hero-bleed--custom-aspect > .ni__hero-bleed__image > div {
  width: 100%;
  height: 100%;
}

.ni__hero-bleed--custom-aspect > .ni__hero-bleed__image img {
  height: 100%;
  object-fit: cover;
}

.ni__hero-bleed--custom-aspect > .ni__hero-bleed__image video {
  object-fit: cover;
}

.ni__hero-bleed--aspect-21-9 > .ni__hero-bleed__image {
  aspect-ratio: 21 / 9;
}

.ni__hero-bleed--aspect-3-1 > .ni__hero-bleed__image {
  aspect-ratio: 3 / 1;
}

.ni__hero-bleed--aspect-4-1 > .ni__hero-bleed__image {
  aspect-ratio: 4 / 1;
}

.ni__hero-bleed--top-aligned-title {
  margin-bottom: 24px;
  color: var(--charcoal);

  & .ni__contact {
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 0;
    padding: 0;
  }

  .ni__contact--details {
    margin-left: 0;
  }

  & > .ni__hero-bleed__headline {
    margin: 24px;
  }

  & > .ni__hero-bleed__children {
    margin: 24px;
  }

  @include media-breakpoint-up(sm) {
    & > .ni__hero-bleed__headline, & > .ni__hero-bleed__children {
      max-width: #{map-get($container-max-widths, sm)};
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-up(md) {
    & > .ni__hero-bleed__headline, & > .ni__hero-bleed__children {
      max-width: #{map-get($container-max-widths, md)};
    }

    & > .ni__hero-bleed__headline {
      margin-top: 48px;
      margin-bottom: 0;
    }

    & > .ni__hero-bleed__children {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: calc((100% - map-get($container-max-widths, lg)) / 2) auto auto calc((100% - map-get($container-max-widths, lg)) / 2);
    grid-template-areas:
      ". title children ."
      "image image image image"
      "caption caption caption caption";

    & > .ni__hero-bleed__headline {
      grid-area: title;
      margin: 48px 0 32px 0;
      max-width: none;
    }

    & > .ni__hero-bleed__children {
      grid-area: children;
      margin: 48px 0 32px 0;
      align-self: end;
      max-width: none;
    }

    & > .ni__hero-bleed__image {
      grid-area: image;
    }

    & > .ni__hero-bleed__caption {
      grid-area: caption;
    }

    .ni__contact {
      justify-content: flex-start;
    }

  }

}

.ni__hero-bleed--full-height-call-out {
  color: var(--charcoal);
  & > .ni__hero-bleed__caption {
    padding: 20px 20px 0;
  }

  & > .ni__hero-bleed__text {
    padding: 24px 20px 48px;

    &.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-down(sm) {
    & > .ni__hero-bleed__caption.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-up(sm) {
    --hero-bleed-container-width: #{map-get($container-max-widths, sm)};  // we need to align the "non-full-bleed" parts with the width of Bootstrap containers, so we use this helper variable to record what that width is

    & > .ni__hero-bleed__caption {
      padding-left: calc((100% - var(--hero-bleed-container-width)) / 2);
      padding-right: calc((100% - var(--hero-bleed-container-width)) / 2);
    }

    & > .ni__hero-bleed__text {
      padding-left: calc((100% - var(--hero-bleed-container-width)) / 2);
      padding-right: calc((100% - var(--hero-bleed-container-width)) / 2);
    }
  }

  @include media-breakpoint-up(md) {
    --hero-bleed-container-width: #{map-get($container-max-widths, md)};
    --hero-bleed-text-image-gap: 96px;
    padding-top: 24px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      "image text"
      "caption .";

    &.ni__hero-bleed--reversed {
      grid-template-areas:
        "text image"
        ". caption";
    }

    & > .ni__hero-bleed__image {
      grid-area: image;
    }

    &.ni__hero-bleed--custom-aspect > .ni__hero-bleed__image {
      // The custom aspect ratio must be ignored at this breakpoint. The image must always cover the available space.
      aspect-ratio: auto;
      // Stop this element from contributing to the grid row height calculation. Not exactly sure how it works, but
      // it seems to convince the layout engine that a zero minimum height is OK while still occupying all the available vertical space.
      height: 0;
      min-height: 100%;
    }

    & > .ni__hero-bleed__caption {
      grid-area: caption;
      padding: 8px 0 0 0;
      background-color: transparent;
    }

    & > .ni__hero-bleed__text {
      grid-area: text;
      padding-top: 48px;
      padding-bottom: 42px;
      padding-left: var(--hero-bleed-text-image-gap);
      padding-right: calc(100% - var(--hero-bleed-container-width) / 2 + #{$grid-gutter-width} / 2);
    }

    &.ni__hero-bleed--reversed > .ni__hero-bleed__text {
      padding-left: calc(100% - var(--hero-bleed-container-width) / 2 + #{$grid-gutter-width} / 2);
      padding-right: var(--hero-bleed-text-image-gap);
    }
  }

  @include media-breakpoint-up(lg) {
    --hero-bleed-container-width: #{map-get($container-max-widths, lg)};
    --hero-bleed-text-image-gap: 110px;
  }

  @include media-breakpoint-up(xl) {
    --hero-bleed-text-image-gap: 124px;
  }
}

.ni__hero-bleed--center-aligned-call-out {
  color: var(--charcoal);
  & > .ni__hero-bleed__caption {
    padding: 20px 24px 0;
  }

  & > .ni__hero-bleed__text {
    padding: 24px 24px 48px;

    &.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-down(sm) {
    & > .ni__hero-bleed__caption.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-up(sm) {
    & > .ni__hero-bleed__caption, & > .ni__hero-bleed__text {
      padding-left: calc((100% - map-get($container-max-widths, sm)) / 2);
      padding-right: calc((100% - map-get($container-max-widths, sm)) / 2);
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto #{map-get($container-max-widths, md)} auto;
    grid-template-rows: 120px auto auto;

    & > .ni__hero-bleed__image {
      grid-area: 1 / 1 / 3 / 4;
    }

    & > .ni__hero-bleed__text {
      grid-area: 2 / 2 / 3 / 3;
      padding: 48px 80px;
    }

    & > .ni__hero-bleed__caption {
      grid-area: 3 / 2 / 4 / 3;
      background-color: transparent;
      padding: 8px 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      grid-template-rows: 178px auto auto;
      & > .ni__hero-bleed__text {
        padding: 48px 96px;
      }
    }

    @include media-breakpoint-up(xl) {
      grid-template-rows: 225px auto auto;
      & > .ni__hero-bleed__text {
        padding: 48px 108px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: auto #{map-get($container-max-widths, lg)} auto;
  }
}

.ni__hero-bleed--call-out-card-box {
  color: var(--charcoal);
  & > .ni__hero-bleed__caption {
    padding: 20px 24px 0;
  }

  & > .ni__hero-bleed__text {
    padding: 24px 24px 48px;

    &.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-down(sm) {
    & > .ni__hero-bleed__caption.ni__background--forrest-green {
      color: var(--off-white);
    }
  }

  @include media-breakpoint-up(sm) {
    & > .ni__hero-bleed__caption {
      padding-left: 60px;
      padding-right: 60px;
    }

    & > .ni__hero-bleed__text {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 85px 450px 565px;
    grid-template-rows: 31px auto auto;

    &.ni__hero-bleed--reversed {
      grid-template-columns: 565px 450px 85px;
    }

    & > .ni__hero-bleed__image {
      grid-area: 1 / 1 / 3 / 4;
    }

    & > .ni__hero-bleed__text {
      grid-area: 2 / 2 / 3 / 3;
      padding: 32px;
    }

    & > .ni__hero-bleed__caption {
      grid-area: 3 / 2 / 4 / 3;
      background-color: transparent;
      padding: 8px 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      grid-template-rows: 34px auto auto;
      grid-template-columns: 95px 540px 665px;
      &.ni__hero-bleed--reversed {
        grid-template-columns: 665px 540px 95px;
      }

      & > .ni__hero-bleed__text {
        padding: 48px 96px;
      }
    }

    @include media-breakpoint-up(xl) {
      grid-template-rows: 68px auto auto;
      grid-template-columns: 88px 616px 736px;
      &.ni__hero-bleed--reversed {
        grid-template-columns: 736px 616px 88px;
      }

      & > .ni__hero-bleed__text {
        padding: 48px 96px;
      }
    }
  }
}

// Special rendering rules for embedded hero-bleed-info components

:is(.hero-full-bleed-image-contact, .hero) .hero-bleed-info {
  margin-top: 24px;
  display: grid;
  grid-auto-columns: 100%;
  grid-template-areas:
    "text"
    "date"
    "social-media";
  align-items: start;

  & > .hero-bleed-info-date {
    grid-area: date;
    margin-bottom: 20px;
    padding-top: 0;
    color: var(--non-interactive-text-color);
  }

  & > .social-media {
    grid-area: social-media;
    padding-left: 0;
    line-height: 1;
    a {
      display: inline-block;
      height: 16px;
      margin-right: 18px;
    }
    // HACK: Visually replace the SVGs with alternate icons specified by the designers.
    a.social-networks-icon-facebook {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.32258 12.652H9.5149V21.6774C9.5149 21.8556 9.65929 22 9.83748 22H13.5546C13.7328 22 13.8772 21.8556 13.8772 21.6774V12.6945H16.3975C16.5614 12.6945 16.6992 12.5715 16.7179 12.4088L17.1007 9.08606C17.1112 8.99465 17.0823 8.9031 17.0211 8.83452C16.9599 8.76587 16.8723 8.72658 16.7803 8.72658H13.8774V6.64374C13.8774 6.01587 14.2154 5.69748 14.8823 5.69748C14.9773 5.69748 16.7803 5.69748 16.7803 5.69748C16.9585 5.69748 17.1029 5.55303 17.1029 5.3749V2.32497C17.1029 2.14677 16.9585 2.00239 16.7803 2.00239H14.1645C14.1461 2.00148 14.1051 2 14.0447 2C13.5908 2 12.0132 2.0891 10.767 3.23555C9.38626 4.506 9.57819 6.02716 9.62406 6.2909V8.72652H7.32258C7.14439 8.72652 7 8.8709 7 9.0491V12.3294C7 12.5075 7.14439 12.652 7.32258 12.652Z' fill='%23333'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-twitter {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6443 6.53146C20.5018 6.02592 21.1434 5.23062 21.4498 4.29353C20.6437 4.7642 19.7618 5.09574 18.8424 5.27398C17.2908 3.64744 14.6946 3.56779 13.0434 5.09609C12.2192 5.859 11.7513 6.92318 11.7501 8.03711C11.7506 8.3474 11.7858 8.65682 11.855 8.9595C8.56029 8.8009 5.48768 7.27561 3.39813 4.76101C2.31696 6.60085 2.8677 8.95136 4.6579 10.1384C4.006 10.1182 3.3683 9.94527 2.7978 9.63391V9.68772C2.79924 11.6108 4.17616 13.2663 6.08981 13.6462C5.48571 13.806 4.85286 13.8299 4.23797 13.7166C4.77344 15.3627 6.31333 16.4904 8.0676 16.5212C6.61485 17.6402 4.82249 18.2463 2.97838 18.2419C2.65136 18.2415 2.32469 18.2222 2 18.184C3.87753 19.3756 6.06501 20.0063 8.29849 20C15.8568 20 19.9676 13.8448 19.9676 8.50866V7.98329C20.7654 7.4112 21.4536 6.70405 22 5.8944C21.2509 6.21975 20.457 6.43428 19.6443 6.53146Z' fill='%23333'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-linkedin {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16164 3C3.96786 3 3 3.95974 3 5.14385C3 6.32796 3.96786 7.2877 5.16164 7.2877C6.35541 7.2877 7.32328 6.3278 7.32328 5.14385C7.32328 3.95974 6.35541 3 5.16164 3ZM3 21H7.06817V8.89592H3V21ZM13.0034 10.5372C13.7322 9.30194 15.0872 8.5645 16.5292 8.61823C20.3083 8.61823 21 11.0806 21 14.2814V20.8067H17.2737V15.0236C17.2737 13.6442 17.2509 11.8678 15.3388 11.8678C13.4266 11.8678 13.1015 13.3669 13.1015 14.9223V20.8067H9.37917L9.36397 8.91045H12.9353V10.5372H13.0034Z' fill='%23333'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-email {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6428 4H5.09739C3.59531 4 2.37012 5.07817 2.37012 6.4V17.6C2.37012 18.9218 3.59531 20 5.09739 20H19.6428C21.1449 20 22.3701 18.9218 22.3701 17.6V6.4C22.3701 5.07817 21.1449 4 19.6428 4ZM6.17019 6L12.3701 10.7411L18.57 6H6.17019ZM4.37012 7.14123V17.25C4.37012 17.6608 4.77215 18 5.25901 18H19.4812C19.9681 18 20.3701 17.6608 20.3701 17.25V7.14123L12.9776 12.7944C12.619 13.0685 12.1212 13.0685 11.7627 12.7944L4.37012 7.14123Z' fill='%23333'/%3E%3C/svg%3E");
    }
  }

  & > .hero-bleed-text {
    grid-area: text;
    margin-top: initial;
    margin-bottom: 24px;
    margin-left: initial;
    margin-right: initial;
    color: var(--non-interactive-text-color);
    max-width: initial;
    text-align: initial;
  }

  .hero-bleed-info-date + .social-media:before {
    content: none;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "text text"
      "date social-media";

    & > .hero-bleed-info-date {
      padding-right: 34px;
      border-right: 1px solid currentColor;
    }

    & > .social-media {
      margin-left: 34px;
    }
  }
}

// Special case: social media icons need to have a different color than text on forest green background

:is(.hero-full-bleed-image-contact, .hero) .ni__background--forrest-green .hero-bleed-info > .social-media {
    // These images are the same as for the regular case, but with a different "fill" value. It's a shame they need to be repeated here, but there is no easy way to
    // override just the fill color of replaced content. There are various hacks to work around that, but ultimately they have their own downsides. Compression
    // should optimize out most of this redundancy, so it shouldn't affect client-side performance too much.
    a.social-networks-icon-facebook {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.32258 12.652H9.5149V21.6774C9.5149 21.8556 9.65929 22 9.83748 22H13.5546C13.7328 22 13.8772 21.8556 13.8772 21.6774V12.6945H16.3975C16.5614 12.6945 16.6992 12.5715 16.7179 12.4088L17.1007 9.08606C17.1112 8.99465 17.0823 8.9031 17.0211 8.83452C16.9599 8.76587 16.8723 8.72658 16.7803 8.72658H13.8774V6.64374C13.8774 6.01587 14.2154 5.69748 14.8823 5.69748C14.9773 5.69748 16.7803 5.69748 16.7803 5.69748C16.9585 5.69748 17.1029 5.55303 17.1029 5.3749V2.32497C17.1029 2.14677 16.9585 2.00239 16.7803 2.00239H14.1645C14.1461 2.00148 14.1051 2 14.0447 2C13.5908 2 12.0132 2.0891 10.767 3.23555C9.38626 4.506 9.57819 6.02716 9.62406 6.2909V8.72652H7.32258C7.14439 8.72652 7 8.8709 7 9.0491V12.3294C7 12.5075 7.14439 12.652 7.32258 12.652Z' fill='%2332eb96'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-twitter {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6443 6.53146C20.5018 6.02592 21.1434 5.23062 21.4498 4.29353C20.6437 4.7642 19.7618 5.09574 18.8424 5.27398C17.2908 3.64744 14.6946 3.56779 13.0434 5.09609C12.2192 5.859 11.7513 6.92318 11.7501 8.03711C11.7506 8.3474 11.7858 8.65682 11.855 8.9595C8.56029 8.8009 5.48768 7.27561 3.39813 4.76101C2.31696 6.60085 2.8677 8.95136 4.6579 10.1384C4.006 10.1182 3.3683 9.94527 2.7978 9.63391V9.68772C2.79924 11.6108 4.17616 13.2663 6.08981 13.6462C5.48571 13.806 4.85286 13.8299 4.23797 13.7166C4.77344 15.3627 6.31333 16.4904 8.0676 16.5212C6.61485 17.6402 4.82249 18.2463 2.97838 18.2419C2.65136 18.2415 2.32469 18.2222 2 18.184C3.87753 19.3756 6.06501 20.0063 8.29849 20C15.8568 20 19.9676 13.8448 19.9676 8.50866V7.98329C20.7654 7.4112 21.4536 6.70405 22 5.8944C21.2509 6.21975 20.457 6.43428 19.6443 6.53146Z' fill='%2332eb96'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-linkedin {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.16164 3C3.96786 3 3 3.95974 3 5.14385C3 6.32796 3.96786 7.2877 5.16164 7.2877C6.35541 7.2877 7.32328 6.3278 7.32328 5.14385C7.32328 3.95974 6.35541 3 5.16164 3ZM3 21H7.06817V8.89592H3V21ZM13.0034 10.5372C13.7322 9.30194 15.0872 8.5645 16.5292 8.61823C20.3083 8.61823 21 11.0806 21 14.2814V20.8067H17.2737V15.0236C17.2737 13.6442 17.2509 11.8678 15.3388 11.8678C13.4266 11.8678 13.1015 13.3669 13.1015 14.9223V20.8067H9.37917L9.36397 8.91045H12.9353V10.5372H13.0034Z' fill='%2332eb96'/%3E%3C/svg%3E");
    }
    a.social-networks-icon-email {
      content: url("data:image/svg+xml,%3Csvg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.6428 4H5.09739C3.59531 4 2.37012 5.07817 2.37012 6.4V17.6C2.37012 18.9218 3.59531 20 5.09739 20H19.6428C21.1449 20 22.3701 18.9218 22.3701 17.6V6.4C22.3701 5.07817 21.1449 4 19.6428 4ZM6.17019 6L12.3701 10.7411L18.57 6H6.17019ZM4.37012 7.14123V17.25C4.37012 17.6608 4.77215 18 5.25901 18H19.4812C19.9681 18 20.3701 17.6608 20.3701 17.25V7.14123L12.9776 12.7944C12.619 13.0685 12.1212 13.0685 11.7627 12.7944L4.37012 7.14123Z' fill='%2332eb96'/%3E%3C/svg%3E");
    }
}

@include media-breakpoint-up(md) {
  .ni__hero-bleed--full-height-call-out .hero-bleed-info {
    margin-top: 35px;
    grid-template-areas:
      "date social-media"
      "text text";

      & > .hero-bleed-info-date {
        margin-bottom: 36px;
      }
  }

  .ni__hero-bleed--center-aligned-call-out .hero-bleed-info {
    margin-top: 40px;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: "date social-media text";

    & > .hero-bleed-text {
      text-align: right;
    }
  }
}

// End of special rendering rules for embedded hero-bleed-info components

// AEM Override
:is(.hero-lock-up-full-bleed-image, .hero).parbase {
  overflow: visible;
}
