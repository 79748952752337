// ========================================================================
// Component: MultiImageSpotlightSchematic
// ========================================================================

// settings
$MultiSpotlightSchematic--UseDottedLines: true;
$MultiSpotlightSchematic--UseSolidLines: true;
$MultiSpotlightSchematic--Color: var(--forrest-green);
$MultiSpotlightSchematic--Background-Color: #f8f8f8;

// Breakpoint => All
// ========================================================================
// prettier-ignore
.ni__multi-image-spotlight-schematic {
  color: $MultiSpotlightSchematic--Color;
  background: $MultiSpotlightSchematic--Background-Color;
  background-color: $MultiSpotlightSchematic--Background-Color;
  padding: 52px 0;
  @include breakpoint('small')   { padding: 65px 0 66px; };
  @include breakpoint('desktop') { padding: 72px 0 70px; };
  @include breakpoint('large')   { padding: 100px 0 99px; };

  //_____________________________________
  // ni__container max tweak between mobile and tablet
  .ni__container {
    position: relative;
    overflow: hidden;
    @media (max-width: 719px) { max-width: 375px; }
  }

  //_____________________________________
  // Typography
  &-title {
    color: $MultiSpotlightSchematic--Color;
    margin-bottom: 53px;
    @include breakpoint('small')   { margin-bottom: 44px; };
    @include breakpoint('desktop') { margin-bottom: 37px; };
    @include breakpoint('large')   { margin-bottom: 57px; };
    @include type-class('.ni__small-detail--2');
  }

  h3 {
    color: $MultiSpotlightSchematic--Color;
    margin: 14px 0 12px;
  }

  p {
    font-family: map-get($fonts, 'sans');
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }

  //_____________________________________
  // Row and Col relative positioning
  [class*='ni__schematic-col'] { position: relative; }

  //_____________________________________
  // Row vertical spacing
  .ni__schematic-row + .ni__schematic-row {
    margin-top: 26px;
    @include breakpoint('small')   { margin-top: 47px; }
    @include breakpoint('desktop') { margin-top: 44px; }
    @include breakpoint('large')   { margin-top: 82px; }
  }
}

// Breakpoint => Mobile
// ========================================================================
// prettier-ignore
.ni__multi-image-spotlight-schematic .ni__schematic--mobile {
  display: block;
  @include breakpoint('small') { display: none; }

  //_____________________________________
  // Row & column spacing + alignments + image sizes
  .ni__schematic-row {
    @include breakpoint('mobile') {
      .ni__schematic-col--image { padding-right: 10px; }
      .ni__schematic-col--copy  { padding-left: 10px; }
    }
  }

  .ni__schematic-row--chassis {
    .ni__schematic-col--copy { max-width: 250px; }
    .ni__schematic-col--image {
      img { width: 130px; }
      @include breakpoint('mobile') { padding: 0 20px 0 10px; }
    }
  }

  .ni__schematic-row--controller {
    .ni__schematic-col--copy { max-width: 250px; }
    .ni__schematic-col--image {
      img { height: 54px; width: auto; }
      @include breakpoint('mobile') {
        @include display-flex(column nowrap, flex-end, flex-start);
      }
    }
  }

  .ni__schematic-row--modules {
    .ni__schematic-col--copy { max-width: 250px; }
    .ni__schematic-col--image {
      img { width: 39px; }
      @include breakpoint('mobile') {
        @include display-flex(column nowrap, flex-end, flex-start);
      }
    }
  }

  .ni__schematic-row--software-services {
    p { max-width: 147px; }
    img { height: 50px; }
    .ni__schematic-col--software {
      p { margin: 0 0 26px 0; }
      @include breakpoint('mobile') {
        text-align: right;
        p { margin: 0 0 0 auto; }
      }
    }
    .ni__schematic-col--services { text-align: left; }
  }
}

// Breakpoint => Tablet
// ========================================================================
// prettier-ignore
.ni__multi-image-spotlight-schematic .ni__schematic--tablet {
  display: none;
  @include breakpoint('small') { display: block; }

  //_____________________________________
  // Row & column spacing + alignments + image sizes
  [class*='ni__schematic-col'] img {
    height: 55px;
    @include breakpoint('desktop') { height: 67px; }
    @include breakpoint('large')   { height: 93px; }
  }

  .ni__schematic-col--software img,
  .ni__schematic-col--services img {
    height: 50px;
    @include breakpoint('large') { height: 70px; }
  }

  //_____________________________________
  // Text Alignment
  .ni__schematic-col--software { text-align: left; }
  .ni__schematic-col--services { text-align: right; }
}

// Pseudo line graphics => solid lines
// ========================================================================
// prettier-ignore
@if $MultiSpotlightSchematic--UseSolidLines == true {
  .ni__multi-image-spotlight-schematic {
    .ni__schematic-col--software h3,
    .ni__schematic-col--services h3 {
      position: relative;
    }

    //_____________________________________
    // [solid] Mobile Container left (before) and right (after)
    .ni__schematic--mobile .ni__container:before {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      width: 1px;
      // height: 461px;
      position: absolute;
      left: 19px;
      top: auto;
      bottom: var(--MultiImageSpotlightSchematic-SolidLine-BottomOffset);
      height: 100%;
      max-height: 0;
      animation: growSolidVerticalLines 300ms ease-in-out 500ms forwards;
    }

    .ni__schematic--mobile .ni__container:after {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      width: 1px;
      // height: 462px;
      position: absolute;
      right: 19px;
      top: auto;
      bottom: var(--MultiImageSpotlightSchematic-SolidLine-BottomOffset);
      height: 100%;
      max-height: 0;
      animation: growSolidVerticalLines 300ms ease-in-out 500ms forwards;
    }

    //_____________________________________
    // [solid] Tablet Container left (before) and right (after)
    .ni__schematic--tablet .ni__container:before {
      content: '';
      background: $MultiSpotlightSchematic--Color;
      width: 1px;
      position: absolute;
      left: 0;
      @include breakpoint('large') { left: 14px; }
      top: auto;
      bottom: var(--MultiImageSpotlightSchematic-SolidLine-BottomOffset);
      height: 100%;
      max-height: 0;
      animation: growSolidVerticalLines 300ms ease-in-out 500ms forwards;
    }

    .ni__schematic--tablet .ni__container:after {
      content: '';
      background: $MultiSpotlightSchematic--Color;
      width: 1px;
      position: absolute;
      right: 0;
      @include breakpoint('large') { right: 14px; }
      top: auto;
      bottom: var(--MultiImageSpotlightSchematic-SolidLine-BottomOffset);
      height: 100%;
      max-height: 0;
      animation: growSolidVerticalLines 300ms ease-in-out 500ms forwards;
    }

    //_____________________________________
    // [solid] Software line (before) and dot (after)
    .ni__schematic-col--software h3:before {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      height: 1px;
      position: absolute;
      max-width: 0;
      animation: growSolidHorizontalLines 300ms ease-in-out 300ms forwards;
                                       width: 64px;  right: 85px;  top: -25px;
      @include breakpoint('small')   { width: 100vw; right: 145px; top: 7px; }
      @include breakpoint('desktop') { width: 100vw; right: 230px; top: 7px; }
      @include breakpoint('large')   { width: 264px; right: 269px; top: 7px; }
    }

    .ni__schematic-col--software h3:after {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      position: absolute;
      transform: scale(0.1);
      animation: growSolidDots 300ms ease-in-out forwards;
                                     left: 56px;  top: -30px;
      @include breakpoint('small') { left: -30px; top: 2px; }
    }

    //_____________________________________
    // [solid] Services line (before) and dot (after)
    .ni__schematic-col--services h3:before {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      height: 1px;
      position: absolute;
      max-width: 0;
      animation: growSolidHorizontalLines 300ms ease-in-out 300ms forwards;
                                       width: 63px;  left: 85px;  top: -25px;
      @include breakpoint('small')   { width: 100vw; left: 145px; top: 7px; }
      @include breakpoint('desktop') { width: 100vw; left: 225px; top: 7px; }
      @include breakpoint('large')   { width: 280px; left: 269px; top: 7px; }
    }

    .ni__schematic-col--services h3:after {
      @include breakpoint('mobile') { content: ''; }
      background: $MultiSpotlightSchematic--Color;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      position: absolute;
      transform: scale(0.1);
      animation: growSolidDots 300ms ease-in-out forwards;
                                     right: 56px;  top: -30px;
      @include breakpoint('small') { right: -30px; top: 2px; }
    }
  }

  @keyframes growSolidDots {
    0% { transform: scale(0.1); }
    100% { transform: scale(1); }
  }

  @keyframes growSolidHorizontalLines {
    0% { max-width: 0; }
    100% { max-width: 100vw; }
  }

  @keyframes growSolidVerticalLines {
    0% { max-height: 0; }
    100% { max-height: 100%; }
  }
}

// Pseudo line graphics => dotted lines
// ========================================================================
// prettier-ignore
@if $MultiSpotlightSchematic--UseDottedLines == true {
  .ni__multi-image-spotlight-schematic {
    
    //_____________________________________
    // [dotted] mobile lines
    .ni__schematic-row--chassis .ni__schematic-col--image:before {
      @include breakpoint('mobile') { content: ''; }
      border-top: 2px dotted var(--ni-green);
      height: 2px;
      position: absolute;
      top: 25px;
      left: -18px;
      width: 26px;
    }

    .ni__schematic-row--controller .ni__schematic-col--image:before {
      @include breakpoint('mobile') { content: ''; }
      border-left: 2px dotted var(--ni-green);
      width: 2px;
      position: absolute;
      right: 16px;
      top: auto;
      left: auto;
      height: 158px;
      bottom: 85px;
    }

    .ni__schematic-row--controller .ni__schematic-col--image:after {
      @include breakpoint('mobile') { content: ''; }
      border-left: 2px dotted var(--ni-green);
      width: 2px;
      position: absolute;
      right: 16px;
      top: 56px;
      left: auto;
      height: 50px;
    }
    
    //_____________________________________
    // [dotted] tablet+ lines
    .ni__schematic-col--chassis:before {
      @include breakpoint('small') { content: ''; }
      border-top: 2px dotted var(--ni-green);
      height: 2px;
      position: absolute;
                                       top: 25px; left: 164px; width: 78px;
      @include breakpoint('desktop') { top: 32px; left: 192px; width: 114px; }
      @include breakpoint('large')   { top: 45px; left: 257px; width: 154px; }
    }

    .ni__schematic-col--controller:before {
      @include breakpoint('small') { content: ''; }
      border-top: 2px dotted var(--ni-green);
      height: 2px;
      position: absolute;
                                       top: 25px; left: 37px; width: 158px;
      @include breakpoint('desktop') { top: 32px; left: 36px; width: 270px; }
      @include breakpoint('large')   { top: 45px; left: 40px; width: 290px; }
    }
  }
}
