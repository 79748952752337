.ni__content-block--copy-only--rich-text {
  width: 100%;
  height: auto;
  padding: 40px 20px;
  background-color: #f4f4f4;
  @include media-breakpoint-up(sm) {
    padding: 80px;
  }
  @include media-breakpoint-up(md) {
    max-width: 828px;
    padding: 64px 66px 48px 64px;
    margin: 0 auto;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1110px;
    padding: 80px;
  }
  h3 {
    color: #044123;
    font-family: FoundersGrotesk;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    margin: 0;
    padding-bottom: 24px;
  }
  p {
    color: #044a27;
    font-family: map-get($fonts, 'sans');
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    margin: 0 0 29px 0;
  }
  p strong {
    font-weight: 500;
  }
  p i {
    font-style: italic;
  }
  ol,
  ul {
    counter-reset: ol-counter;
    list-style: none;
    margin: 20px 0 32px 0;
    padding-left: 0;
    @include media-breakpoint-up(sm) {
      margin: 20px 0 40px 0;
    }
  }
  ol strong,
  li strong {
    font-weight: 500;
  }
  ol li,
  ul li {
    color: var(--forrest-green);
    font-family: map-get($fonts, 'sans');
    font-weight: normal;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 24px;
    font-stretch: normal;
    font-style: normal;
    counter-increment: ol-counter;
    margin: 0 0 29px 0;
    padding-left: 25px;
    position: relative;
    &::before {
      color: #35b77d;
      content: counter(ol-counter) '. ';
      font-family: $fontFamilyMono;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      left: 0;
      letter-spacing: normal;
      line-height: 1.14;
      position: absolute;
      top: 4px;
    }
  }
  ul li::before {
    background-color: #03b585;
    content: '';
    height: 8px;
    left: 0;
    top: 9px;
    width: 8px;
  }
  li > ul,
  li > ol {
    margin: 24px 0 0 0;
  }
  li > ul > li,
  li > ol > li {
    margin: 0 0 24px 0;
  }
  ul li > ul li::before,
  ol li > ol li > ol li::before,
  ul li > ol li::before,
  ol li > ul li > ul li::before {
    background-color: #03b585;
  }
  b,
  strong {
    font-weight: inherit;
  }
}
