// ========================================================================
// Component: Copy and Image
//
// Comments read like so:
// [Style] Parent => Child
// ========================================================================

// Component => Settings
// ========================================================================
$NI__CopyAndImage-ClassString: '.ni__copy-and-image';

// [Style::Default]
// ========================================================================
// prettier-ignore
.ni__copy-and-image {
  color: var(--charcoal);

  //_____________________________________
  // [Style::Default] Padding
  padding: 15px 0 0;
  @include breakpoint('small') { padding: 20px 0 0; }

  //_____________________________________
  // [Style::Default] => Title and Text Inherits/Resets
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: inherit;
    margin-bottom: 0;
  }

  //_____________________________________
  // [Style::Default] => Img properties
  img {
    height: auto;
    width: 100%;
  }

  .ni__copy-and-image-image-container {
    grid-area: image;
    width: 100%;
    margin: 49px 0 17px;
    @include breakpoint('small') { margin: 12px 0 49px; }
    @include breakpoint('desktop') {
      margin: 0 auto 0 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
  }

  .ni__copy-and-image-title-container {
    grid-area: title;
  }

  .ni__copy-and-image-copy {
    grid-area: copy;

    & > *:not(:last-child) {
      padding-bottom: var(--copy-and-image-copy-entries-distance);
    }
  }

  .ni__copy-and-image-content--reverse .ni__copy-and-image-image-container {
    @include breakpoint('desktop') {
      margin-right: 0; margin-left: auto;
    }
  }

  .ni__copy-and-image-eyebrow {
    margin-bottom: var(--copy-and-image-eyebrow-padding, 56px);
    @include breakpoint('small')         { margin-bottom: var(--copy-and-image-eyebrow-padding, 53px); }
    @include breakpoint('desktop small') { margin-bottom: var(--copy-and-image-eyebrow-padding, 57px); }
    @include breakpoint('large')         { margin-bottom: var(--copy-and-image-eyebrow-padding, 42px); }
  }

  //_____________________________________
  // [Style::Default] => Title
  .ni__copy-and-image-title {
    position: relative;
    @include breakpoint('desktop small') { margin-bottom: var(--copy-and-image-headline-padding, 27px); }
    @include breakpoint('large') { margin-bottom: var(--copy-and-image-headline-padding, 32px); }
  }

  //_____________________________________
  // [Style::Default] => Title
  .ni__copy-and-image-body-copy .richtext p {
    white-space: pre-line; // @see https://organic-inc.atlassian.net/browse/NC-1860
  }

  //_____________________________________
  // [Style::Default] => Title => Graphic Bit
  .ni__copy-and-image-title:before {
    @if $UseGraphicBit == true { content: ''; }
    position: absolute;
    right: -22px;
    top: -15px;
    width: 15px;
    height: 13px;
    background-color: var(--ni-green);
    border-top-right-radius: 20px;
  
    @include breakpoint('small') {
      top: -20px;
      width: 20px;
      height: 17px;
    }
  
    @include breakpoint('desktop small') {
      width: 20px;
      height: 20px;
    }
  }

  //_____________________________________
  // [Style::Default] => Optional Button
  .ni__copy-and-image-button-container {
    grid-area: buttons;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    @include breakpoint('desktop') {
      margin-top: 42px;
    }
    
    @include breakpoint('large') {
      margin-top: 40px;
    }

      .ni__button span {
        display: inherit;
      }
    
  }

  //_____________________________________
  // [Style::Default] => AEM Button Wrapper Fix
  .ni__copy-and-image-button-container .button.parbase {
    overflow: visible;
  }
}

.ni__copy-and-image {
  &.ni__copy-and-image--default {
    .ni__copy-and-image-content {
      @include breakpoint('desktop') {
        display: grid;
        grid-template-areas:
          "image ."
          "image title"
          "image copy"
          "image buttons"
          "image .";
        grid-template-rows: 1fr auto auto auto 1fr;
        // To mimic the previous Bootstrap-based layout, we need to do some tricky calculations.
        // To get our expected column width:
        // 1. Start from what the border-box width would have been on Bootstrap, (100% + 30px)
        //    (Bootstrap has 15px padding on each side.)
        // 2. Multiply it with the right fraction.
        // 3. Subtract the 30px that Bootstrap would have used for right/left padding.
        grid-template-columns: calc((100% + 30px) * 0.5 - 30px) calc((100% + 30px) * 5 / 12 - 30px);
        // For the gap, the same calculation applies, but add the 30px we have subtracted from
        // the inner edges of the columns.
        column-gap: calc((100% + 30px) / 12 + 30px);
        align-items: center;
        
        &.ni__copy-and-image-content--reverse {
          grid-template-areas:
            ".       image"
            "title   image"
            "copy    image"
            "buttons image"
            ".       image";
          grid-template-columns: calc((100% + 30px) * 5 / 12 - 30px) calc((100% + 30px) * 0.5 - 30px);
        }

        $supportedRatios: ((1,3), (1,2), (5,7), (1,1), (7,5), (2,1), (3,1));

        @each $ratio1, $ratio2 in $supportedRatios {
          &.ni__copy-and-image--column-layout-#{$ratio1}_#{$ratio2} {
            grid-template-columns: #{$ratio1}fr #{$ratio2}fr;
            column-gap: 30px;
          }
        }

        &.ni__copy-and-image--thick-gutter {
          column-gap: 96px;
        }
      }

      @include breakpoint('lg') {
        &.ni__copy-and-image--thick-gutter {
          column-gap: 112px;
        }
      }

      @include breakpoint('xl') {
        &.ni__copy-and-image--thick-gutter {
          column-gap: 120px;
        }
      }

      .ni__copy-and-image-image-container {
        @include breakpoint('mobile') {
          max-width: 75%;
        }
        
        @include breakpoint('small') {
          max-width: 66.66667%;
        }
        
        @include breakpoint('desktop') {
          max-width: none;
        }
      }
    }
  }
}

// [Style::Alt1]
// ========================================================================
// prettier-ignore
.ni__copy-and-image.ni__copy-and-image--alt-1 {
  .ni__copy-and-image-content {
    @include breakpoint('desktop') {
      display: grid;
      grid-template-areas:
        ".       image"
        "title   image"
        "copy    image"
        "buttons image"
        ".       image";
      grid-template-rows: 1fr auto auto auto 1fr;
      // The calculations are for Bootstrap compatibility. See the comments for the
      // default layout for a detailed explanation.
      grid-template-columns: calc((100% + 30px) * 5 / 12 - 30px) calc((100% + 30px) * 7 / 12 - 30px);
      column-gap: 30px;
      align-items: center;

      &.ni__copy-and-image-content--reverse {
        grid-template-areas:
          "image ."
          "image title"
          "image copy"
          "image buttons"
          "image .";
        grid-template-columns: calc((100% + 30px) * 7 / 12 - 30px) calc((100% + 30px) * 5 / 12 - 30px);
      }
    }
  }

  .ni__copy-and-image-image-container {
    margin: 48px 0 0;
    @include breakpoint('small') { margin: 56px 0 0; width: 83.33333%; }
    @include breakpoint('desktop') { margin: 0 0 0 auto; width: 100%; height: 100%; }
    div { width: 100%; height: 100%; }
  }

  .ni__copy-and-image-content--reverse .ni__copy-and-image-image-container {
    @include breakpoint('desktop') { margin-right: auto; margin-left: 0; }
  }

  .ni__copy-and-image-image-container div[style*='background-image'] {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ni__copy-and-image-title-container {
    @include breakpoint('small') { width: 75%; }
    @include breakpoint('desktop') { width: auto; }
  }

  .ni__copy-and-image-body-copy {
    @include breakpoint('small') { width: 91.66667%; }
    @include breakpoint('desktop') { width: 100%; }
    @include breakpoint('large') { width: 83.33333%; }
  }

  //_____________________________________
  // [Style::Alt1] => Title
  .ni__copy-and-image-title {
    margin-bottom: 15px;
    @include breakpoint('small')         { margin-bottom: 16px; }
    @include breakpoint('desktop small') { margin-bottom: 33px; }
    @include breakpoint('large')         { margin-bottom: 40px; }
  }

  //_____________________________________
  // [Style::Alt1] => Title => Remove Graphic Bit
  .ni__copy-and-image-title:before { content: none; }

  .ni__copy-and-image-button-container {
    .ni__button {
      padding: 0 28px;
    }
  }
}

// [Style::Light]
// ========================================================================
// prettier-ignore
.ni__copy-and-image.ni__copy-and-image--light {
  background-color: var(--off-white);
  margin: 0 auto;

  //_____________________________________
  // [Style::Light] Padding
  padding: 38px 0 61px;
  @include breakpoint('small')         { padding: 80px 0 92px; }
  @include breakpoint('desktop small') { padding: 220px 0 160px; }
  @include breakpoint('large')         { padding: 72px 0 115px; }

  //_____________________________________
  // [Style::Light] Border Radius for Background Color
  border-top-left-radius: 0;

  //_____________________________________
  // [Style::Light] => Container Tweaks for bg
  & > .ni__container {
    @include breakpoint('desktop small') { padding: 0 60px; }
    @include breakpoint('large')         { padding: 0 100px; }
    @include breakpoint('max content')   { padding: 0 20px; }
  }

  .ni__copy-and-image-content {
    @include breakpoint('small') {
      display: grid;
      grid-template-areas:
        "title"
        "copy"
        "image"
        "subtitle"
        "buttons";
    }

    @include breakpoint('desktop') {
      grid-template-areas:
        ".        image"
        "title    image"
        "subtitle image"
        "copy     image"
        "buttons  image"
        ".        image";
      grid-template-rows: 1fr auto auto auto auto 1fr;
      // The calculations are for Bootstrap compatibility. See the comments for the
      // default layout for a detailed explanation.
      grid-template-columns: calc((100% + 30px) * 5 / 12 - 30px) calc((100% + 30px) * 0.5 - 30px);
      column-gap: calc((100% + 30px) / 12 + 30px);
      align-items: center;

      &.ni__copy-and-image-content--reverse {
        grid-template-areas:
          "image ."
          "image title"
          "image subtitle"
          "image copy"
          "image buttons"
          "image .";
        grid-template-columns: calc((100% + 30px) * 0.5 - 30px) calc((100% + 30px) * 5 / 12 - 30px);
      }
    }
  }

  .ni__copy-and-image-title-container {
    @include breakpoint('mobile') { width: 66.66667%; }
    @include breakpoint('small') { width: 50%; }
    @include breakpoint('desktop') { width: 100%; }
  }

  .ni__copy-and-image-subtitle-container {
    grid-area: subtitle;
    @include breakpoint('small') { width: 58.33333%; }
    @include breakpoint('desktop') { width: 91.66667%; }
    @include breakpoint('large') { width: 66.66667%; }
  }

  .ni__copy-and-image-copy {
    @include breakpoint('small') { width: 75%; }
    @include breakpoint('desktop') { width: 100%; }
    @include breakpoint('large') { width: 83.33333%; }
  }

  .ni__copy-and-image-image-container {
    margin: 42px 0 30px;
    @include breakpoint('mobile') { width: 75%; }
    @include breakpoint('small') { margin: 40px 0 33px; width: 66.66667%; }
    @include breakpoint('desktop') { margin: 0 0 0 auto; width: 100%; }
  }

  .ni__copy-and-image-content--reverse .ni__copy-and-image-image-container {
    @include breakpoint('desktop') {
      margin-right: auto;
      margin-left: 0;
    }
  }

  //_____________________________________
  // [Style::Light] => Title
  .ni__copy-and-image-title {
    @include breakpoint('small')         { margin-bottom: 22px; }
    @include breakpoint('desktop small') { margin-bottom: 33px; }
    @include breakpoint('large')         { margin-bottom: 47px; }
  }

  //_____________________________________
  // [Style::Default] => Title => Graphic Bit
  .ni__copy-and-image-title:before {
    @include breakpoint('small')         { right: 0; }
    @include breakpoint('desktop small') { right: -40px; }
    @include breakpoint('large')         { right: -24px; }
  }

  //_____________________________________
  // [Style::Light] => Subtitle
  .ni__copy-and-image-subtitle {
    margin-bottom: 10px;
    @include breakpoint('small')         { margin-bottom: 0; }
    @include breakpoint('desktop small') { margin-bottom: 18px; }
    @include breakpoint('large')         { margin-bottom: 26px; }
  }

  //_____________________________________
  // [Style::Default] => Optional Button
  .ni__copy-and-image-button-container {
    margin-top: 35px;
  }
}

// [Style::Bullets]
// ========================================================================
// prettier-ignore
.ni__copy-and-image.ni__copy-and-image--bullets {

  .ni__copy-and-image-content {
    @include breakpoint('desktop') {
      display: grid;
      grid-template-areas:
        "image ."
        "image title"
        "image copy"
        "image ."
        "buttons buttons";
      grid-template-rows: 1fr auto auto 1fr auto;
      // The calculations are for Bootstrap compatibility. See the comments for the
      // default layout for a detailed explanation.
      grid-template-columns: calc((100% + 30px) * 0.5 - 30px) calc((100% + 30px) * 5 / 12 - 30px);
      column-gap: calc((100% + 30px) / 12 + 30px);
      align-items: center;

      &.ni__copy-and-image-content--reverse {
        grid-template-areas:
          ".       image"
          "title   image"
          "copy    image"
          ".       image"
          "buttons buttons";
        grid-template-columns: calc((100% + 30px) * 5 / 12 - 30px) calc((100% + 30px) * 0.5 - 30px);
      }
    }
  }

  .ni__copy-and-image-image-container {
    margin: 40px 0 32px;
    @include breakpoint('small') { margin: 23px 0 53px; }
    @include breakpoint('desktop') { margin: auto; }
  }

  .ni__copy-and-image-copy {
    @include breakpoint('small') { width: 91.66667%; }
    @include breakpoint('desktop') { width: 100%; }
    @include breakpoint('large') { width: 83.33333%; }
  }

  .ni__copy-and-image-copy p {
    & + p { margin-top: 24px; }
    & + ul { margin-top: 19px; }
    strong { @include type-class('.ni__button-text'); }
  }

  .ni__copy-and-image-copy ul {
    list-style-type: square; // @see https://organic-inc.atlassian.net/browse/NC-2299
    margin: 0;
    padding: 0 0 0 18px;
  }

  .ni__copy-and-image-copy .bullets-container .ni-notransform ul > li::marker,
  .ni__copy-and-image-copy ul > li::marker {
    color: var(--ni-green); // @see https://organic-inc.atlassian.net/browse/NC-2299
  }
  
  .ni__copy-and-image-copy ul > li:before {
    content: none; // @see https://organic-inc.atlassian.net/browse/NC-2299
    color: #01b585;
    position: relative;
    left: -16px;
    width: 24px;
    height: 24px;
    margin-bottom: -24px;
    display: list-item;
    list-style-position: inside;
    text-align: right;
    list-style-type: disc;
  }

  .ni__copy-and-image-button-container {
    @include breakpoint('desktop') {
      margin: 58px auto 0;
    }

    @include breakpoint('large') {
      margin: 80px auto 0;
    }
  }

  .ni__copy-and-image-button-container {
    .button.parbase {
      @include breakpoint('desktop') {
        text-align: center;
      }
    }

    .ni__button  {
      span {
        @include breakpoint('small') {
          display: block;
          width: 100%;
        }

        a {
          @include breakpoint('small') {
            width: 100%;
          } 
        }
      }
    }
  }

  .ni__copy-and-image-button-container a {
    @include breakpoint('small') {
      width: auto;
    }
  }
}

// [Style::Bullets Alt]
// ========================================================================
// prettier-ignore
.ni__copy-and-image.ni__copy-and-image--bullets.ni__copy-and-image--bullets-alt {
  .ni__copy-and-image-content {
    @include breakpoint('desktop') {
      display: grid;
      grid-template-areas:
        "image ."
        "image title"
        "image copy"
        "image buttons"
        "image .";
      grid-template-rows: 1fr auto auto auto 1fr;
      // The calculations are for Bootstrap compatibility. See the comments for the
      // default layout for a detailed explanation.
      grid-template-columns: calc((100% + 30px) * 0.5 - 30px) calc((100% + 30px) * 5 / 12 - 30px);
      column-gap: calc((100% + 30px) / 12 + 30px);
      align-items: center;

      &.ni__copy-and-image-content--reverse {
        grid-template-areas:
          ".       image"
          "title   image"
          "copy    image"
          "buttons image"
          ".       image";
        grid-template-columns: calc((100% + 30px) * 5 / 12 - 30px) calc((100% + 30px) * 0.5 - 30px);
      }
    }
  }

  .ni__copy-and-image-image-container {
    @include breakpoint('desktop') { margin-left: 0; margin-right: auto; }
  }

  .ni__copy-and-image-content--reverse .ni__copy-and-image-desktop-image-container {
    @include breakpoint('desktop') { margin-left: auto; margin-right: 0; }
  }

  .ni__copy-and-image-image-container .embed-responsive {
    margin: 40px 0 32px;
    @include breakpoint('small') { margin: 23px 0 53px; }
    @include breakpoint('desktop') { margin: 0; }
  }

  .ni__copy-and-image-copy {
    @include breakpoint('small') { width: 91.66667%; }
    @include breakpoint('desktop') { width: 100%; }
    @include breakpoint('large') { width: 83.33333%; }
  }

  .ni__copy-and-image-button-container {
    margin: 30px 0 0;
    @include breakpoint('small') {
      margin: 40px 0 0;
    }

    & > div.parbase {
      flex: 0 0 50%;
      text-align: left;
      &:nth-child(1) { padding: 0 24px 16px 0; }
      &:nth-child(2) { padding: 0 0 16px 0; }
    }

    &.ni__copy-and-image-button-container--single-button > div.parbase:nth-child(1) {
      max-width: none;
      padding: 0;
      .ni__button {
        padding: 0 28px;
      }
    }
  }

  @media (max-width: calc(#{map-get($breakpoints, 'desktop')} - 1px)) {
    .ni__button {
      min-width: 0;
    }
  }
}

// Rich Text AEM Tweaks
// ========================================================================
.ni__copy-and-image .ni__copy-and-image-copy .ni__rich-text,
.ni__copy-and-image .ni__copy-and-image-copy .richtext {
  overflow: visible !important;

  p + p {
    margin-top: 20px;
  }

  ul {
    margin-top: 20px;
    padding-left: 16px;
  }

  ul li + li {
    margin-top: 10px;
  }
}
