// ========================================================================
// Components => Content Two Column Editorial
// ========================================================================

$NI__ContentTwoColumnEditorial: '.ni__content-two-column-editorial';
$NI__ContentTwoColumnEditorial--ContentColor: var(--forrest-green);
$NI__ContentTwoColumnEditorial--MaxBreakpoint: map-get($breakpoints, 'max content');

// Component
// ========================================================================
#{$NI__ContentTwoColumnEditorial} {
  margin: 0 auto;
  max-width: $NI__ContentTwoColumnEditorial--MaxBreakpoint;
  padding: 0;
}

// Component => Columns
// ========================================================================
#{$NI__ContentTwoColumnEditorial} .ni__container > .ni__row > div {
  // prettier-ignore
  &:nth-child(2) {
    padding-left: 0;
    padding-right: 0;

    @include breakpoint('small') {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

// Component => Section Title
// ========================================================================
// prettier-ignore
#{$NI__ContentTwoColumnEditorial} h2 {
  color: $NI__ContentTwoColumnEditorial--ContentColor;
  @include type-class('.ni__h2');
  
  margin-bottom: 26px;
  @include breakpoint('desktop') { margin-bottom: 34px; }
  @include breakpoint('large')   { margin-bottom: 48px; }
}

// Component => Paragraph
// ========================================================================
// prettier-ignore
#{$NI__ContentTwoColumnEditorial} {
  p{
	color: $NI__ContentTwoColumnEditorial--ContentColor;
	margin-bottom: 0;
	@include type-class('.ni__body-text--3');

	// @see https://organic-inc.atlassian.net/browse/NC-2376
	column-count: 1;
	@include breakpoint('desktop') { column-count: 2;}
	}
   p:lang(ko), p:lang(zh-CN), p:lang(zh-TW){
	@include breakpoint('desktop') { line-height: 28px!important;}	
   }
  }
  
// Component => Link
// ========================================================================
#{$NI__ContentTwoColumnEditorial} a {
  margin-bottom: 0;
  @include type-class('.ni__button');
  @include type-class('.ni__button--secondary');
}

// Component => Image
// ========================================================================
#{$NI__ContentTwoColumnEditorial} .image-wrapper {
  align-items: center;
  display: flex;

  & > div {
    width: 100%;
  }

  & > div > .img {
    margin-bottom: 48px;
    width: 100%;

    @include breakpoint('desktop') {
      margin-bottom: 0;
      width: 100%;
    }

    img {
      max-width: 100%;
      width: 100%;
    }
  }
}

// Component => Button
// ========================================================================
#{$NI__ContentTwoColumnEditorial} .ni__button {
  margin-top: 40px;
}

// Component => Theme => Image Left
// ========================================================================
#{$NI__ContentTwoColumnEditorial}.image-left {
  .ni__container > .ni__row > div[class^='ni__col-'] {
    &:nth-child(1) {
      @include media-breakpoint-up(desktop) {
        order: 2;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(desktop) {
        order: 1;
      }
    }
  }
}
