// ========================================================================
// Component: Tiled Images Carousel
// ========================================================================

//_____________________________________
// settings => misc.
$NI__CarouselIndustry-Background-Color: transparent;
$NI__CarouselIndustry-Color: var(--forrest-green);
$NI__CarouselIndustry-Min-Height: 630px;
$NI__CarouselIndustry-Content-Padding: 130px 0;
$NI__CarouselIndustry-Content-Max-Width: 1015px;

//_____________________________________
// settings => max width breakpoint / layout change
// this pixel breakpoint is used to determine when the carousel
// functionality changes from the original design, into a centered
// standard carousel. used to prevent odd behavior or 4k/5k screens.
$NI__CarouselIndustry-Max-Width: 1440px;

// Carousel
// ========================================================================
.ni__carousel-browse-by-industry {
  background: transparent;
  color: $NI__CarouselIndustry-Color;
  font-size: 18px;
  margin: 0 auto;
  overflow: hidden;
  width: calc(100vw - 40px);

  @include breakpoint('desktop large') {
    width: 90vw;
    margin: 0 0 0 auto;
  }

  @media (min-width: calc(#{$NI__CarouselIndustry-Max-Width} + 60px)) {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.ni__carousel-browse-by-industry .ni__carousel-container {
  width: 100%;
}

// Carousel => Section Title
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry .ni__carousel-section-title {
  color: $NI__CarouselIndustry-Color;
  margin: 0 0 50px;
  @include breakpoint('small') { margin: 0 0 65px; }
}

// Carousel => Slider => Item
// ========================================================================
.ni__carousel-browse-by-industry .ni__carousel-item {
  background: inherit;
  color: inherit;
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.ni__carousel-browse-by-industry .ni__carousel-items > li {
  background-color: transparent;
  color: var(--forrest-green);
  width: 100%;
  transition: 200ms ease-in-out;
  transition-property: background-color, color;
  min-height: 100%;
  height: auto;
}

// Card
// ========================================================================
.ni__carousel-browse-by-industry .ni__molecule-card {
  height: 100%;
}

// Card => Image => Container
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry .ni__molecule-card-image-container {
  margin-bottom: 23px;
  @include breakpoint('small') { margin-bottom: 32px; }
}

// Card => Body => Container
// ========================================================================
.ni__carousel-browse-by-industry .ni__molecule-card-body-container {
  // prettier-ignore
  @include breakpoint('small') { min-height: 200px; }
  @include display-flex(column nowrap, flex-start, space-between);
}

// Card => Body => Title
// ========================================================================
.ni__carousel-browse-by-industry .ni__molecule-card-body-container .ni__h8 {
  color: $NI__CarouselIndustry-Color;
  margin-bottom: 26px;
  white-space: pre-line;
}

.ni__carousel-browse-by-industry .ni__carousel-item.slide__subtitle-charcoal .ni__molecule-card-body-container .ni__h8 {
  color: var(--charcoal);
}

// Card => Body => Text
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry .ni__molecule-card-body-container p {
  color: $NI__CarouselIndustry-Color;
  @include breakpoint('small') {
    max-width: 75%;
    margin: 0 auto 0 0;
  }
}

// Carousel => Controls => Bullets
// ========================================================================
.ni__carousel-browse-by-industry .ni__carousel-bullets {
  height: 12px;
  margin: 37px auto 0;

  @include breakpoint('small') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }

  .glide__bullet {
    appearance: none;
    background: var(--gray-20);
    border-radius: 50%;
    border: 0;
    height: 8px;
    margin: 0;
    padding: 0;
    width: 8px;

    &.glide__bullet--active {
      background: var(--ni-green);
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

// Carousel => Controls => Arrows
// ========================================================================
.ni__carousel-browse-by-industry .ni__carousel-controls {
  display: none;
  margin: 37px 0 0;
  width: 100%;

  & > button {
    @include button-style('.ni__button--controller');
  }

  & > button[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
  }

  @include breakpoint('small') {
    @include display-flex(row nowrap, center, flex-start);
  }
}

.ni__carousel-browse-by-industry.glide.ni__carousel--showmore-active .glide__slides {
  column-gap: 40px;
}

// Buttons
// ========================================================================

.ni__carousel-browse-by-industry a.ni__carousel-item .ni__molecule-card:hover {

  .ni-btn.ni-btn-primary, .ni-btn.ni-btn-commerce, .ni-btn.ni-btn-app {
    background-color: #03b585;
    border-color: #03b585;
    color: #fff;
    text-decoration: none !important;
  }

  .ni-btn.ni-btn-secondary, .ni-btn.ni-btn-tertiary, .ni-btn.ni-btn-commerce-secondary,
  .ni-btn.ni-btn-app-secondary {
    background-color: transparent;
    border-color: #03b585;
    color: #03b585;
    text-decoration: none !important;
  }

  .ni-btn.ni-btn-questionnaire {
    background-color: #ffe76b;
    color: #333;
  }

  .ni-btn.ni__button.ni__button--primary, .ni-btn.ni__button.ni__button--outline,
  .ni-btn.ni__button.ni__button--secondary {
    background: var(--ni-green);
    border-color: var(--ni-green);
    color: var(--off-white);
  }

  .ni-btn.ni-primary-button-round.ni-design-system-button {
    background-color: #006b46e6;
  }

  .ni-btn.ni-secondary-button-round.ni-design-system-button,
  .ni-btn.ni-tertiary-button-round.ni-design-system-button.ni-tertiary-button-with-container {
    background-color: #ccdcc833;
  }

}
