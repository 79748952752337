// ========================================================================
// Component: Model Detail Banner
// ========================================================================

.ni__model-detail-banner {
  clear: both;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    max-width: 584px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    max-width: 828px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1110px;
  }

  .banner-image img {
    width: 100%;

    @include media-breakpoint-up(sm) {
      border-top-left-radius: 24px;
      height: 260px;
    }

    @include media-breakpoint-up(md) {
      height: 114px;
      width: 260px;
    }
  }
  .banner-text {
    h2 {
      @include type-class(".ni__h8");
      color: var(--off-white);
      margin: 0;
    }

    align-items: center;
    background: var(--ni-green);
    display: flex;
    min-height: 185px;
    padding: 23px 20px;

    @include media-breakpoint-up(sm) {
      min-height: unset;
      padding: 30px 50px;
    }

    @include media-breakpoint-up(md) {
      width: 54.0540541%; // 600/1110
      padding: 0 30px;
      height: 114px;
    }
  }

  .banner-cta {
    background: var(--forrest-green);
    text-align: center;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 22.972973%; // 255/1110
      height: 114px;
    }

    a {
      color: var(--off-white);
      display: inline-block;
      @include type-class(".ni__body-text--3");
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyMyIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE1IDIzIj4KICAgIDxwYXRoIHN0cm9rZT0iI0Y0RjRGNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiIGQ9Ik0xLjY0IDIwLjUxOGwxMS05LjUtMTEtOS41Ii8+Cjwvc3ZnPgo=")
        no-repeat right 50%;
      padding: 37px 25px 36px 0;

      @include media-breakpoint-up(sm) {
        padding: 42px 50px;
      }

      @include media-breakpoint-up(md) {
        padding: 42px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    a:hover {
      color: var(--ni-green);
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyMyIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE1IDIzIj4KICAgIDxwYXRoIHN0cm9rZT0iIzAzYjU4NSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiIGQ9Ik0xLjY0IDIwLjUxOGwxMS05LjUtMTEtOS41Ii8+Cjwvc3ZnPgo=")
        no-repeat right 50%;
    }
  }
}
