// ========================================================================
// Component: NewsBanner
// ========================================================================

.ni__news-banner {
  overflow: hidden;
}

.ni__news-banner--image-wrapper {
  margin-left: -20px;
  position: relative;
  min-height: 201px;
  width: 100%;
  margin-bottom: 27px;
}

.ni__news-banner--image,
.ni__news-banner--image-wrapper .parbase div div,
.ni__news-banner--image-wrapper .parbase div img {
  width: calc(100% + 40px);
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.ni__news-banner--image-wrapper .parbase div img {
  object-fit: cover;
}

.ni__news-banner--container {
  margin-bottom: 25px;

  h2 {
    line-height: 1 !important;
    // max-width: 200px;
  }
}

.ni__news-banner--desc {
  margin-bottom: 30px;
  margin-top: 24px;

  p {
    @include type-class('.ni__body-text--5');
    color: var(--forrest-green);
  }
}

.ni__news-banner--button {
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--forrest-green);

  .button.parbase {
    overflow: visible;
    text-align: left;
    padding: 0;
  }

  .parbase {
    overflow: visible;
  }

  a {
    text-decoration: underline;
    font-family: map-get($fonts, 'sans');
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 25px;
  }

  a:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='22' viewBox='0 0 13 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='white' stroke-width='3'%3E%3Cg%3E%3Cg%3E%3Cpath d='M67 9L76.5 19 86 9' transform='translate(-217 -533) translate(0 501) translate(147 29) rotate(-90 76.5 14)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 18px;
    height: 25px;
    right: 0;
  }

  a:hover {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='22' viewBox='0 0 13 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='#32eb96' stroke-width='3'%3E%3Cg%3E%3Cg%3E%3Cpath d='M67 9L76.5 19 86 9' transform='translate(-217 -533) translate(0 501) translate(147 29) rotate(-90 76.5 14)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  svg {
    width: 19px;
    height: 19px;
    margin-left: 10px;
    color: var(--off-white);
  }
}

.ni__news-banner--profile {
  display: flex;
  align-items: center;
}

.ni__news-banner--avatar {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;
  background-size: contain;
}

// table up
@include media-breakpoint-up(sm) {
  .ni__news-banner {
    .ni__container {
      border-top-left-radius: 20px;
      // border-top-right-radius: 11px;
      border-bottom-left-radius: 11px;
      border-bottom-right-radius: 11px;
      overflow: hidden;
    }
  }

  .ni__news-banner--image-wrapper {
    height: 312px;
    margin-bottom: 47px;
  }

  .ni__news-banner--container {
    padding: 0 29px;
    h2 {
      max-width: 100%;
    }
  }

  .ni__news-banner--desc {
    margin-bottom: 45px;
  }
}

// desktop
@include media-breakpoint-up(md) {
  .ni__news-banner {
    .ni__container {
      padding: 0;
      height: 181px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      // bordes
      border-bottom-left-radius: 0;
    }
  }

  $w: 825px;
  $h: 181px;

  .ni__news-banner--image-wrapper {
    min-width: 259px;
    max-width: 31%;
    padding: 0;
    margin: 0;
    height: $h;
  }

  .ni__news-banner--image,
  .ni__news-banner--image-wrapper .parbase div div,
  .ni__news-banner--image-wrapper .parbase div img {
    width: 100%;
  }

  .ni__news-banner--container {
    max-width: 54%;
    margin-top: 20px;
    margin-bottom: 25px;

    h2 {
      margin-bottom: 8px;
    }
  }

  .ni__news-banner--desc {
    margin: 10px 0 0 0;
  }

  .ni__news-banner--button {
    min-width: 117px;
    max-width: 15%;
    height: $h;
  }
}

// large desktop
@include media-breakpoint-up(lg) {
  .ni__news-banner {
    .ni__container {
      justify-content: flex-start;
    }
  }

  .ni__news-banner--image-wrapper {
    width: 259px;
  }

  .ni__news-banner--container {
    max-width: 721px;
    width: 721px;

    display: flex;
    align-items: center;
  }

  .ni__news-banner--headline {
    width: 223px;
    margin-right: 61px;
    height: 133px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px var(--ni-green);

    h2 {
      margin-top: 0;
      margin-bottom: 9px;
    }

    p {
      margin-top: 20px;
    }
  }

  .ni__news-banner--desc {
    width: 378px;
    margin: 0;
  }

  .ni__news-banner--button {
    width: 160px;
    margin: 0;
  }
}

// theme No Image

.ni__news-banner.no-image {
  .ni__news-banner--image-wrapper {
    display: none;
  }

  .ni__news-banner--container {
    padding-top: 30px;
  }

  @include media-breakpoint-up(md) {
    .ni__news-banner--container {
      padding-top: 0;
      padding-right: 0;
      max-width: 670px;
      width: 100%;
      padding-left: 70px;
      margin-top: 30px;
    }

    .ni__container {
      max-width: 826px;
    }
  }

  @include media-breakpoint-up(lg) {
    .ni__news-banner--container {
      padding-left: 95px;
      max-width: 950px;
    }

    .ni__container {
      max-width: 1110px;
    }
    .ni__news-banner--headline {
      width: 350px;
      p {
        margin-top: 0;
      }
    }
    .ni__news-banner--desc {
      width: 350px;
    }
  }
}
