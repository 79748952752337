// ========================================================================
// Component: MultiImageSpotlightSchematic
// ========================================================================

// returns a border-color for var(--ni-green) with given opacity
@mixin NavListItemNoTabsBorderColor($opacity: 1) {
  border-color: rgba(3, 181, 133, $opacity);
}

// prettier-ignore
.ni__list-items-no-tabs {
  color: var(--forrest-green);

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a {
    display: block;
    padding: 20px 0;
    @include type-class('.ni__text-link--1');
  }

  //_____________________________________
  // accordion
  .ni__accordion .uk-accordion-title {
    font-weight: 500;
    border-bottom: 1px solid;
    @include NavListItemNoTabsBorderColor(0.22);
  }

  .ni__accordion > li {
    border: none;
  }

  .ni__accordion .uk-accordion-content {
    padding: 0;
  }

  .ni__accordion .uk-accordion-content ul > li {
    border-bottom: 1px solid var(--pale-green);
  }

  //_____________________________________
  // grid
  .ni__list-items-no-tabs-grid {
    overflow-x: hidden;

    @include breakpoint('desktop') {
      margin-left: 15px;
      margin-right: 15px;

      & > .ni__col-desktop-4:first-child { padding-left: 0; }
      & > .ni__col-desktop-4:last-child { padding-right: 0; }
    }
  }

  .ni__list-items-no-tabs-grid .uk-accordion-title {
    padding: 4px 0 15px;
    position: relative;
    // border-color: #c4e8da;
    border-color: transparent;

    &:before,
    &:after {
      content: '';
      background-image: none;
      bottom: -1px;
      position: absolute;
      clear: both;
      width: 200vw;
      height: 1px;
      float: none;
      border-bottom: 1px solid;
      border-color: #c4e8da;
    }

    &:before {
      left: -41px;
    }

    &:after {
      left: 41px;
    }
  }

  .ni__list-items-no-tabs-grid .uk-accordion-content a {
    padding: 35px 0 15px;
    position: relative;
  }

  .ni__list-items-no-tabs-grid .uk-accordion-content a:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    @include breakpoint('small')         { bottom: 15px; }
    @include breakpoint('desktop small') { bottom: 16px; }
    @include breakpoint('large')         { bottom: 21px; }
    left: auto;
    right: 0;
    top: auto;
    height: 12px;
    width: 15px;
  }
}
