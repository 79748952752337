// ========================================================================
// Component: Tiled Images Carousel
// ========================================================================

//_____________________________________
// settings => misc.
$NI__CarouselFocusArea-Background-Color: var(--forrest-green);
$NI__CarouselFocusArea-Color: var(--white-three);
$NI__CarouselFocusArea-Min-Height: 630px;
$NI__CarouselFocusArea-Content-Padding: 130px 0;
$NI__CarouselFocusArea-Content-Max-Width: 1015px;

//_____________________________________
// settings => max width breakpoint / layout change
// this pixel breakpoint is used to determine when the carousel
// functionality changes from the original design, into a centered
// standard carousel. used to prevent odd behavior or 4k/5k screens.
$NI__CarouselFocusArea-Max-Width: 1440px;

// Carousel
// ========================================================================
.ni__carousel-focus-area {
  background: $NI__CarouselFocusArea-Background-Color;
  color: $NI__CarouselFocusArea-Color;
  font-size: 18px;
  overflow: hidden;
  padding: 0 0 57px 0;

  &.slide-background--pale-green {
    background: var(--pale-green);
  }

  &.slide-background--light-grey {
    background: var(--light-grey);
  }

  @include breakpoint('desktop small') {
    margin: 0 auto;
    max-width: map-get($breakpoints, 'desktop small');
    padding: 72px 99px 120px 99px;
  }

  @include breakpoint('large') {
    max-width: map-get($breakpoints, 'max content');
    padding-left: 160px;
    padding-right: 160px;
  }
}

.ni__carousel-focus-area .ni__carousel-header {
  background-color: white;
  padding: 0 20px 24px 20px;
  h4 {
    color: var(--forrest-green);
    margin-bottom: 40px;
  }
  h2 {
    color: #054123;
  }

  @include breakpoint('desktop small') {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    h4 {
      color: var(--power-green);
    }
    h2 {
      color: var(--white-three);
    }
  }
}

.ni__carousel-focus-area .ni__carousel-container {
  width: 100%;
}

.ni__carousel-focus-area .ni__button {
  color: #f5f5f5;
  margin: 30px 0 2px;
}

// Carousel => Section Title
// ========================================================================
// prettier-ignore
.ni__carousel-focus-area .ni__carousel-section-title {
  color: $NI__CarouselFocusArea-Color;
  margin: 0 0 0;
  @include breakpoint('small') { margin: 0 0 20px; }
}

// Carousel => Slider => Item
// ========================================================================
.ni__carousel-focus-area .ni__carousel-item {
  background: inherit;
  color: inherit;
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.ni__carousel-focus-area .ni__carousel-items {
  padding-left: 24px;
  padding-right: 24px;

  @include breakpoint('small') {
    padding-left: 0;
    padding-right: 0;
  }
}

.ni__carousel-focus-area .ni__carousel-items > li {
  background-color: transparent;
  color: var(--forrest-green);
  width: 100%;
  transition: 200ms ease-in-out;
  transition-property: background-color, color;
  min-height: 100%;
  height: auto;
}

// Card
// ========================================================================
.ni__carousel-focus-area .ni__molecule-card {
  height: 100%;
}

// Card => Image => Container
// ========================================================================
// prettier-ignore
.ni__carousel-focus-area .ni__molecule-card-image-container {
  margin-bottom: 23px;
  @include breakpoint('small') { 
    max-height: 264px;
    overflow: hidden;
    margin-bottom: 0;
  }
}

// Card => Body => Container
// ========================================================================
.ni__carousel-focus-area .ni__molecule-card-body-container {
  @include breakpoint('small') {
    min-height: 200px;
    padding-top: 50px;
  }

  @include display-flex(column nowrap, flex-start, space-between);
}

// Card => Body => Title
// ========================================================================
.ni__carousel-focus-area .ni__molecule-card-body-container :is(h1, h2, h3, h4, h5, h6) {
  color: $NI__CarouselFocusArea-Color;
  margin-bottom: 14px;

  @include breakpoint('desktop small') {
    font-size: 40px !important;
    line-height: 1.2 !important;
  }
}

:where(.ni__carousel-focus-area .ni__molecule-card-body-container) :is(h1, h2, h3, h4, h5, h6) {
  font-weight: 400;
}

// Card => Body => Text
// ========================================================================
// prettier-ignore
.ni__carousel-focus-area .ni__molecule-card-body-container p {
  color: $NI__CarouselFocusArea-Color;
  // @include breakpoint('small') {
  //   max-width: 75%;
  //   margin: 0 auto 0 0;
  // }
}

// Carousel => Controls => Bullets
// ========================================================================
.ni__carousel-focus-area .ni__carousel-bullets {
  height: 12px;
  margin: 37px auto 0;

  @include breakpoint('desktop small') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }

  .glide__bullet {
    appearance: none;
    background: var(--gray-20);
    border-radius: 50%;
    border: 0;
    height: 8px;
    margin: 0;
    padding: 0;
    width: 8px;

    &.glide__bullet--active {
      background: var(--ni-green);
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

// Carousel => Controls => Arrows
// ========================================================================
.ni__carousel-focus-area .ni__carousel-controls {
  display: none;
  margin: 71px 0 0;
  width: 100%;

  & > button {
    border-color: var(--white-three) !important;
    svg {
      g {
        stroke: var(--white-three) !important;
      }
    }
  }

  & > button:nth-child(2) {
    margin-left: 10px;
  }

  & > button[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
  }

  @include breakpoint('desktop small') {
    @include display-flex(row nowrap, center, flex-start);
  }
}

.ni__carousel-focus-area.glide.ni__carousel--showmore-active .glide__slides {
  gap: 64px;
}
