// ========================================================================
// Component: NavOffers
// ========================================================================

.ni__system-product-background.is-light {
  background-color: var(--off-white);
}



.ni__system-product {
  max-width: 100%;
  color: var(--forrest-green);

  // themes
  &.is-dark {
    background-color: var(--forrest-green);

    .ni__system-product--content {
      p {
        color: var(--white-three);
      }
    }
  }

  // themes
  &.is-light {
    padding-top: 57px;
    padding-bottom: 57px;
  }

  @media (max-width: 719px) {
    .ni__navlist-accordion .ni__accordion > li a {
      padding: 16px 15px 16px 0;
    }
  }
}

.ni__system-product--header {
  padding: 0 0 22px 0;

  h2 {
    color: var(--forrest-green);
    margin-bottom: 18px;
  }

  h3 {
    color: var(--forrest-green);
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.33;
  }
}

.ni__system-product--container {
  padding: 0 20px;
}

.ni__system-product--content {
  color: var(--white-three);
  margin-bottom: 83px;
  padding: 31px 0 32px 0;

  p {
    color: var(--forrest-green);
  }
}

.ni__system-product--image .parbase > div > div {
  width: 335px;
  height: 299px;
  // margin: 0 auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

// Theme green bit
.is-green-bit {
  .ni__system-product--header {
    padding-bottom: 0;

    h2 {
      margin-bottom: 62px;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .ni__system-product--image {
    margin-bottom: 32px;

    .parbase {
      overflow: visible;

      > div > div {
        width: 334px;
        height: 211px;
        background-size: cover;
        border-bottom-right-radius: 22px;
        border-top-left-radius: 50px;
        border-top-right-radius: 22px;
        position: relative;
        width: 100%;

        @media (min-width: 425px) and (max-width: 719px) {
          height: 50vw;
        }

        @include media-breakpoint-up(md) {
          background-size: contain;
          height: 211px;
          width: 334px;
        }

        // video type
        &.embed-responsive {
          width: 375px;
        }

        &:after {
          background-color: var(--power-green);
          border-top-left-radius: 50px;
          content: '';
          height: 59px;
          left: -18px;
          position: absolute;
          top: -25px;
          width: 58px;
        }
      }
    }
  }

  .ni__system-product--content {
    padding-top: 34px;
    padding-bottom: 0;
  }
}

@include media-breakpoint-up(sm) {
  .ni__system-product {
    max-width: 720px;
    padding-left: 70px;
    padding-right: 70px;
    margin: 0 auto;

    &.is-light {
      padding-top: 80px;
    }
  }

  .ni__system-product--container {
    padding: 0;
  }

  .ni__system-product--image .parbase > div > div {
    height: 318px;
    width: 434px;
    margin-left: 0;
  }

  .ni__system-product--header {
    padding-bottom: 48px;

    h2 {
      margin-bottom: 32px;
    }
  }

  .ni__system-product--content {
    padding-left: 0;
    max-width: 434px;
  }

  // Theme green bit
  .is-green-bit {
    .ni__system-product--header {
      padding-bottom: 0;

      h2 {
        margin-bottom: 72px;
      }
    }

    .ni__system-product--image {
      margin-bottom: 40px;

      .parbase > div > div {
        width: 581px;
        height: 367px;

        &.embed-responsive {
          width: 580px;
          height: 326px;
        }

        &:after {
          border-top-left-radius: 102px;
          height: 102px;
          left: -34px;
          top: -39px;
          width: 102px;
        }
      }
    }

    .ni__system-product--content {
      padding-top: 44px;
      max-width: 580px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__system-product {
    max-width: 1024px;

    .ni__accordion {
      margin-top: 70px;
    }
  }

  .ni__system-product--container {
    position: relative;
  }

  .ni__system-product--image .parbase > div > div {
    width: 425px;
    height: 311px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ni__system-product--header {
    padding: 0;
    background-color: transparent;
    max-width: 354px;

    h2 {
      margin-bottom: 60px;
    }
  }

  .ni__system-product--content {
    max-width: 354px;
    padding: 24px 0 0 0;
  }

  // Themes
  .ni__system-product.is-light {
    padding: 176px 100px;

    .ni__system-product--header {
      padding-bottom: 44px;
      width: 354px;
    }

    .ni__system-product--content {
      padding-top: 0;
      margin-bottom: 60px;
    }
  }

  .ni__system-product.is-green-bit {
    padding-left: 0;
    padding-right: 0;
    min-height: 328px;

    .ni__system-product--image {
      margin-bottom: 0;

      .parbase > div > div {
        position: absolute;
        width: 331px;
        height: 210px;
        top: 32px;

        &:after {
          width: 66px;
          height: 66px;
          top: -32px;
          left: -17px;
        }

        &.embed-responsive {
          width: 376px;
          height: 212px;
        }
      }
    }

    .ni__system-product--header {
      padding-top: 32px;
      max-width: 401px;

      h2 {
        margin-bottom: 19px;
      }
    }

    .ni__system-product--content {
      padding-top: 36px;
      max-width: 401px;
    }
  }

  .ni__system-product.is-reverse {
    .ni__system-product--image .parbase > div > div {
      left: 0;
      right: auto;
    }

    .ni__system-product--header,
    .ni__system-product--content {
      margin-right: 0;
      margin-left: calc(331px + 95px);
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__system-product {
    max-width: 1440px;

    .ni__accordion {
      margin-top: 83px;
    }
  }

  .ni__system-product--image .parbase > div > div {
    background-size: contain;
    height: 400px;
    right: 0;
    top: 0;
    width: 54.55%;
  }

  .ni__system-product--header {
    padding: 0;
    background-color: transparent;
    max-width: 445px;
  }

  .ni__system-product--content {
    max-width: 349px;
  }

  // themes
  .ni__system-product.is-light {
    padding-top: 72px;
    padding-bottom: 230px;
    padding-left: 166px;
    padding-right: 166px;
  }

  .ni__system-product.is-green-bit {
    padding-left: 0;
    padding-right: 0;

    .ni__system-product--container {
      margin: 0 auto;
      max-width: 1017px;
    }

    .ni__system-product--image {
      .parbase > div > div {
        width: 446px;
        height: 283px;
        top: 45px;

        &:after {
          width: 89px;
          height: 89px;
          top: -43px;
          left: -23px;
          border-top-left-radius: 100px;
        }

        &.embed-responsive {
          top: 50px;
          width: 446px;
          height: 250px;
        }
      }
    }

    .ni__system-product--header {
      padding-top: 45px;
      max-width: 455px;

      h2 {
        margin-bottom: 24px;
      }
    }

    .ni__system-product--header,
    .ni__system-product--content {
      max-width: 443px;
      margin-left: calc(446px + 127px);
    }
  }
}