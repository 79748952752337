// ========================================================================
// BASE => HTML/BODY
// Normalize basic styles for base HTML and Body tags.
// ========================================================================

html {
  height: auto;
  overflow-x: hidden;
  overflow-y: visible;
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  max-width: 100vw;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

// Used for header cart badge
.header-icon {
  &.cart-badge {
    display: none;

    a {
      height: 30px !important;
      position: relative;
      width: 52px !important;

      .counter {
        background-color: #fff;
        border-radius: 10px;
        border: 2px solid #044123;
        display: none;
        font-family: FoundersGrotesk, Helvetica, Arial, sans-serif;
        font-size: 13px;
        left: 4px;
        line-height: 16px;
        min-height: 20px;
        min-width: 20px;
        position: absolute;
        text-align: center;
        z-index: 20;

        &.show {
          display: block !important;
        }
      }
    }
  }
}

.ni-aem-body {
  .breadcrumb {
    &.row {
      &.parbase {
        margin: 0 !important;
      }
    }
  }
}
