// ========================================================================
// Component: SysClientInfo
// ========================================================================

.ni__sys-client-info {
  background-color: var(--off-white);
  padding: 63px 20px 47px 20px;

  h4,
  span {
    color: #054123;
  }

  h4 {
    margin-bottom: 56px;
  }
}

.ni__sys-client-info--content {
  max-width: 334px;
  margin: 0 auto;
}

.ni__sys-client-info--name {
  padding-left: 89px;
  span {
    display: block;
  }
}

.ni__sys-client-info h4 {
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    display: block;
    background-color: var(--ni-green);
  }

  &:before {
    top: -15px;
    left: -15px;
    border-top-left-radius: 300px;
  }

  &:after {
    right: -15px;
    bottom: -15px;
    border-bottom-right-radius: 300px;
  }

  @include media-breakpoint-up(md) {
    &:after,
    &:before {
      width: 40px;
      height: 40px;
    }

    &:before {
      top: -58px;
      left: -50px;
    }

    &:after {
      right: -40px;
      bottom: -40px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .ni__sys-client-info {
    max-width: 582px;
    margin: 0 auto;
  }

  .ni__sys-client-info--content {
    max-width: 384px;
  }

  .ni__sys-client-info--name {
    padding-left: 200px;
  }
}

@include media-breakpoint-up(md) {
  .ni__sys-client-info {
    max-width: 1024px;
    border-top-right-radius: 400px;
    padding-top: 109px;
    padding-bottom: 71px;

    h4 {
      margin-bottom: 177px;
    }
  }

  .ni__sys-client-info--content {
    max-width: 542px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ni__sys-client-info--name {
    padding-left: 0;
    position: relative;

    $h: 678px;
    $w: 493px;

    &:before {
      content: '';
      width: $w;
      height: $h;
      position: absolute;
      top: -($h - 10px);
      left: -($w + 71px);
      display: block;
      border-left: 3px solid var(--forrest-green);
      border-bottom: 3px solid var(--forrest-green);
      border-bottom-left-radius: 400px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__sys-client-info {
    max-width: 1440px;
    padding-top: 144px;
    padding-bottom: 72px;

    h4 {
      margin-bottom: 204px;
    }
  }

  .ni__sys-client-info--content {
    max-width: 730px;
  }

  .ni__sys-client-info--name {
    $h: 678px;
    $w: 726px;

    &:before {
      content: '';
      width: $w;
      height: $h;
      top: -($h - 10px);
      left: -($w + 34px);
      // border-bottom-left-radius: 500px;
    }
  }
}
