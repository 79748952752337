.ni__eyebrow_headline_CTA {
  .ni__eyebrow {
    color: var(--forrest-green);
    text-align: right;
    padding-top: 17.067vw;
    @include type-class('.ni__eyebrow--5');

    @include media-breakpoint-up(sm) {
      text-align: left;
      padding-top: 75px;
    }

    @include media-breakpoint-up(tablet) {
      padding-top: 84px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 176px;
    }

    span {
      position: relative;
      background: white;
      padding-left: 4vw;
      z-index: 10;

      @include media-breakpoint-up(sm) {
        padding-left: 15px;
        margin-left: -10px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 25px;
        margin-left: -25px;
      }

      &:before {
        content: '';
        position: absolute;
        border-top: solid 2px #151715;
        width: 200%;
        height: calc(50% + 1px);
        bottom: 0;
        right: 0;
        z-index: -1;

        @include media-breakpoint-up(sm) {
          width: 300%;
          left: -300%;
        }
      }
    }
  }

  .ni__headline-cta {
    .ni__headline-cta__container {
      padding-top: 56px;
      padding-bottom: 64px;

      @include media-breakpoint-up(sm) {
        padding-top: 55px;
        padding-bottom: 75px;
      }

      @include media-breakpoint-up(tablet) {
        padding-top: 65px;
        padding-bottom: 84px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 88px;
        padding-bottom: 120px;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 176px;
      }
    }

    .ni__headline-cta__headline.textMedium {
      @include type-class('.ni__h2');
    }
  }

  .ni__headline-cta-button__container {
    .ni__button {
      padding-bottom: asdf;
      &:nth-child(2) {
        display: none;
      }
    }
  }
}
