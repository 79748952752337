.ni__about-us-headline--image {
  &.small-text,
  &.small-text.light-grey,
  &.small-text.image-left {
    .ni__about-us-headline__wrapper {
      .ni__row {
        .ni__col-mobile-12 {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-up(md) {
            flex-direction: row;
          }

          .content {
            width: 100%;
            order: 2;

            @include media-breakpoint-up(md) {
              order: 1;
            }
          }

          .ni__about-us-headline__image {
            margin-bottom: 58px;
            order: 1;

            @include media-breakpoint-up(md) {
              margin-bottom: 0;
              order: 2;
            }
          }
        }
      }
    }
  }

  &.small-text,
  &.small-text.light-grey {
    min-height: 300px !important;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;

    @include media-breakpoint-up(sm) {
      min-height: 385px !important;
    }

    @include media-breakpoint-up(md) {
      min-height: 445px !important;
      padding-bottom: 85px;
    }

    .ni__about-us-headline__wrapper {
      .ni__row {
        flex-direction: column;

        .ni__about-us-headline__h2 {
          order: 2;

          @include media-breakpoint-up(sm) {
            padding-left: 0;
            padding-right: 0;
          }

          @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
          }

          @include media-breakpoint-up(desktop) {
            order: 1;
          }
        }

        .ni__about-us-headline__text {
          margin-bottom: 0;
          order: 3;
          padding-bottom: 0;

          @include media-breakpoint-up(sm) {
            padding-left: 0;
            padding-right: 0;
          }

          @include media-breakpoint-up(md) {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
            padding-left: 20px;
            padding-right: 20px;
          }

          @include media-breakpoint-up(desktop) {
            order: 2;
            padding-bottom: 16px;
          }
        }

        > .ni__about-us-headline__image {
          margin-bottom: 54px;

          @include media-breakpoint-up(desktop) {
            order: 3;
            margin-bottom: 0;
          }
        }

        .ni__about-us-headline__image {
          order: 1;
          padding-left: 0;
          padding-right: 0;

          @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 20px;
          }

          @include media-breakpoint-up(desktop) {
            order: 3;
            margin-bottom: 0;
          }
        }
      }

      @include media-breakpoint-up(sm) {
        min-height: 385px !important;
      }

      @include media-breakpoint-up(md) {
        min-height: 445px;
      }

      @include breakpoint('medium') {
        position: relative;
      }

      .ni__aboutus__number {
        display: none;
      }

      .ni__about-us-headline__h2 {
        color: var(--forrest-green);
        flex: 1;
        font-family: FinancierDisplay;
        font-size: 60px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1;
        margin-bottom: 24px;
        margin-left: 0;
        max-width: 100%;
        padding-bottom: 0;
        width: 100% !important;

        @include media-breakpoint-up(md) {
          flex: 0 0 33.33333%;
          margin-bottom: 33px;
          max-width: 33.33333%;
          max-width: 332px;
          width: inherit;
        }

        @include media-breakpoint-up(lg) {
          margin-left: 0;
        }
      }

      .ni__about-us-headline__text {
        @include type-class('.ni__body-text--3');

        padding-top: 0;
        margin-bottom: 48px;
        margin-left: 0%;

        p {
          color: var(--forrest-green);
          font-family: FoundersGrotesk;
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          font-weight: normal;
          letter-spacing: normal;
          line-height: 1.33;
          margin-bottom: 40px;
        }

        a {
          @include type-class('.ni__button');
          @include type-class('.ni__button--secondary');
        }
      }

      > .ni__about-us-headline__image {
        @include media-breakpoint-up(lg) {
          margin-right: 82.5px;
        }
      }

      .ni__about-us-headline__image {
        display: block;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        top: 0;
        width: 100%;

        @include media-breakpoint-up(md) {
          height: auto;
          margin-left: 0;
          max-width: 332px;
          position: absolute;
          right: 0;
          width: 100%;
        }

        @include media-breakpoint-up(desktop) {
          max-height: 411px;
          max-width: 445px;
        }

        &::after {
          background-color: var(--ni-green);
          bottom: 0;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          right: 33px;
          top: 0;
          width: 30px;

          @include media-breakpoint-up(md) {
            max-height: 445px;
          }

          @include media-breakpoint-up(desktop) {
            left: 19px;
            width: 25px;
          }

          @include media-breakpoint-up(lg) {
            max-height: 445px;
            width: 33px;
          }
        }

        img {
          display: block;
          height: 100%;
          width: 100%;
          margin-bottom: 0;

          @include media-breakpoint-up(sm) {
            margin-top: 0;
          }
        }
      }
    }

    &.small-text {
      background: var(--white-three) !important;
    }

    &.small-text.light-grey {
      background: var(--off-white) !important;
      padding-bottom: 100px;
      padding-top: 100px;
    }

    &.image-left {
      .ni__about-us-headline__wrapper {
        .ni__row {
          align-items: flex-end;
          flex-direction: column;

          .ni__about-us-headline__h2 {
            margin-left: auto;
            margin-right: 0;

            @include media-breakpoint-up(md) {
              flex: 0 0 41.66667%;
              max-width: 41.66667%;
              padding-left: 45px;
            }
          }

          .ni__about-us-headline__text {
            @include type-class('.ni__body-text--3');

            margin-left: auto;
            margin-right: 0;
            position: relative;

            @include media-breakpoint-up(md) {
              padding-left: 45px;
              flex: 0 0 41.66667%;
              max-width: 41.66667%;
            }
          }

          > .ni__about-us-headline__image {
            @include media-breakpoint-up(lg) {
              margin-left: 82.5px;
              margin-right: 0;
            }
          }

          .ni__about-us-headline__image {
            left: 0;
            margin-left: auto;
            right: inherit;

            &::after {
              bottom: 0;
              left: 0;
              right: inherit;
              top: 0;

              @include media-breakpoint-up(md) {
                left: 19px;
              }
            }
          }
        }
      }
    }
  }

  .ni__about-us-headline {
    background: $NI__AboutUsValues-BG-Color;
    margin: 0 auto;
    max-width: 1440px;
    padding-top: 32px;

    .ni__aboutus__number {
      display: inline-block;
      padding-bottom: 5px;
      padding-top: 0;
    }
  }

  .where-we-are-headed,
  .whats-next {
    &.parbase {
      overflow: visible !important;
    }
  }

  .ni__about-us-headline {
    padding-bottom: 32px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 88px;
      padding-top: 88px;
    }

    .ni__about-us-headline__wrapper {
      display: flex;
      flex-direction: column;
      min-height: 300px;

      .ni__aboutus__number {
        @include media-breakpoint-up(lg) {
          position: relative;
          text-align: right;
        }

        @media (min-width: 1200px) and (max-width: 1299px) {
          margin-left: 0 !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }

      .ni__about-us-headline__h2 {
        color: $NI__aboutUsHeadline-color;
        font-family: $NI__aboutUsHeadline-font;
        font-size: 30px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.33;
        padding-bottom: 16px;
        white-space: pre-line;

        @include media-breakpoint-up(xxxs) {
          font-size: 40px;
          line-height: 1.1;
          margin-bottom: 25px;
        }
      }

      .ni__about-us-headline__text {
        color: #044022;
        font-family: map-get($fonts, 'sans');
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-height: 1.33;
        padding-bottom: 16px;
        padding-top: 16px;
      }
    }
  }
}

.ni__about-us-headline--image {
  position: relative;
  padding-top: 64px;

  @include media-breakpoint-up(md) {
    padding-bottom: 85px;
  }

  .ni__about-us-headline__h2 {
    @include type-class('.ni__h2');
  }

  .ni__about-us-headline__text {
    @include type-class('.ni__body-text--2');

    margin-bottom: 64px;
    padding-top: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .ni__about-us-headline__image {
    display: block;
    height: auto;
    position: relative;
    width: 100%;

    @media (min-width: 550px) {
      height: 425px;
      overflow: hidden;
    }

    @include media-breakpoint-up(sm) {
      height: auto;
      overflow: visible;
    }

    @include media-breakpoint-up(tablet) {
      height: 388px;
      margin: 0;
      position: absolute;
      right: 0;
      top: 40px;
      width: 445px;
    }

    .parbase {
      height: 100% !important;
      width: 100% !important;
      > div {
        position: relative;
        height: 100% !important;
        width: 100% !important;

        img {
          top: 0;
        }
      }
    }

    &:after {
      background-color: var(--ni-green);
      content: '';
      height: 100%;
      position: absolute;
      right: 15px;
      top: 0;
      width: 30px;

      @include media-breakpoint-up(tablet) {
        right: 0;
        width: 18px;
      }
    }

    // prettier-ignore
    img {
      display: block;
      height: auto;
      width: 100%;

      @media (min-width: 550px) {
        margin-top: -50px;
      }

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }
  }
}

// tablet up
@include breakpoint('medium') {
  .ni__about-us-headline--image {
    .ni__about-us-headline__wrapper {
      position: relative;
    }
  }
}

// desktop large
@include breakpoint('large') {
  .ni__about-us-headline--image {
    min-height: 791px;

    .ni__about-us-headline__image {
      height: 556px;
      padding: 0;
      right: 0;
      top: 0;
      width: 557px;

      &:after {
        right: -54px;
        width: 54px;
      }
    }
  }

  .ni__about-us-headline--image {
    .ni__aboutus__number {
      font-size: 32px;
      margin-left: -30px;
      margin-top: -32px;
      padding-top: 0;
      position: absolute;
      text-align: left;
    }
  }
}

.ni__about-us-headline__imageGroup--wrapper {
  margin-left: -20px;
  width: calc(100% + 40px); //padding left + right
}

.ni__about-us-headline__image--with-caption {
  display: block;
  height: auto;
  margin: 64px 0 0;
  position: relative;
  width: 100%;

  & > .ni__about-us-headline__image {
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  & > .ni__about-us-headline__image:after {
    right: 0;
  }

  @media (min-width: 550px) {
    overflow: hidden;
  }

  @include breakpoint('medium') {
    height: auto;
    margin: 0;
    position: absolute;
    right: 0;
    top: 20px;
    width: 445px;

    & > .ni__about-us-headline__image {
      height: auto;
      margin: 0;
      position: relative;
      right: 0;
      top: 0;
      width: 100%;
    }
  }

  @include breakpoint('large') {
    width: 557px;
    height: auto;
    right: 0;
    top: 10px;
    padding: 0;
  }
}
