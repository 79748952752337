// forked from https://getuikit.com/docs/visibility

[hidden],
.ni__hidden {
  display: none !important;
}

.ni__visible {
  display: block !important;
}

// Visibility => Hidden @ Breakpoints
// ========================================================================
// prettier-ignore
.ni__hidden-- {
  @include breakpoint('mobile') { &\@mobile { display: none !important; }}
  @include breakpoint('xxxsmall') { &\@xxxsmall { display: none !important; }}
  @include breakpoint('xxsmall') { &\@xxsmall { display: none !important; }}
  @include breakpoint('xsmall') { &\@xsmall { display: none !important; }}
  @include breakpoint('small') { &\@small { display: none !important; }}
  @include breakpoint('tablet') { &\@tablet { display: none !important; }}
  @include breakpoint('medium') { &\@medium { display: none !important; }}
  @include breakpoint('desktop small') { &\@desktop { display: none !important; }}
  @include breakpoint('desktop small') { &\@desktop-small { display: none !important; }}
  @include breakpoint('laptops') { &\@laptops { display: none !important; }}
  @include breakpoint('grid') { &\@grid { display: none !important; }}
  @include breakpoint('large') { &\@large { display: none !important; }}
  @include breakpoint('desktop large') { &\@desktop-large  { display: none !important; }}
  @include breakpoint('max content') { &\@max-content { display: none !important; }}
  @include breakpoint('xlarge') { &\@xlarge { display: none !important; }}
  @include breakpoint('xxlarge') { &\@xxlarge { display: none !important; }}
  @include breakpoint('xxxlarge') { &\@xxxlarge { display: none !important; }}
  @include breakpoint('imac') { &\@imac { display: none !important; }}
}

// Visibility => Visible @ Breakpoints
// ========================================================================
// prettier-ignore
.ni__visible-- {
  @media (max-width: calc(#{map-get($breakpoints, 'mobile')} - 1px)) { &\@mobile { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xxxsmall')} - 1px)) { &\@xxxsmall { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xxsmall')} - 1px)) { &\@xxsmall { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xsmall')} - 1px)) { &\@xsmall { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'small')} - 1px)) { &\@small { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'tablet')} - 1px)) { &\@tablet { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'medium')} - 1px)) { &\@medium { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'desktop small')} - 1px)) { &\@desktop { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'desktop small')} - 1px)) { &\@desktop-small { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'laptops')} - 1px)) { &\@laptops { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'grid')} - 1px)) { &\@grid { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'large')} - 1px)) { &\@large { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'desktop large')} - 1px)) { &\@desktop-large { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'max content')} - 1px)) { &\@max-content { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xlarge')} - 1px)) { &\@xlarge { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xxlarge')} - 1px)) { &\@xxlarge { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'xxxlarge')} - 1px)) { &\@xxxlarge { display: none !important; }}
  @media (max-width: calc(#{map-get($breakpoints, 'imac')} - 1px)) { &\@imac { display: none !important; }}
}

.ni__invisible {
  visibility: hidden !important;
}
