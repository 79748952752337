// ========================================================================
// Component: SolutionsHero_combo
// ========================================================================

$SolutionsHero: '.ni__solutions-hero_combo .ni__solutions-hero_combo__container';
$pointerEvents: auto;

#{$SolutionsHero} {
  padding-top: 14.933vw;

  // prettier-ignore
  @media (min-width: 720px) and (max-width: 1023px) {
    .ni__container { max-width: none !important; }
  }
}

// prettier-ignore
#{$SolutionsHero} .ni__solutions-hero_combo__headline {
  margin: 0 auto;
  max-width: 358px;
  @include breakpoint('small')  { max-width: 720px; }
  @include breakpoint('tablet') { max-width: none; }
}

#{$SolutionsHero} .ni__solutions-hero_combo__headline h1 {
  color: var(--forrest-green);
  margin: 0;
  @include type-class('.ni__hero--3');

  &.ni__solutions-hero_combo__headline-small {
    @include type-class('.ni__hero--4');
  }

  span {
    display: block;
  }

  // prettier-ignore
  .ni__solutions-hero_combo__headline-bottom {
    padding-left: 15.667vw;
    @include media-breakpoint-up(xs) { padding-left: 6.5vw; }
    @include media-breakpoint-up(lg) { padding-left: 190px; }
  }
}

#{$SolutionsHero} .ni__solutions-hero_combo__bg_container {
  background-color: var(--pale-green);
  margin-bottom: 103px;
  margin-top: -18px;
  padding-bottom: 271px;
  padding-top: 82px;
  position: relative;
  z-index: -1;

  .ni__solutions-hero_combo__bg_containerText .paragraph {
    color: var(--forrest-green);
    @include type-class('.ni__body-text--1');
  }

  .ni__solutions-hero_combo__bg_containerImg {
    width: 100%;
    padding-top: 32px;
    text-align: right;
    position: absolute;
    pointer-events: $pointerEvents;
  }

  .ni__solutions-hero_combo__bg_containerImg img {
    width: 48.267vw;
    margin-right: 8vw;
    max-width: 440px;
  }
}

// prettier-ignore
#{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container {
  .ni__solutions__curvedBG { display: none; }

  .ni__solutions-hero_combo__bg_containerImg {
    width: 100%;
    height: auto;
    overflow: hidden;
    pointer-events: $pointerEvents;
    img { width: 100%; }
  }
}

#{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container {
  .ni__solutions-hero_combo__bg_containerText {
    padding-bottom: 17.067vw;

    .ni__solutions-hero_combo__bg_containerTextTitle {
      color: var(--forrest-green);
      margin: 0;
      padding-bottom: 8.533vw;
      padding-top: 14.933vw;
      white-space: pre-line;
      @include type-class('.ni__h2');
    }

    .paragraph_small {
      color: var(--forrest-green);
      @include type-class('.ni__body-text--2');
    }
  }
}

@media (min-width: 450px) {
  #{$SolutionsHero} .ni__solutions-hero_combo__bg_container .ni__solutions-hero_combo__bg_containerImg img {
    width: auto;
    height: 330px;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container .ni__solutions-hero_combo__bg_containerImg {
    margin: 0 auto;
    max-width: 450px;
    padding: 0 20px;
  }

  #{$SolutionsHero}
    .ni__solutions-hero_combo__curved_bg_container
    .ni__solutions-hero_combo__bg_containerText
    .ni__solutions-hero_combo__bg_containerTextTitle {
    padding-top: 56px;
    padding-bottom: 32px;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container .ni__solutions-hero_combo__bg_containerText {
    padding-bottom: 64px;
  }
}

@media (max-width: 719px) {
  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container .ni__solutions-hero_combo__bg_containerText {
    max-width: 450px;
  }
}

// Tablet styles
@include media-breakpoint-up(sm) {
  #{$SolutionsHero} {
    padding-top: 130px;
    padding-bottom: 56px;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container .ni__solutions-hero_combo__bg_containerText {
    padding-bottom: 0;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__bg_container {
    margin-top: -40px;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container {
    display: flex;
    flex-flow: row-reverse nowrap;
  }

  #{$SolutionsHero} .imageContainer > .parbase {
    overflow: visible !important;
  }

  #{$SolutionsHero} .ni__solutions-hero_combo__curved_bg_container .ni__solutions-hero_combo__bg_containerImg img {
    height: auto;
    width: 100%;
  }
}

// Tablet styles
@include media-breakpoint-up(tablet) {
  #{$SolutionsHero} {
    padding-top: 10vw;
    padding-bottom: 0;

    .ni__solutions-hero_combo__headline {
      h1 {
        font-size: 9.333vw;
        line-height: 9.986vw;
      }
    }

    .ni__solutions-hero_combo__curved_bg_container {
      display: block !important;
      .imageContainer {
        height: auto;
      }
    }

    .ni__solutions-hero_combo__curved_bg_container {
      display: flex;
      flex-flow: row-reverse nowrap;
    }

    .ni__solutions-hero_combo__bg_container {
      padding-top: 13.333vw;
      padding-bottom: 10.25vw;
      margin-top: -4.65vw;
      margin-bottom: 0;

      .ni__solutions-hero_combo__bg_containerText {
        margin: 0;
      }

      .ni__solutions-hero_combo__bg_containerImg {
        top: 13.333vw;
        padding-top: 0;
        pointer-events: $pointerEvents;

        img {
          height: auto;
          width: 28.667vw;
          margin-right: 13.417vw;
        }
      }
    }

    .ni__solutions-hero_combo__curved_bg_container {
      position: relative;

      .ni__solutions__curvedBG {
        background-color: var(--pale-green);
        display: block;
        position: absolute;
        height: 40vw;
        width: 40vw;
        z-index: -1;

        &:before {
          content: '';
          height: 40vw;
          width: 40vw;
          background-color: var(--white-three);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .ni__solutions-hero_combo__bg_containerImg {
        height: 89.333vw;
        padding: 0;
        width: 100%;
        position: absolute;
        pointer-events: $pointerEvents;
        max-width: none;

        .imageContainer > div,
        .imageContainer > div > div {
          height: auto;
          width: 100%;
        }

        .imageContainer {
          height: 43vw;
          overflow: hidden;
          position: absolute;
          text-align: right;
          right: 0;
          top: 15.75vw;
          z-index: 999;

          img {
            height: 470.844px;
            width: auto;
          }
        }
      }

      .ni__solutions-hero_combo__bg_containerText {
        padding-bottom: 12.5vw;
        .ni__solutions-hero_combo__bg_containerTextTitle {
          padding-top: 25.583vw;
          padding-bottom: 2vw;
        }
      }
    }
  }
}

// Between 890px and desktop Headline title breaks
#{$SolutionsHero} .ni__solutions-hero_combo__headline h1 {
  @media (min-width: 818px) {
    font-size: 7vw;
  }
}

// Desktop styles
@include media-breakpoint-up(lg) {
  #{$SolutionsHero} {
    padding-top: 72px;
    margin: 0 auto;

    .ni__solutions-hero_combo__headline {
      h1 {
        font-size: 8.15vw;
        line-height: 8.333vw;

        @media (min-width: 1200px) {
          font-size: 120px;
          line-height: 120px;
        }

        &.ni__solutions-hero_combo__headline-bottom {
          padding-left: 190px;
        }
      }
    }

    .ni__solutions-hero_combo__bg_container {
      padding-top: 199px;
      padding-bottom: 16.667vw;
      margin-top: -58px;
      margin-bottom: 0;
      max-height: 790px;

      .ni__solutions-hero_combo__bg_containerText {
        margin: 0;
      }

      .ni__solutions-hero_combo__bg_containerImg {
        top: 199px;
        max-width: 1440px;
        width: auto;
        left: auto;
        right: 0;
        transform:none img {
          min-width: 345px;
          margin-right: 10vw;
          max-width: 439px;
          width: 439px;
          margin-right: 186px;
        }
      }
    }

    .ni__solutions-hero_combo__curved_bg_container {
      position: relative;

      .ni__solutions__curvedBG {
        background-color: var(--pale-green);
        display: block;
        position: absolute;
        height: 40vw;
        width: 40vw;
        max-width: 375px;
        z-index: -1;

        &:before {
          content: '';
          height: 40vw;
          width: 40vw;
          max-width: 375px;
          background-color: var(--white-three);
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .ni__solutions-hero_combo__bg_containerImg {
        margin: 0 auto;
        max-width: 1440px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;

        .imageContainer {
          width: 515px;
          height: 647px;
          top: 168px;
          z-index: 999;

          img {
            height: 50vw;
            width: auto;
            max-height: 647px;
          }
        }
      }

      .ni__solutions-hero_combo__bg_containerText {
        padding-bottom: 12.5vw;
        padding-bottom: 178px;
        .ni__solutions-hero_combo__bg_containerTextTitle {
          padding-top: 355px;
          padding-bottom: 48px;
        }
      }
    }
  }
}

// description

.ni__solutions-hero_combo__bg_containerImg {
  .ni__description {
    padding-left: 20px;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      width: 374px;
      position: absolute;
      right: 0;
      top: calc(15.75vw + 480px);
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      width: 515px;
      position: absolute;
      right: 0;
      top: 825px;
    }
  }
}
