// ========================================================================
// BASE => BOX SIZING
// Normalize box-sizing for all elements and pseudo selectors.
// ========================================================================

*,
*::before,
*::after {
  box-sizing: border-box;
}
