$NI__CarouselLight-Background-Color: var(--pale-green);
$NI__CarouselLight-Color: var(--forrest-green);
$NI__CarouselLight-Content-Max-Width: inherit;

.ni__carousel.ni__carousel--lead {
  background: $NI__CarouselLight-Background-Color;
  color: $NI__CarouselLight-Color;
  margin: 0 auto;
  padding: 0 0 60px 0;

  @include breakpoint('small') {
    padding: 112px 0;
  }

  @include breakpoint('medium') {
    max-width: 1440px;
  }

  @include breakpoint('desktop ') {
    padding: 112px 0;
  }

  @include breakpoint('large') {
    padding: 176px 0;
  }

  &:before {
    background: linear-gradient(to right, $NI__CarouselLight-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    right: auto;
  }

  &:after {
    background: linear-gradient(to left, $NI__CarouselLight-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: auto;
    right: 0;
  }

  .ni__carousel-item-container {
    .ni__carousel-image-container {
      padding-top: 0;
    }
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-container {
  border-radius: 0;
  background: $NI__CarouselLight-Background-Color;
  min-height: 0;

  @include breakpoint('desktop small') {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
}

.ni__carousel.ni__carousel--lead .glide__track,
.ni__carousel.ni__carousel--lead .ni__carousel-items {
  overflow: visible;
}

.ni__carousel.ni__carousel--lead .ni__carousel-grid {
  @include breakpoint('desktop small') {
    flex-flow: row-reverse nowrap;
    align-items: flex-end;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-grid-left {
  @include breakpoint('medium') {
    max-width: 540px;
    margin-left: 105px;
    margin-right: auto;
  }

  @include breakpoint('large') {
    margin-left: 125px;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-grid-right {
  padding: 0;

  @include breakpoint('desktop small') {
    max-width: 320px;
    padding: 0;
  }

  @include breakpoint('large') {
    max-width: 350px;
    padding: 0;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-image-container {
  margin: 0 auto;
  position: relative;

  @include breakpoint('desktop small') {
    max-width: none;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-image {
  height: 394px;
  margin: 0 0 0 auto;
  position: relative;
  width: 100%;
  z-index: 3;

  @media (min-width: 520px) {
    height: 450px;
  }

  @media (min-width: 640px) {
    height: 500px;
  }

  @include breakpoint('small') {
    height: 560px;
  }

  @include breakpoint('desktop small') {
    height: 540px;
    margin: 0 auto 0 0;
    width: 320px;
  }

  @include breakpoint('large') {
    height: 590px;
    margin: 0 auto 0 0;
    width: 350px;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-item-container,
.ni__carousel.ni__carousel--lead .ni__carousel-controls-item-container {
  box-sizing: border-box;
  margin-top: 0;

  @include breakpoint('medium') {
    margin: 0;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 100%;
  }

  @include breakpoint('large') {
    margin-top: 40px;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-controls-container {
  margin: 0;
  flex-flow: row-reverse nowrap;
  justify-content: flex-start;
}

.ni__carousel.ni__carousel--lead .ni__carousel-counter {
  font-family: map-get($fonts, 'serif');
  font-size: 0.889em;
  line-height: 1;
  margin: 0 0 0 40px;
  min-width: 60px;
  text-align: right;

  .ni__carousel-counter-current {
    color: var(--forrest-green);
  }

  .ni__carousel-counter-divider {
    color: var(--forrest-green);
    margin: 0 4px;
    opacity: 0.6;
  }

  .ni__carousel-counter-total {
    color: var(--forrest-green);
    opacity: 0.6;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-item-container.ni__carousel-controls-item-container {
  display: none;
  margin-top: 40px !important;

  @include breakpoint('desktop') {
    display: block;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-controls > button:nth-child(2) {
  margin-left: 10px;
}

.ni__carousel.ni__carousel--lead .ni__carousel-content .ni__carousel-content-title {
  margin: 0 0 40px;
  max-width: 60vw;
  @include type-class('.ni__h2');

  @include breakpoint('desktop small') {
    margin: 0 0 76px;

    // per https://organic-inc.atlassian.net/browse/NC-1133
    max-width: 100%;
    // max-width: 350px;
  }

  @include breakpoint('large') {
    margin: 0 0 83px;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-content .ni__carousel-content-text-lead {
  color: inherit;
  margin: 0 0 16px;
  opacity: 0;
  @include type-class('.ni__body-text--1');

  @include breakpoint('large') {
    margin: 0 0 8px;
  }
}

.ni__carousel.ni__carousel--lead .ni__carousel-content .ni__carousel-content-text {
  @include type-class('.ni__body-text--4');
}

.ni__carousel.ni__carousel--lead .ni__carousel-bullets {
  margin: 32px auto 56px;
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.ni__carousel.ni__carousel--lead .ni__carousel-bullets .glide__bullet.glide__bullet--active {
  background: var(--forrest-green);
}

.ni__carousel.ni__carousel--lead .ni__carousel-eyebrow {
  @include type-class('.ni__eyebrow--5');

  &.ni__carousel-eyebrow--mobile {
    display: block;
    margin: 0 auto 56px;
    box-sizing: border-box;
    padding: 0;
    width: 100%;

    @include breakpoint('desktop small') {
      display: none;
    }
  }

  &.ni__carousel-eyebrow--desktop {
    display: none;

    @include breakpoint('desktop small') {
      display: block;
    }

    @include breakpoint('large') {
      margin: 0 auto 89px;
    }
  }
}

.three-columns-carousel.parbase {
  overflow: visible;
}

.ni__three-columns-lead-carousel {
  position: relative;

  // prettier-ignore
  .ni__aboutus-values {
    background: var(--white-three);
    padding: 0;
    margin-bottom: 64px;
    @include media-breakpoint-up(md) { margin-bottom: 120px; }
    @include media-breakpoint-up(lg) { margin-bottom: 176px; }

    .ni__aboutus-values__container,
    .ni__aboutus-values_eyebrow {
      background: var(--white-three);
    }

    .ni__aboutus-values_eyebrow {
      @include type-class('.ni__eyebrow--5');
    }
  }

  .ni__aboutus-values .ni__aboutus-values__container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ni__aboutus-values__line {
    display: none;
  }

  .curved_line {
    border-bottom: 3px solid black;
    display: none;
    position: absolute;
    width: 100%;
    top: 73px;
    pointer-events: none;

    @include breakpoint('desktop small') {
      display: block;
      left: -3%;
      top: 50px;
      border-left: 3px solid #000;
      border-bottom-left-radius: 330px;
      height: 151%;
      width: 50%;
      z-index: 10;
    }

    @include breakpoint('large') {
      left: -1%;
      height: 173%;
      top: 2px;
    }

    @include breakpoint('desktop large') {
      left: -4%;
      width: 52.5%;
    }
  }
}

// animations
.ni__carousel.ni__carousel--lead .glide__slide--active {
  .ni__carousel-bullets {
    opacity: 1;
  }

  .ni__carousel-content-text-lead {
    animation-delay: 200ms;
    @include animation-fade-in(left, small);
  }
}

// german lang positioning
// prettier-ignore
[data-aem-fallback-locale="de-DE"] {
  .ni__carousel.ni__carousel--lead .ni__carousel-eyebrow--desktop {
    @include breakpoint('tablet') { height: 147%; }
    @include breakpoint('large')  { height: 168.5%; }
  }
}
