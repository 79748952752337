// ========================================================================
// Molecules: Textbox Container
// ========================================================================

.ni__molecule-textbox {
  padding: 32px 20px 32px 22px;
  margin: 0;
  width: 100%;

  @include breakpoint('small') {
    padding: 32px 50px;
  }

  @include breakpoint('desktop small') {
    padding: 32px 72px;
  }
}

.ni__molecule-textbox .ni__molecule-textbox-inner {
  border-top: 1px solid var(--forrest-green);
  border-bottom: 1px solid var(--forrest-green);
  padding: 32px 0 31px;
}

.ni__molecule-textbox h1,
.ni__molecule-textbox h2,
.ni__molecule-textbox h3,
.ni__molecule-textbox h4,
.ni__molecule-textbox h5,
.ni__molecule-textbox h6 {
  margin: 0;
}

.ni__molecule-textbox .ni__molecule-textbox-title {
  margin-bottom: 20px;
}

// prettier-ignore
.ni__molecule-textbox-buttons-container > .ni__button:nth-child(2),
.ni__molecule-textbox-buttons-container > div + div {
  margin-left: 6px;
  @media (min-width: 425px) { margin-left: 20px; }
  @include breakpoint('desktop small') { margin-left: auto; }
}

// prettier-ignore
.ni__molecule-textbox .ni__molecule-textbox-buttons-container {
  @include display-flex(row nowrap, flex-start, flex-start);
  @include breakpoint('desktop') { justify-content: space-between; }
}

// add top margin if there's a possible button that exists
.ni__molecule-textbox .ni__molecule-textbox-inner > .ni__row + .ni__molecule-textbox-buttons-container {
  margin-top: 40px;
}

// fix AEM .button.parse styles
.ni__molecule-textbox .button.parbase {
  overflow: visible;
  text-align: left;
  padding: 0;
}
