// ========================================================================
// Component: PerspectiveArticle
// ========================================================================

// settings
$ni__perspective-padding: 40px;

.ni__perspective-article {
  a {
    text-decoration: none;
  }
}

.is-article--feature {
  padding: $ni__perspective-padding calc($ni__perspective-padding / 2);
  background-color: var(--pale-green);
  .ni__perspective-article__num {
    &:after {
      width: 100%;
    }
  }

  &:after {
    width: 100%;
    content: '';
    height: 1px;
    width: 100%;
    background-color: var(--forrest-green);
    display: block;
    margin-top: $ni__perspective-padding;
  }
}

.is-post--feature {
  padding-top: 40px;
  padding-bottom: 20px;
}

.ni__perspective-article__num {
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  span {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--ni-green);
    @include type-class('.ni__number-eyebrow--2');
  }

  &:after {
    content: '';
    height: 3px;
    background-color: var(--forrest-green);
    width: 81px;
  }
}

.ni__perspective-article__title {
  @include type-class('.ni__h9');
  color: var(--forrest-green);
  font-stretch: normal;
  font-style: normal;
  padding-bottom: 15px;
  margin: 0;
  white-space: pre-line;
}

.ni__perspective-article__text {
  color: var(--forrest-green);
  @include type-class('.ni__body-text--4');
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.ni__perspective-article__image {
  height: 199px;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.ni__perspective-article__next100 {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

@include media-breakpoint-up(md) {
  .is-article--feature {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .is-article--feature {
    &:after {
      margin-top: 38px;
    }
  }

  .is-post--feature {
    .ni__perspective-article__num:after {
      height: 3px;
    }
  }

  .ni__perspective-article__image {
    max-width: 100%;
  }
}

// Perspective Grid Especial fixes
@media (min-width: 768px) and (max-width: 1023px) {
  .is-article--feature {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ni__perspective-header {
    .ni__perspective-headline {
      margin-bottom: 10px;
    }
  }

  .ni__perspective-post {
    // padding-left: 15px;
    // padding-right: 15px;
    .ni__col-md {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .ni__perspective-grid .ni__container:after {
    left: 67px;
  }
  .ni__perspective-grid .ni__container:before {
    left: 15px;
  }

  .ni__perspective-post,
  .ni__perspective-banner,
  .ni__perspective-filter,
  .ni__perspective-grid {
    .ni__container {
      max-width: calc(100% - 30px);
    }

    [class*='ni__col-'] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.ni__perspective-article__links {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  &>.ni__perspective-article__link {
    flex: 1;
  }
  .ni__button {
    width: 100%;
    padding: 0 8px;
  }
}

.ni__perspective-article__links:not(.ni__perspective-article__links--vertical) {
  @media (min-width: 375px) {
    flex-wrap: nowrap;
    &>.ni__perspective-article__link {
      flex: 0 0 auto;
      width: calc((100% - 20px) / 2);
    }
  }

  @media (min-width: 426px) {
    column-gap: 40px;
    &>.ni__perspective-article__link {
      width: calc((100% - 40px) / 2);
    }
  }

  @include breakpoint('desktop') {
    column-gap: 30px;
    &>.ni__perspective-article__link {
      width: calc((100% - 30px) / 2);
    }
  }
}

.ni__perspective-article__links.ni__perspective-article__links--vertical {
  width: min-content;
  @media (min-width: 375px) {
    row-gap: 16px;
  }
}