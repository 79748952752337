/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'variables';

@import 'node_modules/bootstrap/scss/mixins/breakpoints';
// NI custom col and classes
// Example .ni-container
@import './mixins/grid-framework';
@import 'node_modules/bootstrap/scss/mixins/grid';

// NI custom containers -
// Example .ni-container
@import 'grid';
@import 'node_modules/bootstrap/scss/utilities/display';
@import 'node_modules/bootstrap/scss/utilities/flex';
@import 'node_modules/bootstrap/scss/utilities/spacing';
@import 'node_modules/bootstrap/scss/utilities/embed';
