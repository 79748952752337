// ========================================================================
// Component: PerspectiveHeader
// ========================================================================

@include media-breakpoint-down(sm) {
  .ni__perspective-header {
    .ni__perspective-headline .ni__container {
      max-width: 100%;
      padding: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .ni__perspective-header {
    .ni__perspective-headline .ni__container {
      max-width: calc(100% - 60px);
      padding: 0;

      .ni__perspective-article__text {
        max-width: 100%;
      }
    }
  }
}

// edit media-query
// @include breakpoint('desktop small') {
@include media-breakpoint-up(md) {
  .ni__perspective-header {
    .ni__perspective-headline {
      margin-top: -270px;
    }
  }
}
