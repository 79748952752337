// ========================================================================
// Component: AboutUSHero
// ========================================================================

.ni__aboutus-hero {
  margin: 0 auto;
}

.ni__aboutus-hero .ni__aboutus-hero__container {
  background: var(--pale-green);
  padding-top: 64px;
  padding-bottom: 64px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    overflow: hidden;
    padding-top: 76px;
    padding-bottom: 76px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 176px;
    padding-bottom: 88px;
  }

  .ni__container {
    position: relative;
  }
}

.ni__aboutus-hero__curevedLine {
  border-top: 2px solid black;
  position: absolute;
  width: 100%;
  top: 7px;
  display: block;

  @include media-breakpoint-up(sm) {
    right: -65px;
    border-right: 2px solid black;
    border-top-right-radius: 196px;
    height: 87%;
    width: 100%;
    display: block;
  }

  @include media-breakpoint-up(tablet) {
    right: -20px;
    width: 90%;
  }

  @include media-breakpoint-up(lg) {
    border-top: 3px solid black;
    border-right: 3px solid black;
    top: 8px;
    right: -105px;
    border-top-right-radius: 436px;
    height: 88%;
    width: 1220px;
  }
}

.ni__aboutus-hero .ni__aboutus-hero_eyebrow {
  color: var(--forrest-green);
  background: var(--pale-green);
  position: relative;
  z-index: 1;
  @include type-class('.ni__eyebrow--5');

  // do not touch
  // https://organic-inc.atlassian.net/browse/NC-1204
  flex: none !important;
  max-width: 300px;

  @media (min-width: 460px) {
    max-width: 440px;
  }

  @include breakpoint('small') {
    width: auto !important;
    max-width: 500px;
    padding-right: 15px !important;
  }
}

.ni__aboutus-hero .ni__aboutus-hero__headline {
  color: var(--forrest-green);
  letter-spacing: normal;
  padding-top: 63px;
  @include type-class('.ni__h2');

  // @media (max-width: map-get($breakpoints, 'tablet') - 1px) {
  //   font-size: 32px !important;
  //   line-height: 40px !important;
  // }

  // @media (min-width: map-get($breakpoints, 'tablet')) and (max-width: map-get($breakpoints, 'desktop small') - 1px) {
  //   font-size: 40px !important;
  //   line-height: 48px !important;
  // }

  @include media-breakpoint-up(lg) {
    padding-top: 176px;
  }
}

.ni__aboutus-hero .ni__button--tertiary {
  @include breakpoint('large') {
    float: right;
  }
}
