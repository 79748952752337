// ========================================================================
// Component: ContentSpotlightContentModule
// ========================================================================

// settings
$ContentSpotlightContentModule--Color: var(--forrest-green);

// prettier-ignore
.ni__content-spotlight-content-module {
                                   padding: 64px 0 0;
  @include breakpoint('small')   { padding: 96px 0 0; }
  @include breakpoint('desktop') { padding: 88px 0 0; }
  @include breakpoint('large')   { padding: 105px 0 0; }

  .ni__container {
    @media (max-width: 719px) { max-width: 425px; }
  }

  img { 
    width: 100%; 
    @include media-breakpoint-up(sm) {
      width: 484px;  
    }

    @include media-breakpoint-up(md) {
      width: 400px;
    }

    @include media-breakpoint-up(xl) {
      width: 100%; 
    }
  }

  h2 {
    color: $ContentSpotlightContentModule--Color;
    white-space: pre-line;
                                     margin: 0 0 16px;
    @include breakpoint('small')   { margin: 0 0 30px; }
    @include breakpoint('desktop') { margin: 0 0 24px; }
    @include breakpoint('large')   { margin: 0 0 26px; }
  }

  &--image {
                                     margin: 0 0 70px;
    @include breakpoint('small')   { margin: 0 0 40px; }
    @include breakpoint('desktop') { margin: 0; }
  }

  .ni__body-text--3 {
    color: $ContentSpotlightContentModule--Color;
    @include type-class('.ni__body-text--3');
                                   margin-bottom: 40px;
    @include breakpoint('large') { margin: 0 0 46px; }
  }

  .ni__molecule-content-module-body-container h3 {
    color: $ContentSpotlightContentModule--Color;
  }

  .ni__molecule-content-module-body-container .parbase,
  .ni__molecule-content-module-body-container a {
    display: block;
    overflow: visible;
    @include type-class('.ni__eyebrow--3');
  }

  // with icons
  [class*='ni__content-module--'] .ni__molecule-content-module-body-container a {
    position: relative;
    padding-left: 26px;
    margin-bottom: 4px;
  }

  // video icon
  .ni__content-module--video .ni__molecule-content-module-body-container a {
    &:before {
      content: '';
      width: 19px;
      height: 14px;
      @include breakpoint('small') { height: 16px; }
      position: absolute;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3Cpath fill='%2303B585' d='M5.564 6h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v6.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267-.327-.174-.583-.43-.757-.756-.174-.326-.267-.65-.267-1.54V8.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267zM17 10.333l2.36-1.966c.424-.354 1.055-.296 1.408.128.15.18.232.406.232.64v5.73c0 .552-.448 1-1 1-.234 0-.46-.082-.64-.232L17 13.667v-3.334z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  // paper icon
  .ni__content-module--paper .ni__molecule-content-module-body-container a {
    &:before {
      content: '';
      width: 19px;
      height: 21px;
      @include breakpoint('small') { height: 22px; }
      position: absolute;
      left: 0;
      top: -3px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3Cpath fill='%2335B77D' d='M13 4.18V8c0 .552.448 1 1 1h3.82c-.098-.216-.235-.415-.406-.586l-3.828-3.828c-.171-.171-.37-.308-.586-.406zM18 11h-4c-1.657 0-3-1.343-3-3V4H8c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h8c1.105 0 2-.895 2-2v-7zm-5.328-9c.53 0 1.039.21 1.414.586l5.328 5.328c.375.375.586.884.586 1.414V18c0 2.21-1.79 4-4 4H8c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h4.672z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
