// ========================================================================
// Mixin: Breakpoints
//  imac..............5120px
//  xxxlarge..........2560px
//  xxlarge...........1800px
//  xlarge............1600px
//  max content.......1440px
//  desktop large.....1366px
//  large.............1200px
//  grid..............1140px
//  laptops...........1100px
//  desktop small.....1024px
//  medium............960px
//  tablet............720px
//  small.............640px
//  xsmall............410px
//  xxsmall...........375px
//  xxxsmall..........335px
// ========================================================================

// prettier-ignore
@mixin breakpoint($point) {
  @if $point == 'imac' {
    @media (min-width: map-get($breakpoints, 'imac'))           { @content ; }
  }

  @if $point == 'xxxlarge' {
    @media (min-width: map-get($breakpoints, 'xxxlarge'))       { @content ; }
  }

  @else if $point == 'xxlarge' {
    @media (min-width: map-get($breakpoints, 'xxlarge'))        { @content ; }
  }

  @else if $point == 'xlarge' {
    @media (min-width: map-get($breakpoints, 'xlarge'))         { @content ; }
  }

  @else if $point == 'max content' {
    @media (min-width: map-get($breakpoints, 'max content'))    { @content ; }
  }

  @else if $point == 'desktop large' {
    @media (min-width: map-get($breakpoints, 'desktop large'))  { @content ; }
  }

  @else if $point == 'large' {
    @media (min-width: map-get($breakpoints, 'large'))          { @content ; }
  }

  @else if $point == 'grid' {
    @media (min-width: map-get($breakpoints, 'grid'))           { @content ; }
  }

  @else if $point == 'laptops' {
    @media (min-width: map-get($breakpoints, 'laptops'))        { @content ; }
  }

  @else if $point == 'desktop' {
    @media (min-width: map-get($breakpoints, 'desktop'))        { @content ; }
  }

  @else if $point == 'desktop small' {
    @media (min-width: map-get($breakpoints, 'desktop small'))  { @content ; }
  }

  @else if $point == 'medium' {
    @media (min-width: map-get($breakpoints, 'medium'))         { @content ; }
  }

  @else if $point == 'tablet' {
    @media (min-width: map-get($breakpoints, 'tablet'))         { @content ; }
  }

  @else if $point == 'small' {
    @media (min-width: map-get($breakpoints, 'small'))          { @content ; }
  }

  @else if $point == 'xsmall' {
    @media (min-width: map-get($breakpoints, 'xsmall'))         { @content ; }
  }

  @else if $point == 'xxsmall' {
    @media (min-width: map-get($breakpoints, 'xxsmall'))        { @content ; }
  }

  @else if $point == 'xxxsmall' {
    @media (min-width: map-get($breakpoints, 'xxxsmall'))       { @content ; }
  }

  @else if $point == 'mobile' {
    @media (min-width: map-get($breakpoints, 'mobile'))         { @content ; }
  }
}
