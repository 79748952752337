// ========================================================================
// Component: AboutUsVideo
// ========================================================================

// settings
$NI__VideoMainColor: var(--ni-green);
$NI__VideoWhite: var(--white-three);
$NI__VideoFont: map-get($fonts, 'sans');
$NI__AboutUsHero-Font-Family-Serif: map-get($fonts, 'serif');

.ni__video-component,
.ni__aboutus-values.video {
  .vjs-big-play-button {
    height: 64px !important;
    width: 64px !important;
    background-color: $NI__VideoMainColor !important;
    opacity: 0.7 !important;

    &:hover {
      opacity: 1 !important;
    }

    &:before {
      margin: 18px auto !important;
    }
  }

  .vjs-progress-control {
    .vjs-play-progress {
      background-color: $NI__VideoMainColor !important;
    }
  }

  .vjs-control-bar {
    color: $NI__VideoWhite !important;
  }

  .vjs-volume-level {
    background-color: $NI__VideoMainColor !important;
  }

  .vjs-dock-text {
    font-family: $NI__VideoFont !important;
  }
}

// global
.vjs-captions-button .vjs-menu-content {
  max-height: 175px !important;
}

// table up
@include media-breakpoint-up(md) {
  .vjs-captions-button .vjs-menu-content {
    max-height: 347px !important;
  }
}
