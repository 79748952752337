// ========================================================================
// Component: Content Description And Image Schematic
// ========================================================================

.ni__content-description-iamge-schematic {
  img {
    image-rendering: pixelated;
    width: 100%;
  }

  picture {
    display: block;
  }

  //_____________________________________
  // component => main contain box sizing for responsive comp
  & > .ni__container {
    @include breakpoint('mobile') {
      box-sizing: content-box;
    }
  }

  //_____________________________________
  // component => main contain max widths
  // prettier-ignore
  & > .ni__container {
    width: 100%;
    max-width: 335px;
    @include breakpoint('small')       { max-width: 502px; }
    @include breakpoint('desktop')     { max-width: 793px; }
    @include breakpoint('large')       { max-width: 1068px; }
  }

  //_____________________________________
  // component => text column container
  // prettier-ignore
  &-text-container {
    margin-bottom: 38px;
    @include breakpoint('small')   { margin-bottom: 55px; }
    @include breakpoint('desktop') { margin-bottom: 62px; }
    @include breakpoint('large')   { margin-bottom: 56px; }
  }

  //_____________________________________
  // component => text column title
  // prettier-ignore
  &-text-container > h2 {
    color: var(--forrest-green);
    margin-bottom: 20px;
    @include breakpoint('small')   { margin-bottom: 21px; }
    @include breakpoint('desktop') { margin-bottom: 30px; }
    @include breakpoint('large')   { margin-bottom: 26px; }
  }

  //_____________________________________
  // component => text copy widths
  // prettier-ignore
  &-text-container > p {
    color: var(--forrest-green);
    max-width: 100%;
    @include breakpoint('small')   { max-width: 344px; }
    @include breakpoint('desktop') { max-width: 471px; }
    @include breakpoint('large')   { max-width: 635px; }
  }

  // THEMES => LIGHT GRAY
  // ========================================================================
  // prettier-ignore
  &.theme--light-gray,
  &.theme--light-grey {
    background: var(--gray-20);
    padding: 68px 0 78.5px;
    @include breakpoint('small')   { padding: 67px 0 59px; }
    @include breakpoint('desktop') { padding: 100px 0 99px; }
    @include breakpoint('large')   { padding: 100px 0 100px; }
  }
}
