.ni__pagination {
    height: 24px;
    gap: 8px;
    padding: 0;
    padding-bottom: 130px;
}

.ni__pagination-container,
.ni__pagination-numbers {
    display: flex;
    justify-content: center;
}

.ni__pagination-previous,
.ni__pagination-next {
    display: flex;

    .ni__pagination-item {
        padding: 2px;
    }

    .ni__pagination-item a {
        border-radius: 4px;
        padding: 0px;
    }

    svg {
        @media (max-width: 768px) {
            height: 24px;
            width: 24px;
        }
    }
}

.ni__pagination-item {

    height: 24px;
    font-family: map-get($fonts, 'mono');
    size: 18px;
    line-height: 24px;
    align-items: center;
    display: flex;
    margin-right: 8px;

    @media (max-width: 768px) {
        height: 36px;
    }


    .ni__pagination-ellipsis {
        padding: 0 6px 8px 6px;

        @media (max-width: 768px) {
            padding: 0 16px 8px 16px;
        }
    }

    a {
        padding: 0 6px;
        border-radius: 4px;

        @media (max-width: 768px) {
            padding: 10px 16px;
            border-radius: 6px;
        }
    }

    &.ni__pagination-active {
        a {
            background-color: var(--digital-green);
            color: var(--off-white);
        }

    }

    &.ni__pagination-inactive {
        a {
            color: var(--dark-gray-76);
        }

        a:hover {
            color: var(--digital-green);
            background-color: rgba(204, 220, 200, 0.2);
        }
    }

}

.ni__pagination-inactive {
    path {
        fill: var(--pale-grey);
    }

}

.ni__pagination-active {
    path {
        fill: var(--digital-green);
    }
}

.ni__pagination-container a:hover {
    .ni__pagination-active {
        background-color: rgba(204, 220, 200, 0.2);
    }
}

.ni__pagination-numbers {
    .ni__pagination-item {
        display: none;
    }

    .ni__pagination-visible {
        display: flex;
    }
}

.ni__pagination-disabled {
    pointer-events: none;
    cursor: default;
}
