// ========================================================================
// Component: SolutionsTitleGrid
// ========================================================================

// Mobile First
.ni__solutions-title_grid {
  background-color: var(--pale-green);
  .ni__solutions-title_grid-container {
    background-color: var(--off-white);
    .ni__container {
      padding-top: 64px;
      padding-bottom: 40px; // 64px (total) - 24px (title margin)

      .ni__solutions-title_grid-title {
        padding-bottom: 56px;

        h2 {
          color: var(--forrest-green);
          @include type-class('.ni__h3');
        }
      }

      .ni__solutions-title_grid-grid {
        a {
          display: block;
        }

        .ni__col {
          padding: 0 4vw;
          padding-bottom: 2.133vw;

          @include media-breakpoint-up(tablet) {
            &:nth-child(odd) {
              padding-right: 2vw;
            }

            &:nth-child(even) {
              padding-left: 2vw;
            }
          }
        }

        .ni__solutions-title_grid-grid_imgContainer {
          padding-bottom: 9px;
          overflow: hidden;

          & + .ni__solutions-title_grid-grid_imgContainer {
            padding-bottom: 0 !important;
          }

          img {
            width: 128%;
            display: block;
          }
        }

        .ni__solutions-title_grid-grid_text {
          color: var(--forrest-green);
          margin-bottom: 24px;
          @include type-class('.ni__eyebrow--3');
        }
      }
    }
  }
}

// Tablet (720-1023) styles
@include media-breakpoint-up(sm) {
  .ni__solutions-title_grid {
    .ni__solutions-title_grid-container {
      .ni__container {
        padding-top: 122px;
        padding-bottom: 78px; // 102px (total) - 24px (title margin)
      }
    }
  }
}

// Desktop (1024-1199) styles
@include media-breakpoint-up(tablet) {
  .ni__solutions-title_grid {
    .ni__solutions-title_grid-container {
      position: relative;
      border-top-right-radius: 20vw;

      .ni__container {
        .ni__solutions-title_grid-title {
          padding-bottom: 64px;

          h2 {
            margin: 0;
          }
        }

        .ni__solutions-title_grid-grid {
          .ni__solutions-title_grid-grid_imgContainer {
            padding-bottom: 0.667vw;

            img {
              width: 100%;
              display: block;
            }
          }
          .ni__solutions-title_grid-grid_text {
            margin-bottom: 46px;
          }
        }
      }
    }
  }
}

// Large (1200+) styles
@include media-breakpoint-up(lg) {
  .ni__solutions-title_grid {
    .ni__solutions-title_grid-container {
      position: relative;
      border-top-right-radius: 30vw;

      .ni__container {
        padding-top: 176px;
        padding-bottom: 152px; // 176px (total) - 24px (title margin)

        .ni__solutions-title_grid-title {
          padding-bottom: 80px;
        }

        .ni__solutions-title_grid-grid {
          .ni__col {
            $gutter: 30px;
            $halfGutter: 15px;
            padding: 0 15px;
            padding-bottom: 30px;
          }
          .ni__solutions-title_grid-grid_imgContainer {
            padding-bottom: 20px;

            img {
              width: 100%;
              display: block;
              min-height: 272px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

// AEM parbase override fix
.ni__solutions-title_grid
  .ni__solutions-title_grid-container
  .ni__container
  .ni__solutions-title_grid-grid
  .ni__solutions-title_grid-grid_imgContainer
  > .img.image-component.parbase {
  padding-bottom: 0 !important;
}
