// ========================================================================
// Component: Dropdown
// ========================================================================

.ni__dropdown {
  .ni__dropdown__button {
    min-width: auto;
    position: relative;
    padding-left: 22px;
    padding-right: 42px;
    text-transform: capitalize;

    &:hover:after {
      border-top-color: var(--forrest-green);
    }

    &:focus {
      outline: inherit;
      // border-color: #007bff;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-radius: 4px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid var(--ni-green);
      top: 50%;
      margin-top: -4px;
      right: 21px;
    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid var(--ni-green);
    }
  }
}

.ni__dropdown__menu--content {
  box-shadow: 0 2px 64px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px var(--pale-green);
  background-color: var(--white-three);
  padding: 25px 24px;
  position: relative;
  max-width: 100%;
}

.ni__dropdown__menu {
  z-index: 100;
  visibility: hidden;
  position: absolute;
  right: 0;
  max-width: 100%;
  max-width: 350px;
  margin-left: 20px;
  margin-right: 20px;

  &[data-popper-placement] {
    visibility: visible;
  }
}

.ni__dropdown__menu--close {
  display: block;
  position: absolute;
  top: 25px;
  right: 24px;
  cursor: pointer;
  color: var(--ni-green);

  svg {
    display: block;
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: var(--forrest-green);
  }
}

// custom dropdown
.ni__perspective-drowpdown__content {
  h3 {
    font-family: map-get($fonts, 'sans');
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--forrest-green);
    margin-bottom: 20px;
  }

  .ni__category__container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
    }

    .ni__category {
      margin-bottom: 15px;
      margin-right: 16px;
    }
  }
}

// table up
@include media-breakpoint-up(lg) {
  .ni__dropdown__menu {
    max-width: 577px;
  }

  .ni__dropdown__menu--content {
    margin: 0;
  }

  .ni__category__container {
    .ni__category {
      margin-bottom: 25px;
      margin-right: 20px;
    }
  }
}
