// Perspective Grid Especial fixes
@media (min-width: 768px) and (max-width: 1023px) {
  .ni__perspective-header {
    .ni__perspective-headline {
      margin-bottom: 10px;
    }
  }

  .ni__perspective-post {
    // padding-left: 15px;
    // padding-right: 15px;
    .ni__col-md {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  .ni__perspective-grid .ni__container:after {
    left: 67px;
  }
  .ni__perspective-grid .ni__container:before {
    left: 15px;
  }

  .ni__perspective-post,
  .ni__perspective-banner,
  .ni__perspective-filter,
  .ni__perspective-grid {
    .ni__container {
      max-width: calc(100% - 30px);
    }

    [class*='ni__col-'] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .ni__perspective-grid {
    .ni__col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: 640px) {
  .ni__perspective-hero__image {
    background-position: 80% center;
  }
}

html[lang='en-us'] .ni__perspective-grid__item:hover {
  .ni__perspective-grid--content {
    justify-content: flex-end;
    transform: translateY(255px);
    height: 263px;
  }
}

@include media-breakpoint-up(md) {
  .ni__container-article + .related-articles {
    padding-top: 80px;
  }
}
