// ========================================================================
// Component: HeadlineCTA
// ========================================================================

// settings
$NI__HeadlineCTA-BG-Color: var(--gray-20);
$NI__HeadlineCTA-BG-Color2: var(--pale-green);
$NI__HeadlineCTA-Color: var(--forrest-green);
$NI__HeadlineCTA-Font-Family: map-get($fonts, 'serif');

.ni__headline-cta {
  background: white;
  margin: 0 auto;

  &.ni__headline-cta-gray {
    background: $NI__HeadlineCTA-BG-Color;
  }

  &.ni__headline-cta-green {
    background: $NI__HeadlineCTA-BG-Color2;
  }
}

.ni__headline-cta .ni__headline-cta__container {
  margin: 0 auto;
  padding-top: 76px;
  padding-bottom: 76px;

  @include breakpoint('large') {
    padding-top: 176px;
    padding-bottom: 176px;
  }
}

.ni__headline-cta > .ni__headline-cta__container > .ni__row > .ni__col-xxxs-12 > .ni__headline-cta__headline {
  color: $NI__HeadlineCTA-Color;
  letter-spacing: normal;
  margin: 0 auto 40px;
  white-space: pre-line;
}

.ni__headline-cta .ni__headline-cta-richtext__container {
  font-family: FoundersGrotesk;
  font-size: 18px;
  line-height: 1.33;
  color: $NI__HeadlineCTA-Color;
}

.ni__headline-cta .ni__headline-cta-button__container {
  @include display-flex(column nowrap, flex-start, flex-start);

  @media (min-width: 560px) {
    @include display-flex(row nowrap, flex-start, flex-start);
  }

  & > button + button,
  & > div + div {
    margin: 40px 0 0;

    @media (min-width: 560px) {
      margin: 0 0 0 50px;
    }
  }

  // prettier-ignore
  @include breakpoint('large') {
    flex-flow: row nowrap;

    & > button + button,
    & > div + div { margin: 0 0 0 72px; }

    & > button:nth-child(2),
    & > div:nth-child(2) {
      margin: 0 0 0 72px;
    }
  }
}

.headline-copy .ni__eyebrow_headline_CTA .ni__eyebrow.ni__container.low-padding{
  padding-top: 10px;
}

.headline-copy .ni__eyebrow_headline_CTA .ni__eyebrow.ni__container.no-eyebrow-line{
  @media (max-width: 1023px){
    font-size: 18px !important;
    text-align: left;
    padding-left: 15px;
  }
  span:before{
    @media (max-width: 1023px){
      display: none;
    }
  }
}

.headline-copy .ni__headline-cta.value-card{
  .ni__headline-cta__container.ni__container{
    padding: 0;
    max-width: 100%;
  }

  .ni__col-xxxs-12{
    display: flex;
    height: 300px;

    @media (max-width: 1023px){
      flex-direction: column;
      height: fit-content;
    }

    & > div:nth-child(1).ni__background--forrest-green{
      span{
        color: white;
      }
      p{
        color: white;
      }
    }

    & > div:nth-child(1){
      padding-left: 40px;

      @media (max-width: 1023px){
        width: 100%;
        padding-bottom: 5%;
        padding-left: 5%;
      }

      span{
        padding-top: 56px;
        position: absolute;
        font-size: 14px;
        font-family: 'SpaceMono';
        color: $NI__HeadlineCTA-Color;

        @media (max-width: 1023px){
          padding-top: 5%;
        }
      }
      p{
        font-family: 'FinancierDisplay';
        color: $NI__HeadlineCTA-Color;
        padding-top: 80px;

        @media (max-width: 1023px){
          padding-top: 10%;
        }

        @media (max-width: 560px) {
          padding-top: 15%;
        }
      }
    }
    & > div:nth-child(2){
      padding: 56px 101px 84px 60px;

      @media (max-width: 1023px){
        width: 100%;
        padding: 5%;
      }
    }
  }
}
