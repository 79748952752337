// ========================================================================
// Component: HeroCta
// ========================================================================
.ni__hero-lock-up-transparent{
  margin-bottom: 64px;
  
  @include media-breakpoint-up(sm) { 
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(md) { 
    background-color: var(--off-white);
	margin-bottom: 176px;
  }
}

// Component => Bootstrap Container
// ========================================================================
.ni__hero-lock-up-transparent .ni__container {
  position: relative;
}

// Title => Text Element
// ========================================================================
.ni__hero-lock-up-transparent h1 {
  color: var(--forrest-green);
  margin: 20px 0 0 0;

  @include media-breakpoint-up(sm) { margin: 40px 50px 0 50px; }
  @include media-breakpoint-up(md) { margin: 50px 0 0 0; }
  @include media-breakpoint-up(lg) { margin: 100px 0 0 0; }
}

// Text => Text Element
// ========================================================================
.ni__hero-lock-up-transparent p {
  color: var(--forrest-green);
  margin: 23px 0 0 0;
  
  @include media-breakpoint-up(sm) { margin: 35px 50px 0 50px; }
  @include media-breakpoint-up(md) { margin: 20px 0 0 0; }
}

// Text => CTA Buttons
// ========================================================================
.ni__hero-lock-up-transparent .ni__button--secondary {
  margin: 35px 0 0 0;
  
  @include media-breakpoint-up(sm) { margin: 35px 50px 0 50px; }
  @include media-breakpoint-up(md) { margin: 40px 0 0 0; }
}

// Image => Container
// ========================================================================

.ni__hero-lock-up-transparent .ni__hero-lock-up-transparent--image-container-before-md {
  background-color: var(--off-white);
  padding-top: 95%;
  margin: 30px 0 0 -20px;
  position: relative;
  width: 100vw;
  min-width: 375px;
  min-height: 330px;
  
  @include media-breakpoint-up(sm) {
    padding-top: 87.7%;
    margin: 30px 0 0 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.ni__hero-lock-up-transparent .ni__hero-lock-up-transparent--image-container-after-md {
  display: none;
  position: relative;
  width: 100%;
  padding-top: 87.7%;
  margin: 0;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

// Image => Image
// ========================================================================
.ni__hero-lock-up-transparent--image-container-after-md .parbase div,
.ni__hero-lock-up-transparent--image-container-before-md .parbase div {
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.ni__hero-lock-up-transparent--image-container-after-md .parbase div div,
.ni__hero-lock-up-transparent--image-container-before-md .parbase div div {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}