// ========================================================================
// Component: Hero — Dual Column Account
// @author William Pansky <william.pansky@credera.com>
// ========================================================================

// settings
$NI__HeroDualColumnAccount: '.ni__hero-dual-column-account';
$NI__HeroDualColumnAccount--BG-Color: var(--off-white);
$NI__HeroDualColumnAccount--Color: var(--forrest-green);
$NI__HeroDualColumnAccount--Color-Hover: var(--ni-green);
$NI__HeroDualColumnAccount--LinkItem-Icon-Size: 24px;

// Component
// ========================================================================
// prettier-ignore
#{$NI__HeroDualColumnAccount} {
  color: $NI__HeroDualColumnAccount--Color;

  h2, h3, p, a, span {
    color: $NI__HeroDualColumnAccount--Color;
  }

  &-eyebrow {
    margin-bottom: 32px;
    @include breakpoint('small') { margin-bottom: 40px; }
  }

  &-headline {
    margin-bottom: 0;
  }
  
  &-subheadline {
    word-break: break-word;
    // margin-top ----------------------------------
                                     margin-top: 8px;
    @include breakpoint('small')   { margin-top: 16px; }
    @include breakpoint('desktop') { margin-top: 12px; }
  }

  &-bodycopy {
    margin-top: 8px;
  }
}

// Component => Accordion
// ========================================================================
// prettier-ignore
#{$NI__HeroDualColumnAccount} .ni__accordion {
  border-bottom: 1px solid #cddcc8;
  @include breakpoint('desktop') { border-color: transparent; }

  // margin-bottom ---------------------------------
                                   margin-top: 24px;
  @include breakpoint('small')   { margin-top: 40px; }
  @include breakpoint('desktop') { margin-top: 0;    }

  .uk-accordion-title {
    @include breakpoint('desktop') { display: none; }
    padding: 16px 0;
    
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill-rule='nonzero' d='M0 0H24V24H0z' transform='translate(-628 -240) translate(68 224) translate(560 16)'/%3E%3Cpath fill='%2303B585' d='M13 11h7c.552 0 1 .448 1 1s-.448 1-1 1h-7v7c0 .552-.448 1-1 1s-1-.448-1-1v-7H4c-.552 0-1-.448-1-1s.448-1 1-1h7V4c0-.552.448-1 1-1s1 .448 1 1v7z' transform='translate(-628 -240) translate(68 224) translate(560 16)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      height: 24px;
      width: 24px;
    }

    span {
      font-weight: 400;
    }
  }

  .uk-open {
    > .uk-accordion-title {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill-rule='nonzero' d='M0 0H24V24H0z' transform='translate(-628 -240) translate(68 224) translate(560 16)'/%3E%3Cpath fill='%2303B585' d='M5 13c-.552 0-1-.448-1-1s.448-1 1-1h14c.552 0 1 .448 1 1s-.448 1-1 1H5z' transform='translate(-628 -240) translate(68 224) translate(560 16)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
  }

  li {
    border: none;
  }

  .uk-accordion-content {
    @include breakpoint('desktop') {
      display: block !important;
      padding: 0;
    }
  }
}

// Component => Accordion => List & List Items
// ========================================================================
// prettier-ignore
#{$NI__HeroDualColumnAccount} .uk-accordion-content .ni__hero-dual-column-account-link {
  &-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &-list li + li {
    margin-top: 24px;
    @include breakpoint('small') { margin-top: 32px; }
  }

  &-item a {
    color: $NI__HeroDualColumnAccount--Color;
    @include display-flex(row nowrap, center, flex-start);
  }

  &-item a:hover span,
  &-item a:focus span {
    color: $NI__HeroDualColumnAccount--Color-Hover;
  }

  &-item-icon {
    width: $NI__HeroDualColumnAccount--LinkItem-Icon-Size;
    height: $NI__HeroDualColumnAccount--LinkItem-Icon-Size;
    margin-right: 12px;
  }

  &-item-text {
    white-space: nowrap;
  }
}
