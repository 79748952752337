// ========================================================================
// Component: LeadQuote
// ========================================================================

// settings
$NI__leadQuote-bg__color: #ccdcc8;
$NI__leadQuote-color: var(--forrest-green);
$NI__leadQuote-font: map-get($fonts, 'serif');

$NI__leadQuote-path__w: 431px;
$NI__leadQuote-path__h: 1304px;

.ni__lead-quote__path {
  display: none;
  position: absolute;
  right: calc((#{$NI__leadQuote-path__w} + 90px) * -1);
  top: calc((#{$NI__leadQuote-path__h} - 53px) * -1);
  color: $NI__leadQuote-color;
}

.ni__lead-quote .ni__container {
  @include breakpoint('medium') {
    max-width: none;
  }

  @include breakpoint('grid') {
    max-width: map-get($breakpoints, 'grid');
  }
}

.ni__lead-quote__wrapper {
  width: 100%;
  // margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 64px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
}

.ni__lead-quote {
  background-color: $NI__leadQuote-bg__color;

  @include breakpoint('medium') {
    padding-bottom: 32px;
  }

  .ni__button {
    margin-top: 20px;
  }
}

.ni__lead-quote__h2 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $NI__leadQuote-color;
  margin: 0;
  padding: 0 0 16px 0;
  max-width: 300px;
  white-space: pre-line;
  @include type-class('.ni__h2');
}

.ni__lead-quote__headline {
  color: $NI__leadQuote-color;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
  padding: 16px 0 20px 0;
  max-width: 700px;
  @include type-class('.ni__body-text--2');
}

// mobile up
@include breakpoint('small') {
  // .ni__lead-quote__wrapper,
  // .ni__lead-quote__content {
  //   max-width: map-get($breakpoints, 'medium');
  //   padding-left: 95px;
  // }

  .ni__lead-quote__headline {
    max-width: 540px;
  }
}

@include breakpoint('medium') {
  .ni__lead-quote__content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// mobile up
@include breakpoint('desktop small') {
  .ni__lead-quote {
    padding: 72px 0 176px 0;
  }
}

// mobile up{
@include breakpoint('max content') {
  .ni__lead-quote {
    margin: 0 auto;
  }

  .ni__lead-quote__path {
    display: inline-block;
  }
}
