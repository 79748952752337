// ========================================================================
// Component: HeadlineImageLeadHeroCopy
// ========================================================================
.ni__big_hero_bgImg {
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 744px;
  pointer-events: none;

  @include media-breakpoint-up(tablet) {
    height: 901px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    height: 1078px;
    max-width: 1440px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMzIiIGhlaWdodD0iMTQ4MiIgdmlld0JveD0iMCAwIDMzMiAxNDgyIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMDQ0MTIzIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiIGQ9Ik0yIDJ2MTE0OS4wMTdDMiAxMzMyLjcwOSAxNDguODUgMTQ4MCAzMzAgMTQ4MCIvPgo8L3N2Zz4K');
    background-repeat: no-repeat;
    background-position: top left;
    position: absolute;
    display: none;

    @include media-breakpoint-up(desktop-sm) {
      display: block;
      background-size: 376px 1382px;
      background-size: cover;
      top: 30%;
      left: 3%;
      width: 320px;
      height: 1500px;
    }

    @include media-breakpoint-up(lg) {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0ODAiIGhlaWdodD0iMTY3NSIgdmlld0JveD0iMCAwIDQ4MCAxNjc1Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMDQ0MTIzIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiIGQ9Ik0yIDEuNXYxMjQzLjUyMkMyIDE0ODEuMzg4IDI0Mi42NjUgMTY3MyA0NzguNDkgMTY3MyIvPgo8L3N2Zz4K');
      background-size: 476px 1700px;
      top: 28%;
      left: 0.5%;
      width: 476px;
      height: 4000px;
    }

    @include breakpoint('desktop large') {
      left: 50px;
    }
  }
}

.ni__big_hero_copy {
  background-position: 80% center;
  background-repeat: no-repeat;
  background-size: cover;
  // padding-top: 53.067vw;
  // padding-bottom: 61.6vw;
  position: relative;
  height: 744px;
  margin: 0 auto;
  max-width: 440px;

  @include display-flex(column nowrap, center, center);

  @include media-breakpoint-up(sm) {
    max-width: 640px;
    // padding-top: 118.4px;
    // padding-bottom: 142.933px;
  }

  @include media-breakpoint-up(tablet) {
    height: 901px;
    // padding-top: 118.4px;
    // padding-bottom: 142.933px;
  }

  @include media-breakpoint-up(md) {
    // padding-top: 133px;
    // padding-bottom: 161px;
    max-width: 845px;
    margin: 0 auto;
  }

  @include media-breakpoint-up(lg) {
    height: 1078px;
    // padding-top: 256px;
    // padding-bottom: 324px;
    max-width: 1440px;
  }

  // STUPID AEM ADDED DIVS
  & > .ni__container > .ni__row > div,
  & > .ni__container > .ni__row > div span {
    display: block;
    width: 100%;
  }
}

.ni__big_hero_copy .ni__container {
  position: relative;
  max-width: none;

  @include media-breakpoint-up(tablet) {
    max-width: 870px;
  }

  @include media-breakpoint-up(desktop-sm) {
    max-width: 1015px;
  }
}

.ni__big_hero_copy .ni__big_hero_copy-title {
  color: var(--forrest-green);
  // padding-bottom: 21.333vw;
  padding-bottom: 60px;
  @include type-class('.ni__eyebrow--4');

  @include media-breakpoint-up(tablet) {
    padding-bottom: 29.866px;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 33.75px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 72px;
  }
}

.ni__big_hero_copy .ni__big_hero_copy-headline {
  color: var(--forrest-green);
  // padding-bottom: 10.667vw;
  padding-bottom: 58px;
  @include type-class('.ni__hero--2');

  @include media-breakpoint-up(tablet) {
    padding-bottom: 21.333px;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 24px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 72px;
  }
}

.ni__big_hero_copy .ni__big_hero_copy-headline-line {
  display: block;
  white-space: nowrap;

  // prettier-ignore
  &.line2 {
    text-align: right;

    // @include breakpoint('desktop small') {
    //   padding-left: 20%;
    // }

    // @include media-breakpoint-up(lg) {
    //   padding-left: 465px;
    // }
  }
}

.ni__big_hero_copy .ni__button {
  left: 50%;
  transform: translateX(-50%);
}

.ni__headline_lead_hero_columns {
  margin: 64px auto;

  @include media-breakpoint-up(sm) {
    // padding-top: 74px;
    // padding-bottom: 74px;
  }

  @include media-breakpoint-up(tablet) {
    margin: 119px auto 146px;
    // padding-top: 84px;
    // padding-bottom: 252px;
  }

  @include media-breakpoint-up(lg) {
    margin: 176px auto 295px;
    // padding-top: 176px;
    // padding-bottom: 400px;
  }

  .ni__container {
    position: relative;
  }

  .ni__headline_lead_hero_columns-title {
    color: var(--forrest-green);
    @include type-class('.ni__h2');

    span {
      display: block;
    }
  }

  .ni__col-xxxs-12.ni__col-md-4:nth-child(1) > p {
    padding-top: 10px;
  }

  .ni__col-xxxs-12.ni__col-md-4:nth-child(2) > p {
    padding-top: 30px;
  }

  p {
    color: var(--forrest-green);
    @include type-class('.ni__body-text--2');

    @include media-breakpoint-up(tablet) {
      padding-top: 24px !important;
    }
  }

  .ni__headline_lead_hero_columns-image {
    height: 120vw;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-top: 64px;
    overflow: hidden;

    @include media-breakpoint-up(tablet) {
      position: absolute;
      height: 427px;
      width: 293px;
      top: 72px;
      // left: 465px;
      right: 0;
      padding-top: 0;
    }
    @include media-breakpoint-up(md) {
      // width: 216px;
      top: 72px;
      // left: 547px;
    }
    @include media-breakpoint-up(lg) {
      width: 336px;
      height: 465px;
      top: 121px;
      // left: 870px;
    }
    img {
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
  }

  .ni__description {
    p {
      padding-top: 0 !important;
      font-size: 13px !important;
      line-height: 15px !important;
    }

    @include media-breakpoint-up(md) {
      width: 293px;
      position: absolute;
      right: 0;
      top: 475px;
    }

    @include media-breakpoint-up(lg) {
      width: 336px;
      top: 590px;
    }
  }
}

.ni__headline_image_lead_hero_copy a {
  text-decoration: none;
}

.ni__headline_image_lead_hero_copy .ni__big_hero_copy--mobile {
  // text-decoration: none;
  // display: block;
  // height: 744px;
  margin: 0 auto;

  @include media-breakpoint-up(tablet) {
    height: 901px;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.ni__headline_image_lead_hero_copy .ni__big_hero_copy--desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.ni__description {
  color: var(--forrest-green);
  font-size: 13px;
  line-height: 15px;
  padding: 20px 0;
}

// clickable mobile overlay
.ni__headline_image_lead_hero_copy div.ni__big_hero_copy--mobile > a.ni__big_hero_copy--mobile {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 744px;
  z-index: 1;
}
