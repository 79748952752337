@mixin ni__qoutes__headline {
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    display: block;
    background-color: var(--ni-green);
  }

  &:before {
    top: -26px;
    left: -26px;
    border-top-left-radius: 300px;
  }

  &:after {
    right: -23px;
    bottom: -23px;
    border-bottom-right-radius: 300px;
  }

  @include breakpoint('desktop small') {
    &:after,
    &:before {
      width: 53px;
      height: 53px;
    }

    &:before {
      top: -62px;
      left: -55px;
    }

    &:after {
      right: -53px;
      bottom: -53px;
    }
  }
}
