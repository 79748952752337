$NI__Carousel-Background-Color: #034123;
$NI__Carousel-Color: var(--gray-20);
$NI__Carousel-Min-Height: 630px;
$NI__Carousel-Content-Padding: 176px 0;
$NI__Carousel-Content-Max-Width: 1015px;

.ni__carousel {
  color: $NI__Carousel-Color;
  font-size: 18px;
  margin: 0 0 64px;
  overflow: hidden;
  width: 100vw;

  @if $UseVerticalPaddingOnHomepageComponents == true {
    padding: 64px 0 0;

    @include breakpoint('tablet') {
      margin: 0 0 120px;
      padding: 93px 0 0;
    }

    @include breakpoint('large') {
      margin: 0 0 176px;
      padding: 72px 0 0;
    }
  }
}

.ni__carousel .ni__carousel-item-container {
  @media (max-width: map-get($breakpoints, 'tablet') - 1px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.ni__carousel .ni__container {
  // @media (max-width: map-get($breakpoints, 'tablet') - 1px) {
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  @include breakpoint('medium') {
    max-width: none;
  }

  @include breakpoint('grid') {
    max-width: map-get($breakpoints, 'grid');
  }
}

.ni__carousel .ni__carousel-container {
  background: $NI__Carousel-Background-Color;
  height: 100%;
  margin: 0 auto;
  min-height: $NI__Carousel-Min-Height;

  &:before,
  &:after {
    bottom: 0;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 15vw;
    z-index: 2;
  }

  &:before {
    background: linear-gradient(to right, $NI__Carousel-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    right: auto;
  }

  &:after {
    background: linear-gradient(to left, $NI__Carousel-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: auto;
    right: 0;
  }

  @include breakpoint('desktop small') {
    padding: $NI__Carousel-Content-Padding;
  }

  @include breakpoint('desktop large') {
    &:before,
    &:after {
      // content: '';
    }
  }
}

.ni__carousel .ni__carousel-items {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 0;
  -webkit-touch-callout: none;

  // &.no-transform {
  //   transform: translate3d(0px, 0px, 0px) !important;
  // }
}

.ni__carousel:not(.ni__carousel--light):not(.ni__carousel--large-image):not(.ni__carousel--featured-products)
  .ni__carousel-item-container {
  box-sizing: content-box;
  margin: 0 auto;

  @include breakpoint('medium') {
    margin: 0 0 0 auto;
    max-width: 89%;
  }
}

.ni__carousel .ni__carousel-grid {
  width: 100%;
  @include display-flex(column-reverse nowrap, center, space-between);

  @include breakpoint('desktop small') {
    flex-flow: row nowrap;
    margin-top: 10px;
  }

  @include media-breakpoint-up('lg') {
    margin-top: 0;
  }
}

.ni__carousel .ni__carousel-grid-left {
  box-sizing: border-box;
  margin: 0;
  padding: 0 20px 0;
  width: 100%;

  @include breakpoint('desktop small') {
    max-width: 347px;
    padding: 0;
  }
}

.ni__carousel .ni__carousel-grid-right {
  margin: 0;
  width: 100%;

  @include breakpoint('desktop small') {
    max-width: 540px;
  }
}

.ni__carousel .ni__carousel-eyebrow {
  color: inherit;
  font-family: map-get($fonts, 'mono');
  letter-spacing: normal;
  margin: 0 0 48px;
  opacity: 0;
  text-transform: uppercase;
  @include type-class('.ni__eyebrow--3');
}

.ni__carousel .ni__carousel-content {
  font-family: map-get($fonts, 'sans');
  text-align: left;

  &-title {
    color: inherit;
    font-family: map-get($fonts, 'sans');
    font-weight: 400;
    letter-spacing: normal;
    margin: 0 0 32px;
    opacity: 0;
    white-space: pre-line;
    @include type-class('.ni__subhead--1');
  }

  &-text {
    color: inherit;
    font-family: map-get($fonts, 'sans');
    letter-spacing: normal;
    margin: 0 0 37px;
    opacity: 0;
    @include type-class('.ni__body-text--4');
  }

  & > div, // aem fix
  & > .ni__button {
    opacity: 0;
  }
}

.ni__carousel .ni__carousel-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 395px;
  margin: 0 0 32px;
  opacity: 0;
  width: 100%;

  @include breakpoint('desktop small') {
    height: 405px;
    margin: 0;
  }
}

// prettier-ignore
.ni__carousel {
  &:not(.ni__carousel--light):not(.ni__carousel--large-image) .ni__carousel-image-container {
    @include breakpoint('small') { padding-top: 64px; }
    @include breakpoint('medium') { padding-top: 0; }
  }
}

.ni__carousel .ni__carousel-bullets {
  height: 12px;
  margin: 0 auto 60px;

  @include breakpoint('desktop small') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }
}

.ni__carousel .ni__carousel-bullets .glide__bullet {
  appearance: none;
  background: var(--gray-20);
  border-radius: 50%;
  border: 0;
  height: 8px;
  margin: 0;
  padding: 0;
  width: 8px;

  &.glide__bullet--active {
    background: var(--ni-green);
    height: 12px;
    width: 12px;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.ni__carousel:not(.ni__carousel--light):not(.ni__carousel--large-image):not(.ni__carousel--featured-products):not(.ni__carousel.ni__carousel--lead)
  .ni__carousel-controls-item-container {
  display: none;
  margin-top: 40px;

  @include breakpoint('medium') {
    margin-top: 40px;
  }

  @include breakpoint('desktop small') {
    display: block;
  }

  // special buttons carousel
  .ni__button--controller.ni__button {
    border-radius: 0 10px 15px 10px;
  }

  // prettier-ignore
  // .ni__button--controller.ni__button:focus {
  //   background: white;
  //   & svg g { stroke: var(--forrest-green); }
  // }

  .ni__button--controller.ni__button--controller-left {
    border-radius: 15px 10px 0 10px;
  }
}

.ni__carousel .ni__carousel-controls-container {
  display: none;
  margin: 0 auto;
  width: 100%;

  @include breakpoint('desktop small') {
    @include display-flex(row nowrap, center, flex-end);
  }
}

.ni__carousel .ni__carousel-counter {
  font-family: map-get($fonts, 'serif');
  margin: 0 40px 0 0;
  @include type-class('.ni__small-detail--3');

  .ni__carousel-counter-current {
    color: var(--gray-20);
  }

  .ni__carousel-counter-divider {
    color: #cbdcc8;
    margin: 0 4px;
    opacity: 0.6;
  }

  .ni__carousel-counter-total {
    color: #cbdcc8;
    opacity: 0.6;
  }
}

.ni__carousel .ni__carousel-controls {
  @include display-flex(row nowrap, center, flex-end);

  // & > button {
  //   border-color: #cbdcc8;
  //   color: white;
  // }

  & > button:nth-child(2) {
    margin-left: 10px;
  }
}

.ni__carousel:not(.ni__carousel--light):not(.ni__carousel--featured-products):not(.ni__carousel--large-image):not(.ni__blade-carousel):not(.ni__carousel--lead)
  .ni__carousel-controls {
  & > button svg g {
    stroke: white;
  }
}

// animations
.ni__carousel .glide__slide--active {
  .ni__carousel-eyebrow {
    @include animation-fade-in(left, small);
  }

  .ni__carousel-content-title {
    animation-delay: 100ms;
    @include animation-fade-in(left, small);
  }

  .ni__carousel-content-text,
  .ni__carousel-content > div {
    animation-delay: 200ms;
    @include animation-fade-in(left, small);
  }

  .ni__carousel-content .ni__button {
    animation-delay: 300ms;
    @include animation-fade-in(left, small);
  }

  .ni__carousel-image {
    @include animation-fade-in(right, small);
  }
}

.ni__carousel {
  @include breakpoint('max content') {
    max-width: map-get($breakpoints, 'max content');
    margin: 0 auto;
  }
}

.ni__carousel.ni__carousel--light {
  @include media-breakpoint-down(sm) {
    .ni__carousel-container > .ni__container {
      display: none;
    }
  }
}

.glide.non-interactive-mode {
  .glide__slide--clone,
  .ni__carousel-bullets,
  .ni__carousel-controls-item-container,
  .ni__carousel-controls {
    display: none !important;
  }

  .glide__slides {
    transform: translate3d(0px, 0px, 0px) !important;
  }
}

/* Show more / show less functionality */
.glide .ni__carousel-showmore-controls {
  display: none;
}

.glide.ni__carousel--showmore-active {
  &:has(.glide__slide:nth-child(3)) .ni__carousel-showmore-controls {
    display: flex;
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 96px;
    font-size: 18px;
    color: var(--non-interactive-text-color);

    @include breakpoint('small') {
      margin-bottom: 124px;
    }

  }

  .glide__slides {
    display: grid;
    row-gap: 72px;

    @include breakpoint('small') {
      grid-template-columns: 1fr 1fr;
      column-gap: 72px;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .ni__carousel-bullets,
  .ni__carousel-controls-item-container,
  .ni__carousel-controls {
    display: none;
  }

  .ni__carousel-showmore-controls > button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: FoundersGrotesk;
    font-size: 18px;
    line-height: 1.33;

    &:after {
      margin-left: 8px;
      color: var(--interactive-text-color);
      font-family: 'Courier New';
      font-size: 32px;
      line-height: 20px;
      vertical-align: middle;
    }
  }

  .ni__carousel-showmore-controls .ni__carousel-showmore-more:after {
    content: '+';
  }

  .ni__carousel-showmore-controls .ni__carousel-showmore-less:after {
    content: '-';
  }

  &:not(.ni__carousel--showing-more) .ni__carousel-showmore-controls .ni__carousel-showmore-less {
    display: none;
  }

  &.ni__carousel--showing-more .ni__carousel-showmore-controls .ni__carousel-showmore-more {
    display: none;
  }

  /* Hide all but the first two elements outside of "showing more" mode. */
  &:not(.ni__carousel--showing-more) .glide__slide:not(:nth-child(1), :nth-child(2)) {
    display: none;
  }
}
