// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
// prettier-ignore
@if $enable-grid-classes {
  // Single container class with breakpoint max-widths
  .#{$ni-grid-prefix}container {
    @include make-container();
    @include make-container-max-widths();
  }

  // 100% wide container at all breakpoints
  .#{$ni-grid-prefix}container-fluid {
    @include make-container();
  }

  // Responsive containers that are 100% wide until a breakpoint
  @each $breakpoint, $container-max-width in $container-max-widths {
    .#{$ni-grid-prefix}container-#{$breakpoint} {
      @extend .#{$ni-grid-prefix}container-fluid;
    }

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      %responsive-container-#{$breakpoint} {
        max-width: $container-max-width;
      }

      @each $name, $width in $grid-breakpoints {
        @if ($container-max-width > $width or $breakpoint == $name) {
          .#{$ni-grid-prefix}container#{breakpoint-infix($name, $grid-breakpoints)} {
            @extend %responsive-container-#{$breakpoint};
          }
        }
      }
    }
  }
}

// Row
//
// Rows contain your columns.
// prettier-ignore
@if $enable-grid-classes {
  .#{$ni-grid-prefix}row {
    @include make-row();
  }

  // Remove the negative margin from default .#{$ni-grid-prefix}ow, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .#{$ni-grid-prefix}no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .#{$ni-grid-prefix}col,
    > [class*='.#{$ni-grid-prefix}col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  [class*='.#{$ni-grid-prefix}col-md-'] {
    box-sizing: border-box;
  }
}

// Columns
//
// Common styles for small and large grid columns
// prettier-ignore
@if $enable-grid-classes {
  @include make-grid-columns();
}
