// ========================================================================
// Component: PerspectivePhotoCaption
// ========================================================================

.ni__perspective-photo-caption {
  margin-bottom: 35px;
  overflow: visible;

  img {
    margin-bottom: 0px !important;
  }

  .scroll-caption {
    margin: 0;
  }
}

.ni__perspective-photo-caption--image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin: 0 !important;
  max-width: 1140px !important;
  position: relative;
  width: 100%;

  & + p {
    padding-top: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--ni-green);
    max-width: 310px;
    @include type-class('.ni__small-detail--4');

    > b,
    span {
      font-weight: 500;
    }
  }
}

// .dynamic-n100-badge {
//   .ni__perspective-article__next100 {
//     right: 15px;

//     @media (min-width: 768px) {
//       right: 30px;
//     }
//   }
// }

// table up
@include media-breakpoint-up(md) {
  .ni__perspective-photo-caption--image {
    max-width: 1003px;
    // height: 542px;

    // &.is-state--header {
    //   height: 542px;
    // }
  }

  .ni__perspective-photo-caption {
    margin-bottom: 62px;

    p {
      max-width: 100%;
    }
  }
}

// table up
@include media-breakpoint-up(lg) {
  .ni__perspective-photo-caption--image {
    max-width: 1110px;
    // height: 600px;
  }
}
