$NI__CarouselLight-Background-Color: white;
$NI__CarouselLight-Color: var(--forrest-green);
$NI__CarouselLight-Content-Max-Width: inherit;

.ni__carousel.ni__carousel--light {
  background: $NI__CarouselLight-Background-Color;
  color: $NI__CarouselLight-Color;
  margin: 0 auto;
  padding: 0;

  &:before {
    background: linear-gradient(to right, $NI__CarouselLight-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    right: auto;
  }

  &:after {
    background: linear-gradient(to left, $NI__CarouselLight-Background-Color 0%, rgba(0, 0, 0, 0) 100%);
    left: auto;
    right: 0;
  }

  // prettier-ignore
  @if $UseVerticalPaddingOnHomepageComponents == true {
    @include breakpoint('desktop') { margin: 120px auto; }
    @include breakpoint('large')   { margin: 176px auto; }
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-container {
  border-radius: 0;
  background: $NI__CarouselLight-Background-Color;
  min-height: 0;

  @include breakpoint('desktop small') {
    margin: 0;
    padding: 76px 0 0;
  }
}

.ni__carousel.ni__carousel--light .glide__track,
.ni__carousel.ni__carousel--light .ni__carousel-items {
  overflow: visible;
}

.ni__carousel.ni__carousel--light .ni__carousel-grid {
  @include breakpoint('desktop small') {
    flex-flow: row-reverse nowrap;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-grid-left {
  @include breakpoint('medium') {
    max-width: 445px;
    margin-right: 6%;
  }

  @include breakpoint('large') {
    margin-right: 0;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-grid-right {
  padding: 50px 0 0;

  @include breakpoint('desktop small') {
    max-width: 40%;
    padding: 0;
  }

  @include breakpoint('large') {
    max-width: 475px;
    padding: 0;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-image-container {
  margin: 0 auto;
  max-width: 335px;
  position: relative;

  @include breakpoint('desktop small') {
    max-width: 398px;
    margin: 0 0 0 auto;
  }

  @include breakpoint('large') {
    max-width: none;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-content-text {
  position: relative;
  @include type-class('.ni__body-text--3');
}

.ni__carousel.ni__carousel--light .ni__carousel-image,
.ni__carousel.ni__carousel--light .parbase div > div {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 150px;
  height: 320px;
  margin: 0 0 30px auto;
  position: relative;
  width: 222px;
  z-index: 3;

  @include breakpoint('desktop small') {
    height: 389px;
    width: 260px;
  }

  @include breakpoint('large') {
    height: 458px;
    margin: 0 0 40px auto;
    width: 315px;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-controls-item-container {
  margin-top: 0;
}

.ni__carousel.ni__carousel--light .ni__carousel-controls-container {
  margin: 0;
}

.ni__carousel.ni__carousel--light .ni__carousel-counter {
  font-family: map-get($fonts, 'serif');
  font-size: 0.889em;
  line-height: 1;
  margin: 0 40px 0 0;

  .ni__carousel-counter-current {
    color: var(--ni-green);
  }

  .ni__carousel-counter-divider {
    color: var(--forrest-green);
    margin: 0 4px;
    opacity: 0.4;
  }

  .ni__carousel-counter-total {
    color: var(--forrest-green);
    opacity: 0.4;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-controls {
  & > button {
    @include button-style('.ni__button--controller');
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-content .ni__carousel-content-title {
  font-family: map-get($fonts, 'serif');
  margin: 0 0 32px;
  @include type-class('.ni__h6');

  @include breakpoint('desktop small') {
    margin: 0 0 40px;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-shape1 {
  background: var(--ni-green);
  border-top-left-radius: 300px;
  height: 113px;
  width: 113px;
  @include position-absolute(0, auto, auto, auto);

  @include breakpoint('desktop small') {
    height: 138px;
    top: -66px;
    width: 138px;
  }

  @include breakpoint('large') {
    height: 160px;
    top: -75px;
    width: 160px;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-shape-container {
  opacity: 0;
  position: absolute;
  top: -50px;

  @include breakpoint('desktop small') {
    top: 0;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-shape2,
.ni__carousel.ni__carousel--light .ni__carousel-shape3 {
  height: 113px;
  width: 113px;
  @include position-absolute(113px, auto, auto, auto);

  @include breakpoint('desktop small') {
    height: 138px;
    top: 72px;
    width: 138px;
  }

  @include breakpoint('large') {
    height: 160px;
    top: 85px;
    width: 160px;
  }
}

.ni__carousel.ni__carousel--light .ni__carousel-shape2 {
  background: var(--forrest-green);
  z-index: 0;
}

.ni__carousel.ni__carousel--light .ni__carousel-shape3 {
  background: var(--power-green);
  border-bottom-left-radius: 300px;
  z-index: 1;
}

.ni__carousel.ni__carousel--light .ni__carousel-bullets .glide__bullet.glide__bullet--active {
  background: var(--power-green);
}

.ni__carousel.ni__carousel--light .ni__carousel-eyebrow {
  &.ni__carousel-eyebrow--mobile {
    display: block;
    margin: 0 auto 103px;
    box-sizing: border-box;
    padding: 0 20px 0;
    width: 100%;

    @include breakpoint('desktop small') {
      display: none;
    }
  }

  &.ni__carousel-eyebrow--desktop {
    display: none;

    @include breakpoint('desktop small') {
      display: block;
      margin: 0 0 64px;
    }
  }
}

// animations
.ni__carousel.ni__carousel--light .glide__slide--active {
  .ni__carousel-shape-container {
    @include animation-fade-in(left, small);
  }
}
