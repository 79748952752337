$NI__CarouselFP-Background-Color: none;
$NI__CarouselFP-Color: var(--forrest-green);
$NI__CarouselFP-Content-Max-Width: inherit;

.ni__carousel.ni__carousel--featured-products {
  color: $NI__CarouselFP-Color;
  padding: 0;
  margin: 64px 0;
  min-height: 0;

  @include breakpoint('xsmall') {
    margin: 120px auto;
  }

  @include breakpoint('medium') {
    margin: 176px auto;
  }

  .ni__carousel-controls > button {
    @include button-style('.ni__button--controller');
  }

  .ni__carousel-section-title {
    color: inherit;
    margin: 0 0 42px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    @include type-class('.ni__h6');
  }

  .ni__carousel-container {
    background: var(--pale-green);
    min-height: 0;
    padding: 80px 0;

    @include breakpoint('desktop small') {
      background: $NI__CarouselFP-Background-Color;
      padding: 0;
    }
  }

  .ni__carousel-eyebrow {
    margin: 0 0 20px;
  }

  .ni__carousel-content-title {
    margin: 0 0 8px;
    @include type-class('.ni__body-text--2');
  }

  .ni__carousel-content-text {
    @include type-class('.ni__body-text--3');
  }

  .ni__carousel-bullets {
    margin: 0 0 50px;
  }

  .ni__carousel-bullets .glide__bullet.glide__bullet--active {
    background: var(--forrest-green);
  }

  .ni__carousel-grid {
    margin: 0;

    @include breakpoint('desktop small') {
      flex-flow: row-reverse nowrap;
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .ni__carousel-grid-left {
    padding: 0 20px;

    @include breakpoint('medium') {
      padding-left: 35px;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  .ni__carousel-grid-right {
    padding: 0 20px;

    @include breakpoint('medium') {
      padding: 0 15px;
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }
  }

  .ni__carousel-hr {
    border-top: 0;
    border-color: var(--forrest-green);
    display: none;
    margin: 0 0 27px;
    opacity: 0;

    @include breakpoint('desktop small') {
      display: block;
    }
  }

  .ni__carousel-image {
    background-size: contain;
    height: 182px;

    @include breakpoint('desktop small') {
      height: 330px;
    }
  }

  .ni__carousel-controls-container {
    opacity: 0;

    @include breakpoint('desktop small') {
      justify-content: flex-start;
    }
  }

  .ni__carousel-controls-item-container {
    display: none;

    @include breakpoint('desktop small') {
      display: flex;
    }
  }
}

// animations
.ni__carousel.ni__carousel--featured-products .glide__slide--active {
  .ni__carousel-controls-container,
  .ni__carousel-hr {
    @include animation-fade-in(left, small);
  }
}
