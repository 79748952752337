.ni__global__container {
  width: 100%;
  display: block;

  @include breakpoint('grid') {
    max-width: map-get($breakpoints, 'max content');
    margin: 0 auto;
    padding: 0;
  }
}

// AEM specific tweak
.container-fluid.parbase {
  margin: 0 !important;
  padding: 0 !important;
}

.container-fluid.ni-aem-body {
  margin: 0 !important;
  padding: 0 !important;
}

// search temp fix test
// @see https://organic-inc.atlassian.net/browse/NC-2163
.ni-home-search-app {
  padding: 0 15px;
}

// leadspace temp fix
// @see https://organic-inc.atlassian.net/browse/NC-2163
// prettier-ignore
@media (max-width: 767px) {
  .container-fluid.ni-aem-body .shading-container .shading-container.col-xs-12 {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.shading-container {
  .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
    position: relative !important;
  }
}