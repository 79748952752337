.ni-loading {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  bottom: 0;
  display: flex;
  display: inherit;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;

  &.open {
    opacity: 1;
    visibility: visible;
	z-index: 1000; //safari issue: megamenu display incorrect if z-index set on even hidden elements, limiting only when displaying spinner
  }
}

.isLoadingPage {
  height: 100%;
  overflow: hidden;

  .spinner-icon {
    overflow: inherit; /* Override rule from ni.css */

    .spinner {
      margin-top: 0;
    }
  }
}

.isLoadingPage,
.isLoadingProducts {
  @include media-breakpoint-up(md) {
    align-items: flex-start;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .spinner-icon {
    @include media-breakpoint-up(md) {
      height: 100vh;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }
}
