// ========================================================================
// Component: Cold State Hero
// https://app.zeplin.io/project/5e606e6e13239d166416c25f/screen/5f442e8a39be7a7265efd5d5
// ========================================================================
$ColdStateHero-Figure-Breakpoint: 520px;

.ni__cold-state-hero {
  padding-top: 0;
  padding-bottom: 80px;

  @include breakpoint('desktop small') {
    padding-top: 27px;
    min-height: 647px;
  }

  @include breakpoint('desktop large') {
    padding-bottom: 0;
  }
}

// main bootstrap layout
// ========================================================================
.ni__cold-state-hero .ni__global__container > .ni__row {
  @include breakpoint('desktop small') {
    flex-direction: row-reverse;
  }

  // prettier-ignore
  .ni__figure-row {
    margin-bottom: 22px;
    @include breakpoint('desktop small') { margin-bottom: 0; }
  }
}

// image and caption/description
// ========================================================================
.ni__cold-state-hero figure {
  margin: 0;

  @media (min-width: $ColdStateHero-Figure-Breakpoint) {
    display: block;
    margin: 0 auto;
    width: 518px;
  }

  @include breakpoint('desktop small') {
    margin: 0 0 0 auto;
  }
}

.ni__cold-state-hero img {
  width: 100%;
  height: 334px;
  object-fit: cover;
  object-position: 50% 0;

  @media (min-width: $ColdStateHero-Figure-Breakpoint) {
    display: block;
    width: 100%;
    height: 647px;
    object-fit: contain;
    object-position: 50% 50%;
  }
}

// prettier-ignore
.ni__cold-state-hero .ni__image__description {
  padding: 0 20px;
  @media (min-width: $ColdStateHero-Figure-Breakpoint) { padding: 0; }
}

// h1 & text block
// ========================================================================
.ni__cold-state-hero .ni__content-row {
  @include breakpoint('desktop small') {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }
}

// h1 & text block
// ========================================================================
.ni__cold-state-hero {
  .ni__cold-state-hero-header {
    padding: 0 20px;
  }

  // prettier-ignore
  .ni__cold-state-hero-headline {
    margin-bottom: 24px;
    @include breakpoint('desktop small') { margin-bottom: 40px; }
  }

  h1 {
    color: var(--forrest-green);
    letter-spacing: normal;
    margin: 0;
    @include type-class('.ni__h2');
  }

  h1 > span {
    display: block;
    text-align: left;
  }

  p {
    color: var(--forrest-green);
    letter-spacing: normal;
  }
}

// buttons group
// ========================================================================
.ni__cold-state-hero .ni__cold-state-hero-buttons {
  margin-top: 40px;
  flex-wrap: nowrap;

  & > div:nth-child(1) {
    padding-right: 0;
  }

  @include breakpoint('desktop small') {
    margin-left: 0;
    margin-right: 0;

    & > div:nth-child(2) {
      margin-left: 30px;
    }
  }
}
