// ========================================================================
// Component: AdditionalCTA
// ========================================================================

.ni__additional-cta {
  // margin-bottom: 64px;
  margin-top: 64px;

  h3,
  h4 {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin: 0;
  }

  h3 {
    // font-family: $fontFamilySans;
    // font-size: 30px;
    // font-weight: 500;
    // line-height: 1.2;
    color: var(--forrest-green);
    margin-bottom: 8px;
    @include type-class('.ni__subhead--2');
  }

  h4 {
    // font-family: $fontFamilySans;
    // font-size: 24px;
    // font-weight: normal;
    // line-height: 1.5;
    margin-bottom: 10px;
    color: var(--forrest-green);
    @include type-class('.ni__body-text--2');
  }
}

.ni__additional-cta--divider {
  height: 1px;
  display: block;
  width: 100%;
  background-color: #979797;
  margin-top: 20px;
  margin-bottom: 5px;

  @include breakpoint('medium') {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}

// table up
@include media-breakpoint-up(md) {
  .ni__additional-cta {
    max-width: 634px;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    h3,
    h4 {
      margin: 0;
    }
  }
}
