// ========================================================================
// Component: Hero (Alt1 / Lock-up Full Bleed Image)
// ========================================================================

// settings
$NI__HeroAlt1: '.ni__hero.ni__hero--alt-1';

// Component
// ========================================================================
#{$NI__HeroAlt1} {
  min-height: unset;
  min-width: unset !important;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  z-index: 2;
}

// Component => Green Box Positioning Container
// ========================================================================
#{$NI__HeroAlt1} .ni__global__container {
  position: relative;
}

// Component => Image Container
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1} .ni__hero__image__container {
  animation: none;
  height: auto;
  max-width: map-get($breakpoints, 'max content');
  position: relative;
  top: 0;
  z-index: 1;

  // -- HeroAlt1.js mobile no margins
                                       margin-bottom: 0;
  // -- green box default offsets
  @include breakpoint('desktop')     { margin-bottom: 162px; }
  @include breakpoint('large')       { margin-bottom: 85px;  }
  @include breakpoint('max content') { margin-bottom: 71px;  }
}

// Component => Image
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1} .ni__hero__image,
#{$NI__HeroAlt1} img {
  animation: none;
  height: auto;
  position: relative;
  width: 100%;
  &:after { content: none; }
}

// Component => Textbox Molecule
// ========================================================================
#{$NI__HeroAlt1} .ni__molecule-textbox {
  @include breakpoint('desktop small') {
    max-width: 473px;
    @include position-absolute(50%, auto, auto, 49%, 1);
    transform: translate(-100%);
  }

  @include breakpoint('large') {
    transition: margin 300ms ease-out;
    max-width: 540px;
    padding-left: 95px;
    padding-right: 95px;
  }
}

// Component => Textbox Molecule => Inner Content
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1} .ni__molecule-textbox .ni__molecule-textbox-inner {
  @include breakpoint('desktop') { padding: 32px 0;      }
  @include breakpoint('large')   { padding: 31px 0 35px; }
}

// Component => Textbox Molecule => P Tag
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1} .ni__molecule-textbox .ni__molecule-textbox-title {
  color: var(--forrest-green);
  @include breakpoint('small')   { max-width: 366px; }
  @include breakpoint('desktop') { max-width: 100%;  }
}

// Component => Textbox Molecule => P Tag
// TEMPORARY SOLUTION per https://organic-inc.atlassian.net/browse/NC-1649
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1} .ni__molecule-textbox .ni__molecule-textbox-text {
  color: var(--forrest-green);
  white-space: pre-line;
  @include type-class('.ni__body-text--3');
  @include breakpoint('small')   { max-width: 427px; }
  @include breakpoint('desktop') { max-width: 100%; }
}

// Component => Disable Non-Theme Elements
// ========================================================================
#{$NI__HeroAlt1} {
  .ni__molecule-textbox-eyebrow-container,
  .ni__molecule-content-module {
    display: none;
  }
}

// Component => THEMES => Small Text
// @see https://organic-inc.atlassian.net/browse/NC-2129
// ========================================================================
// prettier-ignore
.ni__hero.ni__hero--alt-1.ni__hero.ni__hero--alt-1--small-text {
  margin: 0 auto;

  // max-width -----------------------------------------
                                       max-width: 375px;
  @include breakpoint('small')       { max-width: 583px;  }
  @include breakpoint('desktop')     { max-width: 1024px; }
  @include breakpoint('large')       { max-width: 1200px; }
  @include breakpoint('max content') { max-width: 1440px; }

  .ni__hero__image__container {
    @include breakpoint('desktop')     { margin-bottom: 272px; }
    @include breakpoint('large')       { margin-bottom: 185px; }
    @include breakpoint('max content') { margin-bottom: 186px; }
  }

  // hide CTAs
  .ni__molecule-textbox-buttons-container {
    display: none;
  }

  .ni__molecule-textbox {
    padding: 32px 40px 32px 20px;
    width: calc(100% - 20px);

    @media (max-width: 719px) {
      position: relative;
      top: -14px;
      z-index: 1;
    }

    @include breakpoint('small') {
      padding: 32px 50px;
      width: 100%;
    }

    @include breakpoint('desktop') {
      padding: 36px 46px 37px;
    }

    @include breakpoint('large') {
      max-width: 519px;
      padding: 37px 56px 35px;
    }
  }

  .ni__molecule-textbox .ni__molecule-textbox-inner {
    @include breakpoint('small')   { padding: 55px 0 45px; }
    @include breakpoint('desktop') { padding: 47px 0 55px; }
    @include breakpoint('large')   { padding: 55px 0 65px; }
  }

  .ni__molecule-textbox .ni__molecule-textbox-inner .ni__col-mobile-9 {
    @media (max-width: 719px) {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  // prettier-ignore
  .ni__molecule-textbox .ni__molecule-textbox-eyebrow-container {
    background-color: var(--pale-green);
    display: inline-block;
    position: relative;
    top: 10px;
    width: auto;

    // padding ------------------------------------------------
                                     padding: 2px 40px 2px 0;
    @include breakpoint('small')   { padding: 2px 61px 2px 0; }
    @include breakpoint('desktop') { padding: 2px 39px 2px 0; }
    @include breakpoint('large')   { padding: 2px 91px 2px 0; }
  }

  .ni__molecule-textbox .ni__molecule-textbox-eyebrow {
    color: var(--forrest-green);
  }

  .ni__molecule-textbox .ni__molecule-textbox-title {
    @include type-class('.ni__h8');
  }

  .ni__molecule-content-module {
    display: flex;
    padding: 20px 20px 0;

    .ni__molecule-content-module-body-container div.richtext.parbase{
      overflow: visible;
    }

    @include breakpoint('small') {
      padding: 42px 0 0 0;
    }

    @include breakpoint('desktop') {
      position: absolute;
      width: auto;
      z-index: 1;
      top: 100%;
      left: 51%;
    }
  }

  .ni__molecule-content-module h3 {
    color: var(--forrest-green);
    margin-top: 0;
  }

  .ni__molecule-content-module[class*='ni__content-module--'] a {
    font-size: 14px !important;
  }
}


// Component => THEMES => Text and Link
// ========================================================================
// prettier-ignore
#{$NI__HeroAlt1}.ni__hero--alt-1--text-link {

  @media (min-width: map-get($breakpoints, 'small')) and (max-width: 1023px){
    width: 750px;
    margin: 0 auto 0 auto;
  }

  .ni__hero__image__container-text-link {
    animation: none;
    height: auto;
    max-width: map-get($breakpoints, 'max content');
    position: relative;
    top: 0;
    z-index: 1;
    margin-bottom: 0;
  
    img {
      margin-bottom: 0;
    }
  }

  .ni__molecule-textbox {

    @media (min-width: map-get($breakpoints, 'small')) and (max-width: 1023px){
      padding: 35px 75px;
    }


    .ni__molecule-textbox-buttons-container {
      margin: 30px 0 30px 0;
    }

    .ni__molecule-textbox-eyebrow-container {
      background-color: var(--pale-green);
      display: inline-block;
      position: relative;
      top: 10px;
      width: auto;
    
      // padding ------------------------------------------------
                                       padding: 2px 40px 2px 0;
      @include breakpoint('small')   { padding: 2px 61px 2px 0; }
      @include breakpoint('desktop') { padding: 2px 39px 2px 0; }
      @include breakpoint('large')   { padding: 2px 91px 2px 0; }
    }

    .ni__molecule-textbox-eyebrow {
      color: var(--forrest-green);
    }

    @media (max-width: 1023px) {

      div.ni__row {
        display: block;

        & > div, .ni__molecule-textbox-title, p {
          max-width: 100%;
        }
      }
    }
  }

  .ni__text-and-link-container{
    display: flex;
    max-width: 90%;
    margin-right: 30px;
    margin-top: 50px;
    margin-left: 5%;

    @media (min-width: map-get($breakpoints, 'small')) and (max-width: 1023px){
      margin-left: 75px;
    }

    .richtext {
      font-style: FoundersGrotesk;
      font-size: 18px;
      color: var(--forrest-green);
      margin-right: 5%;

      @media (max-width: 1023px) {
        margin-bottom: 15px;
      }
    }

    .thirdbutton {
      overflow: unset;
    }
  
    @include breakpoint('desktop') {
      margin-left: 60%;
  
      .richtext {
        max-width: 35%;
      }
    }
  
    @include breakpoint('desktop')     { margin-bottom: 170px; }
    @include breakpoint('large')       { margin-bottom: 170px; }
    @include breakpoint('max content') { margin-bottom: 185px; }
  }

}