// ========================================================================
// Molecules: Card
// ========================================================================

// This very basic molecule has no margin or padding, no borders or backgrounds,
// and also stretches 100% of its parent container. This molecule is best used
// inside of larger column-typem components; e.g. carousels etc.

.ni__molecule-card {
  padding: 0;
  margin: 0;
  width: 100%;
}

.ni__molecule-card-image-container {
  margin: 0 auto 10px;
  position: relative;
  width: 100%;
}

.ni__molecule-card-image-container img {
  position: relative;
  width: 100%;
  height: auto;
}

// add top margin if there's a possible button that exists
.ni__molecule-card-body-container > p + .ni__button,
.ni__molecule-card-body-container > p + div {
  margin-top: 30px;
}

.ni__molecule-card__activate-card {
  display: block; // This element may be an <a>, which has display: inline by default
  text-decoration: none;
  border: 1px solid var(--pale-green);
  border-radius: 8px;
  padding: 24px;
  position: relative;
  color: var(--charcoal);

  &:hover {
    text-decoration: none;
    & > .ni__molecule-card__cta {
      text-decoration: underline;
    }
  }

  & > .ni__molecule-card__note {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 2px 8px 0 2px;
    background-color: var(--digital-green-dark);
    color: var(--power-green);
    padding: 6px 9px;
    font-family: $fontFamilyMono;
    font-size: 9px;
    line-height: 12px;
  }

  & > .ni__molecule-card__headline {
    font-family: FinancierDisplay;
    font-size: 24px;
    line-height: 32px;
  }

  & > .ni__molecule-card__body {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  & > .ni__molecule-card__cta {
    display: flex;
    justify-content: flex-end;
    padding-top: 28px;
    border-top: 1px solid var(--pale-green);
    color: var(--forrest-green);
  }
}

.ni__molecule-card__call-out-card {
  display: block; // This element may be an <a>, which has display: inline by default
  text-decoration: none;
  border-radius: 8px;
  font-family: FoundersGrotesk;
  line-height: 1.33;
  transition: box-shadow 0.3s ease-in;
  display: flex;
  flex-direction: column;
  overflow: hidden;  // Make sure the corners of the image are clipped off by the rounded corners in the "full bleed" use case.

  &[href]:not([href=""]):hover {
    box-shadow: var(--digital-green) 0 0 0 2px inset;
    text-decoration: none;
  }

  &[href]:not([href=""]):active {
    box-shadow: var(--digital-green) 0 0 0 3px inset;
  }

  &:not([class*='ni__color--']) {
    color: var(--non-interactive-text-color);
  }

  &.ni__background--forrest-green[href]:not([href=""]) {
    &:hover {
      box-shadow: var(--power-green) 0 0 0 2px inset;
    }

    &:active {
      box-shadow: var(--power-green) 0 0 0 3px inset;
    }
  }

  // The --card-vertical-padding, --card-horizontal-padding, --card-middle-padding and --card-image-aspect properties come from an inline "style" attribute.
  // There are just way too many valid values for them, and introducing a dedicated class for each valid value would bloat the CSS for no good reason.

  &:not(.ni__molecule-card--image-full-bleed) {
    padding: var(--card-vertical-padding) var(--card-horizontal-padding);
  }

  & > .ni__molecule-card-image-container {
    margin-bottom: var(--card-middle-padding);

    img {
      margin-bottom: 0;
    }
  }

  &.ni__molecule-card--custom-aspect > .ni__molecule-card-image-container {
    aspect-ratio: var(--card-image-aspect);

    & > div {
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    video {
      object-fit: cover;
    }
  }

  &.ni__molecule-card--image-on-bottom > .ni__molecule-card-image-container {
    order: 999;
    margin-bottom: 0;
    margin-top: var(--card-middle-padding);
  }

  &.ni__molecule-card--image-full-bleed {
    & > .ni__molecule-card__headline {
      padding: var(--card-vertical-padding) var(--card-horizontal-padding) 0 var(--card-vertical-padding);
    }

    & > .ni__molecule-card__body {
      padding: 0 var(--card-horizontal-padding) var(--card-vertical-padding) var(--card-horizontal-padding);
    }

    &:not(.ni__molecule-card--image-on-bottom) > .ni__molecule-card__headline {
      /* undo top padding so that only the "middle padding" specified by the author is applied between text and image */
      padding-top: 0;
    }

    &.ni__molecule-card--image-on-bottom > .ni__molecule-card__body {
      /* undo bottom padding so that only the "middle padding" specified by the author is applied between text and image */
      padding-bottom: 0;
    }
  }

  & > .ni__molecule-card__headline {
    font-size: 24px;
    font-weight: 500;
  }

  & > .ni__molecule-card__cta {
    font-size: 18px;
  }
}

.ni__molecule-card__article-card {
  display: block; // This element may be an <a>, which has display: inline by default
  text-decoration: none;
  border: 2px solid var(--pale-grey);
  background-color: white;
  color: var(--non-interactive-text-color);
  line-height: 1.33;

  &:hover {
    text-decoration: none;
  }

  & > .ni__molecule-card-image-container {
    width: 100%;
    height: 185px;

    & > div {
      width: 100%;
      height: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
    }

    video {
      object-fit: cover;
    }
  }

  .ni__molecule-card__headline {
    padding: 27px 23px 15px;
    min-height: 135px;
    font-family: $fontFamilySerif;
    font-size: 24px;
  }

  .ni__molecule-card__category {
    padding: 0 23px;
    font-family: $fontFamilySans;
    font-size: 18px;
    font-weight: bold;
  }

  .ni__molecule-card__readtime {
    padding: 0 23px 15px;
    font-family: $fontFamilySans;
    font-size: 18px;

    @include breakpoint('small') {
      padding-bottom: 30px;
    }

    @include breakpoint('desktop') {
      padding-bottom: 15px;
    }
  }

  .ni__molecule-card__headline, .ni__molecule-card__category, .ni__molecule-card__readtime {
    @include breakpoint('small') {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include breakpoint('desktop') {
      padding-left: 18px;
      padding-right: 18px;
    }

    @include breakpoint('large') {
      padding-left: 22px;
      padding-right: 22px;
    }

    @include breakpoint('max content') {
      padding-left: 23px;
      padding-right: 23px;
    }
  }
}