// ========================================================================
// Component: PinnedNav
// ========================================================================

// prettier-ignore
.ni__pinned-nav--container {
  width: 100%;
  background-color: var(--white-three);
  padding-top: 26px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0);
  will-change: box-shadow, border-color, border-width;
  overflow-x: scroll;

  // bottom-border -----------------------------------------------------------
                                   border-bottom: 1px solid var(--pale-green);
  @include breakpoint('desktop') { border-bottom: 0px solid transparent; }
}

.nav-centered {
  margin: 0 auto !important;
}

// ========================================================================
// necessary to remove scroll box on windows machines
@include breakpoint('desktop') {
  body[data-browser-os*='windows'] .ni__pinned-nav--container {
    overflow-x: hidden;
  }
}

// ========================================================================
// prettier-ignore
.ni__pinned-nav {
  border-bottom: 0px solid transparent;
  @include breakpoint('desktop') { border-bottom: 1px solid var(--pale-green); }
  will-change: border-color, border-width;

  //_____________________________________
  // necessary on mobile and small breakpoints only
  @media (max-width: 1023px) { width: fit-content; }
}

// ========================================================================
// different aesthetics for normal and fixed positioning
// prettier-ignore
.ni__pinned-nav--container.uk-active {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.085);
  .ni__pinned-nav { border-color: transparent; }
}

// ========================================================================
// prettier-ignore
.ni__pinned-nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;

  // justify-content ----------------------------------------------
                                   justify-content: flex-start;
  @include breakpoint('desktop') { justify-content: space-evenly; }

  // max-width ----------------------------------------
  @include breakpoint('desktop') { max-width: 826px;  }
  @include breakpoint('large')   { max-width: 1110px; }
  
  // margin ----------------------------------------
  margin-left: auto; 
  margin-right: auto;
  @include breakpoint('desktop') { margin: 0 auto; }

  //_____________________________________
  // necessary on mobile and tablet devices to
  // add width on the right-hand side of overflow
  padding-right: 20px;
  @include breakpoint('desktop') { padding-right: 0; }

  a.uk-active {
    font-weight: 500;
  }

  a {
    color: #02542c;
    line-height: 1.1 !important;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    @include display-flex(column nowrap, center, center);

    // padding-bottom --------------------------------------
                                     padding-bottom: 14px;
    @include breakpoint('small')   { padding-bottom: 15px; }
    @include breakpoint('desktop') { padding-bottom: 20px; }
    @include breakpoint('large')   { padding-bottom: 25px; }
  }

  a:hover,
  a:focus {
    color: #02542c;
  }

  a.nowrap {
    white-space: nowrap;
  }

  a.wrap {
    white-space: normal;
    min-width: 68px;
    @include breakpoint('small') { min-width: 80px; }
    @include breakpoint('desktop') { min-width: none; }
  }

  a:after {
    content: '';
    position: absolute;
    width: 102%;
    left: -1px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 0px;
    bottom: -1px;
    opacity: 0;
    background-color: var(--ni-green);
    transition: 100ms ease-in-out;
    transition-property: height, opacity;
    will-change: height, opacity;
  }

  .uk-active:after {
    height: 7px;
    opacity: 1;
    transition: 200ms ease-in-out;
    transition-property: height, opacity;
  }

  a + a {
    // margin-left --------------------------------------
                                     margin-left: 30px;
    @include breakpoint('small')   { margin-left: 40px; }
    @include breakpoint('desktop') { margin-left: 50px; }
    @include breakpoint('large')   { margin-left: 80px; }
  }

  //_____________________________________
  // only add hover effect on desktop+ for mouse users
  @include breakpoint('desktop') { 
    a:hover { font-weight: 500; }
  }
}
