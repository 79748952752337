// ========================================================================
// Component: PerspectiveQoute
// ========================================================================

@mixin ni__perspective-qoute {
  @include ni__qoutes__headline;
  font-family: $fontFamilySerif;
  font-size: 32px;
  line-height: 1;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--forrest-green);

  padding-top: 70px;
  padding-bottom: 40px;
  margin-bottom: 42px;
  border: 0 !important;

  &:before {
    left: 0;
    top: 20px;
  }

  &:after {
    right: 0;
    bottom: 0;
  }

  // only tablet
  @include media-breakpoint-up(md) {
    padding-top: 110px;
    padding-bottom: 70px;
    margin-bottom: 42px;

    font-size: 40px;
    max-width: 620px;
    line-height: 1.2;

    &:before {
      left: 0;
      top: 20px;
    }

    &:after {
      right: 0;
      bottom: 0;
    }
  }
}

.ni__perspective-qoute,
.ni__rich-text blockquote {
  @include ni__perspective-qoute;
}
