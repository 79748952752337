$bar: 2px;
$singHeight: 18px;
$singWidth: 18px;

.ni-collapse-group {
  .panel-default {
    border: none;
    border-bottom: 1px solid var(--pale-green);
    border-radius: 0;
    box-shadow: none;
    margin: 0;

    .panel-heading {
      background-color: transparent;
      border: 0;
      padding: 0;

      .trigger {
        display: block;
        font-family: FinancierDisplay;
        font-size: 22px !important;
        line-height: 40px !important;
        padding: 5.5px 38px 5.5px 0;
        position: relative;
        text-decoration: none;

        @include breakpoint('small') {
          font-size: 24px !important;
        }

        @include breakpoint('large') {
          font-size: 30px !important;
        }

        .sign {
          cursor: pointer;
          height: 17px;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 18px;

          @include breakpoint('small') {
            height: 18px;
          }

          span {
            background: var(--ni-green);
            border-radius: 2px;
            position: absolute;
            transition: 100ms;

            &:first-child {
              height: $bar;
              top: 50%;
              transform: translateY(-50%);
              width: $singWidth;
            }

            &:last-child {
              height: $singHeight;
              left: 50%;
              transform: translateX(-50%);
              width: $bar;
            }
          }
        }

        &[aria-expanded='true'] {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .collapsable {
      display: block;
      overflow: hidden;

      .body {
        max-height: 0;
        overflow: hidden;
        transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

        & > * {
          opacity: 0;
        }
      }

      &.show {
        overflow: visible;
        .body {
          max-height: 150vh;

          & > * {
            opacity: 1;
            transition: opacity 400ms;
          }
        }
      }

      .description {
        color: var(--forrest-green);
        margin-bottom: 8px;
      }

      ul {
        margin: 0;

        li {
          a {
            border-bottom: 1px solid var(--pale-green);
            color: var(--forrest-green);
            display: block;
            overflow: hidden;
            padding: 15px 28px 15px 0;
            position: relative;
            white-space: nowrap;

            @include media-breakpoint-up(md) {
              padding: 20px 28px 20px 0;
            }

            &:hover {
              color: var(--ni-green);
              transition: all 250ms ease-out;
            }

            &::after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' fill='none' viewBox='0 0 14 12'%3E%3Cpath stroke='%2303B585' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.371' d='M1.305 5.548H11.59M8.16 10.327l4.801-4.779-4.8-4.552'/%3E%3C/svg%3E%0A");
              content: '';
              height: 10px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 14px;
            }

            p {
              display: block;
              line-height: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;

              @include media-breakpoint-up(md) {
                line-height: 24px;
              }

              &.has-overflow {
                overflow: hidden;
                position: relative;

                &:after {
                  background: var(--white-three);
                  content: '..';
                  padding-left: 2px;
                  position: absolute;
                  right: 0;
                  width: 10px;
                }
              }
            }
          }
        }
      }
    }

    &.opened {
      border-bottom: 1px solid transparent;
      padding-bottom: 26.5px !important;

      .panel-heading {
        .trigger {
          .sign {
            span {
              &:last-child {
                transform: rotate(90deg);
                opacity: 0;
              }
            }
          }
        }
      }

      .collapsable {
        .body {
          padding-bottom: 0;
        }
      }
    }
  }

  // Overriding bootstrap style to fix
  // the issue reported under this
  // ticket: https://dev.azure.com/ni/IT/_boards/board/t/Organic%20-%20N2P/Work%20Items/?workitem=1837794
  .glyphicon-plus,
  .glyphicon-minus {
    &:before {
      content: none !important;
    }
  }
}
