// ========================================================================
// Component: Support Tab
// ========================================================================

.ni__support-tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
  @include media-breakpoint-up(lg) {
    width: 540px;
  }

  .support-tab-item {
    border-bottom: 1px solid var(--pale-green);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-bottom: 72px;
      width: calc(50% - 12px);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
      width: calc(50% - 15px);
    }

    .support-tab-icon-container {
      margin-bottom: 16.4px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 18px;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }

      .support-tab-icon {
        height: 73.2px;
        width: 68px;

        @include media-breakpoint-up(sm) {
          height: 90.5px;
          width: 84px;
        }

        @include media-breakpoint-up(md) {
          height: 50.6px;
          width: 47px;
        }

        @include media-breakpoint-up(lg) {
          height: 70px;
          width: 65px;
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;

      .support-tab-text {
        @include type-class(".ni__body-text--3");

        color: var(--forrest-green);
        margin-bottom: 14px;
        min-height: 68px;

        @include media-breakpoint-up(sm) {
          margin-bottom: 25px;
          min-height: 48px;
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
          min-height: 120px;
        }

        @include media-breakpoint-up(lg) {
          margin-bottom: 16px;
          min-height: 96px;
        }
      }
    }

    .bottom {
      .support-tab-cta {
        @include type-class(".ni__eyebrow--3");

        display: block;
        margin-bottom: 10px;
        padding: 6px 14px 6px 0;
        position: relative;

        &:after {
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxMiIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE0IDEyIj4KICAgIDxwYXRoIHN0cm9rZT0iIzAzQjU4NSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuMzcxIiBkPSJNMS4zNDQgNi4wMkgxMS42M004LjIgMTAuOEwxMyA2LjAyIDguMiAxLjQ3Ii8+Cjwvc3ZnPgo=")
            no-repeat;
          bottom: 8px;
          content: "";
          height: 12px;
          position: absolute;
          right: 0;
          width: 14px;
        }

        &:hover {
          color: var(--ni-green);
        }
      }
    }
  }
}
