// ========================================================================
// Component: HeroSolutions
// ========================================================================

// settings
$NI__HeroSolutions-color: var(--forrest-green);
$NI__HeroSolutions-font: map-get($fonts, 'serif');
$NI__HeroSolutions-path__w: 480px;
$NI__HeroSolutions-path__h: 1288px;

// prettier-ignore
.ni__hero-solutions__path {
  display: none;
  position: absolute;
  // right: calc((#{$NI__HeroSolutions-path__w} + 40px) * -1);
  right: -80%;
  // @include breakpoint('large') { right: -70%; }
  // top: calc((#{$NI__HeroSolutions-path__h} - 38px) * -1);
  top: -1200px;
  color: $NI__HeroSolutions-color;
  // height: $NI__HeroSolutions-path__h;
  // width: $NI__HeroSolutions-path__w;
  width: 425px;
  z-index: 1;

  &:before {
    content: '';
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0ODAiIGhlaWdodD0iMTI4OCIgdmlld0JveD0iMCAwIDQ4MCAxMjg4Ij4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMDQ0MTIzIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiIGQ9Ik00NzggMnY4NTYuMDIyQzQ3OCAxMDk0LjM4OCAyMzcuNTgzIDEyODYgMiAxMjg2Ii8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 1245px;
    width: 476px;
    display: block;
  }

  @include breakpoint('tablet') {
    right: -67%;
    top: -1000px;

    &:before {
      height: 1040px;
    }
  }

  // prettier-ignore
  & {
    @media (min-width: 1048px) { right: -69%; }
    @media (min-width: 1076px) { right: -71%; }
    @media (min-width: 1092px) { right: -73%; }
    @media (min-width: 1110px) { right: -75%; }
    @media (min-width: 1135px) { right: -76%; }
  }


  @include breakpoint('large') {
    right: -69%;
    top: -1090px;

    &:before {
      height: 1140px;
    }
  }

  svg {
    display: none;
    // display: inline-block;
    pointer-events: none;
  }
}

.ni__hero-solutions {
  margin: 0 auto;
  padding: 27px 0;
  width: 100%;

  .ni__button {
    margin-top: 20px;
  }
}

.ni__headline-h2 {
  font-family: $NI__HeroSolutions-font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $NI__HeroSolutions-color;
  margin: 0;
  padding: 0 0 16px 0;
  @include type-class('.ni__h2');
}

.ni__hero-solutions__headline {
  color: $NI__HeroSolutions-color;
  font-family: map-get($fonts, 'sans');
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  margin: 0;
  max-width: 700px;
  padding: 16px 0 20px 0;
  @include type-class('.ni__body-text--2');
}

// mobile up
@include breakpoint('small') {
  .ni__hero-solutions__content {
    margin-left: 95px;
  }

  .ni__hero-solutions__headline {
    max-width: 540px;
  }

  .ni__headline-h2 {
    position: relative;
    max-width: 636px;
  }
}

// mobile up
@include breakpoint('desktop small') {
  // .ni__hero-solutions {
  //   background-color: var(--off-white);
  // }

  .ni__hero-solutions__wrapper {
    padding-top: 88px;
    // padding-bottom: 88px;
    // max-width: map-get($breakpoints, 'grid');
  }

  .ni__hero-solutions__path {
    display: inline-block;
    opacity: 0;
    @include animation('fadeIn 600ms cubic-bezier(0.4, 0, 0.23, 1) 600ms forwards');
  }
}

@include breakpoint('large') {
  .ni__hero-solutions {
    padding: 0;
  }
}
