// ========================================================================
// Component: Tiled Images Carousel
// ========================================================================

//_____________________________________
// settings => misc.
$NI__CarouselTiledImages-Background-Color: transparent;
$NI__CarouselTiledImages-Color: var(--gray-20);
$NI__CarouselTiledImages-Min-Height: 630px;
$NI__CarouselTiledImages-Content-Padding: 130px 0;
$NI__CarouselTiledImages-Content-Max-Width: 1015px;

//_____________________________________
// settings => max width breakpoint / layout change
// this pixel breakpoint is used to determine when the carousel
// functionality changes from the original design, into a centered
// standard carousel. used to prevent odd behavior or 4k/5k screens.
$NI__CarouselTiledImages-Max-Width: 1440px;

//_____________________________________
// settings => alternating item colors
$NI__CarouselTiledImages-Item1-BG-Color: var(--ni-powercopper-light);
$NI__CarouselTiledImages-Item1-Text-Color: var(--forrest-green);
$NI__CarouselTiledImages-Item2-BG-Color: #8ad0c8;
$NI__CarouselTiledImages-Item2-Text-Color: var(--forrest-green);
$NI__CarouselTiledImages-Item3-BG-Color: #ccdcc8;
$NI__CarouselTiledImages-Item3-Text-Color: #004a27;

.ni__carousel-tiled-images {
  background: transparent;
  color: $NI__CarouselTiledImages-Color;
  font-size: 18px;
  margin: 0 auto;
  // prettier-ignore
  @if $UseVerticalPaddingOnHomepageComponents == true { margin-bottom: 64px; }
  overflow: hidden;
  width: calc(100vw - 40px);

  @include breakpoint('desktop small') {
    // prettier-ignore
    @if $UseVerticalPaddingOnHomepageComponents == true { margin-bottom: 120px; }
  }

  @include breakpoint('desktop large') {
    width: 90vw;
    // prettier-ignore
    @if $UseVerticalPaddingOnHomepageComponents == true { margin: 122px 0 176px auto; }
  }

  @media (min-width: $NI__CarouselTiledImages-Max-Width) {
    width: 100%;
    max-width: 1440px;
    // prettier-ignore
    @if $UseVerticalPaddingOnHomepageComponents == true { margin: 122px auto 176px auto; }
  }
}

.ni__carousel-tiled-images .ni__carousel-container {
  width: 100%;
}

.ni__carousel-tiled-images .ni__carousel-section-title {
  color: var(--forrest-green);
  font-family: map-get($fonts, 'serif');
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0 0 55px;
  max-width: 50%;

  @include breakpoint('desktop large') {
    margin: 0 0 80px;
  }
}

.ni__carousel-tiled-images .ni__carousel-item {
  background: inherit;
  color: inherit;
  display: block;
  // min-height: 492px;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  // @include breakpoint('desktop large') {
  //   min-height: 530px;
  // }
}

.ni__carousel-tiled-images .ni__carousel-items > li {
  background-color: $NI__CarouselTiledImages-Item1-BG-Color;
  color: $NI__CarouselTiledImages-Item1-Text-Color;
  width: 100%;
  transition: 200ms ease-in-out;
  transition-property: background-color, color;
  min-height: 100%;
  height: auto;

  &:nth-child(3n + 1) {
    background-color: $NI__CarouselTiledImages-Item1-BG-Color;
    color: $NI__CarouselTiledImages-Item1-Text-Color;
  }

  &:nth-child(3n + 2) {
    background-color: $NI__CarouselTiledImages-Item2-BG-Color;
    color: $NI__CarouselTiledImages-Item2-Text-Color;
  }

  &:nth-child(3n + 3) {
    background-color: $NI__CarouselTiledImages-Item3-BG-Color;
    color: $NI__CarouselTiledImages-Item3-Text-Color;
  }
}

.ni__carousel-tiled-images .ni__carousel-image-container {
  height: 229px;
  overflow: hidden;
  @include display-flex(column nowrap, center, center);

  @include breakpoint('desktop large') {
    height: 279px;
  }
}

.ni__carousel-tiled-images .ni__carousel-image-container div {
  height: 100%;
  width: 100%;
}

.ni__carousel-tiled-images .ni__carousel-image,
.ni__carousel-tiled-images .ni__carousel-image-container .parbase div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  margin: 0;
  width: 100%;
}

.ni__carousel-tiled-images .ni__carousel-divider {
  background: none;
  border-color: var(--forrest-green);
  height: 2px;
  margin: 0 auto 25px;
}

.ni__carousel-tiled-images .ni__carousel-content-container {
  padding: 0 30px 35px;

  @include breakpoint('desktop large') {
    padding: 0 35px 40px;
  }
}

.ni__carousel-tiled-images .ni__carousel-eyebrow {
  color: inherit;
  margin: 0 0 19px;
  @include type-class('.ni__eyebrow--3');
}

.ni__carousel-tiled-images .ni__carousel-title {
  color: inherit;
  letter-spacing: normal;
  margin: 0 0 5px;
  @include type-class('.ni__body-text--2');
}

.ni__carousel-tiled-images .ni__carousel-text {
  color: inherit;
  letter-spacing: normal;
  margin: 0;
  @include type-class('.ni__body-text--3');
}

.ni__carousel-tiled-images .ni__carousel-bullets {
  height: 12px;
  margin: 31px auto 0;

  @include breakpoint('desktop small') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }

  .glide__bullet {
    appearance: none;
    background: var(--gray-20);
    border-radius: 50%;
    border: 0;
    height: 8px;
    margin: 0;
    padding: 0;
    width: 8px;

    &.glide__bullet--active {
      background: var(--ni-green);
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.ni__carousel-tiled-images .ni__carousel-controls {
  display: none;
  margin: 40px 0 0;
  width: 100%;

  & > button {
    @include button-style('.ni__button--controller');
  }

  & > button[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
  }

  @include breakpoint('desktop small') {
    @include display-flex(row nowrap, center, flex-start);
  }
}

.ni__carousel-tiled-images.glide.ni__carousel--showmore-active .glide__slides {
  gap: 40px;
}
