// ========================================================================
// Component: NavProducts
// ========================================================================

.ni__nav-products {
  max-width: 100%;
  color: var(--forrest-green);

  .ni__nav-list {
    margin-top: 32px;
  }

  .ni__button {
    margin-top: 38px;
  }

  .ni__nav-products--content {
    p {
      margin: 0;
      @include type-class('.ni__body-text--3');
    }

    a:not(.ni__button) {
      margin: 0;
      @include type-class('.ni__body-text--3');
    }
  }

  // themes
  &.is-dark {
    background-color: var(--forrest-green);

    .ni__nav-products--content {
      padding-bottom: 38px;

      p {
        color: var(--white-three);
      }

      a {
        color: var(--power-green);
      }
    }

    .ni__button {
      color: var(--white-three);
    }
  }
  // themes
  &.is-light {
    background-color: var(--off-white);
  }
}

.ni__nav-products--header {
  background-color: var(--white-three);
  padding: 0 20px 5px 20px;
  h2,
  h3 {
    color: var(--forrest-green);
    margin: 0;
  }

  h2 {
    margin-bottom: 16px;
  }
}

// image
.ni__nav-products--image .parbase div > div {
  width: 246px;
  height: 246px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  margin-left: 20px;
}

.ni__nav-products--content {
  color: var(--white-three);
  padding: 10px 20px 0 20px;

  p {
    color: var(--forrest-green);
  }

  .button.parbase {
    text-align: left;
    padding: 0;
  }
}

@include media-breakpoint-up(sm) {
  .ni__nav-products {
    max-width: 582px;
    margin: 0 auto;
    .ni__nav-list {
      margin-top: 25px;
    }
  }

  .ni__nav-products--header {
    padding-bottom: 35px;
    h2 {
      margin-bottom: 24px;
    }
  }

  .ni__nav-products--image .parbase div > div {
    width: 384px;
    height: 322px;
  }

  .ni__nav-products--content {
    padding-top: 0;
  }
}

@include media-breakpoint-up(md) {
  .ni__nav-products {
    max-width: map-get($breakpoints, 'desktop');
    border-top-left-radius: 400px;

    .ni__nav-list {
      margin-top: 38px;
      > div {
        max-width: 188px;
        width: 100%;
        display: inline-block;
      }

      > div:nth-child(odd) {
        margin-right: 20px;
      }
    }
  }

  .ni__nav-products--container {
    display: grid;
    margin-left: 103px;
    margin-right: 95px;
    column-gap: 28px;
    grid-template-columns: 398px 400px;
    grid-template-areas:
      "image header"
      "image content";
  }

  .ni__nav-products.ni__nav-products--reversed > .ni__nav-products--container {
    margin-left: 95px;
    margin-right: 103px;
    grid-template-columns: 400px 398px;
    grid-template-areas:
      "header image"
      "content image";
  }

  .ni__nav-products--image {
    grid-area: image;
  }

  .ni__nav-products--image .parbase,
  .ni__nav-products--image .parbase > div
  {
    height: 100%;
  }

  .ni__nav-products--image .parbase div > div {
    height: 100%;
    width: 100%;
    margin-left: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ni__nav-products--header {
    grid-area: header;
    padding: 0;
    background-color: transparent;
    h2 {
      margin-bottom: 37px;
    }
  }

  .is-dark {
    .ni__nav-products--header {
      h2,
      h3 {
        color: var(--white-three);
      }
    }
  }

  .is-dark,
  .is-light {
    .ni__nav-products--container {
      padding-top: 164px;
      padding-bottom: 147px;
    }
  }

  .ni__nav-products--content {
    grid-area: content;
    padding: 24px 0 0 0;
    p {
      max-width: 331px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__nav-products--container {
    margin-left: 0;
    margin-right: 0;
    column-gap: 30px;
    grid-template-columns: 540px 453px;
  }

  .ni__nav-products.ni__nav-products--reversed > .ni__nav-products--container {
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 453px 540px;
  }

  .ni__nav-products--content p {
    max-width: 350px;
  }

  .ni__nav-products .ni__nav-list > div {
    max-width: 209px;
  }

  .ni__nav-products .ni__nav-list > div:nth-child(odd) {
    margin-right: 32px;
  }

  .ni__nav-products.is-light,
  .ni__nav-products.is-dark {
    max-width: map-get($breakpoints, 'max content');

    .ni__nav-products--container,
    &.ni__nav-products--reversed > .ni__nav-products--container {
      margin-left: 95px;
      margin-right: 95px;
      column-gap: calc(100% - 540px - 453px);
    }
  }
}

@include media-breakpoint-up(xl) {
  .ni__nav-products--container,
  .ni__nav-products.ni__nav-products--reversed > .ni__nav-products--container {
    grid-template-columns: 540px 540px;
  }

  .ni__nav-products {
    max-width: map-get($breakpoints, 'grid');
    // display: flex;
    // justify-content: center;
    padding-right: 15px;
    padding-left: 15px;

    .ni__nav-list {
      margin-top: 31px;
    }
  }

  .ni__nav-products--content {
    p {
      max-width: 350px;
    }
  }

  .ni__nav-products .ni__nav-list > div {
    max-width: 252.5px;
  }

  .ni__nav-products.is-light,
  .ni__nav-products.is-dark {
    .ni__nav-products--container {
      margin-left: 150px;
      margin-right: 165px;
      column-gap: 15px;
    }

    &.ni__nav-products--reversed > .ni__nav-products--container {
      margin-left: 165px;
      margin-right: 150px;
    }
  }
}
