.ni__nav {
  background-color: var(--white-three);
  border-bottom: 1px solid var(--pale-green);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0);
  width: 100%;
  z-index: 100;

  .items {
    align-items: center;
    border-bottom: 0 solid transparent !important;
    display: flex;
    justify-content: space-between;

    @include breakpoint("small") {
      justify-content: flex-start;
    }

    @include breakpoint("desktop") {
      border-bottom: 1px solid var(--pale-green);
    }

    .ni__in-page-nav--1 {
      color: var(--forrest-green);
      display: block;
      justify-content: center;
      min-width: 160px !important;
      padding: 5px 22px 14px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 45%;

      @include breakpoint("small") {
        min-width: auto;
        padding: 8px 40px 20px;
        width: auto;
      }

      @include breakpoint("desktop") {
        margin: 0 35px;
        min-width: 116px !important;
        padding-left: 28px;
        padding-right: 28px;
      }

      @include breakpoint("large") {
        min-width: 160px !important;
        padding-left: 30px;
        padding-right: 30px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:after {
        background-color: var(--power-green);
        bottom: -1px;
        content: "";
        height: 0;
        left: -1px;
        margin-left: auto;
        margin-right: auto;
        opacity: 0;
        position: absolute;
        right: 0;
        transition-property: height, opacity;
        transition: 100ms ease-in-out;
        width: 102%;
      }

      &:hover,
      &:focus {
        color: #02542c;
      }

      &.active {
        font-weight: 500;

        &:after {
          height: 7px;
          opacity: 1;
          transition-property: height, opacity;
          transition: 200ms ease-in-out;
        }
      }
    }
  }
}

.ni__nav-content {
  padding-top: 32px;

  @include breakpoint("desktop") {
    padding-top: 30px;
  }

  .tabcontent {
    display: none;

    &.active {
      display: block !important;
    }
  }
}
