// ========================================================================
// Component: LinksTwoColumnAccount
// ========================================================================

// prettier-ignore
.ni__links-two-columns-account-tabs {
  color: var(--forrest-green);
  margin: 20px auto;
  max-width: 335px;

  @include media-breakpoint-up(sm) {
    max-width: 555px;
  }

  @include media-breakpoint-up(md) {
    max-width: 826px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1110px;
  }

  h2 {
    color: var(--core-ni-forest-green);
    font-family: FoundersGrotesk;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.33;
    margin-bottom: 32px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 40px;
    }
  
    @include media-breakpoint-up(md) {
      margin-bottom: 56px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    li {
      border: none;
      width: 100%;

      .content {
        padding: 0;

        .ni__nav-list {
          margin-right: 0;

          @include media-breakpoint-up(md) {
            margin-right: 27px;
          }
        }

        a {
          @include type-class('.ni__text-link--1');

          color: var(--forrest-green);
          display: block;
          overflow: hidden;
          padding: 17px 0;
          position: relative;
          white-space: nowrap;

          &:hover {
            color: var(--ni-green);
          }

          @include media-breakpoint-up(sm) {
            padding: 20px 0;
          }
        
          @include media-breakpoint-up(lg) {
            padding: 17px 0;
          }

          &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            content: "";
            display: none;
            height: 12px;
            left: auto;
            position: absolute;
            right: 0;
            top: auto;
            white-space: nowrap;
            width: 15px;

            @include media-breakpoint-up(sm) {
              bottom: 20px;
              display: block;
            }
          
            @include media-breakpoint-up(md) {
              bottom: 22px;
            }
          }
        }
      }
    }
  }
}
