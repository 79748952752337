// ========================================================================
// Component: Tiled Images Carousel
// ========================================================================

//_____________________________________
// settings => misc.
$NI__CarouselNavFocusAreas-Background-Color: var(--forrest-green);
$NI__CarouselNavFocusAreas-Color: var(--white-three);
$NI__CarouselNavFocusAreas-LinkColor: var(--power-green);
$NI__CarouselNavFocusAreas-LinkColor--Hover: var(--white-three);

$NI__CarouselNavFocusAreas-Min-Height: 630px;
$NI__CarouselNavFocusAreas-Content-Padding: 130px 0;
$NI__CarouselNavFocusAreas-Content-Max-Width: 1015px;
$NI__CarouselNavFocusAreas-Desktop-PaddingLeft: 104px;
$NI__CarouselNavFocusAreas-Large-PaddingLeft: 165px;

//_____________________________________
// settings => max width breakpoint / layout change
// this pixel breakpoint is used to determine when the carousel
// functionality changes from the original design, into a centered
// standard carousel. used to prevent odd behavior or 4k/5k screens.
$NI__CarouselNavFocusAreas-Max-Width: 1440px;

// Carousel
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas {
  background: $NI__CarouselNavFocusAreas-Background-Color;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;

  @include breakpoint('desktop') {
    padding: 72px 0 118px 0;
  }

  @include breakpoint('large') {
    max-width: map-get($breakpoints, 'max content');
    padding: 72px 0 111px 0;
  }
}

// Carousel => Component Header
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas .ni__carousel-header {
  background: white;

  @include breakpoint('desktop') {
    background: $NI__CarouselNavFocusAreas-Background-Color;
    padding-left: $NI__CarouselNavFocusAreas-Desktop-PaddingLeft !important;
  }

  @include breakpoint('large') {
    padding-left: $NI__CarouselNavFocusAreas-Large-PaddingLeft !important;
  }

  //_____________________________________
  // -- title
  .ni__carousel-header-title {
    color: var(--forrest-green);
    @include breakpoint('desktop') { color: $NI__CarouselNavFocusAreas-Color; }
  }

  //_____________________________________
  // -- eyebrow
  [class*='ni__eyebrow'] {
    letter-spacing: -0.01px;
    color: var(--forrest-green);
    @include breakpoint('desktop') { color: $NI__CarouselNavFocusAreas-LinkColor; }

    // margin-bottom-------------------------------------
                                     margin-bottom: 56px;
    @include breakpoint('small')   { margin-bottom: 46px; }
    @include breakpoint('desktop') { margin-bottom: 32px; }
  }

  //_____________________________________
  // -- spacer
  .ni__carousel-header-spacer {
    box-sizing: border-box;
    width: 100%;

    // height ------------------------------------
                                     height: 48px;
    @include breakpoint('small')   { height: 50px; }
    @include breakpoint('desktop') { height: 74px; }
    @include breakpoint('large')   { height: 85px; }
  }
}

.ni__carousel-nav-focus-areas .ni__carousel-items {
  padding-left: 24px;
  padding-right: 24px;

  @include breakpoint('small') {
    padding-left: 0;
    padding-right: 0;
  }
}

// Carousel => Glide Track
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas .glide__track {
  background: $NI__CarouselNavFocusAreas-Background-Color;
  padding-top: 56px;

  @include breakpoint('desktop') {
    padding-top: 0;
    padding-left: $NI__CarouselNavFocusAreas-Desktop-PaddingLeft;
  }

  @include breakpoint('large') {
    padding-left: $NI__CarouselNavFocusAreas-Large-PaddingLeft;
  }
}

// Carousel => Slide Item
// ========================================================================
.ni__carousel-nav-focus-areas .glide__slide {
  background: $NI__CarouselNavFocusAreas-Background-Color;
  height: 100%;

  //_____________________________________
  // -- text colors
  h2,
  h3,
  p {
    color: var(--white-three);
  }
}

// Carousel => Card and Header Mobile/Tablet Widths
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas .ni__carousel-header h4,
.ni__carousel-nav-focus-areas .ni__carousel-header h2,
.ni__carousel-nav-focus-areas .ni__molecule-card {
  margin: 0 auto;
  padding-left: 20px; padding-right: 20px;
  
  // padding --------------------------------------------------------------
                                   padding-left: 20px; padding-right: 20px;
  @include breakpoint('small')   { padding-left: 0;    padding-right: 0; }
  
}

// Carousel => Slide Item => Card
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas .ni__molecule-card {
  height: 100%;
  @include display-flex(column nowrap, flex-start, flex-start);

  h3, p, ul {
    @include breakpoint('desktop') {
      max-width: 350px;
    }
  }

  //_____________________________________
  // -- headline
  h3 {
    color: $NI__CarouselNavFocusAreas-Color;
    // margin-bottom ------------------------------------
                                     margin-bottom: 24px;
    @include breakpoint('desktop') { margin-bottom: 34px; }
    @include breakpoint('large')   { margin-bottom: 38px; }

    // height ---------------------------------
                                     height: auto;
    @include breakpoint('desktop') { height: 150px; }
    @include breakpoint('large')   { height: 170px; }
  }

  p {
    color: $NI__CarouselNavFocusAreas-Color;
    transition: height 200ms ease-out;
    will-change: height;

    // margin ----------------------------------------
                                     margin: 0 0 32px;
    @include breakpoint('small')   { margin: 0 0 24px; }
    @include breakpoint('desktop') { margin: 0 0 22px; }
    @include breakpoint('large')   { margin: 0 0 40px; }

    @media (max-width: 1023px) {
      height: auto !important;
    }
  }

  //_____________________________________
  // -- list
  ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    // flex: 2;
    // margin ----------------------------------------
                                     margin: 0;
    @include breakpoint('small')   { margin: 0; }
    @include breakpoint('desktop') { margin: auto 0 0; }
  }

  li {
    border-bottom: 1px solid var(--pale-green);
  }

  li a {
    @include type-class('.ni__body-text--5');

    display: block;
    padding: 7px 0 11px;
    position: relative;
    padding-right: 15px;
    color: var(--interactive-text-color);
  }

}

// Carousel => Controls
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas {
  .ni__molecule-card li a:before {
    content: '';
    display: none;
    @include breakpoint('small') { display: block; }
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    bottom: 0;
    left: auto;
    width: 15px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%23FFF' stroke-width='1.371'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M.593 5.021L12.461 5.021M8.504 9.8L14.044 5.021 8.504.469' transform='translate(-634 -401) translate(68 389) translate(566.613 12)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

// Carousel => Controls
// ========================================================================
// prettier-ignore
.ni__carousel-nav-focus-areas {

  //_____________________________________
  // -- bullets
  .ni__carousel-bullets {
    height: 12px;
    // margin -----------------------------------------
                                   margin: 37px auto 0;
    @include breakpoint('small') { margin: 56px auto 0; }

    // padding-bottom ----------------------------------
                                   padding-bottom: 64px;
    @include breakpoint('small') { padding-bottom: 56px; }

    // display -------------------------------------
                                     display: block;
    @include breakpoint('desktop') { display: none; }

    // bullets alignment
    .glide__bullets {
      @include display-flex(row nowrap, center, center);
    }

    // bullets + bullets
    .glide__bullets .glide__bullet + .glide__bullet {
      margin-left: 6px;
    }

    // single bullet
    .glide__bullet {
      $size: 5px;
      appearance: none;
      background: var(--gray-20);
      border-radius: 50%;
      border: 0;
      height: $size;
      margin: 0;
      padding: 0;
      width: $size;

      &.glide__bullet--active {
        $size: 9px;
        background: var(--ni-green);
        height: $size;
        width: $size;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  //_____________________________________
  // -- arrows
  .ni__carousel-controls {
    display: none;
    margin: 71px 0 0;
    width: 100%;

    & > button {
      @include button-style('.ni__button--controller-dark');
    }

    & > button:nth-child(2) {
      margin-left: 10px;
    }

    & > button[disabled] {
      cursor: not-allowed;
      opacity: 0.25;
    }

    @include breakpoint('desktop') {
      padding-left: $NI__CarouselNavFocusAreas-Desktop-PaddingLeft;
      @include display-flex(row nowrap, center, flex-start);
    }

    @include breakpoint('large') {
      padding-left: $NI__CarouselNavFocusAreas-Large-PaddingLeft;
    }
  }
}

.ni__carousel-nav-focus-areas.glide.ni__carousel--showmore-active .glide__slides {
  gap: 64px;
}
