// ========================================================================
// UTILITIES => Flex
// Various flex-based utility classes
// ========================================================================

.ni__flex {
  display: flex;
}

.ni__align-items-center {
  align-items: center;
}

.ni__justify-content-center {
  justify-content: center;
}

.ni__row--reverse {
  flex-direction: row-reverse;
}

.ni__col--reverse {
  flex-direction: column-reverse;
}

.ni__flex--bottom {
  align-items: flex-end;
}
