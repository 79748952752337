@each $color-name, $color-value in $colors {
  .ni__background#{$color-name} {
    background-color: var($color-name);
  }
}

:root {
  --non-interactive-text-color: var(--charcoal);
  --interactive-text-color: var(--digital-green-dark);
}

/* Interactive text color can be DigitalGreen for large text (defined by WCAG as 18pt or larger if regular, 14pt or larger if bold),
   but must be the default DigitalGreenDark for small text. We define the small-text case as default and override it for large text.
   This way, if we miss a class, it will default to the "safer" color (the one with more contrast) and we don't risk violating accessibility guidelines. 
*/

$light-background-selector: ".ni__background--white, .ni__background--off-white, .ni-white, .ni-lightgrey, .ni-lightgrey20";
$wcag-large-text-selector: ".u-ni-font-detail-medium-compact, .u-ni-font-body-default, .u-ni-font-body-large, .u-ni-font-body-x-large";

$pale-background-selector: ".ni__background--pale-green, .ni__background--pale-grey, .ni__background--pinkish-grey";
$dark-background-selector: ".ni__background--forrest-green, .ni__background--charcoal, .ni-blue, .ni-darkgrey, .ni__news-banner--button, .ni__promo-banner--button, .is-dark, .ni__carousel-nav-focus-areas, .ni__carousel-focus-area";

:is(#{$light-background-selector}):is(#{$wcag-large-text-selector}), :is(#{$light-background-selector}) :is(#{$wcag-large-text-selector}) {
  --interactive-text-color: var(--digital-green);
}

/* dark color backgrounds */

#{$dark-background-selector} {
  --non-interactive-text-color: var(--off-white);
  --interactive-text-color: var(--power-green);
}
