// ========================================================================
// Molecules: Content Module
// ========================================================================

.ni__molecule-content-module {
  padding: 0;
  margin: 0;
  width: 100%;
  @include display-flex(row nowrap, flex-start, flex-start);
}

.ni__molecule-content-module-image-container {
  margin: 0 20px 0 0;
  position: relative;
  min-width: 67px;
  width: 67px;

  @include breakpoint('small') {
    min-width: 65px;
    width: 65px;
  }
}

.ni__molecule-content-module-image-container img {
  position: relative;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}

// prettier-ignore
.ni__molecule-content-module-body-container {
  height: 67px;
  @include breakpoint('small') { height: 65px; }
  @include display-flex(column nowrap, flex-start, space-between);
}

.ni__molecule-content-module-body-container h3 {
  margin: 10px 0 auto;
  line-height: 1.1 !important;
}

.ni__molecule-content-module-body-container a {
  margin: auto 0 0;
  display: block;
}

// Icon link formatting
// ========================================================================
.ni__molecule-content-module {
  &[class*='ni__content-module--'] a {
    padding-left: 35px;
    position: relative;
    text-transform: uppercase;
    @include type-class('.ni__eyebrow--3');
    min-height: 23px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    &:before {
      content: '';
      width: 22px;
      height: 23px;
      position: absolute;
      top: 0;
      left: 0;
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }
}

// Icons
// ========================================================================
.ni__molecule-content-module.ni__content-module--download a:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M0 0h23v23H0z'/%3E%3Cpath fill='%2335b77d' d='M19.167 14.375a.957.957 0 111.916 0v3.833a2.875 2.875 0 01-2.875 2.875H4.792a2.875 2.875 0 01-2.875-2.875v-3.833a.957.957 0 111.916 0v3.833c0 .53.43.959.959.959h13.416a.96.96 0 00.959-.959v-3.833zm-8.625-2.314V2.875a.957.957 0 111.916 0v9.186l3.156-3.155a.957.957 0 011.355 0 .957.957 0 010 1.355l-4.791 4.792a.96.96 0 01-1.356 0L6.031 10.26a.957.957 0 010-1.355.957.957 0 011.355 0l3.156 3.155z'/%3E%3C/g%3E%3C/svg%3E");
}

.ni__molecule-content-module.ni__content-module--video a:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%2303B585' d='M5.564 6h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v6.873c0 .892-.093 1.215-.267 1.54a1.81 1.81 0 01-.756.757c-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267a1.81 1.81 0 01-.757-.756c-.174-.326-.267-.65-.267-1.54V8.563c0-.892.093-1.215.267-1.54a1.81 1.81 0 01.756-.757c.326-.174.65-.267 1.54-.267zM17 10.333l2.36-1.966a1 1 0 011.64.768v5.73a1 1 0 01-1.64.768L17 13.667v-3.334z'/%3E%3C/g%3E%3C/svg%3E");
}

.ni__molecule-content-module.ni__content-module--paper a:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%2335B77D' d='M13 4.18V8a1 1 0 001 1h3.82a1.994 1.994 0 00-.406-.586l-3.828-3.828A1.994 1.994 0 0013 4.18zM18 11h-4a3 3 0 01-3-3V4H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2v-7zm-5.328-9c.53 0 1.039.21 1.414.586l5.328 5.328A2 2 0 0120 9.328V18c0 2.21-1.79 4-4 4H8c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h4.672z'/%3E%3C/g%3E%3C/svg%3E");
}

// AEM parbase override
// ========================================================================
.ni__molecule-content-module-body-container .richtext .link.parbase {
  overflow: visible;
}
