.ni__profile {
  display: flex;
}

.ni__profile--content {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h4 {
    color: var(--forrest-green);
    margin-bottom: 0;
  }
}

.ni__profile--icon a {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
  }
}

.ni__icon--download a:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='#35b77d' d='M20 15a1 1 0 012 0v4a3 3 0 01-3 3H5a3 3 0 01-3-3v-4a1 1 0 012 0v4a1 1 0 001 1h14a1 1 0 001-1v-4zm-9-2.414V3a1 1 0 012 0v9.586l3.293-3.293a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L11 12.586z'/%3E%3C/g%3E%3C/svg%3E");
}

.ni__profile .ni__molecule-content-module.ni__content-module--video a:before {
  top: 1px;
}

.ni__profile--divider {
  padding-left: 19px;
  padding-right: 19px;
}
