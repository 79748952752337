// ========================================================================
// Component: SolutionsLeadStatements
// ========================================================================

// Mobile First
.ni__solutions-ls__curvedLineContainer {
  display: none;
}

.ni__solutions-lead_statements {
  margin: 0 auto;

  .ni__solutions-lead_statements__headline-top span {
    display: block;
  }

  .ni__solutions-lead_statements__container {
    position: relative;
  }

  .ni__solutions-ls-topImage {
    width: 100%;
    height: auto;
    margin: 0 auto;
    // overflow: hidden;

    img {
      width: 100%;
    }
  }

  .ni__solutions-ls-top {
    position: relative;
  }

  h2 {
    color: var(--forrest-green);
    margin: 0;
    @include type-class('.ni__h2');
  }

  h3 {
    margin: 0;
    color: var(--forrest-green);
    white-space: pre-line;
    @include type-class('.ni__h5');
  }

  p {
    color: var(--forrest-green);
    @include type-class('.ni__body-text--2');

    &.ni__p-small {
      @include type-class('.ni__body-text--2');
    }
  }

  .ni__solutions-ls__headline {
    padding-top: 14.933vw;
  }

  .ni__solutions-ls__text {
    padding-top: 8.533vw;
    padding-bottom: 17.067vw;
  }

  .ni__solutions-ls__bg {
    background-color: var(--pale-green);
    padding: 17.067vw 0;

    .ni__solutions-ls__headlineSecond {
      padding-bottom: 14.933vw;
      @include type-class('.ni__h2');
    }

    .ni__number {
      color: var(--forrest-green);
      padding-bottom: 24px;
      @include type-class('.ni__number-eyebrow--2');
    }

    .ni__solutions-ls__imgContainer {
      padding-right: 10px;
    }

    .columns {
      h3 {
        padding-top: 32px;
        padding-bottom: 16px;
      }

      p {
        padding-bottom: 32px;
      }

      img,
      svg {
        width: 100%;
        max-width: 80px;
        max-height: 80px;
      }
    }
  }
}

@media (min-width: 450px) {
  .ni__solutions-lead_statements .ni__solutions-ls__headline {
    padding-top: 56px;
  }

  .ni__solutions-lead_statements .ni__solutions-ls__text {
    padding-top: 32px;
    padding-bottom: 64px;
  }

  .ni__solutions-lead_statements .ni__solutions-ls-topImage {
    margin: 0 auto;
    max-width: 450px;
    padding: 0 20px;
  }
}

@media (max-width: 719px) {
  .ni__solutions-lead_statements .ni__solutions-ls-top {
    max-width: 450px;
  }
}

@include media-breakpoint-up(sm) {
  .ni__solutions-lead_statements .ni__solutions-ls__text {
    padding-bottom: 0;
  }

  .ni__solutions-lead_statements .ni__solutions-ls-topImage {
    // height: 402px;
    // width: 410px;
    margin-bottom: 64px;

    img {
      // margin-top: -2vw;
    }
  }
}

@media (min-width: 840px) {
  .ni__solutions-lead_statements .ni__solutions-ls-topImage img {
    // margin-top: -8.5vw;
  }
}

@media (min-width: 720px) and (max-width: 1023px) {
  .ni__solutions-lead_statements .ni__solutions-lead_statements__container {
    display: flex;
    flex-flow: row wrap;
  }

  .ni__solutions-lead_statements .ni__solutions-ls-topImage {
    // padding-left: 0;
  }

  .ni__solutions-lead_statements .ni__solutions-ls-topImage,
  .ni__solutions-lead_statements .ni__solutions-ls-top {
    flex: 1;
  }

  .ni__solutions-lead_statements .ni__solutions-ls__bg {
    flex: none;
    width: 100%;
  }
}

// Tablet styles
@include media-breakpoint-up(tablet) {
  .ni__solutions-ls__curvedLineContainer {
    width: 100vw;
    height: 110%;
    display: block;
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translateX(-50%);

    .ni__solutions-ls__curvedLine {
      display: block;
      border-right: solid 3px var(--forrest-green);
      border-bottom: solid 3px var(--forrest-green);
      position: absolute;
      width: 28%;
      height: calc(100% + 15.5vw);
      border-bottom-right-radius: 31.25vw;
      right: 4.25vw;
      top: -24px;
    }
  }

  .ni__solutions-lead_statements {
    .ni__solutions-ls-topImage {
      width: 35.083vw;
      height: 48.75vw;
      position: absolute;
      padding-top: 4.667vw;
      // overflow: hidden;
      margin-bottom: 0;
      padding-left: 0;

      img {
        // height: 470.844px;
        width: 100%;
        height: auto;
        margin-top: 0;
      }
    }

    p {
      padding-bottom: 3.333vw;
    }

    .ni__solutions-ls__text {
      padding-top: 2vw;
      padding-bottom: 17.067vw;
    }

    .ni__solutions-ls__headline {
      padding-top: 14.933vw;
    }

    .ni__solutions-ls__bg {
      padding: 9.75vw 0;

      .ni__solutions-ls__headlineSecond {
        padding-bottom: 6.667vw;

        span {
          display: block;
        }
      }

      .ni__number {
        padding-bottom: 2vw;
      }

      .columns {
        h3 {
          padding-top: 2.833vw;
          padding-bottom: 1.333vw;
        }

        .ni__p-small {
          padding-bottom: 3.333vw;
          @include make-col(8);
        }

        img,
        svg {
          max-width: 133px;
        }

        &:last-child {
          p {
            @include make-col(12);
          }
        }
      }
    }
  }
}

// Desktop styles
@include media-breakpoint-up(lg) {
  .ni__solutions-ls__curvedLineContainer {
    max-width: 1440px;
    min-width: 1300px;
    .ni__solutions-ls__curvedLine {
      height: calc(100% + 246px);
      width: 25.903vw;
      max-width: 373px;
      right: 2.917vw;
    }
  }

  .ni__solutions-lead_statements {
    .ni__solutions-ls__headline {
      padding-top: 272px;
    }

    .ni__solutions-ls-topImage {
      max-width: 1440px;
      width: 100%;
      padding-top: 80px;
      left: 50%;
      transform: translateX(-50%);
      height: 728px;

      .ni__solutions-ls-topImage_container {
        // width: 516px;
        width: 495px;
        height: 648px;
        overflow: hidden;

        img {
          width: auto;
          height: 648px;
        }
      }
    }

    p {
      padding-bottom: 0;
    }

    .ni__solutions-ls__text {
      padding-top: 48px;
      padding-bottom: 322px;
    }

    .ni__solutions-ls__bg {
      padding: 176px 0;

      .ni__solutions-ls__headlineSecond {
        padding-bottom: 109px;
      }

      .ni__number {
        padding-bottom: 32px;
      }

      .ni__solutions-ls__imgContainer {
        padding-right: 15px;
      }

      .columns {
        h3 {
          padding-top: 34px;
          padding-bottom: 32px;
        }

        .ni__p-small {
          padding-bottom: 40px;
          flex: 0 0 72%;
          max-width: 72%;
        }

        img,
        svg {
          max-width: 160px;
        }

        &:last-child {
          p {
            flex: 0 0 72%;
            max-width: 72%;
          }
        }
      }
    }
  }
}

@media (min-width: 1250px) {
  .ni__solutions-lead_statements .ni__solutions-ls-topImage .ni__solutions-ls-topImage_container {
    width: 516px;
  }
}

// Show/hide for mobile or desktop/tablet
.hideMobile {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.showMobile {
  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.statements-headline-copy.parbase {
  overflow: visible;
}

// Description
.ni__solutions-ls-topImage {
  .ni__description {
    padding-left: 20px;

    p {
      padding-top: 0 !important;
      font-size: 13px !important;
      line-height: 15px !important;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(md) {
    }

    @include media-breakpoint-up(lg) {
      // width: 336px;
      // top: 590px;
    }
  }
}
