// ========================================================================
// Component: PerspectiveHeroVideo
// ========================================================================

.ni__perspective-hero-video {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  max-width: 1440px;
  margin: auto;

  .image-component {
    img {
      margin-bottom: 0 !important;
    }
  }

  .embed-responsive,
  .embed-responsive * {
    pointer-events: none;
  }

  .ni__perspective-hero-video-js {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    top: 0;
  }

  .vjs-control-bar {
    display: none !important;
  }
}
