// ========================================================================
// Component: HeroCta
// ========================================================================

// Component => Bootstrap Container
// ========================================================================
.ni__hero-cta .ni__container {
  position: relative;

  // @see https://organic-inc.atlassian.net/browse/NC-1909
  // prettier-ignore
  @include breakpoint('large') {
    height: 636px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;

    & > .ni__row { width: 100%; }
  }
}

// Title => Text Element
// ========================================================================
.ni__hero-cta h2 {
  color: var(--forrest-green);
  position: relative;
  margin: 20px 0 0;
  white-space: pre-wrap;

  // prettier-ignore
  & {
    @include media-breakpoint-up(sm) { margin: 40px 0 0; }
    @include media-breakpoint-up(md) { margin: 62px 0 0; }
    @include media-breakpoint-up(lg) { margin: 0 0 0;    } // NC-1909
  }
}

// Title => Graphic Bit
// ========================================================================
// prettier-ignore
.ni__hero-cta h2:before {
  @if $UseGraphicBit == true { content: ''; }
  position: absolute;
  left: -15px;
  top: -15px;
  width: 15px;
  height: 15px;
  background-color: var(--ni-green);
  border-top-left-radius: 20px;

  @include media-breakpoint-up(sm) {
    width: 29px;
    height: 29px;
    border-top-left-radius: 40px;
    left: -29px;
    top: -30px;
  }  
}

// Text => Text Element
// ========================================================================
// prettier-ignore
.ni__hero-cta p {
  color: var(--forrest-green);
  margin: 24px 0 0;
  @include media-breakpoint-up(md) { margin: 24px 0 0; }

  // font sizing
  @include type-class('.ni__body-text--3');
  @include media-breakpoint-up(lg) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

// Text => CTA Buttons
// ========================================================================
.ni__hero-cta .ni__hero-cta--buttons {
  margin-top: 40px;

  // We are overriding some Bootstrap classes here to get the right look.
  // This is ugly, but it lets us make changes without touching the AEM code.
  &.ni__hero-cta--buttons--vertical > .ni__row {
    width: min-content;
    row-gap: 16px;
    & > .ni__col-mobile-6 {
      flex: 1;
      max-width: none;
    }
  }

  .ni__button {
    width: 100%;
    padding: 0 8px;
  }

  // Fix AEM styles .button styles
  .button.parbase,
  .secondbutton.parbase {
    overflow: visible;
    text-align: inherit;
    padding: 0;
  }

  // fix padding only on mobile
  @media (min-width: 375px) and (max-width: 425px) {
    // prettier-ignore
    &:not(.ni__hero-cta--buttons--vertical) .ni__row > .ni__col-mobile-6 {
      &:first-child { padding-right: 10px; }
      &:last-child  { padding-left: 10px; }
    }
  }

  // if viewport is smaller than 375px and there's 2 CTAs
  @media (max-width: 374px) {
    &:not(.ni__hero-cta--buttons--vertical) .ni__col-mobile-6 + .ni__col-mobile-6 {
      margin-top: 20px;
    }
  }
}

// Image => Container
// ========================================================================
.ni__hero-cta .ni__hero-cta--image-container {
  height: 267px;
  margin-left: -20px;
  position: relative;
  width: 100vw;

  // try and maintain a square ratio
  // for anything above 376px - 719px
  @media (min-width: 376px) {
    height: 73.5vw;
  }

  @include media-breakpoint-up(sm) {
    height: 414px;
    margin-left: 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    height: 405px;
    left: auto;
    position: absolute;
    right: -25px;
    top: 0;
    width: 570px;
    z-index: -1;
  }

  @include media-breakpoint-up(lg) {
    height: 636px;
    left: auto;
    position: absolute;
    right: -175px;
    // top: -83px;
    top: 0;
    width: 895px;
    z-index: -1;
  }
}

// Image => Image
// ========================================================================
.ni__hero-cta .ni__hero-cta--image,
.ni__hero-cta .ni__hero-cta--image-container .parbase div,
.ni__hero-cta .ni__hero-cta--image-container .parbase img {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

// AEM code.
.hero-lock-up-image-right-aligned.parbase {
  overflow: visible !important;
}

.hero-lock-up-dual-image .imgHeroDual {
  min-height: 100px;
}

.hero-lock-up-image-right-aligned .imgHeroCta {
  min-height: 100px;
}
