// ========================================================================
// Component: HardwareNavMenu
// ========================================================================

// Component => Settings
// ========================================================================
$NI__HardwareNav-Color: var(--forrest-green);
$NI__HardwareNav-Color--Hover: var(--ni-green);
$NI__HardwareNav-BackgroundColor: var(--off-white);

// Component
// ========================================================================
// prettier-ignore
.ni__hardware-nav-menu {
  //_____________________________________
  // Component => Adjust button row width
  .ni__copy-and-image-button-container {
    @include breakpoint('desktop small') { width: 100%; }
  }

  .ni__copy-and-image {
    padding-bottom: 31.5px;

    @include breakpoint('desktop') {
      padding-bottom: 27.5px;
    }

    @include breakpoint('desktop') {
      padding-bottom: 88.5px;
    }

    @include breakpoint('large') {
      padding-bottom: 101.5px;
    }
  }

  hr {
    background-color: transparent;
    border-top: 1px solid var(--ni-nickel);
    margin: 0;
    opacity: .22;
  }

  //_____________________________________
  // Component => CopyAndImage--Light Text Color
  .ni__copy-and-image.ni__copy-and-image--light {
    .ni__copy-and-image-title,
    .ni__copy-and-image-subtitle,
    .ni__copy-and-image-body-text {
      color: var(--forrest-green);
    }
  }

  //_____________________________________
  // Component => Nav Menu Title
  .ni__hardware-nav-menu--nav-title {
    color: var(--forrest-green);
    margin-top: 64px;
    margin-bottom: 38px;
    @include breakpoint('small')         { margin-bottom: 67px; }
    @include breakpoint('desktop small') { margin-bottom: 93px; }
    @include breakpoint('large')         { margin-bottom: 90px; }
  }

  .ni__horizontal-scroller {
    @include breakpoint('small')         { margin-bottom: 30px; }
    @include breakpoint('desktop small') { margin-bottom: 83px; }
    @include breakpoint('large')         { margin-bottom: 81px; }
    @media (max-width: 719px) { margin: 0; } // disable ni__container below 720px
  }

  //_____________________________________
  // Component => Switcher => Controls/Toggler
  .uk-switcher-controls {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    @include display-flex(row nowrap, center, flex-start);
    align-items: flex-end;
  }

  //_____________________________________
  // Component => Switcher => Menu Header => Default Style
  .uk-switcher-controls > * > a {
    border-bottom: 6px solid transparent;
    color: $NI__HardwareNav-Color;
    display: block;
    padding: 9px 20px;
    text-align: center;
    text-decoration: none;
    transition: 100ms ease-in-out;
    transition-property: border-color, color;
    @include type-class('.ni__in-page-nav--1');

    &:lang(ja), &:lang(ko), &:lang(zh-CN), &:lang(zh-TW){
      @include breakpoint('desktop small') { line-height: normal!important;}
      @include breakpoint('large') { line-height: normal!important;}	
    }
  }

  //_____________________________________
  // Component => Switcher => Menu Header => Active Style
  .uk-switcher-controls > .uk-active > a {
    color: var(--forrest-green);
    border-color: var(--power-green);
    padding: 9px calc(20px - 1.58px);
    @include type-class('.ni__in-page-nav--2');
  }

  //_____________________________________
  // Component => Switcher => Menu Header => Item Alignment
  .uk-switcher-controls > li {
    position: relative;
    width: 100%;
  }

  //_____________________________________
  // Component => Switcher => Menu Header => Bottom Border 
  .uk-switcher-controls > li:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--pale-green);
  }

  //_____________________________________
  // Component => Switcher => Menu Header => Spans
  .uk-switcher-controls > li span {
    display: block;
    text-align: center;
    width: 124px;
    @include breakpoint("small"){ width: 144px}
    @include breakpoint("desktop"){ 
      width: 172px;
                                  }
    @include breakpoint("large"){width: 200px}
  }

  //_____________________________________
  // Component => Switcher => Container
  .ni__hardware-nav-menu--container {
    background-color: $NI__HardwareNav-BackgroundColor;
    margin: 0 auto;
  }

  .ni__hardware-nav-menu--container.not-full-bleed {
    max-width: 1440px;
  }
  

  //_____________________________________
  // Component => Switcher
  .uk-switcher {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 64px;
    list-style: none;
    & > :not(.uk-active) { display: none; }
  }

  //_____________________________________
  // Component => Switcher => Content => Image and CTA
  .ni__hardware-nav-menu--container-content-image-and-copy {
    margin-top: 50px;
    @include breakpoint('small')   { margin-top: 56px; }
    @include breakpoint('desktop') { margin-bottom: 50px; }
    @include breakpoint('desktop') { flex-direction: row-reverse; }
    @include breakpoint('large')   { margin-bottom: 63px; }
  }

  //_____________________________________
  // Component => Switcher => Content => Image and CTA => Image
  .ni__hardware-nav-menu--container-content-image {
    @include breakpoint('large') { margin-left: auto; }
    img { width: 100%; }
  }

  //_____________________________________
  // Component => Switcher => Content => Image and CTA => Copy/CTA
  .ni__hardware-nav-menu--container-content-copy {
    margin-top: 23px;
    margin-bottom: 33px;
    @include breakpoint('desktop') { margin-top: 0; margin-bottom: 0; }
  }

  //_____________________________________
  // Component => Switcher => Content => Image and CTA => Copy/CTA => Copy
  .ni__hardware-nav-menu--container-content-copy p {
    margin-bottom: 40px;
    color: var(--forrest-green);
  }

  //_____________________________________
  // Component => Switcher => Content => Image and CTA => Copy/CTA => CTA
  .ni__hardware-nav-menu--container-content-copy {
    .ni__button { width: 100%; }
    .button.parbase { // aem fix
      overflow: visible;
      text-align: left;
      padding: 0;
    }
  }

  //_____________________________________
  // Component => Switcher => Content => Content List
  .ni__hardware-nav-menu--container-content-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  //_____________________________________
  // Component => Switcher => Content => Content List => Link Item
  .ni__hardware-nav-menu--container-content-list a {
    border-bottom: solid 1px var(--pale-green);
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;

    @include display-flex(column nowrap, flex-start, center);
    @include type-class('.ni__text-link--1');
    
    // Link Item => Padding
    padding: 17.5px 0;
    @include breakpoint('small')         { padding: 30px 30px 14px 0; }
    @include breakpoint('desktop small') { padding: 31px 36px 12px 0; }
    @include breakpoint('large')         { padding: 28px 30px 16px 0; }
  }

  //_____________________________________
  // Component => Switcher => Content => Content List => Link Item => Arrow Bit
  .ni__hardware-nav-menu--container-content-list a:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    // @include breakpoint('small')         { bottom: 15px; }
    // @include breakpoint('desktop small') { bottom: 16px; }
    // @include breakpoint('large')         { bottom: 21px; }
    left: auto;
    right: 0;
    top: 50%;
    margin-top: 0px;
    height: 12px;
    width: 15px;
    @media (max-width: calc(#{map-get($breakpoints, 'small')} - 1px)) { display: none; }
  }
  
  //_____________________________________
  // Compoent => Switcher => Content => Load More Button
  .ni__hardware-nav-menu--container-content-load-more-container {
    margin-top: 44px;
    @include breakpoint('small') { margin-top: 40px; }
  }

  // Component => Tooltip

  /* Tooltip text */
.hardware-nav-menu-tooltip .hardware-nav-menu-tooltiptext {
  visibility: hidden;
  text-align: center;
  padding: 4px 4px 2px;
  white-space: nowrap;
  font-family: FoundersGrotesk;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  background-color: white;
  border-radius: 2px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  right: 50%;
  bottom: 90%;
  transform: translateX(50%);

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */
.hardware-nav-menu-tooltip:hover .hardware-nav-menu-tooltiptext.active {
  visibility: visible;
  opacity: 1;
}
}