// ========================================================================
// Component: ContentCardsAccordionGrid
// ========================================================================

// Settings
// ========================================================================
$ContentCardsAccordionGrid__Title--Color: var(--forrest-green);

// prettier-ignore
.ni__content-cards-accordion-grid {
  
  //_____________________________________
  // Component Main Title
  &--section-title {
    color: $ContentCardsAccordionGrid__Title--Color;
    margin-bottom: 41px;
    @include breakpoint('small')   { margin-bottom: 40px; }
    @include breakpoint('desktop') { margin-bottom: 41px; }
    @include breakpoint('large')   { margin-bottom: 62px; }

    h3 {
      margin-bottom: 24px;
      color: var(--forrest-green);
      @include breakpoint('small')   { margin-bottom: 32px; }
      @include breakpoint('desktop') { margin-bottom: 64px; }
    }

    h2 { 
      color: #004a27; 
      margin: 0; 
    }
    
    p {
      margin-top: 32px;
      margin-bottom: 40px;

      @include breakpoint('small')   {
        margin-top: 24px;
        margin-bottom: 57px;
      }

      @include breakpoint('desktop')   {
        max-width: 539px;
        margin-bottom: 72px;
      }
    }
  }

  //_____________________________________
  // Accordion Item => Image
  .ni__accordion li .ni__accordion-image {
    margin-bottom: 33px;
    @include breakpoint('small') { margin-bottom: 33px; }
  }

  //_____________________________________
  // Accordion Item => Text
  .ni__accordion li p {
    margin-bottom: 28px;
    @include breakpoint('small') { margin-bottom: 34px; }
  }


  .ni__accordion .ni__accordion-title--closed {
    font-weight: 500 !important;
  }

  //_____________________________________
  // Grid Card
  .ni__molecule-card {
    color: $ContentCardsAccordionGrid__Title--Color;
  }

  //_____________________________________
  // Grid Card => Image
  .ni__molecule-card .ni__molecule-card-image-container {
    margin-bottom: 14px;



    @include breakpoint('desktop') { 
      min-height: 136px;
    }

    @include breakpoint('large') { 
      margin-bottom: 33px; 
    }
  }

  //_____________________________________
  // Grid Card => Title
  .ni__molecule-card h3 {
    color: inherit;
    margin-bottom: 10px;
    min-height: 75px;
    white-space: pre-line;
  }

  //_____________________________________
  // Grid Card => Text
  .ni__molecule-card p {
    color: inherit;
    margin-bottom: 40px;
    max-width: 255px;
  }

  //_____________________________________
  // Grid Card => Height Alignment
  .ni__molecule-card {
    height: 100%;
    @include display-flex(column nowrap, flex-start, flex-start);

    .ni__molecule-card-body-container {
      height: 100%;
      @include display-flex(column nowrap, flex-start, flex-start);


      .ni__button {
        &:focus {
          background-color: transparent;
          border-color: var(--ni-green);
          color: var(--forrest-green);
        }
      }
    }

    .ni__molecule-card-body-container .ni__button,
    .ni__molecule-card-body-container .button.parbase,
    .ni__molecule-card-body-container p + div {
      margin-top: auto;
    }
  }
}
