// ========================================================================
// Component: PerspectiveDetailHeader
// ========================================================================

.ni__perspective-detail-header {
  margin-bottom: 40px;

  h4 {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--forrest-green);
    margin: 0;
    @include type-class('.ni__body-text--3');

    small {
      display: block;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: var(--forrest-green);
      @include type-class('.ni__mini-detail--1');
    }
  }

  p {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--forrest-green);
    text-transform: lowercase;
    padding-bottom: 2px;
    @include type-class('.ni__eyebrow-detail');
  }
}

.ni__perspective-detail-header--content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > div {
    position: relative;
    padding-right: 23px;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      height: 36px;
      margin-top: -18px;
      top: 50%;
      width: 1px;
      background-color: #cddcc8;
    }

    &:first-child {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 20px;
      width: 100%;
      &:after {
        display: none;
      }
    }

    &:last-child:after {
      display: none;
    }

    &:last-child {
      padding-left: 23px;
    }
  }

  a {
    text-decoration: none;
  }
}

.ni__perspective-detail-header--writer {
  display: flex;
  align-items: center;
}

.ni__perspective-detail-header--avatar {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;
  background-size: contain;
}

// only tablet
@include media-breakpoint-up(sm) {
  .ni__perspective-detail-header {
    margin-bottom: 80px;
  }

  .ni__perspective-detail-header--content {
    > div {
      padding-right: 23px;
      padding-left: 23px;

      &:first-child {
        padding-right: 23px;
        width: auto;
        margin-bottom: 0;
        &:after {
          display: block;
        }
      }
    }
  }
}
