// ========================================================================
// Component: ProductBanner
// ========================================================================

.ni__product-banner {
  .ni__container {
    padding: 0;
  }
}

// Header
.ni__product-banner--header {
  background-color: var(--pale-green);
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.ni__product-banner--image,
.ni__product-banner--header > .parbase > div > div {
  width: 177px;
  height: 81px;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.ni__product-banner--button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
  position: relative;
  z-index: 1;

  a,
  .ni__rich-text a,
  .richtext a {
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 30px;

    &:hover:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='25' viewBox='0 0 18 25'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='#03B585' stroke-width='3'%3E%3Cpath d='M0.5 8.5L9 17.5 17.5 8.5' transform='translate(-605 -63) rotate(-90 343 -258)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  a:before {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='25' viewBox='0 0 18 25'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%23044123' stroke-width='3'%3E%3Cpath d='M0.5 8.5L9 17.5 17.5 8.5' transform='translate(-605 -63) rotate(-90 343 -258)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 18px;
    height: 25px;
    right: 0;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-left: 15px;
  }
}

// Detail
.ni__product-banner--detail {
  background-color: var(--forrest-green);
  padding: 40px 20px;
}

.ni__product-banner--headline {
  color: var(--off-white);
  margin-top: 27px;
  margin-bottom: 42px;
  @include type-class('.ni__h11');
  @include ni__qoutes__headline;

  &:before,
  &:after {
    width: 15px;
    height: 15px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

// Profile
.ni__product-banner--profile {
  display: flex;
  align-items: center;

  h4,
  small {
    color: var(--off-white);
    margin: 0;
  }

  small {
    display: block;
    padding-top: 3px;
  }
}

.ni__product-banner--avatar,
.ni__product-banner--profile > .parbase div > div {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin-right: 16px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;
  background-size: contain;
}

// aem background-image adjustment
.ni__product-banner--profile > .parbase {
  margin-right: 16px;
}

// aem background-image adjustment
.ni__product-banner--profile > .parbase div {
  width: 34px;
  height: 34px;
}

@include media-breakpoint-up(sm) {
  .ni__product-banner--header {
    height: 149px;
  }

  .ni__product-banner--image,
  .ni__product-banner--header > .parbase > div > div {
    width: 333px;
    height: 142px;
  }

  .ni__product-banner--detail {
    padding-left: 50px;
    padding-right: 50px;
  }

  .ni__product-banner--headline {
    &:before {
      left: -17px;
    }
    &:after {
      right: -15px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ni__product-banner {
    .ni__container {
      display: flex;
      max-width: map-get($breakpoints, 'max content');
    }
  }

  .ni__product-banner--image,
  .ni__product-banner--header > .parbase > div > div {
    width: 298px;
    height: 177px;
    right: 213px;
    left: auto;
    background-size: cover;
    background-position: top right;
  }

  .ni__product-banner--button {
    a:before {
      width: 19px;
      height: 27px;
    }
  }

  .ni__product-banner--header,
  .ni__product-banner--detail {
    height: 200px;
    width: 50%;
  }

  .ni__product-banner--detail {
    padding: 43px 0 0 83px;
  }

  .ni__product-banner--headline {
    font-size: 18px;
    max-width: 331px;
    margin-top: 0;
    margin-bottom: 30px;

    &:before {
      top: -20px;
    }
    &:after {
      bottom: -20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__product-banner--image,
  .ni__product-banner--header > .parbase > div > div {
    width: 420px;
    height: 162px;
    left: 0;
  }

  .ni__product-banner--button {
    margin-right: 38px;
  }

  .ni__product-banner--header,
  .ni__product-banner--detail {
    height: 162px;
  }

  .ni__product-banner--headline {
    margin-bottom: 0;
    font-size: 20px;
  }

  .ni__product-banner--detail {
    display: flex;
    align-items: center;
    padding-top: 0;
  }

  // avatar
  .ni__product-banner--profile {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 45px;
  }

  .ni__product-banner--avatar,
  .ni__product-banner--profile > .parbase > div > div {
    width: 65px;
    height: 65px;
    margin-bottom: 17px;
  }

  // aem background-image adjustment
  .ni__product-banner--profile > .parbase {
    margin-bottom: 17px;
  }

  // aem background-image adjustment
  .ni__product-banner--profile > .parbase div {
    width: 65px;
    height: 65px;
  }
}
