// ========================================================================
// Component: Hero
// ========================================================================

// settings
$NI__Hero__Mobile-padding: 19px;
$NI__Hero__Headline-color: #03b585;
$NI__Hero__Headline-font: map-get($fonts, 'serif');

.ni__hero {
  // prettier-ignore
  @if $UseVerticalPaddingOnHomepageComponents == true { padding-top: 56px; }
  padding-bottom: 32px;
  position: relative;
  overflow: hidden;
  min-height: 412px;

  @include breakpoint('xxsmall') {
    min-width: map-get($breakpoints, 'xxsmall');
  }

  .ni__hero-block {
    margin-top: 180px;
  }
}

// prettier-ignore
.ni__headline-h1 {
  color: $NI__Hero__Headline-color;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
  @include type-class('.ni__hero--1');

  // anim before slide
  opacity: 0;
  @include animation('fadeIn 1167ms cubic-bezier(0.4, 0, 0.23, 1) 800ms forwards');

  // adjustments per smaller CMS option
  &.ni__hero--3 { top: 32px; }
}

.ni__headline-h1 span {
  display: block;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(2) {
    // max-width: initial !important;
    text-align: right;
  }
}

// prettier-ignore
.ni__hero__header {
  padding: 0 $NI__Hero__Mobile-padding;
  max-width: 440px;
  @include breakpoint('small') { max-width: map-get($breakpoints, 'small'); }
  margin: 0 auto;
}

// prettier-ignore
.ni__hero__header span:last-child {
  display: block;
  padding-top: 15px;
  @include breakpoint('xxsmall') { padding-left: 12px; }
  position: relative;
}

.ni__hero__header .ni__headline-h1 {
  &.ni__hero--3 span:last-child {
    padding-top: 0;
  }
}

// prettier-ignore
.ni__hero__image__container {
  animation: overflowInImage 967ms ease-in 900ms forwards;
  height: 240px;
  max-width: map-get($breakpoints, 'max content');
  overflow: hidden;
  position: absolute;
  top: 169px;
  z-index: 1;

  @media (min-width: 500px) { height: 275px; }
  @media (min-width: 600px) { height: 315px; }
  @media (min-width: 650px) { height: 350px; }
  // @media (min-width: 625px) { height: 350px; }

  @include breakpoint('small') {
    height: 708px;
    top: 300px;
  }

  @include breakpoint('desktop small') {
    animation-delay: 0ms;
    top: 370px;
    height: 648px;
  }

  @include breakpoint('large') {
    top: 392px;
  }

  @include breakpoint('max content') {
    animation-duration: 800ms;
    height: 848px;
  }
}

// prettier-ignore
.ni__hero__image {
  animation: uk-scale-kenburns 3s ease-out 700ms 1 forwards;
  @include breakpoint('large') { animation: uk-scale-kenburns2 3s ease-out 700ms 1 forwards; }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% !important;
  @media (min-width: 500px) { background-position: 50% 120% !important; }
  @media (min-width: 545px) { background-position: 50% 90% !important; }
  @media (min-width: 585px) { background-position: 50% 80% !important; }
  @media (min-width: 600px) { background-position: 50% 110% !important; }
  @media (min-width: 650px) { background-position: 50% 140% !important; }
  @media (min-width: 685px) { background-position: 50% 120% !important; }
  @media (min-width: 700px) { background-position: 50% 90% !important; }
  @include breakpoint('small') { background-position: 80% !important; }
  height: 100%;
  width: 100vw;
  @include breakpoint('max content') { width: map-get($breakpoints, 'max content'); }
  position: absolute;
  z-index: -1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 25%;
    background-color: white;
    width: 100%;
    background: linear-gradient(#000000, transparent);
  }

  @keyframes uk-scale-kenburns {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.1);
    }
  }

  @keyframes uk-scale-kenburns2 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.2);
    }
  }
}

// .ni__hero .ni__hero-block__headline {
//   opacity: 0;
//   @include animation('fadeIn 1167ms cubic-bezier(0.4, 0, 0.23, 1) 600ms forwards');
// }

// mobile up
@include breakpoint('small') {
  .ni__hero .ni__hero-block {
    margin-top: 250px;
  }

  .ni__headline-h1.ni__hero--3 {
    top: 102px;
  }
}

// table up
@include breakpoint('desktop small') {
  .ni__headline-h1 {
    padding: 0 20px;
  }

  .ni__headline-h1 {
    opacity: 0;
    @include animation('fadeIn 500ms cubic-bezier(0.4, 0, 0.23, 1) 800ms forwards');
  }

  .ni__headline-h1.ni__hero--3 {
    top: 62px;
  }

  .ni__hero {
    // prettier-ignore
    @if $UseVerticalPaddingOnHomepageComponents == true { padding-top: 111px; }
    padding-bottom: 32px;
  }

  .ni__hero__header {
    max-width: map-get($breakpoints, 'grid');
    margin: 0 auto;
    padding: 0;

    span:last-child {
      text-align: right;
      position: relative;
      padding-top: 0;
    }
  }
}

// desktop up
@include breakpoint('large') {
  .ni__hero__header span:last-child {
    padding-left: 162px;
    text-align: left;
  }

  .ni__hero__header .ni__hero--3 span:last-child {
    padding-left: 130px;
  }

  .ni__hero {
    padding-top: 111px;
    padding-bottom: 88px;
  }

  // prettier-ignore
  .ni__headline-h1 {
    animation: none;
    opacity: 1;
    top: -32px;
    &.ni__hero--3 { top: 90px; }
  }

  .ni__headline-h1 span {
    animation: revealHeroText 900ms cubic-bezier(0.77, 0, 0.175, 1) 550ms forwards;
    opacity: 0;
    overflow: hidden;
    text-align: left;
    line-height: 1.05 !important;

    &:nth-child(2) {
      top: -15px;
    }
  }

  .ni__hero .ni__hero-block .ni__hero-block__divider:first-child {
    opacity: 0;
    @include animation('fadeIn 1167ms cubic-bezier(0.4, 0, 0.23, 1) 1400ms forwards');
  }

  // .ni__hero .ni__hero-block .ni__hero-block__headline {
  //   opacity: 0;
  //   @include animation('fadeIn 1167ms cubic-bezier(0.4, 0, 0.23, 1) 1400ms forwards');
  // }

  .ni__hero .ni__hero-block button:first-child {
    opacity: 0;
    @include animation('fadeIn 1167ms cubic-bezier(0.4, 0, 0.23, 1) 1500ms forwards');
  }

  .ni__hero .ni__hero-block button:last-child {
    opacity: 0;
    @include animation('fadeIn 500ms cubic-bezier(0.4, 0, 0.23, 1) 1800ms forwards');
  }

  .ni__hero .ni__hero-block .ni__hero-block__divider:last-child {
    opacity: 0;
    @include animation('fadeIn 500ms cubic-bezier(0.4, 0, 0.23, 1) 1800ms forwards');
  }
}

@keyframes overflowInImage {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes revealHeroText {
  0% {
    max-width: 0;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    max-width: 100%;
    opacity: 1;
  }
}
