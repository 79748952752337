.ni__educational-cards {
  background-color: var(--ni-green);
  position: relative;
}

// Green banner
// ========================================================================
.ni__green-banner {
  .ni__h12 {
    color: var(--forrest-green);
    margin-top: 32px;
  }

  .ni__col-md-4 {
    padding-left: 33px;
  }
}
.ni__green-banner .ni__container-fluid {
  padding: 0 15px;
}

.ni__green-banner .ni__green-cards--container {
  display: flex;
  overflow: scroll;
}

.ni__green-banner .ni__green--card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 318px;
  padding: 0 20px;
  &:nth-child(1) {
    padding-left: 0;
  }
}

.ni__green-banner .ni__green--card {
  &:nth-child(2) {
    border-left: 1px solid var(--power-green);
    border-right: 1px solid var(--power-green);
  }
}

.ni__green-banner--link {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 21px;
  margin-bottom: 34px;
  span {
    margin-right: 9px;
  }
}

@include media-breakpoint-up(sm) {
  .ni__green-banner .ni__green-cards--container {
    padding-left: 53px;
  }
}

@include media-breakpoint-up(md) {
  .ni__educational-cards {
    &:before {
      background-color: var(--ni-green);
      content: '';
      height: 450px;
      position: absolute;
      top: -200%;
      width: 100%;
      z-index: -1;
    }
  }
  .ni__green-banner .ni__green-cards--container {
    padding-left: 0;
    margin: 0;
    overflow: hidden;
  }
  .ni__green-banner .ni__green--card {
    max-width: 355px;
    padding: 0 33px;
    &:nth-child(1) {
      margin-left: 84px;
      max-width: 351px;
    }
  }
  .ni__green-banner--link {
    margin-bottom: 37px;
  }
}
