// ========================================================================
// Component: Accordion
// ========================================================================

// Settings
// ========================================================================
$Accordion__Item-Title--Color: '35b77d';
$Accordion__Item-Border--Color-Open: #35b77d;
$Accordion__Item-Border--Color-Closed: var(--pale-green);

// prettier-ignore
.ni__accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;

  //_____________________________________
  // Accordion => Item
  & > li {
    border-top: 1px solid $Accordion__Item-Border--Color-Closed;
    &.uk-open { border-color: $Accordion__Item-Border--Color-Open; }
    &:last-child { border-bottom: 1px solid $Accordion__Item-Border--Color-Closed; }
  }
  
  //_____________________________________
  // Accordion => Item => Link Title
  .uk-accordion-title {
    color: var(--forrest-green);
    display: block;
    padding: 24px 0;
  }
  
  //_____________________________________
  // Accordion => Item => Link Title => Span Visibility
  // 
  // Since you can't animate font-weight, I chose to show/hide
  // spans based on item's open/close state
  & > li {
    .ni__accordion-title--open {
      display: none;
      font-weight: 500;
    }

    .ni__accordion-title--open,
    .ni__accordion-title--closed {
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }
    }

    &.uk-open {
      .ni__accordion-title--open   { display: block; }
      .ni__accordion-title--closed { display: none; }
    }
  }
  
  //_____________________________________
  // Accordion => Item => Content Div
  .uk-accordion-content {
    padding: 5px 0 40px;
    p { color: var(--forrest-green); }
  }
  
  //_____________________________________
  // Accordion => Item => Content Div => Img
  .uk-accordion-content img,
  .uk-accordion-content div[style*='background-image'] {
    width: 100%
  }
}

// Accordion => Item => Link Title => Open/Close Bits
// ========================================================================
.ni__accordion {
  .uk-accordion-title:before {
    width: 18px;
    height: 18px;
  }

  .uk-accordion-title:before {
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23'+$Accordion__Item-Title--Color+'%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23'+$Accordion__Item-Title--Color+'%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    content: '';
    float: right;
    margin-left: 10px;
  }

  .uk-open > .uk-accordion-title:before {
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%23'+$Accordion__Item-Title--Color+'%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%3C%2Fsvg%3E');
  }
}
