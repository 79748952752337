/*!
 * Custom Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// @import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'custom-reboot';
@import 'bootstrap-grid';

@include media-breakpoint-down(md) {
  $padding: 20px;

  .ni__container {
    padding-left: $padding;
    padding-right: $padding;
  }

  .ni__row {
    margin-right: -$padding;
    margin-left: -$padding;
  }

  [class*='ni__col-'] {
    padding-right: $padding;
    padding-left: $padding;
  }
}
