// ========================================================================
// Molecules: Horizontal Scroller
//
// This is an alternative to plain old "overflow: scroll" where we provide
// some scroller arrows at the edges and suppress the normal scrollbar.
// ========================================================================

.ni__horizontal-scroller {
  position: relative;  // we need to be positioned because we want the arrows to use position:absolute

  .ni__horizontal-scroller--scrolled-content {
    overflow-x: scroll;
    scrollbar-width: none; // standard, but only works in Firefox at the moment

    &::-webkit-scrollbar { // non-standard, works everywhere except Firefox
      display: none;
    }
  }

  .ni__horizontal-scroller--left, .ni__horizontal-scroller--right {
    display: none;
  }

  &.ni__horizontal-scroller--can-scroll-left .ni__horizontal-scroller--left {
    display: block;
    position: absolute;
    padding-left: inherit;  // by inheriting padding-left, we make sure the "left" rule below offsets us relative to the content box of our parent
    left: 8px;
    height: 100%;
    width: 20px;
    box-sizing: content-box; // make sure the width is applied _on top of_ the padding
    object-fit: none;
    cursor: pointer;
    z-index: 100;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpolyline fill='none' stroke='%23009b65' stroke-width='1.03' points='13 16 7 10 13 4'%3E%3C/polyline%3E%3C/svg%3E");
  }

  &.ni__horizontal-scroller--can-scroll-right .ni__horizontal-scroller--right {
    display: block;
    position: absolute;
    padding-right: inherit;  // by inheriting padding-right, we make sure the "right" rule below offsets us relative to the content box of our parent
    right: 8px;
    height: 100%;
    width: 20px;
    box-sizing: content-box; // make sure the width is applied _on top of_ the padding
    object-fit: none;
    cursor: pointer;
    z-index: 100;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpolyline fill='none' stroke='%23009b65' stroke-width='1.03' points='7 4 13 10 7 16'%3E%3C/polyline%3E%3C/svg%3E");
  }

  --mask-left: linear-gradient(to right, rgb(0 0 0 / 0) 36px, rgb(0 0 0 / 1) 60px);
  --mask-right: linear-gradient(to left, rgb(0 0 0 / 0) 36px, rgb(0 0 0 / 1) 60px);

  &.ni__horizontal-scroller--can-scroll-left .ni__horizontal-scroller--scrolled-content {
    mask: var(--mask-left);
    -webkit-mask: var(--mask-left);
  }

  &.ni__horizontal-scroller--can-scroll-right .ni__horizontal-scroller--scrolled-content {
    mask: var(--mask-right);
    -webkit-mask: var(--mask-right);
  }

  &.ni__horizontal-scroller--can-scroll-left.ni__horizontal-scroller--can-scroll-right .ni__horizontal-scroller--scrolled-content {
    mask: var(--mask-left), var(--mask-right);
    mask-composite: intersect;
    -webkit-mask: var(--mask-left), var(--mask-right);
    -webkit-mask-composite: destination-in;
  }
}
