.ni__carousel-products {
  width: auto; /* HACK: overrides a width rule applied to .ni__carousel-tiled-images */
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 0;
  margin-top: 0;

  .ni__carousel-section-title {
    max-width: 100%;
    @media (max-width: 719px) {
      padding: 0 20px;
    }
  }

  .ni__carousel-content-container {
    padding: 31px 21px;
    h2 {
      color: var(--forrest-green);
      max-width: 220px;
    }
  }

  .ni__carousel-items > li:nth-child(3n + 1) {
    background-color: var(--pale-green);
    color: var(--forrest-green);
  }

  .ni__carousel-items > li:nth-child(3n + 2) {
    background-color: var(--ni-green);
    color: var(--forrest-green);
  }

  .ni__carousel-items > li:nth-child(3n + 3) {
    background-color: var(--ni-platinum);
    color: var(--forrest-green);
  }

  // The following two rules have an unnecessary "ul" element selector to push them slightly above the previous three in specificity.
  &.slide-background--off-white ul.ni__carousel-items > li {
    background-color: var(--off-white);
    color: var(--charcoal);

    :is(h1, h2, h3, h4, h5, h6) {
      color: inherit;
    }
  }

  &.slide-background--forest-green ul.ni__carousel-items > li {
    background-color: var(--forrest-green);
    color: var(--off-white);

    :is(h1, h2, h3, h4, h5, h6) {
      color: inherit;
    }
  }

  // image
  .ni__carousel-image-container {
    width: 100%;
    height: 183px;
  }

  .ni__carousel-image {
    background-position: right center;
  }

  .ni__carousel-eyebrow {
    padding: 15px 0 15px 15px;
  }

  .ni__carousel-bullets {
    width: 100%;
  }
}

// tablet
@include media-breakpoint-up(sm) {
  .ni__carousel-products {
    // 720px relation
    margin-left: 60px;
    margin-right: 60px;

    .glide__slide {
      border-top-left-radius: 20px;
      overflow: auto;
    }

    .ni__carousel-content-container {
      padding: 43px 51px 26px 47px;
    }

    // .ni__carousel-image {
    //   background-size: 80%;
    // }

    .ni__carousel-eyebrow {
      padding-left: 47px;
      padding-bottom: 39px;
      margin-bottom: 0;
    }

    .ni__carousel-bullets {
      margin-left: -70px;
    }
  }
}

// desktop
@include media-breakpoint-up(md) {
  .ni__carousel-products {
    // 1024px relation
    margin-left: 101px;
    margin-right: 0;

    .ni__carousel-content-container {
      padding: 50px 71px 26px 68px;
    }

    // .ni__carousel-image {
    //   background-size: 80%;
    // }

    .ni__carousel-eyebrow {
      padding-left: 68px;
    }

    .ni__carousel-controls .ni__button--controller-right {
      border-radius: 0 15px 10px 10px;
    }
  }
}

// Large
@include media-breakpoint-up(lg) {
  .ni__carousel-products {
    margin-left: 165px;
    width: 100%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}
