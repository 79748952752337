// ========================================================================
// Component: Carousel Blade
// ========================================================================

$NI__CarouselBlade-BG-Color: #f8f8f8;

.ni__blade-carousel {
  margin: 0;

  // bullets
  .ni__carousel-bullets {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .ni__carousel-container {
    background-color: var(--white-three);
  }

  .parbase {
    overflow: visible;
  }

  // hide for carousel: custom title
  .ni__blade-product--header {
    h2 {
      display: none;
    }

    h3 {
      margin-top: 74px;
    }
  }

  .ni__carousel-controls > button {
    @include button-style('.ni__button--controller');
  }
}

.ni__blade-carousel--title {
  position: relative;
  max-width: 333px;
  margin: 0 auto;

  & > h2 {
    color: var(--forrest-green);
  }
}

@include media-breakpoint-up(sm) {
  .ni__blade-carousel {
    // bullets
    .ni__carousel-bullets {
      margin-top: 40px;
    }

    .ni__blade-product--header h3 {
      margin-top: 55px;
    }
  }

  .ni__blade-carousel--title {
    max-width: 582px;
  }
}

@include media-breakpoint-up(md) {
  .ni__blade-carousel {
    margin: 0 auto;

    &.ni__carousel {
      max-width: 1140px;
    }

    .ni__carousel--container {
      padding-bottom: 78px;
      position: relative;
    }

    .ni__blade-product--header h3 {
      margin-top: 50px;
    }

    .ni__carousel-controls-container {
      position: absolute;
      bottom: 0;
      right: 99px;
      width: auto;
    }

    // bullets numbers
    .ni__carousel-counter {
      .ni__carousel-counter-current,
      .ni__carousel-counter-divider,
      .ni__carousel-counter-total {
        color: var(--forrest-green);
      }

      .ni__carousel-counter-divider,
      .ni__carousel-counter-total {
        opacity: 0.4;
      }
    }
  }

  .ni__blade-carousel--title {
    max-width: 1024px;
    margin-left: 99px;
  }
}

@include media-breakpoint-up(lg) {
  .ni__blade-carousel {
    padding-right: 15px;
    padding-left: 15px;

    &.ni__carousel {
      max-width: 1440px;
    }

    // themes
    &.is-light {
      background-color: $NI__CarouselBlade-BG-Color;
      padding-top: 220px;
      padding-bottom: 182px;
      border-top-right-radius: 400px;
    }

    .ni__carousel--container {
      margin: 0 auto;
      max-width: 1140px;
    }

    .ni__blade-product {
      min-height: 337px;
      max-width: 1140px;
      margin-left: 0;
      margin-right: auto;
    }

    .ni__blade-product--image .parbase div > div {
      margin-top: -172px;
      right: 0;
    }

    .ni__blade-product--header h3 {
      margin-top: 80px;
    }

    .ni__carousel-controls-container {
      right: 0;
    }
  }

  .ni__blade-carousel--title {
    max-width: 1110px;
    margin-left: 0;
  }
}

// @include media-breakpoint-down(xs) {
// }

// Only mobile
@include media-breakpoint-down(xs) {
  .ni__blade-carousel.is-light {
    overflow: hidden;
    background-color: $NI__CarouselBlade-BG-Color;
    padding-bottom: 66px;

    .ni__blade-product--header,
    .ni__blade-product--image {
      position: relative;
      &:before {
        content: '';
        width: 220%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -50%;
        background-color: var(--white-three);
        z-index: -1;
      }
    }

    .ni__blade-product--image {
      padding-bottom: 30px;
    }
  }
}
