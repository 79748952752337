// ========================================================================
// Component: PerspectiveHeadline
// ========================================================================

.ni__perspective-headline {
  width: 100%;
  display: block;
  position: relative;
  // margin-bottom: 40px;
  z-index: 1;

  .ni__perspective-article__title {
    @include type-class('.ni__h9');
  }

  .ni__perspective-article__num:after {
    height: 1px;
  }

  .ni__perspective-article__text {
    @include type-class('.ni__body-text--4');
  }
}

.ni__perspective-post {
  // background-color: var(--off-white);
  .is-post--feature {
    padding-bottom: 40px;
    .ni__perspective-article__num {
      display: none;
    }
  }
}

// table up
@include media-breakpoint-up(sm) {
  .ni__perspective-headline {
    max-width: map-get($breakpoints, 'grid');
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .is-article--feature {
      width: 100%;
    }

    .ni__perspective-article__title {
      margin-bottom: 0;
    }

    .ni__perspective-article__text {
      max-width: 409px;
    }
  }

  .ni__perspective-post {
    .is-post--feature {
      width: 100%;
      padding-bottom: 0;
    }

    // .ni__perspective-article__title {
    //   line-height: normal;
    //   font-size: 45px;
    // }

    .ni__perspective-article__image {
      height: 324px;
    }

    .ni__perspective-article__text {
      max-width: 395px;
    }
  }

  // .ni__perspective-headline__title {
  //   line-height: 1.14;
  //   font-size: 56px;
  // }
}
