$NI__aboutUsHeadline-path__w: 728px;
$NI__aboutUsHeadline-path__h: 1202px;

.ni__about-us-headline--path {
  position: relative;
  padding-bottom: 64px;

  @include media-breakpoint-up(tablet) {
    padding-bottom: 64px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 64px;
  }

  .ni__about-us-headline__h2 {
    @include type-class('.ni__h2');

    padding-bottom: 32px;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      padding-bottom: 48px;
    }
  }

  .ni__about-us-headline__text {
    @include type-class('.ni__body-text--2');

    padding-bottom: 64px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 16px;
    }
  }

  .ni__about-us-headline__image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    background-size: cover;
    img {
      width: 100%;
    }
  }

  .ni__button {
    margin-top: 24px;
  }

  .ni__about-us-headline__path {
    display: none;
    position: absolute;
    right: -90px;
    bottom: -190px;
    z-index: 0;
    pointer-events: none;
  }

  .ni__about-us-headline__line {
    font-size: 12px;
    font-family: map-get($fonts, 'mono');
    text-transform: uppercase;
    padding-left: 124px;
    position: absolute;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 7px;
      height: 3px;
      background-color: #151715;
      width: 124px;
      display: block;
    }
  }
}

.ni__about-us-headline__imageGroup {
  position: relative;
  //max-width: 374px;
  //min-height: 374px;
  width: 100%;
  height: 100%;
  margin-bottom: 6px;
}

.ni__about-us-headline__imageGroup--content {
  flex: 1;
  max-width: 50%;
  &:nth-child(odd) {
    margin-left: 50%;
  }
  .ni__about-us-headline__image {
    max-width: 100%;
  }
}

.ni__about-us-headline__imageGroup--text {
  padding-top: 16px;
  padding-left: 21px;

  span {
    color: $NI__aboutUsHeadline-color;
    letter-spacing: normal;
    display: block;
    @include type-class('.ni__mini-detail--1');
  }

  @include media-breakpoint-up(tablet) {
    padding-left: 0;
  }
}

// mobile up
@include breakpoint('small') {
  .ni__about-us-headline--path {
    min-height: 600px;

    @include media-breakpoint-up(tablet) {
      min-height: auto;
    }
  }
}

// tablet up
@include media-breakpoint-up(tablet) {
  .ni__about-us-headline--path {
    position: relative;

    .ni__about-us-headline__wrapper {
      position: relative;
    }
    .ni__about-us-headline__imageGroup--wrapper {
      max-width: 426px;
      width: 100%;
      position: absolute;
      top: 100px;
      right: 0px;

      @include media-breakpoint-up(lg) {
        max-width: 552px;
        top: 0;
      }
    }

    .ni__about-us-headline__imageGroup {
      max-width: 552px;
      @include media-breakpoint-up(lg) {
        max-width: 552px;
        min-height: 552px;
      }
    }
    .ni__about-us-headline__image {
      @include media-breakpoint-up(lg) {
        width: 276px;
        height: 279px;
      }
    }
    .ni__about-us-headline__imageGroup--content {
      @include media-breakpoint-up(lg) {
        max-width: 276px;
      }
    }
  }
}

// desktop up
@include breakpoint('large') {
  .ni__about-us-headline--path {
    // overflow: hidden;
    padding-top: 176px;
    padding-bottom: 176px;

    &.ni__about-us-headline .ni__aboutus__number {
      text-align: left;
    }
    .ni__about-us-headline__wrapper {
      min-height: 590px;
    }

    .ni__about-us-headline__path {
      display: block;
    }
  }
}

@include breakpoint('max content') {
  .ni__about-us-headline--path {
    max-width: map-get($breakpoints, 'max content');
    margin-left: auto;
    margin-right: auto;
  }
}
