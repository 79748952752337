// ========================================================================
// Utility: Type Classes
// @see resources/Typography_Pages from NI.com_UI_Kit_WIP_5.12.20.pdf
// @see https://docs.google.com/spreadsheets/d/1vu5JLU_mg6KTY7_swz7biVWFQyzmgZYYzaAYnalzZb8/edit?usp=sharing
// ========================================================================

/**
 * ===================
 * This mixin allows us to define font sizes for the
 * following type class easier as such: `@include fontsize(30, 40);`
 * ===================
 */
@mixin fontsize($fontSize, $lineHeight) {
  @if (str-index(#{$fontSize}, 'px')) {
    font-size: #{$fontSize} !important;
  } @else {
    font-size: #{$fontSize}px !important;
  }

  @if (str-index(#{$lineHeight}, 'px')) {
    line-height: #{$lineHeight} !important;
  } @else {
    line-height: #{$lineHeight}px !important;
  }
  
}

// BREAKPOINTS
$tcBP: (
  1: 'small',
  2: 'desktop small',
  3: 'large'
);

// HEROS
// ========================================================================
.ni__hero--1 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(70, 60);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(136, 144); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(160, 160); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(196, 174); }
}

.ni__hero--2 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(70, 60);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(120, 128); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(144, 120); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(152, 136); }
}

.ni__hero--3 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(32, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(80, 88); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(112, 120); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(120, 120); }
}

.ni__hero--4 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(32, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(60, 68); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(80, 90); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(100, 110); }
}


// HEADINGS (h1 - h7)
// ========================================================================
.ni__h1 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(60, 72);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(72, 80); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(80, 88); }
}

.ni__h2 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(40, 48);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(60, 72); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(72, 80); }
}

.ni__h3 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(40, 48);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(56, 64); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(60, 72); }
}

.ni__h4 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(40, 48); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(56, 64); }
}

.ni__h5 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(40, 48); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(40, 48); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(46, 56); }
}

.ni__h6 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(40, 48); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(40, 48); }
}

.ni__h7 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(36, 44);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(36, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(36, 44); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(36, 44); }
}

.ni__h8 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(36, 44);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(30, 40); }
}

.ni__h9 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(40, 44);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(46, 56); }
}

.ni__h10 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(46, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(56, 64); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(72, 80); }
}

.ni__h11 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(20, 26); }
}

.ni__h12 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(22, 24);
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(20, 22); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(24, 26); }
}


// Subheads
// ========================================================================
.ni__subhead--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(32, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(32, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(30, 40); }
}

.ni__subhead--2 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(30, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(30, 40); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(30, 40); }
}

.ni__subhead--3 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(24, 32);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(24, 32); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(24, 32); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(24, 32); }
}


// Body Text
// ========================================================================
.ni__body-text--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(24, 32);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(24, 32); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(32, 40); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(32, 40); }
}

.ni__body-text--2 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(18, 24);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(24, 32); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(24, 32); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(24, 32); }
}

.ni__body-text--3 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(18, 24);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 24); }
}

.ni__body-text--4 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(24, 32);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 24); }
}

.ni__body-text--5 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(16, 22);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 22); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 22); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 22); }
}


// Text Links
// ========================================================================
.ni__text-link--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  text-decoration: none;
  @include fontsize(18, 18);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(18, 18); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 18); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(24, 24); }
}

.ni__in-page-nav--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(14, 18);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 20); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 22); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(22, 26); }
}

.ni__in-page-nav--2 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(14, 18);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 20); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 22); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(22, 26); }
}


// Small Details
// ========================================================================
.ni__small-detail--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(16, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 16); }
}

.ni__small-detail--2 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(16, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 16); }
}

.ni__small-detail--3 {
  font-family: map-get($fonts, 'serif');
  font-weight: normal;
  @include fontsize(14, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 16); }
}

.ni__small-detail--4 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(14, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(14, 16); }
}

.ni__small-detail--5 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(14, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(14, 16); }
}

.ni__small-detail--6 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(16, 16);
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(14, 16); }
}

.ni__mini-detail--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(12, 12);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(12, 12); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(12, 12); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(12, 12); }
}

.ni__mini-detail--2 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(9, 8);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(9, 8); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(9, 8); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(9, 8); }
}

.ni__mini-detail--3 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(9, 8);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(9, 8); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(9, 8); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(9, 8); }
}

.ni__mini-detail--4 {
  font-family: map-get($fonts, 'sans');
  font-weight: normal;
  @include fontsize(12, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(12, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(12, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(12, 16); }
}


// Dropdowns
// ========================================================================
.ni__dropdown--1 {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(16, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 18); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 18); }
}

.ni__dropdown--2 {
  @extend .ni__dropdown--1;
  font-weight: normal;
}


// Eyebrows
// ========================================================================
.ni__number-eyebrow--1 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(48, 56);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(48, 56); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(48, 56); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(48, 56); }
}

.ni__number-eyebrow--2 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(32, 40);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(32, 40); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(32, 40); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(32, 40); }
}

.ni__number-eyebrow--3 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(56, 64);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(60, 72); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(72, 80); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(80, 88); }
}

.ni__eyebrow--1 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(18, 24);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 24); }
}

.ni__eyebrow--2 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(16, 16);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 16); }
}

.ni__eyebrow--3 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(12, 14);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(14, 16); }
}

.ni__eyebrow--4 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(12, 12);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 24); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 24); }
}

.ni__eyebrow--5 {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(12, 12);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(14, 16); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(16, 16); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(16, 16); }
}

.ni__eyebrow-detail {
  font-family: map-get($fonts, 'mono');
  font-weight: normal;
  text-transform: uppercase;
  @include fontsize(12, 12);
  @include breakpoint(map-get($tcBP, 1)) { @include fontsize(12, 12); }
  @include breakpoint(map-get($tcBP, 2)) { @include fontsize(12, 12); }
  @include breakpoint(map-get($tcBP, 3)) { @include fontsize(12, 12); }
}


// Button
// ========================================================================
.ni__button-text {
  font-family: map-get($fonts, 'sans');
  font-weight: 500;
  @include fontsize(18, 24px);
  // @include breakpoint(map-get($tcBP, 1)) { @include fontsize(18, 18); }
  // @include breakpoint(map-get($tcBP, 2)) { @include fontsize(18, 18); }
  // @include breakpoint(map-get($tcBP, 3)) { @include fontsize(18, 18); }
}


/**
 * ===================
 * This mixin allows us to @include a specific utility
 * type class defined above in any component.
 * ===================
 */
@mixin type-class($string) {
  @extend #{$string} !optional;
}
