// ========================================================================
// Component: PerspectiveDetailTitle
// ========================================================================

$NI__Breadcrumb__PaddingTop: 10px;
$NI__Headline__PaddingTop__Total--Mobile: 34px; // *1
$NI__Headline__PaddingTop__Total--Desktop: 56px; // *2

// *1 https://app.zeplin.io/project/5e606e6e13239d166416c25f/screen/5f3ed02012485e157b9cdc6e
// *2 https://app.zeplin.io/project/5e606e6e13239d166416c25f/screen/5f3ecedb9acaf930d324fb02

// @see https://organic-inc.atlassian.net/browse/NC-1391
.ni__perspective-detail-title {
  padding-top: calc(#{$NI__Headline__PaddingTop__Total--Mobile} - #{$NI__Breadcrumb__PaddingTop});
  padding-bottom: 40px;

  @include media-breakpoint-up(sm) {
    padding-top: calc(#{$NI__Headline__PaddingTop__Total--Desktop} - #{$NI__Breadcrumb__PaddingTop});
    padding-bottom: 60px;
  }
}

.ni__perspective-detail-title h1 {
  color: var(--forrest-green);
  font-stretch: normal;
  letter-spacing: normal;
  max-width: 847px;
  margin: 0;
  font-family: map-get($fonts, 'serif');
  font-weight: normal;

  @include type-class('.ni__h2');

  // @include fontsize(40, 45);

  // @include media-breakpoint-up(sm) {
  //   @include fontsize(46, 50);
  // }

  // @include media-breakpoint-up(md) {
  //   @include fontsize(72, 80);
  // }

  // @include media-breakpoint-up(lg) {
  //   @include fontsize(80, 90);
  // }
}
