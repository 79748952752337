// ========================================================================
// Component: ResourcesContactUs
// ========================================================================

$NI__ResourcesContactUs--Profile-Image-Size: 65px;



.ni__resources-contact-us {
  background-color: var(--off-white);
  padding: 64px 20px 69px 20px;

  @include media-breakpoint-up(sm) {
    padding: 81px 70px 63px 70px;
  }

  @include media-breakpoint-up(md) {
    margin: 0 auto;
    padding: 80px 79px 120px 169px;
    display: grid;
    grid-template-columns: 424px auto 325px;
    grid-template-areas:
      "header .    download"
      "help   help help";

    $supportedRatios: ((1,3), (1,2), (5,7), (1,1), (7,5), (2,1), (3,1));

    @each $ratio1, $ratio2 in $supportedRatios {
      &.ni__resources-contact-us--column-layout-#{$ratio1}_#{$ratio2} {
        grid-template-columns: #{$ratio1}fr #{$ratio2}fr;
        grid-template-areas:
          "header download"
          "help   help";
        column-gap: 30px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 120px 165px 120px 165px;
    grid-template-columns: 58% auto 325px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1440px;
    padding: 120px 165px 120px 261px;
  }

  h2,
  p,
  span,
  h3 {
    color: var(--forrest-green);
  }

  h2 {
    margin-bottom: 42px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 26px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 24px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }

  .ni__profile--content,
  .ni__molecule-content-module-body-container {
    width: 240px;
    color: var(--forrest-green);
    h3 {
      width: 240px;
    }
  }

  .ni__molecule-content-module-body-container h3 {
    margin: 0 0 5px 0;
  }

  .ni__resources-contact-us--header {
    grid-area: header;
    margin-bottom: 38px;

    p {
      @include media-breakpoint-up(sm) {
        max-width: 520px;
      }

      @include media-breakpoint-up(md) {
        max-width: none;
      }
    }

    .ni__resources-contact-us--button {
      .ni__button.ni__button--outline {
        margin-top: 30px;
      }
    }
  }

  .ni__profile {
    h4 {
      display: block;
      position: relative;
      top: -4px;
      @include display-flex(column nowrap, flex-start, flex-start);
      @include type-class('.ni__subhead--3');
      line-height: 1 !important;
    }

    .ni__profile--image {
      .parbase {
        height: $NI__ResourcesContactUs--Profile-Image-Size;
        width: $NI__ResourcesContactUs--Profile-Image-Size;

        div {
          > div {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-top-left-radius: 22px;
            display: block;
            height: $NI__ResourcesContactUs--Profile-Image-Size;
            width: $NI__ResourcesContactUs--Profile-Image-Size;
          }

          > img {
            height: $NI__ResourcesContactUs--Profile-Image-Size;
            width: $NI__ResourcesContactUs--Profile-Image-Size;
          }
        }
      }
    }

    &.is-help {
      grid-area: help;
    }

    &.is-download {
      grid-area: download;
      @include media-breakpoint-up(md) {
        margin-bottom: 38px;
        margin-top: 120px !important;
      }

      @include breakpoint('desktop') {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-top: 50px;

      @include media-breakpoint-up(sm) {
        margin-top: 42px;
      }
    }

    // prettier-ignore
    .ni__profile--content {
      box-sizing: border-box;
      width: 100%;
      @include display-flex(column nowrap, flex-start, space-between);

      span {
        width: 100%;
        display: block;
        @include type-class('.ni__small-detail--1');
        line-height: 1 !important;
      }

      a,
      span {
        word-break: break-word;
      }

      span {
        font-size: 14px !important;

        @include media-breakpoint-up(sm) {
          font-size: 16px !important;
        }
      }

      .ni__profile--help-links {
        align-items: flex-end;
        bottom: -1px;
        display: flex;
        flex-flow: row nowrap !important;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md) {
          align-items: flex-start;
        }

        a,
        span {
          display: block;
          @include type-class('.ni__small-detail--1');
          line-height: 1 !important;
          @include breakpoint('small') { width: auto; }
        }

        & > a:first-of-type {
          font-size: 14px !important;

          @include media-breakpoint-up(sm) {
            font-size: 16px !important;
          }
        }

        & > a:last-of-type {
          font-size: 12px !important;

          @include media-breakpoint-up(sm) {
            font-size: 16px !important;
          }
        }
      }

      .ni__profile--help-links > .ni__profile--divider {
        display: inline-block;
        font-size: 14px !important;
        width: 1px;

        @include media-breakpoint-up(sm) {
          font-size: 16px !important;
        }
      }
    }
  }
}

// THEME

.ni__resources-contact-us.theme-lightgrey,
.ni__resources-contact-us.theme-lightgray {
  background-color: var(--off-white);
}
