// ========================================================================
// Component: Content with Text Stats
// ========================================================================

// settings
// ========================================================================

// styles
// ========================================================================
// prettier-ignore
.ni__content-with-text-stats {
  h2, p { 
    color: var(--forrest-green); 
    max-width: 400px;
  }

  h2 {
    margin-bottom: 24px;
    @include breakpoint('desktop') { margin-bottom: 26px; }
    @include breakpoint('large')   { margin-bottom: 32px; }
  }
  
  p + .ni__content-with-text-stats-button {
    margin-top: 40px;
  }

  .ni__content-with-text-stats-button div,
  .ni__content-with-text-stats-button span {
    width: 100%;
  }

  .ni__button {
    margin-right: auto;
    width: 100%;

    // widths ----------------------------------------
                                     max-width: 100%;
    @include breakpoint('small')   { max-width: 335px; }
    @include breakpoint('desktop') { max-width: 350px; }
    @include breakpoint('large')   { max-width: 350px; }
  }

  .ni__content-with-text-stats-copy-column {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    // vertical alignment of headline/copy/cta column -----------
                                     justify-content: flex-start;
    @include breakpoint('desktop') { justify-content: flex-end; }
    @include breakpoint('large')   { justify-content: center;   }
  }

  .ni__content-with-text-stats-item {
    position: relative;

    // top margin of first item ----------------------
                                     margin-top: 32px;
    @include breakpoint('small')   { margin-top: 48px; }
    @include breakpoint('desktop') { margin-top: 0;    }
  }

  .ni__content-with-text-stats-item h3 {
    color: var(--ni-green);
    margin: 0;

    // kerning ----------------------------------------------
                                     letter-spacing: -0.04px;
    @include breakpoint('desktop') { letter-spacing: -0.05px; }
    @include breakpoint('large')   { letter-spacing: -0.06px; }
  }

  // prettier-ignore
  .ni__content-with-text-stats-item h3 span {
    &[data-value=","] { margin: 0 -0.205em; }
    &[data-value="$"] { margin-right: -0.115em; }
    &[data-value="+"] { margin-left: -0.0625em; }
  }

  .ni__content-with-text-stats-item p {
    color: var(--forrest-green);
  }

  .ni__content-with-text-stats-item + .ni__content-with-text-stats-item {
    box-sizing: border-box;

    // spacing before and after pseudo horizontal line ------------------
                                     padding-top: 16px; margin-top: 16px;
    @include breakpoint('desktop') { padding-top: 15px; margin-top: 18px; }
    @include breakpoint('large')   { padding-top: 24px; margin-top: 24px; }
  }

  .ni__content-with-text-stats-item + .ni__content-with-text-stats-item:before {
    content: '';
    background: var(--ni-green);
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;

    // widths ------------------------------------
                                     width: 246px;
    @include breakpoint('desktop') { width: 221px; }
    @include breakpoint('large')   { width: 256px; }
  }
}

// Icon based on top-level class
// ========================================================================
// prettier-ignore
.ni__content-with-text-stats {
  &--download .ni__button span,
  &--paper .ni__button span,
  &--video .ni__button span {
    display: block;
    position: relative;
    width: 100%;

    // padding -----------------------------------------
                                     padding-left: 30px;
    @include breakpoint('desktop') { padding-left: 36px; }
  }

  //_____________________________________
  // icon => download
  &--download .ni__button {
    span:before {
      content: '';
      width: 22px;
      height: 23px;
      position: absolute;
      top: 10px;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 23 23'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H23V23H0z' transform='translate(-371 -406) translate(101) translate(246 389) translate(24 17)'/%3E%3Cpath fill='%23f4f4f4' d='M19.167 14.375c0-.53.429-.958.958-.958.53 0 .958.429.958.958v3.833c0 1.588-1.287 2.875-2.875 2.875H4.792c-1.588 0-2.875-1.287-2.875-2.875v-3.833c0-.53.429-.958.958-.958.53 0 .958.429.958.958v3.833c0 .53.43.959.959.959h13.416c.53 0 .959-.43.959-.959v-3.833zm-8.625-2.314V2.875c0-.53.429-.958.958-.958.53 0 .958.429.958.958v9.186l3.156-3.155c.374-.375.981-.375 1.355 0 .375.374.375.98 0 1.355l-4.791 4.792c-.375.374-.981.374-1.356 0L6.031 10.26c-.375-.374-.375-.981 0-1.355.374-.375.98-.375 1.355 0l3.156 3.155z' transform='translate(-371 -406) translate(101) translate(246 389) translate(24 17)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  //_____________________________________
  // icon => paper
  &--paper .ni__button {
    span:before {
      content: '';
      width: 19px;
      height: 21px;
      @include breakpoint('small') { height: 22px; }
      position: absolute;
      left: 0;
      top: 10px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3Cpath fill='%23f4f4f4' d='M13 4.18V8c0 .552.448 1 1 1h3.82c-.098-.216-.235-.415-.406-.586l-3.828-3.828c-.171-.171-.37-.308-.586-.406zM18 11h-4c-1.657 0-3-1.343-3-3V4H8c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h8c1.105 0 2-.895 2-2v-7zm-5.328-9c.53 0 1.039.21 1.414.586l5.328 5.328c.375.375.586.884.586 1.414V18c0 2.21-1.79 4-4 4H8c-2.21 0-4-1.79-4-4V6c0-2.21 1.79-4 4-4h4.672z' transform='translate(-260 -41) translate(165) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  //_____________________________________
  // icon => video
  &--video .ni__button {
    span:before {
      content: '';
      width: 19px;
      height: 13px;
      position: absolute;
      left: 0;
      top: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill-rule='nonzero'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M0 0H24V24H0z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3Cpath fill='%23f4f4f4' d='M5.564 6h7.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v6.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267-.327-.174-.583-.43-.757-.756-.174-.326-.267-.65-.267-1.54V8.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267zM17 10.333l2.36-1.966c.424-.354 1.055-.296 1.408.128.15.18.232.406.232.64v5.73c0 .552-.448 1-1 1-.234 0-.46-.082-.64-.232L17 13.667v-3.334z' transform='translate(-355 -526) translate(260 105) translate(0 380) translate(95 41)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
