// ========================================================================
// Component: Price
// ========================================================================

.ni__price-container {
  .price {
    color: var(--forrest-green);
    font-family: FoundersGrotesk;
    font-size: 18px;
    margin-bottom: 10px;

    .product-price-loader,
    .product-price-from {
      display: none;

      &.show {
        display: flex !important;
      }
    }

    .strikethrough {
      color: #05968e;
      margin: 0 3px;
      padding: 0 3px;
      position: relative;
      text-decoration: line-through;

      &:after {
        background-color: #05968e;
        bottom: 0;
        content: '';
        height: 0.5px;
        left: 0;
        position: absolute;
        text-decoration: line-through;
        top: 60%;
        transform: translateY(-60%);
        width: 100%;
      }
    }
  }

  .disclaimer {
    display: none;
    margin-bottom: 20px;

    &.show {
      display: flex !important;
    }

    .icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 17 17'%3E%3Cpath fill='%2303B585' fill-rule='evenodd' d='M1.43 8.765c0 3.682 2.984 6.667 6.666 6.667 3.682 0 6.667-2.985 6.667-6.667S11.778 2.1 8.096 2.1 1.43 5.083 1.43 8.765zm12 0c0 2.946-2.388 5.334-5.334 5.334-2.945 0-5.333-2.388-5.333-5.334 0-2.945 2.388-5.333 5.333-5.333 2.946 0 5.334 2.388 5.334 5.333zm-6 0c0-.368.298-.666.666-.666.369 0 .667.298.667.666v2.667c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667V8.765zm-.167-2.5c0 .46.373.834.833.834.46 0 .834-.373.834-.834 0-.46-.373-.833-.834-.833-.46 0-.833.373-.833.833z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      height: 17px;
      margin-right: 5px;
      width: 17px;
    }

    .text {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
    }
  }

  .error {
    display: none;
    margin-bottom: 0 !important;
    padding-top: 4px;

    .icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 17 17'%3E%3Cpath fill='%2303B585' fill-rule='evenodd' d='M1.43 8.765c0 3.682 2.984 6.667 6.666 6.667 3.682 0 6.667-2.985 6.667-6.667S11.778 2.1 8.096 2.1 1.43 5.083 1.43 8.765zm12 0c0 2.946-2.388 5.334-5.334 5.334-2.945 0-5.333-2.388-5.333-5.334 0-2.945 2.388-5.333 5.333-5.333 2.946 0 5.334 2.388 5.334 5.333zm-6 0c0-.368.298-.666.666-.666.369 0 .667.298.667.666v2.667c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667V8.765zm-.167-2.5c0 .46.373.834.833.834.46 0 .834-.373.834-.834 0-.46-.373-.833-.834-.833-.46 0-.833.373-.833.833z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      height: 13.5px;
      margin-right: 4px;
      margin-top: 2px;
      padding: 0;
      width: 13.5px;
    }

    .text {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: 500;
      letter-spacing: normal;
      line-height: 16px;
    }

    &.show {
      display: flex !important;
    }
  }

  .distributor {
    display: none;
    margin-bottom: 20px;

    &.show {
      display: flex !important;
      flex-wrap: wrap;
    }

    .icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 17 17'%3E%3Cpath fill='%2303B585' fill-rule='evenodd' d='M1.43 8.765c0 3.682 2.984 6.667 6.666 6.667 3.682 0 6.667-2.985 6.667-6.667S11.778 2.1 8.096 2.1 1.43 5.083 1.43 8.765zm12 0c0 2.946-2.388 5.334-5.334 5.334-2.945 0-5.333-2.388-5.333-5.334 0-2.945 2.388-5.333 5.333-5.333 2.946 0 5.334 2.388 5.334 5.333zm-6 0c0-.368.298-.666.666-.666.369 0 .667.298.667.666v2.667c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667V8.765zm-.167-2.5c0 .46.373.834.833.834.46 0 .834-.373.834-.834 0-.46-.373-.833-.834-.833-.46 0-.833.373-.833.833z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      height: 17px;
      margin-right: 5px;
      width: 17px;
      flex-shrink: 0;
    }

    .text {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
    }

    .find-distributor-text {
      color: var(--forrest-green);
      font-family: FoundersGrotesk;
      font-size: 14px;
      font-weight: 1;
      line-height: 1.14;
      margin-left: 22px;
    }

    .find-distributor-text a {
      text-decoration: underline;
    }
  }
}
