.long-form-container {
  padding-bottom: 178px;
  padding-top: 56px;

  .ni__block-quote {
    margin: 0 auto 31px;
    max-width: 335px;

    @include media-breakpoint-up(sm) {
      margin: 0 auto 23px auto;
      max-width: 544px;
    }

    @include media-breakpoint-up(md) {
      margin: 0 auto 34px auto;
      max-width: 764px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 auto 24px auto;
      max-width: 1000px;
    }

    .ni__block-quote--content {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      max-width: 306px;

      @include media-breakpoint-up(sm) {
        max-width: 484px;
      }

      @include media-breakpoint-up(md) {
        max-width: 684px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 920px;
      }

      h4 {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 0;

        @include media-breakpoint-up(sm) {
          font-size: 24px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 30px;
          line-height: 40px;
        }
      }

      .ni__block-quote--author {
        margin-right: 33px;
        padding-top: 16px;

        @include media-breakpoint-up(sm) {
          margin-right: 25px;
          padding-top: 15px;
        }

        @include media-breakpoint-up(md) {
          margin-right: 8px;
          padding-top: 14px;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 51px;
        }

        strong {
          font-weight: 500;
        }

        .name,
        .description {
          color: var(--forrest-green);
          font-family: FoundersGrotesk;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .ni__button--secondary {
    background: $NI__Button-Secondary-BG-Color;
    color: $NI__Button-Secondary-Text-Color;
    &:focus {
      background: $NI__Button-Secondary-BG-Color;
      color: $NI__Button-Secondary-Text-Color;
    }
    &:hover {
      background: var(--ni-green);
    }
    &:active {
      background: $NI__Button-Secondary-BG-Color;
      color: $NI__Button-Secondary-Text-Color;
    }
  }
  .ni__eyebrow {
    padding-bottom: 40px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 53px;
    }
  }
  .rich-text {
    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20px;
    }
    p {
      @include type-class('.ni__body-text--3');
      color: var(--forrest-green);
      margin: 0 0 32px 0;
      @include media-breakpoint-up(sm) {
        margin: 0 0 42px 0;
      }
    }
    p strong {
      font-weight: 500;
    }
    p i {
      font-style: italic;
    }
    h1 {
      @include type-class('.ni__h4');
      color: var(--forrest-green);
      margin: 0 0 18px 0px;
    }
    h2 {
      @include type-class('.ni__h5');
      color: var(--forrest-green);
      margin: 0 0 18px 0px;
    }
    h3 {
      @include type-class('.ni__h6');
      color: var(--forrest-green);
      margin: 0 0 18px 0px;
    }
    h4 {
      color: var(--forrest-green);
      font-family: map-get($fonts, 'serif');
      font-size: 22px;
      font-weight: normal;
      margin: 0 0 18px 0px;
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }
    h5 {
      @include type-class('.ni__subhead--2');
      color: var(--forrest-green);
      font-weight: 500;
      margin: 0 0 18px 0px;
    }
    h6 {
      @include type-class('.ni__subhead--3');
      color: var(--forrest-green);
      font-weight: 500;
      margin: 0 0 18px 0px;
    }
    ol,
    ul {
      counter-reset: ol-counter;
      list-style: none;
      margin: 20px 0 32px 0;
      padding-left: 0;
      @include media-breakpoint-up(sm) {
        margin: 20px 0 40px 0;
      }
    }
    ol li,
    ul li {
      color: var(--forrest-green);
      font-family: map-get($fonts, 'sans');
      font-weight: normal;
      font-size: 18px;
      letter-spacing: normal;
      line-height: 24px;
      font-stretch: normal;
      font-style: normal;
      counter-increment: ol-counter;
      margin: 0 0 10px 0;
      padding-left: 20px;
      position: relative;
      &::before {
        color: #03b585;
        content: counter(ol-counter) '. ';
        font-family: $fontFamilyMono;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        left: 0;
        letter-spacing: normal;
        line-height: 1.14;
        position: absolute;
        top: 4px;
      }
    }
    ul li::before {
      background-color: #03b585;
      content: '';
      height: 8px;
      left: 0;
      top: 9px;
      width: 8px;
    }
    li > ul,
    li > ol {
      margin: 0 0 8px 0;
    }
    li > ul > li,
    li > ol > li {
      margin: 4px 0;
    }
    li > ul > li:last-child,
    li > ol > li:last-child {
      margin: 4px 0 8px 0;
    }
    ul li > ul li::before,
    ol li > ol li > ol li::before,
    ul li > ol li::before,
    ol li > ul li > ul li::before {
      background-color: #ccdcc8;
    }
    b,
    strong {
      font-weight: inherit;
    }
  }
}
