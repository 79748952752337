// ========================================================================
// Component: BannerPullQuote
// ========================================================================

.ni__banner-pull-quote {
  width: 100%;
  background-color: #cddcc8;
  margin-bottom: 52px;

  .ni__button {
    margin-top: 30px;
  }

  b {
    font-size: 18px;
    line-height: 0.5;
    font-weight: 500;
  }

  p {
    font-family: $fontFamilySans;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--forrest-green);
  }
}

.ni__banner-pull-quote--content {
  padding: 28px 22px 36px 22px;

  p {
    @include type-class('.ni__body-text--2');
  }

  p b,
  p strong {
    @include type-class('.ni__button-text');
  }
}

.ni__banner-pull-quote--image {
  width: 100%;
  height: 170px;
  background-size: cover;
  display: block;
}

//temp
.ni__pull-graphic {
  text-align: center;
  margin: 50px 0;
}

// table up
@include media-breakpoint-up(md) {
  .ni__banner-pull-quote {
    max-width: 235px;
  }

  .ni__pull-graphic {
    margin-top: 0;
  }
}

// table up
@include media-breakpoint-up(lg) {
  .ni__banner-pull-quote {
    max-width: 257px;
  }
}
