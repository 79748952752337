/**
 * Allows us to include animations as mixins.
 * 
 * @mixin keyframes
 * @param {String} $animation-name - Animation name
 * -----------------
 * @mixin animation
 * @param {String} $string - Animation name
 * 
 * @see https://codepen.io/ImagineProgramming/pen/HzwfJ
 * 
 * @example
 * ```
 * .class {
 *      @include animation('shrink-bounce-alt 600ms cubic-bezier(0.4, 0, 0.23, 1)');
 * }
 * ```
 */
// prettier-ignore
@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($string) {
  animation: #{$string};
}

/**
* @keyframes shrink-bounce
* @keyframes shrink-bounce-alt
* @keyframes shrink-bounce-fade-in
*/
// prettier-ignore
@include keyframes(shrink-bounce) {
  0% { transform: scale(0); }
  5% { display: inline-block; }
  33% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

// prettier-ignore
@include keyframes(shrink-bounce-alt) {
  0% { transform: scale(1); }
  33% { transform: scale(0.5); }
  66% { transform: scale(1.1875); }
  100% { transform: scale(1); }
}

// prettier-ignore
@include keyframes(shrink-bounce-fade-in) {
  0% { display: none; opacity: 0; transform: scale(0); }
  5% { display: inline-block; }
  33% { transform: scale(1.5); }
  100% { opacity: 1; transform: scale(1); }
}

/**
* @keyframes grow-bounce
*/
// prettier-ignore
@include keyframes(grow-bounce) {
  0%   { transform: scale(0.1); }
  66%  { transform: scale(1.5); }
  100% { transform: scale(1); }
}

/**
* @keyframes dot
*/
// prettier-ignore
@include keyframes(dot) {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

/**
* @keyframes fadeIn
*/
// prettier-ignore
@include keyframes(fadeIn) {
  from { opacity: 0; }
  to { opacity: 1; }
}

/**
* @keyframes draw
*/
// prettier-ignore
@include keyframes(draw) {
  from { width: 0; }
  to { width: 2000px; }
}

/**
* A pulsing placeholder animation.
* 
* @mixin animation-pulse
*/
// prettier-ignore
@mixin animation-pulse {
  @keyframes placeHolderPulse {
      from { opacity: 0.6; }
      to { opacity: 0.9; }
  }

  animation: placeHolderPulse 0.8s ease-in-out infinite;
  animation-direction: alternate-reverse;
}

/**
* A Facebook-style loading placeholder animation.
* 
* @mixin animation-swipe
* @see [CodePen]{@link https://codepen.io/pjosh/pen/kXwaWZ}
*/
// prettier-ignore
@mixin animation-swipe {
  @keyframes placeHolderSwipe {
      from { background-position: -600px 0; }
      to { background-position: 600px 0; }
  }

  animation: placeHolderSwipe 1s infinite linear forwards;
  background-color: var(--gray-100);
  background-image: linear-gradient(
      to right,
      #f3f3f3 0%,
      #f0f0f0 20%,
      #eeeeee 40%,
      #f8f8f8 100%
  );
  background-repeat: no-repeat;
}

/**
* Fade content in from a direction
* Forked via UIkit @see https://getuikit.com/docs/animation
*
* @param $direction: top, right, bottom, left
* @param $size: small, medium, large
*
* Usage example: @include animation-fade-in(left, small);
*/
// prettier-ignore
@mixin animation-fade-in($direction: bottom, $size: small) {
  animation-name: uk-fade-#{$direction}-#{$size};
  animation-duration: 500ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}

@keyframes uk-fade-top-small {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-bottom-small {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-left-small {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes uk-fade-right-small {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes uk-fade-top-medium {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-bottom-medium {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-left-medium {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes uk-fade-right-medium {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes uk-fade-top-large {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-bottom-large {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes uk-fade-left-large {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes uk-fade-right-large {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
