// ========================================================================
// Component: FeatureHighlights
// ========================================================================
.ni__feature-highlights {
  margin-bottom: 100px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }
}

// Component => Bootstrap Container
// ========================================================================
.ni__feature-highlights .ni__container {
  @include media-breakpoint-up(sm) {
    padding: 0 70px 0 70px;
  }
  @include media-breakpoint-up(md) {
    padding: 0 15px 0 15px;
  }
}

.ni__feature-highlights .ni__feature-highlights--column {
  @include media-breakpoint-up(md) {
    padding-top: 70px;
    padding-bottom: 80px;
    margin-top: auto;
    margin-bottom: auto;
  }

  @include media-breakpoint-up(md) {
    padding-top: 90px;
    padding-bottom: 65px;
  }
}

// Title => Text Element
// ========================================================================
// prettier-ignore
.ni__feature-highlights .ni__feature-highlights--headline {
  color: var(--forrest-green);
  margin-top: 50px;
  margin-bottom: 20px;
  
  @include media-breakpoint-up(sm) { margin-top: 60px; }
  
  @include media-breakpoint-up(md) {
    margin-top: 0px;
    margin-bottom: 25px; 
  }
}

// Text => Text Element
// ========================================================================
// prettier-ignore
.ni__feature-highlights .ni__feature-highlights--headline + p {
  color: var(--forrest-green);
}

// Text => Link Element
// ========================================================================
// prettier-ignore
.ni__feature-highlights a {
  display: block;
  color: var(--forrest-green);
  margin: 20px 0 0 0;
  padding-bottom: 10px;
  border-bottom: solid 1px var(--pale-green);
  position: relative;

  @include type-class('.ni__body-text--3');
  @include media-breakpoint-up(md) { margin: 25px 0 0 0; }
}

.ni__feature-highlights a:after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg stroke='%2303B585' stroke-width='1.371'%3E%3Cg%3E%3Cpath d='M.514 5.021L10.8 5.021M7.37 9.8L12.171 5.021 7.37.469' transform='translate(-638 -1372) translate(639 1373)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  height: 12px;
  width: 15px;
  position: absolute;
  right: 0;
  bottom: 16px;
}

// Text => Separators
// ========================================================================
// prettier-ignore
.ni__feature-highlights .ni__feature-highlights--column-separator {
  display: none;
  @include media-breakpoint-up(md) { display: block; }
  background-color: var(--ni-green);
  width: 4px;
  @include media-breakpoint-up(lg) { width: 6px; }
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.ni__feature-highlights .ni__feature-highlights--feature-separator {
  @include media-breakpoint-up(md) {
    height: 40px;
    width: 10px;
  }
}

// Image => Container
// ========================================================================
.ni__feature-highlights .ni__feature-highlights--image-component-container {
  margin-bottom: 41px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 35px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 42px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 48px;
  }
}

// Image => Image
// ========================================================================
.ni__feature-highlights .ni__feature-highlights--image-component-container img {
  width: 100%;
}
