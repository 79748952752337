// ========================================================================
// Component: AboutUsHeadline
// ========================================================================

// settings
$NI__aboutUsHeadline-color: var(--forrest-green);
$NI__aboutUsHeadline-font: map-get($fonts, 'serif');
$NI__AboutUsValues-BG-Color: var(--gray-20);

.ni__about-us-headline {
  background: $NI__AboutUsValues-BG-Color;
  margin: 0 auto;
  padding-top: 32px;

  .ni__aboutus__number {
    padding-bottom: 5px;
    padding-top: 0;
    display: inline-block;
  }
}

.where-we-are-headed.parbase,
.whats-next.parbase {
  overflow: visible !important;
}

.ni__about-us-headline {
  padding-bottom: 32px;
}

.ni__about-us-headline__h2 {
  color: $NI__aboutUsHeadline-color;
  font-family: $NI__aboutUsHeadline-font;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-bottom: 16px;
  white-space: pre-line;
}

.ni__about-us-headline__text {
  font-family: map-get($fonts, 'sans');
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #044022;
}

// mobile up
@include media-breakpoint-up(xxxs) {
  .ni__about-us-headline__h2 {
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 25px;
  }
}

// mobile up
@include media-breakpoint-up(lg) {
  .ni__about-us-headline__wrapper {
  }

  .ni__about-us-headline {
    padding-top: 88px;
    padding-bottom: 88px;

    .ni__aboutus__number {
      position: relative;
      text-align: right;
    }
  }
}

// override per screenshare session
// do not touch
@media (min-width: 1200px) and (max-width: 1299px) {
  .ni__aboutus__number {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 0 !important;
  }
}
