// ========================================================================
// Component: HeroBlock
// ========================================================================

// settings
$NI__HeroBlock-Color: var(--forrest-green);
$NI__HeroBlock-font: map-get($fonts, 'serif');

.ni__hero-block {
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.ni__hero-block__content {
  background-color: #ccdcc8;
  max-width: 356px;
  width: 94.933vw;
  padding: 27px 33px;

  @media (min-width: 425px) {
    max-width: none;
    width: calc(100% - 60px);
  }

  @include breakpoint('xxsmall') {
    width: calc(100% - 16px);
  }
}

.ni__hero-block__headline {
  color: $NI__HeroBlock-Color;
  margin: 0 0 40px;
  padding: 12px 0 0;
  @include type-class('.ni__h4');
}

// divider
.ni__hero-block__divider {
  background-color: $NI__HeroBlock-Color;
  height: 1px;
  width: 100%;
  display: block;
}

// buttons
// @todo make buttons flex horz ASAP on viewport size
// prettier-ignore
.ni__hero-block__buttons {
  & > div {
    margin-bottom: 40px;
  }

  @media (min-width: 475px) {
    & > div {
      flex: 0;
      width: auto;
    }
  }

  .ni__button {
    display: inline-block;
    min-width: 0;
  }
}

// mobile up
@include breakpoint('small') {
  .ni__hero-block__content {
    width: calc(100% - 164px);
    padding: 48px;
  }
}

// mobile up
@include breakpoint('desktop small') {
  .ni__hero-block {
    max-width: map-get($breakpoints, 'grid');
    width: 100%;
    display: block;
  }

  .ni__hero-block__content {
    max-width: 833px;
    padding-left: 68px;
    padding-right: 68px;
  }

  .ni__hero-block__headline {
    max-width: 100%;
  }
}

// desktop up
@include breakpoint('max content') {
  .ni__hero-block__content {
    max-width: 866px;
  }
}
