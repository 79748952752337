// ========================================================================
// Component: HeroImages
// ========================================================================

.ni__hero-images {
  margin-top: 40px;

  h2 {
    color: var(--forrest-green);
    position: relative;
    padding-bottom: 45px;
  }

  p {
    color: var(--forrest-green);
    margin-top: 24px;
  }

  .ni__button {
    margin-top: 55px;
    position: relative;
    z-index: 10;
  }
}

.ni__hero-images--container {
  background-image: linear-gradient(180deg, var(--white-three) 53%, var(--off-white));
  margin-left: -20px;
  position: relative;
  height: 267px;
  width: 100vw;
}

.ni__hero-images--src1,
.ni__hero-images--container .parbase div {
  // background-color: #f4f4f4;
  width: 296px;
  height: 242px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px 45px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  transition: 400ms ease-out;
  transition-property: background-position, height, width;
  will-change: background-position, height, width;

  @media (min-width: 425px) {
    width: 90%;
    height: 100%;
    background-position: left center;
  }

  @media (min-width: 475px) {
    width: 85%;
  }
}

// table up
@include media-breakpoint-up(sm) {
  .ni__hero-images {
    .ni__button {
      margin-top: 40px;
    }
  }

  .ni__hero-images--container {
    margin-left: 0;
    width: 100%;
  }

  .ni__hero-images--src1,
  .ni__hero-images--container .parbase div {
    width: 100%;
    background-size: 69%;
    background-position: 0 10px;
  }
}

@include media-breakpoint-up(md) {
  .ni__hero-images > .ni__container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .ni__hero-images {
    margin-top: 56px;

    h2 {
      max-width: 285px;
      padding-bottom: 17px;
    }

    p {
      max-width: 329px;
    }
  }

  .ni__hero-images--bg {
    width: 100%;
    display: block;
    height: 100%;
    background-color: var(--off-white);
    overflow: hidden;
    position: relative;
  }

  .ni__hero-images--bg .parbase {
    width: 100%;
    height: 100%;
  }

  .ni__hero-images--container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 275px;
    width: 472px;
    background-image: none;
  }

  .ni__hero-images--src1,
  .ni__hero-images--container .parbase div {
    background-color: transparent;
    background-size: contain;
    background-position: 0 0;
  }

  .ni__hero-images--bg2 {
    z-index: 0;
    background-image: linear-gradient(to bottom, var(--white-three) 53%, var(--off-white));
    padding-left: 72px;
    padding-top: 227px;
    padding-bottom: 35px;
    position: relative;
  }

  .ni__hero-images--src2,
  .ni__hero-images--bg .parbase div > div {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .ni__hero-images {
    p {
      max-width: 312px;
    }
  }

  .ni__hero-images--container {
    width: 774px;
    height: 468px;
  }
}

@include breakpoint('max content') {
  .ni__hero-images > .ni__container {
    max-width: map-get($breakpoints, 'max content');
  }
}
