// settings
$NI__aboutUsHeadline-bg__color: #ccdcc8;
$NI__AboutUsValues-BG-Color: var(--gray-20);

.ni__about-us-headline--bg {
  background-color: $NI__AboutUsValues-BG-Color;
  padding-top: 32px;

  .ni__about-us-headline__h2 {
    @include type-class('.ni__h2');
  }

  .ni__about-us-headline__text {
    @include type-class('.ni__body-text--2');
  }

  .ni__about-us-headline__image {
    width: 332px;
    height: 303px;
    display: block;
    position: relative;
    margin: 64px auto 16px;
    background-size: cover;

    @include media-breakpoint-up(xxxs) {
      width: 320px;
      height: 294px;
    }
  }

  .ni__about-us-headline__wrapper {
    background: $NI__aboutUsHeadline-bg__color;
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

// mobile up
@include breakpoint('small') {
  .ni__about-us-headline--bg {
    min-height: 600px;
  }
}

// tablet up
@include media-breakpoint-up(tablet) {
  .ni__about-us-headline--bg {
    position: relative;

    .ni__about-us-headline__wrapper {
      position: relative;
    }

    .ni__about-us-headline__image {
      position: absolute;
      // bottom: 35px;
      right: 0;
      top: 50%;
      // margin-top: 0;
      margin-bottom: 0;
      width: 431px;
      height: 390px;
      margin-top: -190px;
    }

    .ni__about-us-headline__image .parbase {
      overflow: visible;
    }
  }
}

// desktop up
@include breakpoint('large') {
  .ni__about-us-headline--bg {
    padding-top: 72px;
    padding-bottom: 0;
    z-index: 10;

    .ni__aboutus__number {
      text-align: left;
    }

    .ni__about-us-headline__text {
      max-width: 380px;
    }

    .ni__about-us-headline__wrapper {
      min-height: 950px;
      padding-top: 176px;
      padding-bottom: 176px;
    }

    .ni__about-us-headline__image {
      // bottom: -125px;
      // top: auto;
      width: 611px;
      height: 558px;
      margin-right: 15px;
      margin-top: -230px;
    }
  }
}

@include breakpoint('max content') {
  .ni__about-us-headline--bg {
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}
