// ========================================================================
// Component: Areas of Expertise Carousel
// ========================================================================

//_____________________________________
// settings => misc.
$NI__CarouselExpertise-Background-Color: var(--forrest-green);
$NI__CarouselExpertise-Color: var(--gray-20);
$NI__CarouselExpertise-Min-Height: 630px;
$NI__CarouselExpertise-Content-Padding: 130px 0;
$NI__CarouselExpertise-Content-Max-Width: 1015px;

//_____________________________________
// settings => max width breakpoint / layout change
// this pixel breakpoint is used to determine when the carousel
// functionality changes from the original design, into a centered
// standard carousel. used to prevent odd behavior or 4k/5k screens.
$NI__CarouselExpertise-Max-Width: 1440px;

// Carousel
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise {
  background: $NI__CarouselExpertise-Background-Color;
  color: $NI__CarouselExpertise-Color;
  font-size: 18px;
  margin: 0 auto;
  overflow: hidden;
  width: 100vw;
  border-top-left-radius: 35px;

  @include breakpoint('desktop small') {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    border-top-left-radius: 300px;
  }
}

.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-container {
  transform: translate3d(0px, 0px, 0px);
  margin: 0 auto;
}

.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-container {
  width: 100%;
}

// Carousel => Section Title
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-section-title {
  color: $NI__CarouselExpertise-Color;
  margin: 0 0 50px;
  @include breakpoint('small') { margin: 0 0 65px; }
}

// Carousel => Slider => Item
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-item {
  background: inherit;
  color: inherit;
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-items > li {
  background-color: transparent;
  color: var(--forrest-green);
  width: 100%;
  transition: 200ms ease-in-out;
  transition-property: background-color, color;
  min-height: 100%;
  height: auto;
}

// Card
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card {
  height: 100%;
}

// Card => Image => Container
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card-image-container {
  margin-bottom: 23px;
  @include breakpoint('small') { margin-bottom: 32px; }
}

// Card => Body => Container
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card-body-container {
  padding: 0 20px;

  // prettier-ignore
  @include breakpoint('small') { min-height: 200px; }
  @include display-flex(column nowrap, flex-start, space-between);
}

// Card => Body => Container => Button
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card-body-container {
  .ni__button {
    color: var(--gray-20);
  }
}

// Card => Body => Title
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card-body-container h3 {
  color: $NI__CarouselExpertise-Color;
  margin-bottom: 26px;
}

// Card => Body => Text
// ========================================================================
// prettier-ignore
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__molecule-card-body-container p {
  color: $NI__CarouselExpertise-Color;
  @include breakpoint('small') {
    max-width: 75%;
    margin: 0 auto 0 0;
  }
}

// Carousel => Controls => Bullets
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-bullets {
  height: 12px;
  margin: 0 auto;
  padding: 40px 0 60px;

  @include breakpoint('small') {
    display: block;
  }

  @include breakpoint('desktop') {
    display: none;
  }

  .glide__bullets {
    @include display-flex(row nowrap, center, center);
  }

  .glide__bullets .glide__bullet + .glide__bullet {
    margin-left: 10px;
  }

  .glide__bullet {
    appearance: none;
    background: var(--gray-20);
    border-radius: 50%;
    border: 0;
    height: 8px;
    margin: 0;
    padding: 0;
    width: 8px;

    &.glide__bullet--active {
      background: var(--ni-green);
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

// Carousel => Controls => Arrows
// ========================================================================
.ni__carousel-browse-by-industry.ni__carousel-areas-of-expertise .ni__carousel-controls {
  display: none;
  margin: 37px 0 0;
  width: 100%;

  & > button svg {
    fill: var(--ni-green);
    stroke: var(--ni-green);
  }

  & > button {
    border-color: var(--forrest-green);
    color: var(--ni-green);
  }

  & > button:nth-child(2) {
    margin-left: 10px;
  }

  & > button[disabled] {
    cursor: not-allowed;
    opacity: 0.25;
  }

  // prettier-ignore
  & > button:focus {
    background: var(--ni-green);
    border-color: var(--ni-green);
    & svg g { stroke: white; }
  }

  @include breakpoint('small') {
    @include display-flex(row nowrap, center, flex-start);
  }
}

.ni__carousel-areas-of-expertise {
  h2 {
    margin: 50px 0 20px;
  }
}

// prettier-ignore
.ni__areas-of-expertise {
  background: $NI__CarouselExpertise-Background-Color;
  color: $NI__CarouselExpertise-Color;
  font-size: 18px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  // border radius
  border-top-left-radius: 350px;
  @include breakpoint('large') { border-top-left-radius: 400px; }

  // padding
  padding: 180px 0 160px;
  @include breakpoint('large') { padding: 160px 0 130px; }

  & > .ni__container {
    padding: 0 60px;
    @include breakpoint('large')       { padding: 0 140px; }
    @include breakpoint('max content') { padding: 0 20px; }
  }

  h2 {
    margin: 20px 0 50px;
  }

  li { list-style: none; }
  [class*="eyebrow"] { color: #35b77d; }

  .ni__molecule-card {
    @include display-flex(column nowrap, flex-start, space-between);
    .ni__molecule-card-body-container { height: 100%; }
    h3, p, .ni__button { color: $NI__CarouselExpertise-Color; }
    .ni__button { margin-top: 40px; }

    p {
      max-width: 100%;
      margin: auto 0 0;
    }
  }


  // @include breakpoint('large') {
  // }
}
