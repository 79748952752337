// ========================================================================
// Component: PerspectiveGrid
// ========================================================================
.ni__perspective-grid {
  margin-top: 40px;
  padding-bottom: 63px;

  a {
    text-decoration: none;
  }
  .is-perspective-grid--even,
  [class*='ni__col']:nth-child(even) {
    .ni__perspective-grid--content {
      background-color: var(--pale-green);
    }
    .ni__perspective-grid__title,
    .ni__perspective-grid__text {
      color: var(--forrest-green);
    }
  }
  // create shapes
  .ni__container {
    $w: 52px;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      width: $w;
      height: $w;
      top: -$w;
      background-color: var(--ni-green);
      left: 20px;
      content: '';
    }
    &:before {
      border-top-left-radius: $w;
    }
    &:after {
      border-top-right-radius: $w;
      left: $w + 20;
      background-color: var(--power-green);
    }
  }

  // create overlay animation
  &.is-loading .ni__row {
    position: relative;
    &:after {
      content: '';
      height: calc(100% - 32px);
      width: calc(100% - 40px);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0.7;
      background-color: var(--off-white);
    }
  }

  // perspective articles
  &.is-related-articles {
    .ni__container:before,
    .ni__container:after {
      display: none;
    }

    .ni__perspective-grid__item {
      pointer-events: inherit;
    }

    h2 {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: var(--forrest-green);
      margin-bottom: 45px;
      @include type-class('.ni__h6');
    }
  }

  &.is-page-SI {
    padding: 0 !important;
  }
}

.ni__perspective-grid__link {
  display: block;
}

// Keyframe animation
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// animation
.ni__perspective-grid__item {
  animation: fadeInUp 0.4s linear;
  animation-fill-mode: both;
  overflow-y: hidden;
  pointer-events: none;
  height: 518px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 32px;
}

.ni__perspective-grid__image,
.ni__perspective-grid__item div[style*='background-image'] {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;

  .ni__perspective-article__next100 {
    right: 0;
  }
}

// aem background-image fix
.ni__perspective-grid__item div[style*='background-image'] {
  width: 100%;
  height: 255px;
}

// aem img tag fix
.ni__perspective-grid__item div:first-child > div:first-child > img {
  width: 100%;
  height: 255px;
  object-fit: cover;
}

.ni__perspective-grid__item > div:first-child {
  width: 100%;
  height: 255px;
  display: block;
  top: 0;
  position: absolute;
}

.ni__perspective-grid--content {
  background-color: var(--forrest-green);
  padding: 31px 25px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 263px;
  flex-direction: column;
  transform: translateY(255px);
  position: relative;
  overflow: hidden;
}

.ni__perspective-grid__title {
  color: var(--power-green);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
  width: 100%;
  @include type-class('.ni__h7');
}

.ni__perspective-grid__text {
  font-family: map-get($fonts, 'serif');
  color: var(--power-green);
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 40px 0 0 0;
}

.ni__perspective-grid__load-more {
  padding-bottom: 123px;
  text-align: center;
  .ni__button:focus {
    outline: inherit;
    border-color: #007bff;
  }
}

.ni__perspective-grid__text-wrapper {
  position: relative;
  width: 100%;
  display: none;

  .ni__perspective-grid__text {
    position: relative;
  }

  .ni__button {
    opacity: 0;
    position: absolute;
    margin-top: 50px;
  }
}

.ni__perspective-grid__link article div {
  pointer-events: none;
}

// table up
@include media-breakpoint-up(md) {
  .ni__perspective-grid {
    .ni__container {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.is-loading .ni__row:after {
      width: calc(100% - 30px);
    }
    // .ni__perspective-grid--content {
    //   // justify-content: flex-start;
    // }
  }
}

@include media-breakpoint-up(lg) {
  .ni__perspective-grid {
    padding-bottom: 74px;
  }

  .ni__perspective-grid__load-more {
    padding-bottom: 130px;
  }

  .ni__perspective-grid__text-wrapper {
    display: block;

    .ni__perspective-grid__text {
      position: absolute;
      opacity: 0;
      transition: opacity 300ms ease-out 200ms;
    }
  }

  .ni__perspective-grid__item {
    .ni__perspective-grid--content {
      transition: transform 300ms, height 300ms;
    }

    .ni__perspective-grid__title,
    .ni__perspective-grid__text {
      transition: transform 300ms;
    }
  }

  .ni__perspective-grid__link:hover,
  .ni__perspective-grid__link:focus {
    .ni__perspective-grid__text,
    .ni__button {
      opacity: 1;
      position: relative;
    }
  }

  [lang='en'],
  [data-aem-fallback-locale='en-US'] {
    .ni__perspective-grid__link:hover,
    .ni__perspective-grid__link:focus {
      .ni__perspective-grid__text {
        opacity: 1;
      }

      .ni__perspective-grid__item > div:first-child {
        position: absolute;
      }

      .ni__perspective-grid--content {
        justify-content: flex-start;
        transform: translateY(0);
        height: 100%;
      }

      .ni__perspective-article__next100 {
        display: none;
      }
    }
  }
}
