// Override default ni.css .badge class to avoid clashes with n100 aem wrapper
.badge.dynamic-n100-badge.parbase {
  background: none;
  color: unset;
  display: block;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  min-width: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
}

// Vertical spacing for Clark component (LeadQuoteImage)
// this is a ***TEMPORARY*** solution per this ticket:
// https://organic-inc.atlassian.net/browse/NC-1643
@media (min-width: 720px) and (max-width: 1023px) {
  .content-spotlight-multi-use.parbase + .client-Info.parbase {
    margin: 40px auto 60px;
  }
}

// Overrides padding and alignment in .button.parbase
// components when inside an .ni__ class component
[class*='ni__'] .button.parbase {
  overflow: visible;
  text-align: left;
  padding: 0;
}
