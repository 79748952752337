// ========================================================================
// Components => CTA Cards Four Column Account
// ========================================================================

// prettier-ignore
.ni__cta-cards-four-column-account {
  background: var(--off-white);
  padding: 64px 0;
  @include breakpoint('small') { padding: 80px 0; }
  @include breakpoint('large') { padding: 120px 0; }

  &-title {
    color: var(--forrest-green);
    margin-bottom: 32px;
    @include breakpoint('small') { margin-bottom: 40px; }
    @include breakpoint('desktop') { margin-bottom: 56px; }
  }

  // vertically space columns on mobile
  [class*='ni__col'] + [class*='ni__col'] {
    margin-top: 16px;
    
    // -- increase the margin on tablets
    @include breakpoint('small') {
      margin-top: 20px;
    }

    // -- on desktop, remove space from the top "row" of cols
    // -- but also increase subsequent vertical spacing
    @include breakpoint('desktop') {
      &:nth-child(1),
      &:nth-child(2) { margin-top: 0; }
      margin-top: 32px;
    }

    // -- on large, remove all vertical spacing
    @include breakpoint('large') {
      margin-top: 0;
    }
  }
}
