// ========================================================================
// Component: AboutUSGetThere
// ========================================================================

// settings
$NI__AboutUsHero-BG-Color: var(--off-white);
$NI__AboutUsHero-Color: var(--forrest-green);
$NI__AboutUsHero-Font-Family-Mono: map-get($fonts, 'mono');
$NI__AboutUsHero-Font-Family-Serif: map-get($fonts, 'serif');

.ni__aboutus-getThere {
  margin: 0 auto;
  max-width: 1440px;
  background: $NI__AboutUsHero-BG-Color;

  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }

  .ni__aboutus-getThere__container {
    span {
      &:before {
        content: '';
        border-top: 2px solid black;
        position: absolute;
        width: 200%;
        top: 5px;
        left: calc(-200% - 15px);

        @include media-breakpoint-up(md) {
          top: 10px;
        }

        @include media-breakpoint-up(lg) {
          top: 11px;
        }
      }

      &:after {
        @include media-breakpoint-up(tablet) {
          content: '';
          top: -602px;
          left: 340px;
          border-bottom: 2px solid black;
          border-right: 2px solid black;
          border-bottom-right-radius: 270px;
          position: absolute;
          width: 620px;
          height: 612px;
        }

        @include media-breakpoint-up(md) {
          content: '';
          top: -596px;
          left: 340px;
          border-bottom-right-radius: 290px;
          width: 620px;
          height: 608px;
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }

  .ni__headline-cta {
    max-width: 1440px;
  }

  .ni__headline-cta .ni__headline-cta__headline {
    @include type-class('.ni__h2');
  }
}

.ni__aboutus-getThere .ni__aboutus-getThere_eyebrow {
  background: $NI__AboutUsHero-BG-Color;
  color: $NI__AboutUsHero-Color;
  position: relative;
  float: right;
  @include type-class('.ni__eyebrow--5');

  @include media-breakpoint-up(tablet) {
    float: none;
  }

  @include media-breakpoint-up(lg) {
    float: none;
  }
}

.ni__aboutus-getThere .ni__aboutus-getThere__headline {
  color: $NI__AboutUsHero-Color;
  font-family: $NI__AboutUsHero-Font-Family-Serif;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.25;
  margin: 0 auto 40px;
  padding-top: 63px;

  @include breakpoint('large') {
    font-size: 80px;
    line-height: 1.1;
    padding-top: 176px;
  }
}
