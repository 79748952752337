// ========================================================================
// Component: AboutUSHero
// ========================================================================

.ni__aboutus-peoplefirst {
  margin: 0 auto;
  position: relative;
  background: var(--gray-20);
  z-index: 1;
}

.ni__aboutus-peoplefirst .ni__aboutus-peoplefirst__container {
  background: var(--pale-green);
  padding: 0 0 64px;
  margin: 0 auto;
  position: relative;
  // overflow: hidden;

  @include media-breakpoint-up(tablet) {
    padding-top: 44px;
    padding-bottom: 116px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 72px;
    padding-bottom: 164px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  .ni__container {
    position: relative;
  }
}

.ni__aboutus-peoplefirst .ni__aboutus-peoplefirst_eyebrow {
  color: var(--forrest-green);
  background: var(--pale-green);
  position: relative;
  text-align: right;

  span {
    position: relative;
    @include type-class('.ni__eyebrow--5');

    &:before {
      content: '';
      position: absolute;
      left: calc(-300% - 20px);
      width: 300%;
      border-top: 2px solid black;
      top: 7px;

      @include media-breakpoint-up(lg) {
        border-top: 3px solid black;
        top: 10px;
      }
    }
  }

  @include media-breakpoint-up(tablet) {
    text-align: left;
  }
}

.ni__aboutus__number {
  color: var(--ni-green);
  padding-top: 64px;
  @include type-class('.ni__number-eyebrow--2');

  @include media-breakpoint-up(tablet) {
    padding-top: 106px;
  }

  @include media-breakpoint-up(md) {
    padding-top: 119px;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    padding-top: 210px;
    margin-left: -30px;
  }
}

// h2 and p tag content container
.ni__aboutus-peoplefirst .ni__aboutus-peoplefirst__content {
  position: relative;
  z-index: 10;

  @include media-breakpoint-up(tablet) {
    padding-right: 35px;
  }

  @include media-breakpoint-up(md) {
    padding-right: 195px;
  }

  @include media-breakpoint-up(lg) {
    margin: 266px 0 48px;
    max-width: 697px;
    padding-right: 64px;
    padding-top: 0;
    position: absolute;
    width: 65%;
  }
}

.ni__aboutus-peoplefirst .ni__aboutus-peoplefirst__headline {
  color: var(--forrest-green);
  letter-spacing: normal;
  margin-bottom: 32px;
  @include type-class('.ni__h2');
  white-space: pre-line;
}

.ni__aboutus-peoplefirst__paragraph {
  color: var(--forrest-green);
  width: 100%;
  max-width: 100%;
  @include type-class('.ni__body-text--2');

  @include media-breakpoint-up(lg) {
    max-width: 381px;
    margin: 0 auto 0 0;
  }
}

.ni__aboutus-peoplefirst__imagecontainer {
  display: inline-block;
  margin-top: 20px;
  width: 195px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 31.5px;

  & > div:nth-child(1),
  & > div:nth-child(1) > div {
    width: 100%;
    overflow: visible !important;
  }

  @include media-breakpoint-up(tablet) {
    display: block;
    width: 255px;
    margin: 96px 0 0 0;
    position: absolute;
    top: 0;
    // left: 312px;
    transform: none;
    right: 0;
  }

  @include media-breakpoint-up(lg) {
    width: 633px;
    margin: 200px 0 0 0;
    left: 450px;
    position: relative;
  }

  img {
    width: 100%;

    @include media-breakpoint-up(tablet) {
      width: 100%;
      height: auto;
      z-index: 1;
      display: block;
    }

    @include media-breakpoint-up(md) {
      width: 312px;
      z-index: -1;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}

// Description
.ni__aboutus-peoplefirst__imagecontainer {
  .ni__description {
    position: absolute;
    width: calc(100% + 60px);
    left: -63px;
    bottom: -65px;

    @include media-breakpoint-up(md) {
      left: 0;
    }
  }
}

.ni__aboutus-peoplefirst__imagedecoration {
  height: 63px;
  width: 126px;
  background: var(--forrest-green);
  border-bottom-right-radius: 63px;
  position: absolute;
  bottom: 0;
  left: -63px;

  @include media-breakpoint-up(tablet) {
    height: 70px;
    width: 140px;
    border-bottom-right-radius: 70px;
    transform: rotate(90deg);
    top: 25px;
    right: -34px;
    left: auto;
  }

  @include media-breakpoint-up(md) {
    right: -112px;
  }

  @include media-breakpoint-up(lg) {
    height: 140px;
    width: 280px;
    border-bottom-right-radius: 140px;
    transform: rotate(90deg);
    top: 35px;
    right: -112px;
    left: auto;
  }

  .quartercircle {
    height: 63px;
    width: 63px;
    background: var(--ni-green);
    border-bottom-left-radius: 63px;
    position: absolute;

    @include media-breakpoint-up(tablet) {
      height: 70px;
      width: 70px;
      border-bottom-left-radius: 70px;
    }

    @include media-breakpoint-up(lg) {
      height: 140px;
      width: 140px;
      border-bottom-left-radius: 140px;
    }
  }
}
