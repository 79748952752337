// ========================================================================
// Component: Perspective
// ========================================================================

// table up
@include media-breakpoint-up(md) {
  .ni__container-article {
    max-width: 1023px;
  }

  .ni__float-content {
    position: relative;
    width: 100%;
    display: block;
  }
  .ni__float-content {
    > div,
    section {
      position: absolute;
      right: 0;
      top: 0;
    }
    &.is-align--middle {
      > div,
      section {
        margin-top: -30%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .ni__container-article {
    max-width: map-get($container-max-widths, lg);
  }
}
